export const errorHandler = (errorsObj, translate) => {
  if (errorsObj?.message) {
    if (errorsObj.message === "Wrong credentials provided") {
      return "Невірний логін або пароль";
    }
    if (errorsObj.message === "Unauthorized") {
      return "Потрібно авторизуватись";
    }
    if (
      errorsObj.message &&
      errorsObj?.message?.includes("The account without a corporate email")
    ) {
      return "Акаунт має не корпоративну пошту!";
    }
    // if (errorsObj.info.user === "User doesn't exists") {
    //   return errorsObj;
    // } else if (errorsObj.info?.verify === 'No such temporary key exists') {
    //   return 'Некоректний тимчасовий ключ';
    // } else if (
    //   errorsObj.info?.email &&
    //   errorsObj.info?.email.includes('There is an account with that email address')
    // ) {
    //   return 'Акаунт з такою поштою вже існує';
    // } else if (errorsObj.info?.message === 'There is an account with that nickname') {
    //   return 'Акаунт з таким нікнеймом вже існує';
    // } else if (
    //   errorsObj.info?.nickname &&
    //   errorsObj.info?.nickname.includes('There is an account with that nickname')
    // ) {
    //   return 'Користувач з таким нікнеймом, вже зареєстрований';
    // } else if (
    //   errorsObj.info?.name &&
    //   !errorsObj.info?.surname &&
    //   errorsObj.info?.name.includes('Name is not correct')
    // ) {
    //   return "Вказано некоректне ім'я";
    // } else if (
    //   errorsObj.info?.surname &&
    //   !errorsObj.info?.name &&
    //   errorsObj.info?.surname.includes('Surname is not correct')
    // ) {
    //   return 'Вказано некоректне прізвище';
    // } else if (
    //   errorsObj.info?.surname &&
    //   errorsObj.info?.surname.includes('Surname is not correct') &&
    //   errorsObj.info?.name &&
    //   errorsObj.info?.name.includes('Name is not correct')
    // ) {
    //   return "Вказано некоректне прізвище та ім'я";
    // } else if (errorsObj.info?.message === 'Invalid email or password') {
    //   return 'Не вірний логін або пароль';
    // } else if (!errorsObj.info?.auth && errorsObj?.info.includes('There is no user with email')) {
    //   return 'Така електронна пошта не зареєстрована';
    // } else if (errorsObj.info?.auth === 'Account is not activated') {
    //   return 'Акаунт не активований! Перевірте електронну пошту.';
    // }
  } else {
    return errorsObj;
  }
};
