/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ApiService from "../../helpers/apiHelper";

// Data
import { dateTimeFormattingByString } from "helpers/date-helpers";
// import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";
import { useEffect, useState } from "react";

// Dashboard components

function Dashboard() {
  // const { sales, tasks } = reportsLineChartData;
  const [userStats, setUserStats] = useState(null);
  const [postsStats, setPostsStats] = useState(null);
  const [servicesStats, setServicesStats] = useState(null);
  const apiUsers = new ApiService("auth/admin/get-statistic");
  const apiPosts = new ApiService("posts/post/statistic");
  const apiServices = new ApiService("posts/service/statistic");
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      apiUsers.getAll().then((res) => {
        setUserStats(res.result);
      });
      apiPosts.getAll().then((res) => {
        setPostsStats(res.result);
      });
      apiServices.getAll().then((res) => {
        setServicesStats(res.result);
      });
    }
    return () => (mounted = false);
    // eslint-disable-next-line
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="personadd"
                title="Користувачі"
                count={userStats?.countUsers ? userStats?.countUsers : 0}
                countActive={{
                  title: "Активні користувачі",

                  count: userStats?.numberOfActiveUsers
                    ? userStats?.numberOfActiveUsers
                    : 0,
                }}
                percentage={{
                  color: "success",
                  amount: `${
                    userStats?.newRegisteredUsersForOneMonth
                      ? userStats?.newRegisteredUsersForOneMonth
                      : 0
                  }`,
                  label: "за останній місяць",
                }}
                percentage2={{
                  color: "success",
                  amount: `${
                    userStats?.newRegisteredUsersForThreeMonth
                      ? userStats?.newRegisteredUsersForThreeMonth
                      : 0
                  }`,
                  label: "за останні три місяці",
                }}
                percentage3={{
                  color: "success",
                  amount: `${
                    userStats?.newRegisteredUsersForOneYear
                      ? userStats?.newRegisteredUsersForOneYear
                      : 0
                  }`,
                  label: "за останній рік",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="comment"
                title="Пости"
                color="warning"
                count={
                  postsStats?.numberOfAllPosts
                    ? postsStats?.numberOfAllPosts
                    : 0
                }
                percentage={{
                  color: "success",
                  amount: `${
                    postsStats?.numberOfNewPostsForOneMonth
                      ? postsStats?.numberOfNewPostsForOneMonth
                      : 0
                  }`,
                  label: "за останній місяць",
                }}
                percentage2={{
                  color: "success",
                  amount: `${
                    postsStats?.numberOfNewPostsForThreeMonth
                      ? postsStats?.numberOfNewPostsForThreeMonth
                      : 0
                  }`,
                  label: "за останні три місяці",
                }}
                percentage3={{
                  color: "success",
                  amount: `${
                    postsStats?.numberOfNewPostsForOneYear
                      ? postsStats?.numberOfNewPostsForOneYear
                      : 0
                  }`,
                  label: "за останній рік",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                // icon="store"
                icon="storefront"
                title="Послуги"
                count={
                  servicesStats?.numberOfAllService
                    ? servicesStats?.numberOfAllService
                    : 0
                }
                percentage={{
                  color: "success",
                  amount: `${
                    servicesStats?.numberOfNewServicesForOneMonth
                      ? servicesStats?.numberOfNewServicesForOneMonth
                      : 0
                  }`,
                  label: "за останній місяць",
                }}
                percentage2={{
                  color: "success",
                  amount: `${
                    servicesStats?.numberOfNewServicesForThreeMonth
                      ? servicesStats?.numberOfNewServicesForThreeMonth
                      : 0
                  }`,
                  label: "за останні три місяці",
                }}
                percentage3={{
                  color: "success",
                  amount: `${
                    servicesStats?.numberOfNewServicesForOneYear
                      ? servicesStats?.numberOfNewServicesForOneYear
                      : 0
                  }`,
                  label: "за останній рік",
                }}
              />
            </MDBox>
          </Grid>
          {/* <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Followers"
                count="+91"
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid> */}
        </Grid>
        <Grid container spacing={3} sx={{ mt: 2 }}>
          {/* <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="warning"
                icon="personadd"
                title="Активні користувачі"
                count={
                  userStats?.numberOfActiveUsers
                    ? userStats?.numberOfActiveUsers
                    : 0
                }
                // percentage={{
                //   color: "success",
                //   amount: `${
                //     userStats?.newRegisteredUsersForOneMonth
                //       ? userStats?.newRegisteredUsersForOneMonth
                //       : 0
                //   }`,
                //   label: "за останній місяць",
                // }}
              />
            </MDBox>
          </Grid> */}
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={3}>
              <ReportsBarChart
                color="info"
                title="Кількість преміум підписок"
                description="Порівняльний графік"
                date={dateTimeFormattingByString(new Date())}
                chart={{
                  labels: ["Преміум 1м", "Преміум 3м", "Преміум рік"],
                  datasets: {
                    label: "Кількість активних",
                    data: [
                      userStats?.numberOfUsersWithMonthPremium
                        ? userStats?.numberOfUsersWithMonthPremium
                        : 0,
                      userStats?.numberOfUsersWithThreeMonthPremium
                        ? userStats?.numberOfUsersWithThreeMonthPremium
                        : 0,
                      userStats?.numberOfUsersWithYearPremium
                        ? userStats?.numberOfUsersWithYearPremium
                        : 0,
                    ],
                  },
                }}
              />
            </MDBox>
          </Grid>
          {/* <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Followers"
                count="+91"
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid> */}
        </Grid>
        {/* <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="website views"
                  description="Last Campaign Performance"
                  date="campaign sent 2 days ago"
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="daily sales"
                  description={
                    <>
                      (<strong>+15%</strong>) increase in today sales.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="completed tasks"
                  description="Last Campaign Performance"
                  date="just updated"
                  chart={tasks}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox> */}
        {/* <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <Projects />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OrdersOverview />
            </Grid>
          </Grid>
        </MDBox> */}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
