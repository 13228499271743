import { useState } from "react";

//Material
import CloseIcon from "@mui/icons-material/Close";
import { Box, Modal } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

// import { useTranslate } from 'react-redux-multilingual';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import ApiService from "../../helpers/apiHelper";
import { setIsOpenSupportAnswerModal } from "../../redux/mainReducer";
// import './AuthModal.css';

const style = {
  display: "flex",
  // flexDirection: 'column',
  justifyContent: "center",
  position: "relative !important",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: { xs: "100%", sm: "80%", md: "570px", lg: "570px" },
  backgroundColor: "white !important",
  borderRadius: "20px",
  border: "4px solid #66BB6A",
  boxShadow: 24,
  padding: {
    xs: "20px",
    sm: "20px",
    md: "50px 50px 20px",
    lg: "60px 60px 30px",
  },
  // overflow: "scroll",
  maxHeight: { xs: "96vh", sm: "90vh", md: "80vh", lg: "90vh" },
  scrollbarWidth: "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
};

function SupportAnswerModal({ isOpenSupportAnswerModal }) {
  const userData = useSelector((state) => state?.global?.userData);
  const answerApi = new ApiService("mail/send-message");
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const handleSubmit = () => {
    answerApi
      .sendRequest({
        name: `${userData?.name ? userData?.name : ""} ${
          userData?.surname ? userData?.surname : ""
        }`,
        subject: `${isOpenSupportAnswerModal?.data?.subject}-${isOpenSupportAnswerModal?.data?.id}`,
        contact: `user: ${isOpenSupportAnswerModal?.data?.email}`,
        text: message,
      })
      .then((res) => {
        dispatch(setIsOpenSupportAnswerModal({ isOpen: false, data: null }));
      })
      .catch((error) => console.error(error));
  };

  return (
    <div>
      <Modal
        open={isOpenSupportAnswerModal?.isOpen}
        onClose={(event, reason) => {
          if (reason === "backdropClick") {
            return;
          }
          dispatch(setIsOpenSupportAnswerModal({ isOpen: false, data: null }));
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="finlab_auth_modal"
      >
        <Box sx={style} className="finlab_auth_modal-box">
          <MDBox
            sx={{ position: "absolute", top: "-15px", left: "40px" }}
            variant="gradient"
            bgColor="success"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-2}
            p={1}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h5" fontWeight="medium" color="white" m={1}>
              Відповідь на запит
            </MDTypography>
          </MDBox>
          <CloseIcon
            className="finlab_exchange_modal-close"
            style={{
              position: "absolute",
              right: "10px",
              top: "10px",
              cursor: "pointer",
              color: "black",
              zIndex: "99",
            }}
            onClick={() => {
              dispatch(
                setIsOpenSupportAnswerModal({ isOpen: false, data: null })
              );
            }}
          />

          {/* <Grid
            container
            // spacing={2}
            sx={{
              display: "flex",
              // alignContent: 'center',
              flexDirection: "column",

              // flexDirection: { xs: 'column-reverse', sm: 'column', md: 'column', lg: 'column' }
            }}
          >
            TEST
          </Grid> */}
          <MDBox pt={4} pb={3} px={3} sx={{ width: "100%" }}>
            <MDTypography
              variant="h5"
              fontWeight="medium"
              color="black"
              m={1}
              textAlign="center"
            >
              Надайте вашу відповідь
            </MDTypography>
            <MDInput
              multiline
              sx={{ width: "100%" }}
              minRows={6}
              maxRows={6}
              value={message}
              onChange={(event) => setMessage(event?.target?.value)}
            />
            <MDBox mt={4} mb={1}>
              <MDButton
                disabled={message.length <= 0 ? true : false}
                onClick={handleSubmit}
                variant="gradient"
                color="success"
                fullWidth
              >
                Відповісти
              </MDButton>
            </MDBox>
            <MDBox mt={2} mb={0}>
              <MDButton
                onClick={() => {
                  dispatch(
                    setIsOpenSupportAnswerModal({ isOpen: false, data: null })
                  );
                }}
                variant="gradient"
                color="error"
                fullWidth
              >
                Відмінити
              </MDButton>
            </MDBox>
          </MDBox>
        </Box>
      </Modal>
    </div>
  );
}

export default SupportAnswerModal;
