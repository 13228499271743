
// material

import ClearIcon from '@mui/icons-material/Clear';
import { Box, TextField } from '@mui/material';
import { connect, useDispatch } from 'react-redux';
import expandIcon from '../../assets/arrowExpand.png';
// components

import { useEffect, useState } from 'react';
import { setIsOpenSearchBar } from '../../redux/mainReducer';
import './index.css';

//

// ----------------------------------------------------------------------

function SearchBar({ isOpenSearchBar, func }) {

  const [value, setValue] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
   
    // eslint-disable-next-line
    return ()=>dispatch(setIsOpenSearchBar(false))
    // eslint-disable-next-line
  }, []);
  
  return (
    <Box
      id="searchNav"
      className={`header-search-menu ${isOpenSearchBar ? 'header-search-openMenu' : ''}`}
    >
      <TextField
        style={{ marginLeft: '50%', marginRight: '20px' }}
        sx={{
          input: {
            // fontFamily: 'Kyiv-Sans-Light',
            color:'white !important',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '19px',
            letterSpacing: '0em',
            textAlign: 'left',
            '&::placeholder': {
              // fontFamily: 'Kyiv-Sans-Light',
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '19px',
              letterSpacing: '0em',
              textAlign: 'left',
              color: 'white !important'
              // <----- Add this.
              // opacity: 1,
            }
          }
        }}
        placeholder="Введіть свій пошуковий запит"
        variant="standard"
        fullWidth
        value={value}
        onChange={(e) => (e.target?.value !== null ? setValue(e.target.value) : setValue(''))}
        InputProps={{
          endAdornment: (
            <>{isOpenSearchBar && value.length!==0&&<ClearIcon sx={{cursor:'pointer', ":hover":{
              color:'#60A5DF'
            }}} onClick={()=>{
              func('')
              setValue('')
              
            }}/> }<div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: 'fit-content',
                cursor: 'pointer'
              }}
              onClick={() => {
                func(value)
              }}
            >
              {isOpenSearchBar && (
                <img alt="arrow" src={expandIcon} style={{ width: '20px', margin: '5px' }} />
              )}
            </div></>
           
          )
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            func(value)
          }
        }}
      />
    </Box>
  );
}
const mapStateToProps = (state) => {
  return {

    isOpenSearchBar: state.global.isOpenSearchBar
  };
};
export default connect(mapStateToProps)(SearchBar);
