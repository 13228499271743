/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import propTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// Data to delete
// import currencysTableData from "layouts/tables/data/currencysTableData";

import {
  Button,
  Menu,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import MDBadge from "components/MDBadge";
import ApiService from "../../../helpers/apiHelper";

import { useEffect, useState } from "react";
// eslint-disable-next-line
import SearchBar from "components/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import { setIsOpenSearchBar } from "redux/mainReducer";

import { Spinner } from "components/Spinner";
import { dateTimeFormattingByString } from "helpers/date-helpers";
import { useSnackbar } from "notistack";
import { setIsOpenPremiumSetterModal } from "redux/mainReducer";
function createTableData(
  id,
  nickname,
  subscriptionType,
  nextSubscriptionType,
  subscriptionStatus,
  amount,
  creationDate,
  paymentTo,
  cardMask,
  userId
) {
  return {
    id,
    nickname,
    subscriptionType,
    nextSubscriptionType,
    subscriptionStatus,
    amount,
    creationDate,
    paymentTo,
    cardMask,
    userId,
  };
}
function Row({ row, editRow, userData }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const getType = (type) => {
    if (type) {
      if (type === "FREE_TRIAL_PREMIUM") {
        return 'Пробна "Місяць"';
      } else if (type === "TEMP_PREMIUM") {
        return "Тимчасова";
      } else if (type === "MONTH_PREMIUM") {
        return 'Преміум "Місяць"';
      } else if (type === "THREE_MONTH_PREMIUM") {
        return 'Преміум "3 Місяці"';
      } else {
        return 'Преміум "Рік"';
      }
    }
  };
  const badgeContent = () => {
    if (row?.subscriptionStatus) {
      if (row?.subscriptionStatus === "FREEZE") {
        return "Заморожена";
      } else if (row?.subscriptionStatus === "FREEZE_BLOCKED") {
        return "Заморожена";
      } else if (row?.subscriptionStatus === "CANCELED") {
        return "Скасована";
      } else {
        return "Активна";
      }
    }
  };
  const badgeColor = () => {
    if (row?.subscriptionStatus) {
      if (row?.subscriptionStatus === "FREEZE") {
        return "info";
      } else if (row?.subscriptionStatus === "FREEZE_BLOCKED") {
        return "error";
      } else if (row?.subscriptionStatus === "CANCELED") {
        return "error";
      } else {
        return "success";
      }
    }
  };
  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      width: "300px",
      maxWidth: "500px",
    },
  });
  return (
    <>
      <TableRow
        sx={{
          "& > *": {
            borderBottom: "unset",
            backgroundColor:
              row?.subscriptionStatus === "FREEZE_BLOCKED" &&
              "rgba(245, 151, 151, 0.28)",
          },
        }}
      >
        <TableCell align="left">
          <CustomWidthTooltip
            title={row.id}
            placement="right"
            sx={{ width: "10px" }}
          >
            <Typography
              sx={({ typography: { size, fontWeightBold } }) => ({
                fontSize: size.xxs,
                fontWeight: fontWeightBold,
                textTransform: "uppercase",
                ":hover": {
                  color: "lightblue",
                  cursor: "pointer",
                },
                width: "fit-content",
              })}
            >
              Показати
            </Typography>
          </CustomWidthTooltip>
        </TableCell>
        <TableCell
          align="center"
          sx={({ typography: { size, fontWeightBold } }) => ({
            fontSize: size.xxs,
            fontWeight: fontWeightBold,
            textTransform: "uppercase",
            // width: cell.width,
          })}
        >
          <Typography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xxs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {row.nickname}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xxs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {getType(row.subscriptionType)}
          </Typography>
          {row.nextSubscriptionType ? (
            <Typography
              sx={({ typography: { size, fontWeightLight } }) => ({
                fontSize: size.xxs,
                fontWeight: fontWeightLight,
                textTransform: "capitalize",
              })}
            >
              {`Наступна: ${getType(row.nextSubscriptionType)}`}
            </Typography>
          ) : null}
        </TableCell>
        <TableCell
          align="center"
          sx={({ typography: { size, fontWeightBold } }) => ({
            fontSize: size.xxs,
            fontWeight: fontWeightBold,
            textTransform: "uppercase",
            // width: cell.width,
          })}
        >
          <Typography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xxs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {dateTimeFormattingByString(row.creationDate)}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xxs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {dateTimeFormattingByString(row.paymentTo)}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xxs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {`${row.amount} грн`}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xxs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            <MDBadge
              badgeContent={badgeContent()}
              color={badgeColor()}
              variant="gradient"
              size="sm"
              style={{
                marginLeft: 0,
                width: 80,
                justifyContent: "center",
              }}
            />
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <MoreVertIcon />
          </Button>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {userData && userData?.role === "ADMIN" ? (
              <MenuItem
                onClick={() => {
                  editRow(row, "premiumProlongation");
                  handleClose();
                }}
              >
                <WorkspacePremiumOutlinedIcon
                  sx={{
                    fontSize: "16px !important",
                    cursor: "pointer",
                    mr: 1,
                  }}
                />
                Продовжити преміум
              </MenuItem>
            ) : null}

            {/* {userData && userData?.role === "ADMIN" ? (
              <MenuItem
                onClick={() => {
                  editRow(row, "premiumRemove");
                  handleClose();
                }}
              >
                <WorkspacePremiumOutlinedIcon
                  sx={{
                    fontSize: "16px !important",
                    cursor: "pointer",
                    mr: 1,
                  }}
                />
                Забрати преміум
              </MenuItem>
            ) : null} */}
          </Menu>
        </TableCell>
      </TableRow>
    </>
  );
}

function TableSubscriptions() {
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line
  const [selectedRow, setSelectedRow] = useState(null);
  const [pagesCount, setPagesCount] = useState(0);
  const [offset, setOffset] = useState(0);
  // eslint-disable-next-line
  const userData = useSelector((state) => state.global.userData);
  const isOpenSearchBar = useSelector((state) => state.global.isOpenSearchBar);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const apiGetSubscriptions = new ApiService("auth/admin/search-subscriptions");

  const apiPremiumSetter = new ApiService(
    "auth/admin/create-premium-subscription"
  );
  const apiPremiumSetterRemove = new ApiService(
    "auth/admin/remove-premium-subscription"
  );

  const tableCells = [
    { header: "id", align: "left", width: "5%" },
    { header: "Нікнейм", align: "center" },
    { header: "Тип тарифу", align: "center" },
    { header: "Дата початку", align: "center" },
    { header: "Дата наст.оплати", align: "center" },
    { header: "Сума", align: "center" },
    { header: "Статус", align: "center" },
    { header: "Дії", align: "center", width: "5%" },
  ];
  const [rows, setRows] = useState([]);

  const handleGetSubscriptions = () => {
    setIsLoading(true);
    if (userData?.role === "ADMIN") {
      apiGetSubscriptions
        .getAll({ startWith: offset, count: 10 })
        .then((res) => {
          const rows = res.result.map((row) => {
            return createTableData(
              row.id,
              row.nickname,
              row.subscriptionType,
              row.nextSubscriptionType,
              row.subscriptionStatus,
              row.amount,
              row.creationDate,
              row.paymentTo,
              row.cardMask,
              row.userId
            );
          });
          setRows(rows);
          setPagesCount(
            Number.isInteger(res.count / 10)
              ? res.count / 10
              : Math.floor(+(res.count / 10)) + 1
          );
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          enqueueSnackbar("Сталась помилка!", { variant: "error" });
          setIsLoading(false);
        });
    }
  };
  const searchFunction = (searchValue) => {
    setIsLoading(true);
    if (searchValue && searchValue.length !== 0) {
      if (userData?.role === "ADMIN") {
        apiGetSubscriptions
          .getAll({
            startWith: 0,
            count: 10,
            searchString: searchValue ? searchValue : "",
          })
          .then((res) => {
            const rows = res.result.map((row) => {
              return createTableData(
                row.id,
                row.nickname,
                row.subscriptionType,
                row.nextSubscriptionType,
                row.subscriptionStatus,
                row.amount,
                row.creationDate,
                row.paymentTo,
                row.cardMask,
                row.userId
              );
            });
            setRows(rows);
            setPagesCount(
              Number.isInteger(res.count / 10)
                ? res.count / 10
                : Math.floor(+(res.count / 10)) + 1
            );
            setIsLoading(false);
          })
          .catch((error) => {
            console.error(error);
            setIsLoading(false);
          });
      }
    } else {
      handleGetSubscriptions();
    }
  };

  const premiumSetter = (id, date = null) => {
    if (date) {
      apiPremiumSetter
        .postDataQuery({ userId: id, paymentTo: date })
        .then((res) => {
          setSelectedRow(null);
          handleGetSubscriptions();
          dispatch(
            setIsOpenPremiumSetterModal({
              isOpen: false,
              func: null,
              id: null,
              isPremium: false,
              endDate: null,
            })
          );
        })
        .catch((error) => console.error(error));
    } else {
      apiPremiumSetterRemove
        .postDataQuery({ userId: id })
        .then((res) => {
          setSelectedRow(null);
          handleGetSubscriptions();
          dispatch(
            setIsOpenPremiumSetterModal({
              isOpen: false,
              func: null,
              id: null,
              isPremium: false,
              endDate: null,
            })
          );
        })
        .catch((error) => console.error(error));
    }
  };

  const handleEditRow = (row, reason) => {
    if (reason === "premiumProlongation") {
      dispatch(
        setIsOpenPremiumSetterModal({
          isOpen: true,
          func: premiumSetter,
          id: row?.userId,
          isPremium: false,
          endDate: row?.paymentTo,
        })
      );
    }
    if (reason === "premiumRemove") {
      dispatch(
        setIsOpenPremiumSetterModal({
          isOpen: true,
          func: premiumSetter,
          id: row?.userId,
          isPremium: true,
          endDate: row?.paymentTo,
        })
      );
    }
  };
  useEffect(() => {
    handleGetSubscriptions();
    // dispatch(setIsOpenSearchBar(true));
    // eslint-disable-next-line
  }, [offset]);
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="success"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Підписки
                </MDTypography>
                <SearchBar func={searchFunction} />
                <SearchIcon
                  sx={{
                    color: "white !important",
                    cursor: "pointer !important",
                  }}
                  onClick={() => dispatch(setIsOpenSearchBar(!isOpenSearchBar))}
                />
                {/* <Tooltip title="Додати валюту">
                  <AddIcon
                    onClick={() => console.log("CURRENCY ADD")}
                    sx={{
                      color: "white !important",
                      fontSize: "24px !important",
                      cursor: "pointer",
                      ":hover": {
                        color: "black !important",
                      },
                    }}
                  />
                </Tooltip> */}
              </MDBox>
              <MDBox pt={2}>
                {/* <DataTable
                  // canSearch
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                /> */}

                {!isLoading ? (
                  <>
                    {" "}
                    <TableContainer
                      component={Paper}
                      sx={{ borderRadius: "0px !important", mt: 0 }}
                    >
                      <Table>
                        <TableHead
                          sx={{
                            borderRadius: "0px !important",
                            display: "table-header-group",
                          }}
                        >
                          <TableRow>
                            {tableCells.map((cell, index) => (
                              <TableCell
                                key={cell.header + cell + index}
                                align={cell.align}
                                sx={({
                                  typography: { size, fontWeightBold },
                                }) => ({
                                  fontSize: size.xxs,
                                  fontWeight: fontWeightBold,
                                  textTransform: "uppercase",
                                  width: cell.width,
                                })}
                              >
                                {cell.header}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row) => (
                            <Row
                              key={row.id + row.tikker}
                              row={row}
                              editRow={handleEditRow}
                              refreshData={handleGetSubscriptions}
                              userData={userData}
                            />
                          ))}
                        </TableBody>
                      </Table>
                      <Pagination
                        sx={{ display: "flex", justifyContent: "end", m: 1 }}
                        defaultPage={1}
                        siblingCount={0}
                        count={pagesCount}
                        shape="rounded"
                        page={offset / 10 + 1}
                        onChange={(event, value) => setOffset(value * 10 - 10)}
                      />
                    </TableContainer>
                  </>
                ) : (
                  <div style={{ marginTop: "10%", marginBottom: "10%" }}>
                    <Spinner type="small" />
                  </div>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
Row.propTypes = {
  row: propTypes.object,
};
export default TableSubscriptions;
