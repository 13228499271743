/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import propTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import SearchIcon from "@mui/icons-material/Search";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// Data to delete
// import currencysTableData from "layouts/tables/data/currencysTableData";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import {
  Button,
  Menu,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  styled,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import { useEffect, useState } from "react";
// eslint-disable-next-line
import SearchBar from "components/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import { setIsOpenSearchBar } from "redux/mainReducer";

import MDBadge from "components/MDBadge";
import { Spinner } from "components/Spinner";
import { dateTimeFormattingByString } from "helpers/date-helpers";
import { useSnackbar } from "notistack";
import ApiService from "../../../helpers/apiHelper";
import { setIsOpenSupportAnswerModal } from "redux/mainReducer";
function createTableData(
  id,
  creationDate,
  name,
  email,
  subject,
  priority,
  supportStatus,
  templateData
) {
  return {
    id,
    creationDate,
    name,
    email,
    subject,
    priority,
    supportStatus,
    templateData,
  };
}
function Row({ row, editRow, enqueueSnackbar, dispatch }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const badgeContent = () => {
    if (row.priority === "BASIC") {
      return "Базовий";
    } else if (row.priority === "PREMIUM") {
      return "Преміум";
    }
  };
  const badgeColor = () => {
    if (row.priority === "PREMIUM") {
      return "warning";
    } else if (row.priority === "BASIC") {
      return "success";
    }
  };
  const badgeContentStatus = () => {
    if (row.supportStatus === "NEW") {
      return "Нове";
    } else if (row.supportStatus === "PROCESSING") {
      return "В обробці";
    } else {
      return "Завершено";
    }
  };
  const badgeColorStatus = () => {
    if (row.supportStatus === "NEW") {
      return "info";
    } else if (row.supportStatus === "PROCESSING") {
      return "warning";
    } else {
      return "success";
    }
  };
  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      width: "300px",
      maxWidth: "500px",
    },
  });
  const CustomWidthTooltipMail = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      width: "fit-content",
      maxWidth: "500px",
    },
  });
  const CustomWidthTooltipDesc = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      width: "300px",
      maxWidth: "500px",
    },
  });

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell align="left">
          <CustomWidthTooltip
            title={row.id}
            placement="right"
            sx={{ width: "10px" }}
          >
            <Typography
              sx={({ typography: { size, fontWeightBold } }) => ({
                fontSize: size.xxs,
                fontWeight: fontWeightBold,
                textTransform: "uppercase",
                ":hover": {
                  color: "lightblue",
                  cursor: "pointer",
                },
                width: "fit-content",
              })}
            >
              Показати
            </Typography>
          </CustomWidthTooltip>
        </TableCell>
        <TableCell
          align="center"
          sx={({ typography: { size, fontWeightBold } }) => ({
            fontSize: size.xxs,
            fontWeight: fontWeightBold,
            textTransform: "uppercase",
            // width: cell.width,
          })}
        >
          <Typography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xxs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {dateTimeFormattingByString(row.creationDate)}
          </Typography>
        </TableCell>
        <TableCell
          align="center"
          sx={({ typography: { size, fontWeightBold } }) => ({
            fontSize: size.xxs,
            fontWeight: fontWeightBold,
            textTransform: "uppercase",
            // width: cell.width,
          })}
        >
          <Typography
            sx={({ typography: { size, fontWeightLight } }) => ({
              fontSize: size.xxs,
              fontWeight: fontWeightLight,
              textTransform: "uppercase",
            })}
          >
            {`${row.name}`}
          </Typography>

          <CustomWidthTooltipMail
            title="Скопіювати"
            placement="right"
            sx={{ width: "10px" }}
          >
            <Typography
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                enqueueSnackbar("Електронну пошту скопійовано!", {
                  variant: "success",
                });
                navigator.clipboard.writeText(row.email);
              }}
              sx={({ typography: { size, fontWeightBold } }) => ({
                fontSize: size.xxs,
                fontWeight: fontWeightBold,
                textTransform: "uppercase",
                ":hover": {
                  color: "lightblue",
                  cursor: "pointer",
                },
                width: "fit-content",
                m: "0 auto",
              })}
            >
              {`${row.email}`}
            </Typography>
          </CustomWidthTooltipMail>
        </TableCell>
        <TableCell
          align="center"
          sx={({ typography: { size, fontWeightBold } }) => ({
            fontSize: size.xxs,
            fontWeight: fontWeightBold,
            textTransform: "uppercase",
            // width: cell.width,
          })}
        >
          <Typography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xxs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
              display: "flex",
              alignItems: "baseline",
            })}
          >
            {" "}
            {row?.subject && row?.subject.length <= 40 ? (
              <span
                style={{
                  textTransform: "capitalize",
                  fontWeight: "normal",
                  fontSize: "11px",
                  lineHeight: "11px",
                  marginLeft: "0px",
                }}
              >
                {`${row?.subject}`}
              </span>
            ) : (
              <CustomWidthTooltipDesc
                title={row.subject}
                placement="right"
                sx={{ width: "10px" }}
              >
                <Typography
                  sx={({ typography: { size, fontWeightBold } }) => ({
                    fontSize: size.xxs,
                    fontWeight: fontWeightBold,
                    textTransform: "uppercase",
                    ":hover": {
                      color: "lightblue",
                      cursor: "pointer",
                    },
                    width: "fit-content",
                    ml: 0,
                  })}
                >
                  Показати
                </Typography>
              </CustomWidthTooltipDesc>
            )}
          </Typography>

          {/* <CustomDescTooltip title={row.text} placement="left">
            <Typography
              sx={({ typography: { size, fontWeightBold } }) => ({
                fontSize: size.xxs,
                fontWeight: fontWeightBold,
                textTransform: "uppercase",
                width: "fit-content",
                margin: "0 auto",
              })}
            >
              {row.text}
             
            </Typography>
          </CustomDescTooltip> */}
        </TableCell>
        <TableCell
          align="center"
          sx={({ typography: { size, fontWeightBold } }) => ({
            fontSize: size.xxs,
            fontWeight: fontWeightBold,
            textTransform: "uppercase",
            // width: cell.width,
          })}
        >
          <Typography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xxs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            <MDBadge
              badgeContent={badgeContent()}
              color={badgeColor()}
              variant="gradient"
              size="sm"
              style={{
                marginLeft: 0,
                width: 80,
                justifyContent: "center",
              }}
            />
          </Typography>
          {/* {row.priority} */}
          {/* <CustomDescTooltip title={row.text} placement="left">
            <Typography
              sx={({ typography: { size, fontWeightBold } }) => ({
                fontSize: size.xxs,
                fontWeight: fontWeightBold,
                textTransform: "uppercase",
                width: "fit-content",
                margin: "0 auto",
              })}
            >
              {row.text}
             
            </Typography>
          </CustomDescTooltip> */}
        </TableCell>
        <TableCell
          align="center"
          sx={({ typography: { size, fontWeightBold } }) => ({
            fontSize: size.xxs,
            fontWeight: fontWeightBold,
            textTransform: "uppercase",
            // width: cell.width,
          })}
        >
          <Typography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xxs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            <MDBadge
              badgeContent={badgeContentStatus()}
              color={badgeColorStatus()}
              variant="gradient"
              size="sm"
              style={{
                marginLeft: 0,
                width: 80,
                justifyContent: "center",
              }}
            />
          </Typography>
        </TableCell>

        <TableCell align="center">
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <MoreVertIcon />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              id="basic-button-2"
              aria-controls={open2 ? "basic-menu-2" : undefined}
              aria-haspopup="true"
              aria-expanded={open2 ? "true" : undefined}
              onClick={handleClick2}
            >
              <PublishedWithChangesIcon
                sx={{ fontSize: "16px !important", cursor: "pointer", mr: 1 }}
              />
              Змінити статус
            </MenuItem>
            {/* {row?.supportStatus === "PROCESSING" ? ( */}
            <MenuItem
              onClick={() => {
                // window.open(
                //   `https://mail.google.com/mail/u/0/#search/${row?.subject}`
                // );
                dispatch(
                  setIsOpenSupportAnswerModal({
                    isOpen: true,
                    data: row?.templateData,
                  })
                );
                handleClose();
              }}
            >
              <QuestionAnswerIcon
                sx={{ fontSize: "16px !important", cursor: "pointer", mr: 1 }}
              />
              Відповісти
            </MenuItem>
            {/* ) : null} */}
          </Menu>
          <Menu
            id="basic-menu-2"
            anchorEl={anchorEl2}
            open={open2}
            onClose={handleClose2}
            MenuListProps={{
              "aria-labelledby": "basic-button-2",
            }}
            // anchorPosition={{ left: 200, top: 20 }}
            transformOrigin={{
              vertical: 48,
              horizontal: 190,
            }}
          >
            {row?.supportStatus !== "NEW" ? (
              <MenuItem
                onClick={() => {
                  editRow(row?.id, "changeStatus", "NEW");
                  handleClose2();
                }}
              >
                <PublishedWithChangesIcon
                  sx={{
                    fontSize: "16px !important",
                    cursor: "pointer",
                    mr: 1,
                  }}
                />
                Нове
              </MenuItem>
            ) : null}
            {row?.supportStatus !== "PROCESSING" ? (
              <MenuItem
                onClick={() => {
                  editRow(row?.id, "changeStatus", "PROCESSING");
                  handleClose2();
                }}
              >
                <PublishedWithChangesIcon
                  sx={{
                    fontSize: "16px !important",
                    cursor: "pointer",
                    mr: 1,
                  }}
                />
                В обробці
              </MenuItem>
            ) : null}
            {row?.supportStatus !== "DONE" ? (
              <MenuItem
                onClick={() => {
                  editRow(row?.id, "changeStatus", "DONE");
                  handleClose2();
                }}
              >
                <PublishedWithChangesIcon
                  sx={{
                    fontSize: "16px !important",
                    cursor: "pointer",
                    mr: 1,
                  }}
                />
                Завершено
              </MenuItem>
            ) : null}
          </Menu>
          {/* <Typography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xxs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          > */}
        </TableCell>
      </TableRow>
    </>
  );
}

function TableSupport() {
  // eslint-disable-next-line
  // const [selectedRow, setSelectedRow] = useState(null);
  const [pagesCount, setPagesCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [sortDirection, setSortDirection] = useState("ASC");
  const [sortField, setSortField] = useState("creationDate");
  const { enqueueSnackbar } = useSnackbar();
  // eslint-disable-next-line
  const isOpenSearchBar = useSelector((state) => state.global.isOpenSearchBar);

  const dispatch = useDispatch();

  const apiGetSupport = new ApiService("auth/admin/search-support");
  const apiSerSupportStatus = new ApiService(
    "auth/admin/change-support-status"
  );

  const tableCells = [
    { header: "id", align: "left", width: "5%", label: "id" },
    {
      header: "Дата надходження",
      align: "center",
      width: "15%",
      label: "creationDate",
    },
    { header: "Контакти", align: "center", label: "name" },
    { header: "Тема", align: "center", label: "subject" },
    { header: "Пріорітет", align: "center", label: "priority" },
    { header: "Статус", align: "center", label: "supportStatus" },
    { header: "Дії", align: "center", width: "5%", label: "actions" },
  ];
  const [rows, setRows] = useState([]);

  const handleGetSupport = () => {
    setIsLoading(true);
    apiGetSupport
      .getAll({
        startWith: offset,
        count: 10,
        searchString: "",
        sortDirection: sortDirection,
        sortField: sortField,
      })
      .then((res) => {
        const rows = res.result.map((row) => {
          return createTableData(
            row.id,
            row.creationDate,
            row.name,
            row.email,
            row.subject,
            row.priority,
            row.supportStatus,
            row
          );
        });
        setRows(rows);
        setPagesCount(
          Number.isInteger(res.count / 10)
            ? res.count / 10
            : Math.floor(+(res.count / 10)) + 1
        );
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };
  const searchFunction = (searchValue) => {
    setIsLoading(true);
    if (searchValue && searchValue.length !== 0) {
      apiGetSupport
        .getAll({
          startWith: offset,
          count: 10,
          sortDirection: sortDirection,
          sortField: sortField,
          searchString: searchValue ? searchValue : "",
        })
        .then((res) => {
          const rows = res.result.map((row) => {
            return createTableData(
              row.id,
              row.creationDate,
              row.name,
              row.email,
              row.subject,
              row.priority,
              row.supportStatus,
              row
            );
          });
          setRows(rows);
          setPagesCount(
            Number.isInteger(res.count / 10)
              ? res.count / 10
              : Math.floor(+(res.count / 10)) + 1
          );
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    } else {
      handleGetSupport();
    }
  };

  const editRow = (id, type, newStatus = null) => {
    if (newStatus) {
      const status = () => {
        if (newStatus === "NEW") {
          return "Нове";
        } else if (newStatus === "PROCESSING") {
          return "В обробці";
        } else {
          return "Завершено";
        }
      };
      apiSerSupportStatus
        .postDataQuery({ supportId: id, supportStatus: newStatus })
        .then((res) => {
          handleGetSupport();
          enqueueSnackbar(`Статус змінено на ${status()}`, {
            variant: "success",
          });
        })
        .catch((error) => {
          console.error(error);
          enqueueSnackbar(`Сталася помилка!`, {
            variant: "error",
          });
        });
    }
  };
  useEffect(() => {
    handleGetSupport(offset, sortDirection, sortField);
    // dispatch(setIsOpenSearchBar(true));
    // eslint-disable-next-line
  }, [offset, sortDirection, sortField]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* {isOpenPostModal && (
        <PostModal
          isOpenModal={isOpenPostModal}
          data={selectedRow?.templateData}
          refreshData={handleGetSupport}
        />
      )} */}

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="success"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Підтримка
                </MDTypography>
                <SearchBar func={searchFunction} />
                <SearchIcon
                  sx={{
                    color: "white !important",
                    cursor: "pointer !important",
                  }}
                  onClick={() => dispatch(setIsOpenSearchBar(!isOpenSearchBar))}
                />
                {/* <Tooltip title="Додати валюту">
                  <AddIcon
                    onClick={() => console.log("numberComments ADD")}
                    sx={{
                      color: "white !important",
                      fontSize: "24px !important",
                      cursor: "pointer",
                      ":hover": {
                        color: "black !important",
                      },
                    }}
                  />
                </Tooltip> */}
              </MDBox>
              <MDBox pt={2}>
                {/* <DataTable
                  // canSearch
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                /> */}
                {!isLoading ? (
                  <>
                    {" "}
                    <TableContainer
                      component={Paper}
                      sx={{ borderRadius: "0px !important", mt: 0 }}
                    >
                      <Table>
                        <TableHead
                          sx={{
                            borderRadius: "0px !important",
                            display: "table-header-group",
                          }}
                        >
                          <TableRow>
                            {tableCells.map((cell, index) => (
                              <TableCell
                                key={cell.header + cell + index}
                                align={cell.align}
                                sx={({
                                  typography: { size, fontWeightBold },
                                }) => ({
                                  fontSize: size.xxs,
                                  fontWeight: fontWeightBold,
                                  textTransform: "uppercase",
                                  width: cell.width,
                                })}
                              >
                                {cell?.label !== "actions" &&
                                cell?.label !== "name" &&
                                cell?.label !== "subject" &&
                                cell?.label !== "id" ? (
                                  <TableSortLabel
                                    active={sortField === cell?.label}
                                    direction={
                                      sortDirection === "ASC" ? "asc" : "desc"
                                    }
                                    onClick={() => {
                                      if (sortDirection === "ASC") {
                                        setSortDirection("DESC");
                                      } else {
                                        setSortDirection("ASC");
                                      }
                                      setSortField(cell?.label);
                                    }}
                                  />
                                ) : null}

                                {cell.header}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row) => (
                            <Row
                              key={row.id + row.tikker}
                              row={row}
                              refreshData={handleGetSupport}
                              editRow={editRow}
                              enqueueSnackbar={enqueueSnackbar}
                              dispatch={dispatch}
                            />
                          ))}
                        </TableBody>
                      </Table>
                      <Pagination
                        sx={{ display: "flex", justifyContent: "end", m: 1 }}
                        defaultPage={1}
                        siblingCount={0}
                        count={pagesCount}
                        shape="rounded"
                        page={offset + 1}
                        onChange={(event, value) => setOffset(value - 1)}
                      />
                    </TableContainer>
                  </>
                ) : (
                  <div style={{ marginTop: "10%", marginBottom: "10%" }}>
                    <Spinner type="small" />
                  </div>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
Row.propTypes = {
  row: propTypes.object,
};
export default TableSupport;
