import React, { useEffect, useState } from 'react';

//Material
import CloseIcon from '@mui/icons-material/Close';
import { Box, ClickAwayListener, Grid, Modal, TextField, Typography } from '@mui/material';

import styled from '@emotion/styled';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import './PostModal.css';
// import { useTranslate } from 'react-redux-multilingual';
// import { useSnackbar } from 'notistack';
import { StyledLabel } from '../../components/StyledComponents';
// import ApiService from '../../helpers/apiHelper';
import {  useDispatch, useSelector } from 'react-redux';
import { LetsGoBtn3 } from '../../components/Buttons';
import { setIsOpenImageCropModal, setIsOpenPostModal } from '../../redux/mainReducer';
// import { useSnackbar } from 'notistack';
// import {  encode as btoa, decode as atob } from 'base-64';
// import sha256 from 'crypto-js/sha256';

import starBlue from '../../assets/StarBlue.png';
import starGreen from '../../assets/StarGreen.png';
// import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

import ApiService from '../../helpers/apiHelper';
import CropModal from '../CropModal/CropModal';
import EmojiPicker from 'emoji-picker-react';
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import { Spinner } from '../../components/Spinner';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 900,
  bgcolor: 'background.paper',
  borderRadius: '20px',
  border: 'none',
  boxShadow: 24,
  padding: '50px 80px 50px 80px',
  overflow: 'scroll',
  height: 'inherit',
  maxHeight: '100vh',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none'
  }
};

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#000000'
  },
  '& label': {
    color: '#A1A1A1',
    fontFamily: 'Kyiv-Sans-Light'
  },
  '& .MuiInputBase-root': { alignItems: 'end' },

  margin: '5px 0px'
});

function PostModal({ isOpenModal,  refreshData, data }) {
  const [imgs, setImgs] = useState([]);
  const [newImgs, setNewImgs] = useState([]);
  const [imgsToDelete, setImgsToDelete] = useState([]);
  const [file, setFile] = useState(null);
  const [screenType, setScreenType] = useState('desktop');
  const [isShowEmoji, setIsShowEmoji] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const isOpenImageCropModal = useSelector((state) => state.global.isOpenImageCropModal);
  useEffect(() => {
    if (window.screen.width <= 600) {
      setScreenType('mobile');
    }
  }, []);



  // const api = new ApiService(`post${data ? '/' : ''}${data ? data.id : ''}`);
  const api = new ApiService(`posts/post${data ? '/' : ''}${data ? data.id : ''}`);

  const apiPhoto = new ApiService('photo/get-photo');
  // const apiPhoto = new ApiService('get-photo');
  const schema = Yup.object().shape(
    {
      description: Yup.string().when('description', (val, schema) => {
        if (imgs.length === 0) {
          return Yup.string()
            .min(1, 'Мінімально 1 символ')
            .max(1000, 'Максимально 1000 символів')
            .required('Мінімально 1 символ');
        } else {
          return Yup.string().max(1000, 'Максимально 1000 символів');
        }
      })
    },
    [['description', 'description']]
  );
  useEffect(() => {
    const fileInput = document.getElementById('button-file-post');
    if (fileInput) {
      fileInput.addEventListener('change', (event) => {
        if (imgs.length !== 10) {
          setFile(event.target.files[0]);
          dispatch(setIsOpenImageCropModal(true));
        }
        event.target.value = '';
      });
    }
  });

  const formik = useFormik({
    initialValues: {
      description: data?.description ? data.description : ''
    },
    enableReinitialize: true,
    validationSchema: schema,

    onSubmit: (values, { resetForm, setErrors }) => {
      const apiUserToken = new ApiService("auth/admin/get-user-token");
      setIsLoading(true)
      if (data) {
        apiUserToken.getAll({ userId: data?.userDTO?.id }).then(async res=>{
          await localStorage.setItem("userToken", `Bearer ${res.token}`);
          api
          .updateDataAdmin({ ...values, newPhotos: newImgs, listDeletedPhotos: imgsToDelete }, true)

          .then((res) => {
            dispatch(setIsOpenPostModal({ isOpen: false, data: null, func: null }));
            setIsShowEmoji(false);
            setIsLoading(false)
            refreshData();
            localStorage.removeItem("userToken");
          }).catch(error=>{
            setIsLoading(false)
          });
        })
        
      } else
        api.sendRequest({ ...values, media: imgs }, true).then((res) => {
          dispatch(setIsOpenPostModal({ isOpen: false, data: null, func: null }));
          setIsShowEmoji(false);
          refreshData(false);
          setIsLoading(false)
        }).catch(error=>{
          setIsLoading(false)
        });
    }
  });
  function getFileFromBase64(string64, fileName) {
    const trimmedString = string64.replace('dataimage/jpegbase64', '');
    const imageContent = atob(trimmedString);
    const buffer = new ArrayBuffer(imageContent.length);
    const view = new Uint8Array(buffer);

    for (let n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }
    const type = 'image/jpeg';
    const blob = new Blob([buffer], { type });
    return new File([blob], fileName, { lastModified: new Date().getTime(), type });
  }
  useEffect(() => {
    if (data) {
      const getPhotos = async () => {
        const newPhotos = await Promise.all(
          data?.encodedPhotos.map((photo) =>
            apiPhoto
              .getItemById(photo)
              .then((res) => {
                return res.result;
              })
              .catch((error) => console.error(error))
          )
        ).then((res) => {
          return res;
        });
        setImgs([
          ...newPhotos.map((photo) => getFileFromBase64(photo?.encodedContent, data?.nameFile))
        ]);
      };
      if(data?.encodedPhotos&& data?.encodedPhotos.length!==0){    
        getPhotos();
      }

      // setImgs([
      //   ...data.encodedPhotos.map((photo) => getFileFromBase64(photo.encodedContent, data.nameFile))
      // ]);
    }
    // eslint-disable-next-line
  }, []);

  const {
    errors,
    touched,
    values,
    handleSubmit,
    getFieldProps,
    submitForm,
    resetForm,
    setErrors,
    setFieldValue
  } = formik;

  return (
    <div>
      <Modal
        open={isOpenModal}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            return;
          }
          dispatch(setIsOpenPostModal({ isOpen: false, data: null, func: null }));

          resetForm();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="bonfair_post_modal"
      >
        <Box sx={style} className="bonfair_post_modal-box">
          <img
            style={{ position: 'fixed', zIndex: '0', top: '0', left: '0' }}
            src={starGreen}
            alt="star-green"
          />
          <img
            style={{ position: 'fixed', zIndex: '0', bottom: '40px', right: '0' }}
            src={starBlue}
            alt="star-green"
          />
          <CloseIcon
            className="bonfair_post_modal-close"
            style={{ position: 'absolute', right: '10px', top: '10px', cursor: 'pointer' }}
            onClick={() => {
              dispatch(setIsOpenPostModal({ isOpen: false, data: null, func: null }));

              resetForm();
            }}
          />
          <CropModal
            isOpenModal={isOpenImageCropModal}
            file={file}
            refreshData={(file) => {
              setErrors({});
              if (data) {
                const newImgsArray = [...newImgs];
                newImgsArray.push(file);
                setNewImgs([...newImgsArray]);
              }
              const newArray = [...imgs];
              newArray.push(file);
              setImgs([...newArray]);
            }}
          />
          <FormikProvider value={formik}>
            <Form
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit}
              style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}
            >
              <Grid container spacing={screenType !== 'desktop' ? 1 : 2}>
                <Grid item xs={12} md={12} lg={12}>
                  <StyledLabel className="postModal-title-text">
                    {/* {translate('bonfair_service_modal')} */}
                    {data ? 'Редагуємо пост' : 'Створюємо пост'}
                  </StyledLabel>
                </Grid>
                {/* <Grid  item sx={{ mb: 4, mt:8, display:'flex' }}>
              
                <Avatar
                  sx={{
                    width: 70,
                    height: 70,
                    // mr: 2,
                    border: '1px solid #60A5DF'
                  }}
                />
             
              
                <Stack sx={{ width: '100%', marginLeft:'20px', marginTop:'10px' }}>
                  <Typography
                    fontSize={24}
                    lineHeight="29px"
                    fontWeight={400}
                    sx={{ position: 'relative' }}
                  >
                    @TEST
                  </Typography>
                  <Typography fontSize={16} lineHeight="20px" fontWeight={400}>
                    Name Surname
                  </Typography>
                </Stack>
              
             
            </Grid> */}
                {!data && (
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: 16, fontFamily: 'Kyiv-Sans-Bold' }}
                    >
                      Текст
                    </Typography>
                  </Grid>
                )}

                <Grid item xs={12} sx={{ position: 'relative' }}>
                  <CssTextField
                    placeholder="Чим хочеш поділитися сьогодні? "
                    multiline
                    rows={8}
                    fullWidth
                    inputProps={{ style: { textAlign: 'left' } }}
                    InputProps={{
                      endAdornment: (
                        <>
                          <EmojiEmotionsOutlinedIcon
                            onClick={() => {
                              setIsShowEmoji(!isShowEmoji);
                            }}
                            sx={{
                              color: '#60A5DF',
                              cursor: 'pointer',
                              fontSize: '20px',
                              mr: 1
                            }}
                          />
                        </>
                      )
                    }}
                    error={Boolean(touched.description && errors.description)}
                    {...getFieldProps('description')}
                    value={values.description}
                  >
                    {values.description}
                  </CssTextField>
                  {values.description.length !== 0 && (
                    <p
                      style={{
                        margin: '0 5px',
                        color: 'gray',
                        textAlign: 'right',
                        fontSize: '14px'
                      }}
                    >{`${values.description.length}/1000`}</p>
                  )}

                  {touched.description && errors.description && (
                    <div
                      style={{
                        color: 'red',
                        margin: '5px 0px',
                        fontSize: '14px',
                        fontFamily: 'Kyiv-Sans-Regular'
                      }}
                    >
                      {errors.description}
                    </div>
                  )}
                  {isShowEmoji && (
                    <ClickAwayListener
                      onClickAway={() => {
                        setIsShowEmoji(false);
                      }}
                    >
                      <Box>
                        <EmojiPicker
                          onEmojiClick={(emojiData, event) => {
                            let newStr = `${values.description}${emojiData.emoji}`;
                            setFieldValue('description', newStr);
                          }}
                          skinTonesDisabled
                          searchDisabled
                          previewConfig={{ showPreview: false }}
                          style={{
                            position: 'absolute',
                            bottom: '-280px',
                            right: '50px',
                            zIndex: '99'
                          }}
                          width={'60%'}
                          height={'300px'}
                        />
                      </Box>
                    </ClickAwayListener>
                  )}
                </Grid>
                {!data && (
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography
                      variant="subtitle2"
                      sx={{ mb: 2, fontSize: 16, fontFamily: 'Kyiv-Sans-Bold' }}
                    >
                      Додай фото
                    </Typography>
                    <Typography variant="subtitle" sx={{ fontSize: 16, color: '#B1B1B1' }}>
                      Можливо завантажити до 10 вкладень. Один елемент не має важити більше 10 МБ.
                    </Typography>
                  </Grid>
                )}

                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ display: 'flex', alignItems: 'left', flexDirection: 'column' }}
                >
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      // justifyContent:'space-between',
                      paddingBottom: '14px',
                      marginBottom: '20px',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      border: '1px dashed #60a5df',
                      borderRadius: '5px'
                    }}
                  >
                    {imgs.length !== 0 &&
                      imgs.map((file, index) => (
                        <div
                          key={'image' + index}
                          className="uploaded-img"
                          style={{ marginLeft: '14px', marginTop: '14px' }}
                          onClick={() => {
                            const newArray = imgs;
                            const newImgsToDelete = imgsToDelete;
                            if (data) {
                              newImgsToDelete.push(
                                data.encodedPhotos.find((file, i) => index === i)
                              );
                            }

                            newArray.splice(index, 1);
                            setImgs([...newArray]);
                            setImgsToDelete([...newImgsToDelete]);
                          }}
                        >
                          <img
                            style={{ cursor: 'pointer' }}
                            alt={'img' + index}
                            width={100}
                            src={URL.createObjectURL(file)}
                          />
                        </div>
                      ))}
                    <input
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="button-file-post"
                      disabled={imgs.length === 10 ? true : false}
                      // onChange={(e) => {
                      //   if (imgs.length !== 10) {
                      //     setFile(e.target.files[0]);
                      //     // dispatch(setIsOpenImageCropModal(true));
                      //   } else e.target.value = '';
                      // }}
                      type="file"
                    />
                    {imgs.length !== 10 && (
                      <label
                        htmlFor="button-file-post"
                        style={{
                          width: '100px',
                          height: '100px',
                          marginLeft: '14px',
                          marginTop: '14px',

                          cursor: 'pointer'
                        }}
                      >
                        <div
                          component="span"
                          variant="outlined"
                          style={{
                            width: '100px',
                            height: '100px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: '1px solid #A1A1A1',
                            borderRadius: '5px',
                            cursor: 'pointer'
                          }}
                        >
                          <AddIcon
                            style={{ color: 'rgba(96, 165, 223, 1)' }}
                            sx={{
                              '&:hover': {
                                width: 100
                              }
                            }}
                          />
                        </div>
                      </label>
                    )}
                  </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
                >
                  {!isLoading ? (
                    <LetsGoBtn3
                      disabled={values.description.length === 0 && imgs.length === 0}
                      size="large"
                      onClick={() => {
                        submitForm();
                      }}
                      width={257}
                      style={{ textTransform: 'inherit' }}
                      text={data ? 'Зберегти зміни' : 'Створити'}
                    />
                  ) : (
                    <div style={{ marginTop: '10px' }}>
                      <Spinner type="small" />
                    </div>
                  )}
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Box>
      </Modal>
    </div>
  );
}


export default PostModal
