/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import propTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import SearchIcon from "@mui/icons-material/Search";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// Data to delete
// import currencysTableData from "layouts/tables/data/currencysTableData";

import {
  Autocomplete,
  Box,
  FormControlLabel,
  FormGroup,
  Pagination,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import { useEffect, useState } from "react";
// eslint-disable-next-line
import SearchBar from "components/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import { setIsOpenSearchBar } from "redux/mainReducer";

import { dateTimeFormattingByString } from "helpers/date-helpers";
import ApiService from "../../../helpers/apiHelper";
import { Spinner } from "components/Spinner";
function createTableData(
  id,
  creationTimeOfAction,
  name,
  surname,
  nickname,
  text,

  templateData
) {
  return {
    id,
    creationTimeOfAction,
    name,
    surname,
    nickname,
    text,
    templateData,
  };
}
function Row({ row, actions }) {
  // const userData = useSelector((state) => state.global.userData);
  // const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  // const badgeContent = () => {
  //   if (row.status === "premium") {
  //     return "Преміум";
  //   } else if (row.status === "base") {
  //     return "Базовий";
  //   }
  // };
  // const badgeColor = () => {
  //   if (row.status === "premium") {
  //     return "warning";
  //   } else if (row.status === "base") {
  //     return "success";
  //   }
  // };
  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      width: "300px",
      maxWidth: "500px",
    },
  });
  // const CustomDescTooltip = styled(({ className, ...props }) => (
  //   <Tooltip {...props} classes={{ popper: className }} />
  // ))({
  //   [`& .${tooltipClasses.tooltip}`]: {
  //     width: "max-content",
  //     maxWidth: "600px",
  //   },
  // });
  const actionHandler = (str) => {
    const action = actions.map((act) => {
      // Знаходимо нікнейм Адміна
      const adminStartIndex = str.indexOf("[") + 1; // Позиція після '['
      const adminEndIndex = str.indexOf("]", adminStartIndex); // Позиція ']'
      const adminNickname = str.substring(adminStartIndex, adminEndIndex); // Витягуємо нікнейм Адміна

      // Знаходимо нікнейм Юзера
      const userStartIndex =
        str.indexOf("change status user with nickname [") +
        "change status user with nickname [".length; // Позиція після '[', де Юзер
      const userEndIndex = str.indexOf("]", userStartIndex); // Позиція ']'
      const userNickname = str.substring(userStartIndex, userEndIndex); // Витягуємо нікнейм Юзера
      if (act?.enum === row?.templateData?.adminActionType) {
        return `Користувач ${adminNickname} ${act?.translate} ${userNickname}`;
      } else {
        return "";
      }
    });
    if (action.length !== 0) {
      return action;
    }
  };
  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell align="left">
          <CustomWidthTooltip
            title={row.id}
            placement="right"
            sx={{ width: "10px" }}
          >
            <Typography
              sx={({ typography: { size, fontWeightBold } }) => ({
                fontSize: size.xxs,
                fontWeight: fontWeightBold,
                textTransform: "uppercase",
                ":hover": {
                  color: "lightblue",
                  cursor: "pointer",
                },
                width: "fit-content",
              })}
            >
              Показати
            </Typography>
          </CustomWidthTooltip>
        </TableCell>
        <TableCell
          align="center"
          sx={({ typography: { size, fontWeightBold } }) => ({
            fontSize: size.xxs,
            fontWeight: fontWeightBold,
            textTransform: "uppercase",
            // width: cell.width,
          })}
        >
          <Typography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xxs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {dateTimeFormattingByString(row.creationTimeOfAction)}
          </Typography>
        </TableCell>
        <TableCell
          align="center"
          sx={({ typography: { size, fontWeightBold } }) => ({
            fontSize: size.xxs,
            fontWeight: fontWeightBold,
            textTransform: "uppercase",
            // width: cell.width,
          })}
        >
          <Typography
            sx={({ typography: { size, fontWeightLight } }) => ({
              fontSize: size.xxs,
              fontWeight: fontWeightLight,
              textTransform: "uppercase",
            })}
          >
            {`@${row.nickname}`}
          </Typography>
          <Typography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xxs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            <Typography
              sx={({ typography: { size, fontWeightBold } }) => ({
                fontSize: size.xxs,
                fontWeight: fontWeightBold,
                textTransform: "uppercase",
              })}
            >
              {`${row.surname} ${row.name}`}
            </Typography>
          </Typography>
        </TableCell>
        <TableCell
          align="center"
          sx={({ typography: { size, fontWeightBold } }) => ({
            fontSize: size.xxs,
            fontWeight: fontWeightBold,
            textTransform: "uppercase",
            // width: cell.width,
          })}
        >
          {actionHandler(row.text)}
          {/* {row.text} */}
          {/* <CustomDescTooltip title={row.text} placement="left">
            <Typography
              sx={({ typography: { size, fontWeightBold } }) => ({
                fontSize: size.xxs,
                fontWeight: fontWeightBold,
                textTransform: "uppercase",
                width: "fit-content",
                margin: "0 auto",
              })}
            >
              {row.text}
             
            </Typography>
          </CustomDescTooltip> */}
        </TableCell>

        {/* <TableCell align="center"> */}
        {/* <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <MoreVertIcon />
          </Button> */}
        {/* <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                editRow(row, "block");
                handleClose();
              }}
            >
              <BlockIcon
                sx={{ fontSize: "16px !important", cursor: "pointer", mr: 1 }}
              />
              {row?.templateData?.isBlockedPost
                ? "Розблокувати"
                : "Заблокувати"}
            </MenuItem>
            {userData && userData?.role && userData?.role !== "ADMIN" && (
              <MenuItem
                onClick={() => {
                  editRow(row, "edit");
                  handleClose();
                }}
              >
                <EditIcon
                  sx={{
                    fontSize: "16px !important",
                    cursor: "pointer",
                    mr: 1,
                  }}
                />
                <Typography>Змінити</Typography>
              </MenuItem>
            )}

            <MenuItem
              onClick={() => {
                editRow(row, "delete");
                handleClose();
              }}
            >
              <DeleteOutlineIcon
                sx={{ fontSize: "16px !important", cursor: "pointer", mr: 1 }}
              />
              Видалити
            </MenuItem>
          </Menu> */}

        {/* <Typography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xxs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          > */}
        {/* </TableCell> */}
      </TableRow>
    </>
  );
}

function TableHistory() {
  // eslint-disable-next-line
  // const [selectedRow, setSelectedRow] = useState(null);
  const [pagesCount, setPagesCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [filterBy, setFilterBy] = useState("");
  // eslint-disable-next-line
  const isOpenSearchBar = useSelector((state) => state.global.isOpenSearchBar);

  const dispatch = useDispatch();

  const apiGetHistory = new ApiService("auth/admin-action/search-admin-action");

  const tableCells = [
    { header: "id", align: "left", width: "5%" },
    { header: "Час дії", align: "center", width: "15%" },
    { header: "Від", align: "center", width: "20%" },
    { header: "Дія", align: "center" },
    // { header: "Дії", align: "center", width: "5%" },
  ];
  const actions = [
    { enum: "DELETE_USER", translate: "Видалив користувача" },
    { enum: "BLOCKED_USER", translate: "Заблокував користувача" },
    { enum: "UNBLOCKED_USER", translate: "Розблокував користувача" },
    { enum: "CHANGE_USER_STATUS", translate: "Змінив статус користувача" },
    {
      enum: "CREATE_PREMIUM_SUBSCRIPTION_FOR_USER",
      translate: "Надав підписку користувачу",
    },
    {
      enum: "REMOVE_PREMIUM_SUBSCRIPTION_FOR_USER",
      translate: "Забрав підписку користувача",
    },
  ];
  const [rows, setRows] = useState([]);

  const handleGetHistory = () => {
    setIsLoading(true);
    apiGetHistory
      .getAll({
        startWith: offset,
        count: 10,
        filterWithAdminActionType: isFilter,
        adminActionType: filterBy?.enum && isFilter ? filterBy?.enum : "",
      })
      .then((res) => {
        const rows = res.result.map((row) => {
          return createTableData(
            row.id,
            row.creationTimeOfAction,
            row.name,
            row.surname,
            row.nickname,
            row.text,
            row
          );
        });
        setRows(rows);
        setPagesCount(
          Number.isInteger(res.count / 10)
            ? res.count / 10
            : Math.floor(+(res.count / 10)) + 1
        );
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };
  const searchFunction = (searchValue) => {
    setIsLoading(true);
    if (searchValue && searchValue.length !== 0) {
      apiGetHistory
        .getAll({
          startWith: 0,
          count: 10,
          searchString: searchValue ? searchValue : "",
          filterWithAdminActionType: isFilter,
          adminActionType: filterBy?.enum && isFilter ? filterBy?.enum : "",
        })
        .then((res) => {
          const rows = res.result.map((row) => {
            return createTableData(
              row.id,
              row.description,
              row.creationTimeOfAction,
              row.valueLikes,
              row.numberComments,
              row
            );
          });
          setRows(rows);
          setPagesCount(
            Number.isInteger(res.count / 10)
              ? res.count / 10
              : Math.floor(+(res.count / 10)) + 1
          );
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    } else {
      handleGetHistory();
    }
  };

  useEffect(() => {
    handleGetHistory();
    // dispatch(setIsOpenSearchBar(true));
    // eslint-disable-next-line
  }, [offset, filterBy]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* {isOpenPostModal && (
        <PostModal
          isOpenModal={isOpenPostModal}
          data={selectedRow?.templateData}
          refreshData={handleGetHistory}
        />
      )} */}

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="success"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                alignItems="start"
              >
                <Box
                  sx={{
                    p: 0,
                    m: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <MDTypography variant="h6" color="white" textAlign="left">
                    Історія дій
                  </MDTypography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      width: "90%",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <SearchBar func={searchFunction} />
                    <SearchIcon
                      sx={{
                        color: "white !important",
                        cursor: "pointer !important",
                      }}
                      onClick={() =>
                        dispatch(setIsOpenSearchBar(!isOpenSearchBar))
                      }
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    p: 0,
                    m: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    width: "100%",
                  }}
                >
                  {isFilter ? (
                    <Autocomplete
                      disableClearable
                      value={filterBy}
                      onChange={(event, newValue) => {
                        setFilterBy(newValue);
                      }}
                      getOptionLabel={(option) => {
                        const label = actions.find(
                          (op) => op?.enum === option.enum
                        );
                        if (label) {
                          return label?.translate;
                        } else {
                          return "";
                        }
                      }}
                      options={actions}
                      sx={{ width: 300, mr: 2 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Дія"
                          variant="standard"
                          sx={{
                            "& .MuiInputBase-root": {
                              m: 0,
                              color: "white !important",
                            },

                            "& label": {
                              top: "-10px",
                              color: "lightgray !important",
                            },
                          }}
                        />
                      )}
                    />
                  ) : null}

                  <FormGroup>
                    <FormControlLabel
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          color: "white !important",
                        },
                        mr: 0,
                        display: "flex",
                        alignItems: "center",
                      }}
                      control={
                        <Switch
                          value={isFilter}
                          onChange={(event, value) => {
                            if (value) {
                              setIsFilter(value);
                            } else {
                              setIsFilter(false);
                              setFilterBy("");
                            }
                            setOffset(0);
                          }}
                        />
                      }
                      label="Фільтр дій"
                    />
                  </FormGroup>
                </Box>

                {/* <Tooltip title="Додати валюту">
                  <AddIcon
                    onClick={() => console.log("numberComments ADD")}
                    sx={{
                      color: "white !important",
                      fontSize: "24px !important",
                      cursor: "pointer",
                      ":hover": {
                        color: "black !important",
                      },
                    }}
                  />
                </Tooltip> */}
              </MDBox>
              <MDBox pt={2}>
                {/* <DataTable
                  // canSearch
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                /> */}
                {!isLoading ? (
                  <>
                    {" "}
                    <TableContainer
                      component={Paper}
                      sx={{ borderRadius: "0px !important", mt: 0 }}
                    >
                      <Table>
                        <TableHead
                          sx={{
                            borderRadius: "0px !important",
                            display: "table-header-group",
                          }}
                        >
                          <TableRow>
                            {tableCells.map((cell, index) => (
                              <TableCell
                                key={cell.header + cell + index}
                                align={cell.align}
                                sx={({
                                  typography: { size, fontWeightBold },
                                }) => ({
                                  fontSize: size.xxs,
                                  fontWeight: fontWeightBold,
                                  textTransform: "uppercase",
                                  width: cell.width,
                                })}
                              >
                                {cell.header}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row) => (
                            <Row
                              key={row.id + row.tikker}
                              row={row}
                              refreshData={handleGetHistory}
                              actions={actions}
                            />
                          ))}
                        </TableBody>
                      </Table>
                      <Pagination
                        sx={{ display: "flex", justifyContent: "end", m: 1 }}
                        defaultPage={1}
                        siblingCount={0}
                        count={pagesCount}
                        shape="rounded"
                        page={offset + 1}
                        onChange={(event, value) => setOffset(value - 1)}
                      />
                    </TableContainer>
                  </>
                ) : (
                  <div style={{ marginTop: "10%", marginBottom: "10%" }}>
                    <Spinner type="small" />
                  </div>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
Row.propTypes = {
  row: propTypes.object,
};
export default TableHistory;
