export const emailRegExp = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
export const passwordRegExp = new RegExp(
  /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/
);
export const telegramRegExp = new RegExp(
  /.*\B@(?=\w{5,32}\b)[a-zA-Z0-9]+(?:_[a-zA-Z0-9]+)*.*/
);
export const disableCyrillicRegExp = new RegExp(/^[^А-Яа-я]*$/gm);
export const whiteSpaceRegExp = new RegExp(/^\S+$/);
export const isUppercase = new RegExp(/^[A-Z][a-z0-9_-]{3,19}$/);
