import { useEffect, useState } from 'react';

//Material
import styled from '@emotion/styled';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  Box,
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  Grid,
  Modal,
  TextField,
  Typography
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import './ServiceModal.css';
// import { useTranslate } from 'react-redux-multilingual';
// import { useSnackbar } from 'notistack';
import { StyledLabel } from '../../components/StyledComponents';
// import ApiService from '../../helpers/apiHelper';
import { connect, useDispatch, useSelector } from 'react-redux';
import { LetsGoBtn3 } from '../../components/Buttons';
import {
  setIsOpenImageCropModal,
  setIsOpenServiceModal
} from '../../redux/mainReducer';

// import {  encode as btoa, decode as atob } from 'base-64';
// import sha256 from 'crypto-js/sha256';

import starBlue from '../../assets/StarBlue.png';
import starGreen from '../../assets/StarGreen.png';
// import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import EmojiPicker from 'emoji-picker-react';
import { Spinner } from '../../components/Spinner';
import ApiService from '../../helpers/apiHelper';
import CropModal from '../CropModal/CropModal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 900,
  bgcolor: 'background.paper',
  borderRadius: '20px',
  border: 'none',
  boxShadow: 24,
  padding: '50px 80px 50px 80px',
  overflow: 'scroll',
  height: 'inherit',
  maxHeight: '100vh',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none'
  }
};

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#000000'
  },
  '& label': {
    color: '#A1A1A1',
    fontFamily: 'Kyiv-Sans-Light',
    fontSize: '15px'
  },
  '& .MuiInputBase-root': { alignItems: 'end' },
  fontFamily: 'Kyiv-Sans-Regular !important',
  margin: '5px 0px'
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function ServiceModal({ isOpenModal, refreshData, data }) {
  const [imgs, setImgs] = useState([]);
  const [newImgs, setNewImgs] = useState([]);
  const [imgsToDelete, setImgsToDelete] = useState([]);
  const [file, setFile] = useState(null);
  const [screenType, setScreenType] = useState('desktop');
  const [isShowEmoji, setIsShowEmoji] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isOpenImageCropModal = useSelector((state) => state.global.isOpenImageCropModal);
  // const isOpenDeleteModal = useSelector((state) => state.global.isOpenDeleteModal);

  // const { btoa } = window;
  //const userData = useSelector((state=>state.global.userData)) //for test
  //console.log(userData) // for test
  const dispatch = useDispatch();
  useEffect(() => {
    if (window.screen.width <= 600) {
      setScreenType('mobile');
    }
  }, []);
 

  // const api = new ApiService(`service${data ? '/' : ''}${data ? data.id : ''}` );
  const api = new ApiService(`posts/service${data ? '/' : ''}${data ? data.id : ''}`);


  const apiPhoto = new ApiService('photo/get-photo');
  // const apiPhoto = new ApiService('get-photo');
 
  

  const schema = Yup.object().when((values, schema) => {
    // console.log(values[0]?.availability === 'Є в наявності', values)
    if (values&&values[0]?.availability === 'Є в наявності') {
      
      return schema.shape({
        nameService: Yup.string()
          .min(2, 'Мінімально 2 символи')
          .max(32, 'Максимально 32 символи')
          .required("Це поле обов'язкове"),
        price: Yup.number()
          .min(0, 'Ціна не може бути меншою за 0')
          .typeError('Тільки число')
          .required("Це поле обов'язкове"),
        valueOfInitOfMeasure: Yup.number()
          .typeError('Тільки число')
          .min(0, 'Кількість не може бути меншою за 0')
          .required("Це поле обов'язкове"),
        description: Yup.string()
          .min(10, 'Мінімально 10 символів')
          .max(1000, 'Максимально 1000 символів'),
        availability: Yup.string().required("Це поле обов'язкове"),
        initOfMeasure: Yup.string().required("Це поле обов'язкове"),
        category: Yup.string().required("Це поле обов'язкове"),
        currency: Yup.string().required("Це поле обов'язкове"),
        deliveryOption: Yup.array()
          .min(1, 'Потрібно обрати хочаб один варінт')
          .required("Це поле обов'язкове"),
        paymentOption: Yup.array()
          .min(1, 'Потрібно обрати хочаб один варінт')
          .required("Це поле обов'язкове"),
        deliveryDays: Yup.array()
          .min(1, 'Потрібно обрати хочаб один варінт')
          .required("Це поле обов'язкове"),
        estimatedTime: Yup.number()
          .min(1, 'Не може бути меншим за 1')
          .max(366, 'Не може бути більшим за 366')
          .typeError('Тільки число')
          .required("Це поле обов'язкове"),
        availableQuantity: Yup.number().typeError('Тільки число').min(1, 'Мінімально 1')
      });
    } else if (values&&values[0]?.availability === 'Під замовлення') {
      return schema.shape({
        nameService: Yup.string()
          .min(2, 'Мінімально 2 символи')
          .max(32, 'Максимально 32 символи')
          .required("Це поле обов'язкове"),
        price: Yup.number()
          .min(0, 'Ціна не може бути меншою за 0')
          .typeError('Тільки число')
          .required("Це поле обов'язкове"),
        valueOfInitOfMeasure: Yup.number()
          .typeError('Тільки число')
          .min(0, 'Кількість не може бути меншою за 0')
          .required("Це поле обов'язкове"),
        description: Yup.string()
          .min(10, 'Мінімально 10 символів')
          .max(1000, 'Максимально 1000 символів'),
        availability: Yup.string().required("Це поле обов'язкове"),
        initOfMeasure: Yup.string().required("Це поле обов'язкове"),
        category: Yup.string().required("Це поле обов'язкове"),
        currency: Yup.string().required("Це поле обов'язкове"),
        deliveryOption: Yup.array()
          .min(1, 'Потрібно обрати хочаб один варінт')
          .required("Це поле обов'язкове"),
        paymentOption: Yup.array()
          .min(1, 'Потрібно обрати хочаб один варінт')
          .required("Це поле обов'язкове"),
        deliveryDays: Yup.array()
          .min(1, 'Потрібно обрати хочаб один варінт')
          .required("Це поле обов'язкове"),
        estimatedTime: Yup.number()
          .min(1, 'Не може бути меншим за 1')
          .max(366, 'Не може бути більшим за 366')
          .typeError('Тільки число')
          .required("Це поле обов'язкове"),
        availableQuantity: Yup.number()
          .typeError('Тільки число')
          .max(0, 'Максимально 0')
          .min(0, 'Мінімально 0')
      });
    } else {
      return schema.shape({
        nameService: Yup.string()
          .min(2, 'Мінімально 2 символи')
          .max(32, 'Максимально 32 символи')
          .required("Це поле обов'язкове"),
        price: Yup.number()
          .min(0, 'Ціна не може бути меншою за 0')
          .typeError('Тільки число')
          .required("Це поле обов'язкове"),
        valueOfInitOfMeasure: Yup.number()
          .typeError('Тільки число')
          .min(0, 'Кількість не може бути меншою за 0')
          .required("Це поле обов'язкове"),
        description: Yup.string()
          .min(10, 'Мінімально 10 символів')
          .max(1000, 'Максимально 1000 символів'),
        availability: Yup.string().required("Це поле обов'язкове"),
        initOfMeasure: Yup.string().required("Це поле обов'язкове"),
        category: Yup.string().required("Це поле обов'язкове"),
        currency: Yup.string().required("Це поле обов'язкове"),
        deliveryOption: Yup.array()
          .min(1, 'Потрібно обрати хочаб один варінт')
          .required("Це поле обов'язкове"),
        paymentOption: Yup.array()
          .min(1, 'Потрібно обрати хочаб один варінт')
          .required("Це поле обов'язкове"),
        deliveryDays: Yup.array()
          .min(1, 'Потрібно обрати хочаб один варінт')
          .required("Це поле обов'язкове"),
        estimatedTime: Yup.number()
          .min(1, 'Не може бути меншим за 1')
          .max(366, 'Не може бути більшим за 366')
          .typeError('Тільки число')
          .required("Це поле обов'язкове"),
        availableQuantity: Yup.number()
          .typeError('Тільки число')
          .max(0, 'Максимально 0')
          .min(0, 'Мінімально 0')
      });
    }
  });

  const formik = useFormik({
    initialValues: {
      nameService: data?.nameService ? data.nameService : '',
      category: data?.category ? data.category : '',
      description: data?.description ? data.description : '',
      price: data?.price ? data.price : '',
      currency: data?.currency ? data.currency : 'грн',
      valueOfInitOfMeasure: data?.valueOfInitOfMeasure ? data.valueOfInitOfMeasure : '',
      initOfMeasure: data?.initOfMeasure ? data.initOfMeasure : '',
      availability: data?.availability ? data.availability : '',
      deliveryOption: data?.deliveryOption ? data.deliveryOption : [],
      paymentOption: data?.paymentOption ? data.paymentOption : [],
      deliveryDays: data?.deliveryDays ? data.deliveryDays : [],
      estimatedTime: data?.estimatedTime ? data.estimatedTime : '',
      availableQuantity: data?.availableQuantity ? data?.availableQuantity : 0
    },
    enableReinitialize: true,
    validationSchema: schema,

    onSubmit: (values, { resetForm, setErrors }) => {
      const apiUserToken = new ApiService("auth/admin/get-user-token");
      setIsLoading(true)
      if (data) {
        apiUserToken.getAll({ userId: data?.userDTO?.id }).then(async (res) => {
          await localStorage.setItem("userToken", `Bearer ${res.token}`);
          api
          .updateDataAdmin(
            {
              ...values,
              newPhotos: newImgs,
              listDeletedPhotos: imgsToDelete,
              deliveryDays: values.deliveryDays.filter((option) => option !== 'Щодня')
            },
            true
          )
          .then((res) => {
            dispatch(setIsOpenServiceModal({ isOpen: false, data: null, func: null }));
            setIsShowEmoji(false);
            localStorage.removeItem("userToken");
            setIsLoading(false)
            refreshData()
           
          }).catch(error=>{
            setIsLoading(false)
           
          });
        })

        
      } else
        api
          .sendRequest(
            {
              ...values,
              media: imgs,
              deliveryDays: values.deliveryDays.filter((option) => option !== 'Щодня')
            },
            true
          )
          .then((res) => {
            dispatch(setIsOpenServiceModal({ isOpen: false, data: null, func: null }));
           
            setIsShowEmoji(false);
            setIsLoading(false)
           
          }).catch(error=>{
            setIsLoading(false)
           
          });
    }
  });
  useEffect(() => {
    const fileInput = document.getElementById('button-file-service');
    if (fileInput) {
      fileInput.addEventListener('change', (event) => {
        if (imgs.length !== 10) {
          setFile(event.target.files[0]);
          dispatch(setIsOpenImageCropModal(true));
        }
        event.target.value = '';
      });
    }
  });
  const {
    errors,
    touched,
    values,
    handleSubmit,
    getFieldProps,
    submitForm,
    resetForm,
    setFieldValue
  } = formik;
  function getFileFromBase64(string64, fileName) {
    const trimmedString = string64.replace('dataimage/jpegbase64', '');
    const imageContent = atob(trimmedString);
    const buffer = new ArrayBuffer(imageContent.length);
    const view = new Uint8Array(buffer);

    for (let n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }
    const type = 'image/jpeg';
    const blob = new Blob([buffer], { type });
    return new File([blob], fileName, { lastModified: new Date().getTime(), type });
  }
  
  useEffect(() => {
    if (data) {
      const getPhotos = async () => {        
        const newPhotos = await Promise.all(
          data?.encodedPhotos.map((photo) =>
            apiPhoto
              .getItemById(photo)
              .then((res) => {
                return res.result;
              })
              .catch((error) => console.error(error))
          )
        ).then((res) => {
          return res;
        });
        setImgs([
          ...newPhotos.map((photo) => getFileFromBase64(photo?.encodedContent, data?.nameFile))
        ]);
      };
      if(data?.encodedPhotos&& data?.encodedPhotos.length!==0){    
      getPhotos();
    }

      // setImgs([
      //   ...getPhotos().map((photo) => getFileFromBase64(photo.encodedContent, data.nameFile))
      // ]);
      if (
        data &&
        data?.deliveryDays &&
        data.deliveryDays.includes('Понеділок') &&
        data.deliveryDays.includes('Вівторок') &&
        data.deliveryDays.includes('Середа') &&
        data.deliveryDays.includes('Четвер') &&
        data.deliveryDays.includes("П'ятниця") &&
        data.deliveryDays.includes('Субота') &&
        data.deliveryDays.includes('Неділя')
      ) {
        setFieldValue('deliveryDays', [
          'Щодня',
          'Понеділок',
          'Вівторок',
          'Середа',
          'Четвер',
          "П'ятниця",
          'Субота',
          'Неділя'
        ]);
      }
    }
    
    // eslint-disable-next-line
  }, []);
  
  const handleChange = (type, text) => {
    if (type === 'delivery') {
      if (values.deliveryOption.includes(text)) {
        setFieldValue('deliveryOption', [
          ...values.deliveryOption.filter((option) => option !== text)
        ]);
      } else {
        setFieldValue('deliveryOption', [...values.deliveryOption, text]);
      }
    } else {
      if (values.paymentOption.includes(text)) {
        setFieldValue('paymentOption', [
          ...values.paymentOption.filter((option) => option !== text)
        ]);
      } else {
        setFieldValue('paymentOption', [...values.paymentOption, text]);
      }
    }
  };
  return (
    <div>
      {/* <DeleteModal isOpenModal={isOpenDeleteModal} func={deleteService} text="цю послугу?" /> */}
      <Modal
        open={isOpenModal}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            return;
          }
          dispatch(setIsOpenServiceModal({ isOpen: false, data: null, func: null }));

          resetForm();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="bonfair_service_modal"
      >
        <Box sx={style} className="bonfair_service_modal-box">
          <img
            style={{ position: 'fixed', zIndex: '0', top: '0', left: '0' }}
            src={starGreen}
            alt="star-green"
          />
          <img
            style={{ position: 'fixed', zIndex: '0', bottom: '40px', right: '0' }}
            src={starBlue}
            alt="star-green"
          />
          <CloseIcon
            className="bonfair_service_modal-close"
            style={{ position: 'absolute', right: '10px', top: '10px', cursor: 'pointer' }}
            onClick={() => {
              dispatch(setIsOpenServiceModal({ isOpen: false, data: null, func: null }));

              resetForm();
            }}
          />
          <CropModal
            isOpenModal={isOpenImageCropModal}
            file={file}
            setter={(file) => {
              if (data) {
                const newImgsArray = [...newImgs];
                newImgsArray.push(file);
                setNewImgs([...newImgsArray]);
              }
              const newArray = [...imgs];
              newArray.push(file);
              setImgs([...newArray]);
            }}
          />

          <FormikProvider value={formik}>
            <Form
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit}
              style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}
            >
              <Grid container spacing={screenType !== 'desktop' ? 1 : 4}>
                <Grid item xs={12} md={12} lg={12}>
                  <StyledLabel className="serviceModal-title-text">
                    {/* {translate('bonfair_service_modal')} */}
                    {data ? 'Редагуємо послугу' : 'Створюємо послугу'}
                  </StyledLabel>
                </Grid>
                {!data && (
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: 16, fontFamily: 'Kyiv-Sans-Bold' }}
                    >
                      Опиши свою послугу
                    </Typography>
                  </Grid>
                )}

                <Grid
                  item
                  xs={12}
                  sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}
                >
                  <CssTextField
                    label="Назва послуги*"
                    placeholder="Назва послуги*"
                    variant="standard"
                    fullWidth
                    size="normal"
                    inputProps={{ style: { textAlign: 'left' } }}
                    error={Boolean(touched.nameService && errors.nameService)}
                    {...getFieldProps('nameService')}
                    value={values.nameService}
                  >
                    {values.nameService}
                  </CssTextField>
                  {touched.nameService && errors.nameService && (
                    <div
                      style={{
                        position: 'absolute',
                        bottom: '-18px',
                        color: 'red',
                        margin: '5px 0px',
                        fontSize: '14px',
                        fontFamily: 'Kyiv-Sans-Regular'
                      }}
                    >
                      {errors.nameService}
                    </div>
                  )}
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <Autocomplete
                    disableClearable
                    onKeyPress={(e) => {
                      e.preventDefault();
                    }}
                    options={[
                      'Їжа та напої',
                      'Одяг та взуття',
                      'Канцелярія',
                      'Косметика',
                      'Аксесуари',
                      'Товари для дому',
                      'Флористика',
                      'Товари для дітей',
                      'Інше'
                    ]}
                    value={values.category}
                    onChange={(event, value) => setFieldValue('category', value)}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Обери категорію послуги*" />
                    )}
                  />
                  {touched.category && errors.category && (
                    <div
                      style={{
                        color: 'red',
                        margin: '5px 0px',
                        fontSize: '14px',
                        fontFamily: 'Kyiv-Sans-Regular'
                      }}
                    >
                      {errors.category}
                    </div>
                  )}
                </Grid>
                <Grid
                  item
                  xs={2.5}
                  sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}
                >
                  <CssTextField
                    label="К-ть в наявності"
                    placeholder="К-ть в наявності"
                    variant="standard"
                    fullWidth
                    size="normal"
                    inputProps={{ style: { textAlign: 'left' } }}
                    error={Boolean(errors.availableQuantity)}
                    {...getFieldProps('availableQuantity')}
                    value={values.availableQuantity}
                  >
                    {values.availableQuantity}
                  </CssTextField>
                  {errors.availableQuantity && (
                    <div
                      style={{
                        position: 'absolute',
                        bottom: '-18px',
                        color: 'red',
                        margin: '5px 0px',
                        fontSize: '14px',
                        fontFamily: 'Kyiv-Sans-Regular'
                      }}
                    >
                      {errors.availableQuantity}
                    </div>
                  )}
                </Grid>
                <Grid item xs={3.5}>
                  <Autocomplete
                    disableClearable
                    onKeyPress={(e) => {
                      e.preventDefault();
                    }}
                    options={['Є в наявності', 'Під замовлення', 'Немає в наявності']}
                    value={values.availability}
                    sx={{ maxWidth: 300 }}
                    onChange={(event, value) => {
                      setFieldValue('availability', value);
                    }}
                    renderInput={(params) => <TextField {...params} placeholder="Наявність*" />}
                  />
                  {touched.availability && errors.availability && (
                    <div
                      style={{
                        color: 'red',
                        margin: '5px 0px',
                        fontSize: '14px',
                        fontFamily: 'Kyiv-Sans-Regular'
                      }}
                    >
                      {errors.availability}
                    </div>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  style={{ display: 'flex', alignItems: 'flex-end' }}
                >
                  <Grid item xs={3} sx={{ position: 'relative' }}>
                    <CssTextField
                      label="Ціна*"
                      placeholder="Ціна*"
                      variant="standard"
                      fullWidth
                      size="normal"
                      inputProps={{ style: { textAlign: 'left' } }}
                      error={Boolean(touched.price && errors.price)}
                      {...getFieldProps('price')}
                      value={values.price}
                    >
                      {values.price}
                    </CssTextField>
                    {touched.price && errors.price && (
                      <div
                        style={{
                          position: 'absolute',
                          bottom: '-22px',
                          color: 'red',
                          margin: '5px 0px',
                          fontSize: '14px',
                          fontFamily: 'Kyiv-Sans-Regular',
                          width: 'max-content'
                        }}
                      >
                        {errors.price}
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}
                  >
                    <Autocomplete
                      disableClearable
                      disabled
                      onKeyPress={(e) => {
                        e.preventDefault();
                      }}
                      defaultValue="грн"
                      options={['грн', 'USD', 'EUR']}
                      value={values.currency}
                      sx={{ ml: 2, mr: 4 }}
                      onChange={(event, value) => setFieldValue('currency', value)}
                      renderInput={(params) => <TextField {...params} placeholder="Валюта*" />}
                    />
                    <Typography
                      variant="subtitle2"
                      sx={{ mt: 1, mr: 2, fontSize: 16, fontFamily: 'Kyiv-Sans-Bold' }}
                    >
                      за
                    </Typography>
                    {touched.currency && errors.currency && (
                      <div
                        style={{
                          position: 'absolute',
                          bottom: '-18px',
                          color: 'red',
                          margin: '5px 0px',
                          fontSize: '14px',
                          fontFamily: 'Kyiv-Sans-Regular'
                        }}
                      >
                        {errors.currency}
                      </div>
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={3}
                    className="serviceModal-inputs-first"
                    sx={{ position: 'relative' }}
                  >
                    <CssTextField
                      label="Кількість*"
                      placeholder="Кількість*"
                      variant="standard"
                      fullWidth
                      size="normal"
                      inputProps={{ style: { textAlign: 'left' } }}
                      error={Boolean(touched.valueOfInitOfMeasure && errors.valueOfInitOfMeasure)}
                      {...getFieldProps('valueOfInitOfMeasure')}
                      value={values.valueOfInitOfMeasure}
                    >
                      {values.valueOfInitOfMeasure}
                    </CssTextField>
                    {touched.valueOfInitOfMeasure && errors.valueOfInitOfMeasure && (
                      <div
                        style={{
                          position: 'absolute',
                          bottom: '-22px',
                          color: 'red',
                          margin: '5px 0px',
                          fontSize: '14px',
                          fontFamily: 'Kyiv-Sans-Regular',
                          width: 'max-content'
                        }}
                      >
                        {errors.valueOfInitOfMeasure}
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <Autocomplete
                      disableClearable
                      onKeyPress={(e) => {
                        e.preventDefault();
                      }}
                      options={['г', 'кг', 'мл', 'л', 'см', 'м', 'шт', '-']}
                      value={values.initOfMeasure}
                      sx={{ ml: 2, mr: 0 }}
                      onChange={(event, value) => setFieldValue('initOfMeasure', value)}
                      renderInput={(params) => <TextField {...params} placeholder="Од.Виміру*" />}
                    />
                    {touched.initOfMeasure && errors.initOfMeasure && (
                      <div
                        style={{
                          color: 'red',
                          margin: '5px 0px',
                          fontSize: '14px',
                          fontFamily: 'Kyiv-Sans-Regular'
                        }}
                      >
                        {errors.initOfMeasure}
                      </div>
                    )}
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  className="serviceModal-inputs-first"
                  sx={{ position: 'relative' }}
                >
                  <CssTextField
                    placeholder="Можеш описати склад товару, його особливості і тд."
                    multiline
                    rows={8}
                    fullWidth
                    inputProps={{ style: { textAlign: 'left' } }}
                    InputProps={{
                      endAdornment: (
                        <>
                          <EmojiEmotionsOutlinedIcon
                            onClick={() => {
                              setIsShowEmoji(!isShowEmoji);
                            }}
                            sx={{
                              color: '#60A5DF',
                              cursor: 'pointer',
                              fontSize: '20px',
                              mr: 1
                            }}
                          />
                        </>
                      )
                    }}
                    error={Boolean(touched.description && errors.description)}
                    {...getFieldProps('description')}
                    value={values.description}
                  >
                    {values.description}
                  </CssTextField>
                  {values.description.length !== 0 && (
                    <p
                      style={{
                        margin: '0 5px',
                        color: 'gray',
                        textAlign: 'right',
                        fontSize: '14px'
                      }}
                    >{`${values.description.length}/1000`}</p>
                  )}
                  {touched.description && errors.description && (
                    <div
                      style={{
                        color: 'red',
                        margin: '5px 0px',
                        fontSize: '14px',
                        fontFamily: 'Kyiv-Sans-Regular'
                      }}
                    >
                      {errors.description}
                    </div>
                  )}
                  {isShowEmoji && (
                    <ClickAwayListener
                      onClickAway={() => {
                        setIsShowEmoji(false);
                      }}
                    >
                      <Box>
                        {' '}
                        <EmojiPicker
                          onEmojiClick={(emojiData, event) => {
                            let newStr = `${values.description}${emojiData.emoji}`;
                            setFieldValue('description', newStr);
                          }}
                          skinTonesDisabled
                          searchDisabled
                          previewConfig={{ showPreview: false }}
                          style={{
                            position: 'absolute',
                            bottom: '-280px',
                            right: '50px',
                            zIndex: '99'
                          }}
                          width={'60%'}
                          height={'300px'}
                        />
                      </Box>
                    </ClickAwayListener>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}
                >
                  {' '}
                  <Grid
                    container
                    spacing={1}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    {!data && (
                      <>
                        <Typography
                          variant="subtitle2"
                          sx={{ mb: 2, fontSize: 16, fontFamily: 'Kyiv-Sans-Bold' }}
                        >
                          Додай фото
                        </Typography>
                        <Typography
                          variant="subtitle"
                          sx={{ fontSize: 16, color: '#B1B1B1', mb: 2 }}
                        >
                          Можливо завантажити до 10 вкладень. Один елемент не має важити більше 10
                          МБ.
                        </Typography>
                      </>
                    )}

                    <Grid item xs={12} md={12} lg={12} sx={{ display: 'flex', alignItems: 'left' }}>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                          // justifyContent:'space-between',
                          paddingBottom: '14px',
                          marginBottom: '20px',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          border: '1px dashed #60a5df',
                          borderRadius: '5px'
                        }}
                      >
                        {imgs.length !== 0 &&
                          imgs.map((file, index) => (
                            <div
                              key={'image' + index}
                              className="uploaded-img"
                              style={{ marginLeft: '14px', marginTop: '14px' }}
                              onClick={() => {
                                const newArray = imgs;
                                const newImgsToDelete = imgsToDelete;
                                if (data) {
                                  newImgsToDelete.push(
                                    data.encodedPhotos.find((file, i) => index === i)
                                  );
                                }

                                newArray.splice(index, 1);
                                setImgs([...newArray]);
                                setImgsToDelete([...newImgsToDelete]);
                              }}
                            >
                              <img
                                style={{ cursor: 'pointer' }}
                                alt={'img' + index}
                                width={100}
                                src={URL.createObjectURL(file)}
                              />
                            </div>
                          ))}
                        <input
                          multiple
                          accept="image/*"
                          style={{ display: 'none' }}
                          id="button-file-service"
                          disabled={imgs.length === 10 ? true : false}
                          // onChange={(e) => {
                          //   if (imgs.length !== 10) {
                          //     setFile(e.target.files[0]);
                          //     dispatch(setIsOpenImageCropModal(true));
                          //   }
                          //   e.target.value = '';
                          // }}
                          type="file"
                        />
                        {imgs.length !== 10 && (
                          <label
                            htmlFor="button-file-service"
                            style={{
                              width: '100px',
                              height: '100px',
                              marginLeft: '14px',
                              marginTop: '14px',

                              cursor: 'pointer'
                            }}
                          >
                            <div
                              component="span"
                              variant="outlined"
                              style={{
                                width: '100px',
                                height: '100px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: '1px solid #A1A1A1',
                                borderRadius: '5px',
                                cursor: 'pointer'
                              }}
                            >
                              <AddIcon
                                style={{ color: 'rgba(96, 165, 223, 1)' }}
                                sx={{
                                  '&:hover': {
                                    width: 100
                                  }
                                }}
                              />
                            </div>
                          </label>
                        )}
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={6}
                      sx={{ display: 'flex', alignItems: 'left', flexDirection: 'column' }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ mb: 2, fontSize: 16, fontFamily: 'Kyiv-Sans-Bold' }}
                      >
                        Варіанти доставки:
                      </Typography>
                      <Typography variant="subtitle" sx={{ fontSize: 16, color: '#B1B1B1', mb: 2 }}>
                        Оберіть які варіанти доставки можливі для вашої послуги*.
                      </Typography>
                      <FormControlLabel
                        sx={{ ml: '0px', mb: 0.5, width: 'fit-content' }}
                        control={
                          <Checkbox
                            disableRipple
                            checked={values.deliveryOption.includes('Нова Пошта')}
                            onChange={() => {
                              handleChange('delivery', 'Нова Пошта');
                            }}
                            sx={{
                              width: '25px',
                              height: '25px',
                              '&.Mui-checked': {
                                color: 'rgba(96, 165, 223, 1) !important'
                              }
                            }}
                          />
                        }
                        label="Нова Пошта"
                      />
                      <FormControlLabel
                        sx={{ ml: '0px', mb: 0.5, width: 'fit-content' }}
                        control={
                          <Checkbox
                            disableRipple
                            checked={values.deliveryOption.includes('УкрПошта')}
                            onChange={() => handleChange('delivery', 'УкрПошта')}
                            sx={{
                              width: '25px',
                              height: '25px',
                              '&.Mui-checked': {
                                color: 'rgba(96, 165, 223, 1) !important'
                              }
                            }}
                          />
                        }
                        label="УкрПошта"
                      />
                      <FormControlLabel
                        sx={{ ml: '0px', width: 'fit-content' }}
                        control={
                          <Checkbox
                            disableRipple
                            checked={values.deliveryOption.includes("Кур'єрська доставка")}
                            onChange={() => handleChange('delivery', "Кур'єрська доставка")}
                            sx={{
                              width: '25px',
                              height: '25px',
                              '&.Mui-checked': {
                                color: 'rgba(96, 165, 223, 1) !important'
                              }
                            }}
                          />
                        }
                        label="Кур'єрська доставка"
                      />
                      <div
                        style={{
                          color: 'red',
                          margin: '5px 0px',
                          fontSize: '14px',
                          fontFamily: 'Kyiv-Sans-Regular'
                        }}
                      >
                        {touched.deliveryOption && errors.deliveryOption}
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={5}
                      md={6}
                      lg={6}
                      sx={{ display: 'flex', alignItems: 'left', flexDirection: 'column' }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ mb: 2, fontSize: 16, fontFamily: 'Kyiv-Sans-Bold' }}
                      >
                        Варіанти оплати:
                      </Typography>
                      <Typography variant="subtitle" sx={{ fontSize: 16, color: '#B1B1B1', mb: 2 }}>
                        Оберіть які варіанти оплати можливі для вашої послуги*.
                      </Typography>
                      <FormControlLabel
                        sx={{ ml: '0px', mb: 0.5, width: 'fit-content' }}
                        control={
                          <Checkbox
                            disableRipple
                            checked={values.paymentOption.includes('Повна оплата')}
                            onChange={() => handleChange('payment', 'Повна оплата')}
                            sx={{
                              width: '25px',
                              height: '25px',
                              '&.Mui-checked': {
                                color: 'rgba(96, 165, 223, 1) !important'
                              }
                            }}
                          />
                        }
                        label="Повна оплата"
                      />
                      <FormControlLabel
                        sx={{ ml: '0px', mb: 0.5, width: 'fit-content' }}
                        control={
                          <Checkbox
                            disableRipple
                            checked={values.paymentOption.includes('Часткова передоплата')}
                            onChange={() => handleChange('payment', 'Часткова передоплата')}
                            sx={{
                              width: '25px',
                              height: '25px',
                              '&.Mui-checked': {
                                color: 'rgba(96, 165, 223, 1) !important'
                              }
                            }}
                          />
                        }
                        label="Часткова передоплата"
                      />
                      <FormControlLabel
                        sx={{ ml: '0px', width: 'fit-content' }}
                        control={
                          <Checkbox
                            disableRipple
                            checked={values.paymentOption.includes('Оплата при отриманні')}
                            onChange={() => handleChange('payment', 'Оплата при отриманні')}
                            sx={{
                              width: '25px',
                              height: '25px',
                              '&.Mui-checked': {
                                color: 'rgba(96, 165, 223, 1) !important'
                              }
                            }}
                          />
                        }
                        label="Оплата при отриманні"
                      />
                      <div
                        style={{
                          color: 'red',
                          margin: '5px 0px',
                          fontSize: '14px',
                          fontFamily: 'Kyiv-Sans-Regular'
                        }}
                      >
                        {touched.paymentOption && errors.paymentOption}
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={6}
                      sx={{ display: 'flex', alignItems: 'left', flexDirection: 'column' }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ mb: 2, fontSize: 16, fontFamily: 'Kyiv-Sans-Bold' }}
                      >
                        Дні відправки замовлення:
                      </Typography>
                      <Typography variant="subtitle" sx={{ fontSize: 16, color: '#B1B1B1', mb: 2 }}>
                        Оберіть дні, в які можлива відправка для вашої послуги*.
                      </Typography>
                      <Autocomplete
                        value={values.deliveryDays}
                        onChange={(event, newValue) => {
                          if (newValue.includes('Щодня') && newValue.length === 1) {
                            setFieldValue('deliveryDays', [
                              'Щодня',
                              'Понеділок',
                              'Вівторок',
                              'Середа',
                              'Четвер',
                              "П'ятниця",
                              'Субота',
                              'Неділя'
                            ]);
                          } else if (
                            newValue.includes('Щодня') &&
                            values.deliveryDays.includes('Щодня') &&
                            newValue.length < 8
                          ) {
                            setFieldValue(
                              'deliveryDays',
                              newValue.filter((el) => el !== 'Щодня')
                            );
                          } else if (
                            newValue.includes('Щодня') &&
                            !values.deliveryDays.includes('Щодня') &&
                            newValue.length < 8
                          ) {
                            setFieldValue('deliveryDays', [
                              'Щодня',
                              'Понеділок',
                              'Вівторок',
                              'Середа',
                              'Четвер',
                              "П'ятниця",
                              'Субота',
                              'Неділя'
                            ]);
                          } else if (
                            newValue.includes('Понеділок') &&
                            newValue.includes('Вівторок') &&
                            newValue.includes('Середа') &&
                            newValue.includes('Четвер') &&
                            newValue.includes("П'ятниця") &&
                            newValue.includes('Субота') &&
                            newValue.includes('Неділя') &&
                            !values.deliveryDays.includes('Щодня')
                          ) {
                            setFieldValue('deliveryDays', [
                              'Щодня',
                              'Понеділок',
                              'Вівторок',
                              'Середа',
                              'Четвер',
                              "П'ятниця",
                              'Субота',
                              'Неділя'
                            ]);
                          } else if (
                            newValue.includes('Понеділок') &&
                            newValue.includes('Вівторок') &&
                            newValue.includes('Середа') &&
                            newValue.includes('Четвер') &&
                            newValue.includes("П'ятниця") &&
                            newValue.includes('Субота') &&
                            newValue.includes('Неділя') &&
                            !newValue.includes('Щодня') &&
                            values.deliveryDays.includes('Щодня')
                          ) {
                            setFieldValue('deliveryDays', []);
                          } else {
                            setFieldValue('deliveryDays', newValue);
                          }
                        }}
                        multiple
                        id="deliveryDays-autocomplete"
                        options={[
                          'Щодня',
                          'Понеділок',
                          'Вівторок',
                          'Середа',
                          'Четвер',
                          "П'ятниця",
                          'Субота',
                          'Неділя'
                        ]}
                        disableClearable
                        disableCloseOnSelect
                        getOptionLabel={(option) => {
                          switch (option) {
                            case 'Щодня':
                              return 'Щодня';
                            case 'Понеділок':
                              return 'Пн';
                            case 'Вівторок':
                              return 'Вт';
                            case 'Середа':
                              return 'Ср';
                            case 'Четвер':
                              return 'Чт';
                            case "П'ятниця":
                              return 'Пт';
                            case 'Субота':
                              return 'Сб';
                            case 'Неділя':
                              return 'Нд';
                            default:
                              return;
                          }
                        }}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              disableRipple
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option}
                          </li>
                        )}
                        style={{ width: 270 }}
                        renderInput={(params) => {
                          let newParams = params;
                          // if (newParams) {
                          //   let newItems =
                          //     params?.InputProps?.startAdornment &&
                          //     params?.InputProps?.startAdornment?.props &&
                          //     params?.InputProps?.startAdornment?.props?.children &&
                          //     params?.InputProps?.startAdornment?.props?.children.length !== 0
                          //       ? params?.InputProps?.startAdornment?.props?.children.filter(
                          //           (el) => el !== 'Щодня'
                          //         )
                          //       : [];
                          //       if(params?.InputProps?.startAdornment){
                          //   newParams.InputProps.startAdornment.props.children = newItems;}
                          // }
                          return (
                            <TextField
                              {...newParams}
                              // label="Дні відправки*"
                              placeholder={values.deliveryDays.length === 0 ? 'Дні відправки*' : ''}
                            />
                          );
                        }}
                        renderTags={(list) => {
                          if (list.includes('Щодня')) {
                            return <span>Щодня</span>;
                          } else {
                            let displayList = list
                              .map((option) => {
                                switch (option) {
                                  case 'Понеділок':
                                    return 'Пн';
                                  case 'Вівторок':
                                    return 'Вт';
                                  case 'Середа':
                                    return 'Ср';
                                  case 'Четвер':
                                    return 'Чт';
                                  case "П'ятниця":
                                    return 'Пт';
                                  case 'Субота':
                                    return 'Сб';
                                  case 'Неділя':
                                    return 'Нд';
                                  default:
                                    return null;
                                }
                              })
                              .join(', ');
                            return <span>{displayList}</span>;
                          }
                        }}
                      />

                      <div
                        style={{
                          color: 'red',
                          margin: '5px 0px',
                          fontSize: '14px',
                          fontFamily: 'Kyiv-Sans-Regular'
                        }}
                      >
                        {touched.deliveryDays && errors.deliveryDays}
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={6}
                      sx={{
                        display: 'flex',
                        alignItems: 'left',
                        flexDirection: 'column',
                        position: 'relative'
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ mb: 2, fontSize: 16, fontFamily: 'Kyiv-Sans-Bold' }}
                      >
                        Орієнтовний термін збору замовлення:
                      </Typography>
                      <Typography variant="subtitle" sx={{ fontSize: 16, color: '#B1B1B1', mb: 2 }}>
                        Вкажіть, скільки днів триватиме збір замовлення перед відправкою, включаючи
                        час виготовлення*.
                      </Typography>
                      <CssTextField
                        label="Кіл-сть днів*"
                        placeholder="Кіл-сть днів*"
                        variant="standard"
                        fullWidth
                        size="normal"
                        sx={{ width: 130, marginTop: '0px !important' }}
                        inputProps={{ style: { textAlign: 'left' } }}
                        error={Boolean(touched.estimatedTime && errors.estimatedTime)}
                        {...getFieldProps('estimatedTime')}
                        value={values.estimatedTime}
                      >
                        {values.estimatedTime}
                      </CssTextField>
                      {touched.estimatedTime && errors.estimatedTime && (
                        <div
                          style={{
                            position: 'absolute',
                            bottom: '-18px',
                            color: 'red',
                            margin: '5px 0px',
                            fontSize: '14px',
                            fontFamily: 'Kyiv-Sans-Regular'
                          }}
                        >
                          {errors.estimatedTime}
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
                >
                  {!isLoading ? (
                    <>
                      <LetsGoBtn3
                        disableRipple
                        disabled={
                          values.nameService.length < 2 ||
                          values.category.length === 0 ||
                          values.availability.length === 0 ||
                          values.price.length === 0 ||
                          errors?.price ||
                          errors?.valueOfInitOfMeasure ||
                          values.currency.length === 0 ||
                          values.valueOfInitOfMeasure.length === 0 ||
                          values.initOfMeasure.length === 0 ||
                          values.deliveryOption.length === 0 ||
                          values.paymentOption.length === 0 ||
                          values.deliveryDays.length === 0 ||
                          values.estimatedTime === 0 ||
                          errors?.estimatedTime ||
                          errors?.availableQuantity
                        }
                        size="large"
                        onClick={() => {
                          submitForm();
                        }}
                        width={257}
                        style={{ textTransform: 'inherit' }}
                        text={data ? 'Зберегти зміни' : 'Створити'}
                      />
                      {/* {data && (
                        <LetsGoBtn2
                          icon
                          usedIcon={<DeleteOutlineIcon sx={{ color: '#60A5DF', mr: 1 }} />}
                          onClick={() => {
                            dispatch(
                              setIsOpenDeleteModal({
                                isOpen: true,
                                text: 'цю послугу?',
                                func: deleteService,
                                id: data.id
                              })
                            );
                          }}
                          marginTop="0px"
                          width={257}
                          sx={{ textTransform: 'inherit', marginLeft: '20px !important' }}
                          text="Видалити послугу"
                        />
                      )} */}
                    </>
                  ) : (
                    <div style={{ marginTop: '10px' }}>
                      <Spinner type="small" />
                    </div>
                  )}
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Box>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userData: state.global.userData
  };
};
export default connect(mapStateToProps)(ServiceModal);
