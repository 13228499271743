import { useState } from "react";

//Material
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import starBlue from "../../assets/StarBlue.png";
import starGreen from "../../assets/StarGreen.png";

import { useDispatch } from "react-redux";

// import { useTranslate } from 'react-redux-multilingual';
import { LetsGoBtn2, LetsGoBtn3 } from "components/Buttons";
import { StyledLabel } from "components/StyledComponents";
import { setIsOpenStatusSetterModal } from "../../redux/mainReducer";
// import './AuthModal.css';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  maxWidth: 900,
  bgcolor: "background.paper",
  borderRadius: "20px",
  border: "none",
  boxShadow: 24,
  padding: "50px 80px 50px 80px",
  overflow: "scroll",
  height: "inherit",
  maxHeight: "100vh",
  scrollbarWidth: "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
};
const options = [
  { status: "USER", desc: "Базовий" },
  { status: "ADMIN", desc: "Адмін" },
  { status: "SUPER_ADMIN", desc: "Супер-Адмін" },
];
function StatusSetterModal({ isOpenStatusSetterModal }) {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(isOpenStatusSetterModal?.status);

  return (
    <div>
      <Modal
        open={isOpenStatusSetterModal?.isOpen}
        onClose={(event, reason) => {
          if (reason === "backdropClick") {
            return;
          }

          dispatch(
            setIsOpenStatusSetterModal({
              isOpen: false,
              func: null,
              id: null,
              status: "",
            })
          );
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="bonfair_delete_modal"
      >
        <Box sx={style} className="bonfair_delete_modal-box">
          <img
            style={{ position: "fixed", zIndex: "0", top: "0", left: "0" }}
            src={starGreen}
            alt="star-green"
          />
          <img
            style={{
              position: "fixed",
              zIndex: "0",
              bottom: "40px",
              right: "0",
            }}
            src={starBlue}
            alt="star-green"
          />
          <CloseIcon
            className="bonfair_delete_modal-close"
            style={{
              position: "absolute",
              right: "10px",
              top: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              dispatch(
                setIsOpenStatusSetterModal({
                  isOpen: false,
                  func: null,
                  id: null,
                  status: "",
                })
              );
            }}
          />

          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <StyledLabel className="deleteModal-title-text">
                {/* {translate('bonfair_service_modal')} */}
                {"Зміна статусу"}
              </StyledLabel>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  mb: 2,
                  fontSize: 16,
                  fontFamily: "Kyiv-Sans-Bold",
                  textAlign: "center",
                  maxWidth: "70%",
                }}
              >
                {"Змінити статус цього юзера?"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Autocomplete
                value={status}
                disableClearable
                onChange={(event, newValue) => {
                  setStatus(newValue?.status);
                }}
                id="controllable-states"
                options={options}
                getOptionLabel={(option) => {
                  const label = options.find((op) => op?.status === option);
                  if (label) {
                    return label?.desc;
                  } else {
                    return option?.desc;
                  }
                }}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Оберіть статус" />
                )}
              />
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "20px auto 0px",
                maxWidth: "350px",
              }}
            >
              <LetsGoBtn3
                disabled={
                  status === isOpenStatusSetterModal?.status ? true : false
                }
                size="large"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();

                  isOpenStatusSetterModal?.func(
                    isOpenStatusSetterModal?.id,
                    status
                  );
                }}
                width={150}
                style={{ textTransform: "inherit" }}
                text={"Змінити"}
              />
              <LetsGoBtn2
                marginTop="0"
                size="large"
                onClick={() => {
                  dispatch(
                    setIsOpenStatusSetterModal({
                      isOpen: false,
                      func: null,
                      id: null,
                      status: "",
                    })
                  );
                }}
                width={150}
                style={{ textTransform: "inherit" }}
                text="Відмінити"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

export default StatusSetterModal;
