import ApiService from "../../../helpers/apiHelper";
import { setUserData } from "../../mainReducer";
const getUserDataThunk =
  (isAuth = false, navigate, loader) =>
  (dispatch) => {
    const apiUser = new ApiService("auth/user");

    if (isAuth) {
      apiUser
        .getAll()
        .then((res) => {
          loader(false);
          if (
            res?.result.role === "ADMIN" ||
            res?.result.role === "SUPER_ADMIN"
          ) {
            dispatch(setUserData(res.result));
            navigate("/dashboard");
          } else {
            dispatch(setUserData({}));
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            navigate("/sign-in");
          }
        })
        .catch((error) => {
          loader(false);
        });
    } else {
      apiUser.getAll().then((res) => {
        if (
          res?.result.role === "ADMIN" ||
          res?.result.role === "SUPER_ADMIN"
        ) {
          dispatch(setUserData(res.result));
          navigate("/dashboard");
        } else {
          dispatch(setUserData({}));
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          navigate("/sign-in");
        }
      });
    }
  };
// eslint-disable-next-line
export default {
  getUserDataThunk,
};
