import { errorHandler } from "./errorHandler";

export default class ApiService {
  constructor(apiBase, translate) {
    // this._apiBase = `${window.location.protocol}//dev.bonfairplace.com/v1/${apiBase}`;
    this._apiBase = `${window.location.protocol}//${window.location.hostname}/v1/${apiBase}`;

    // this._apiBase = `${this.getUrl(apiBase)}/${
    //   apiBase.includes("post") || apiBase.includes("service")
    //     ? apiBase
    //     : apiBase.substring(apiBase.indexOf("/") + 1)
    // }`;
    this._tanslate = translate;
  }
  // method for development
  // eslint-disable-next-line
  getUrl = (apiBase) => {
    if (
      apiBase.includes("post") ||
      apiBase.includes("service") ||
      apiBase.includes("comment") ||
      apiBase.includes("feedback") ||
      apiBase.includes("ribbon") ||
      apiBase.includes("scheduler") ||
      apiBase.includes("order")
    ) {
      return window.config.REACT_APP_API_HOST_2;
    } else if (
      apiBase.includes("messages") ||
      apiBase.includes("chats") ||
      apiBase.includes("participants") ||
      apiBase.includes("notification")
    ) {
      return window.config.REACT_APP_API_HOST_3;
    } else if (
      apiBase.includes("send-message") ||
      apiBase.includes("send-photo-message")
    ) {
      return window.config.REACT_APP_API_HOST_4;
    } else if (
      apiBase.includes("photo") &&
      !apiBase.includes("send-photo-message") &&
      !apiBase.includes("add-photo")
    ) {
      return window.config.REACT_APP_API_HOST_5;
    } else {
      return window.config.REACT_APP_API_HOST;
    }
  };
  getResource = async (
    url,
    query,
    method = "GET",
    body,
    headers = {
      "Accept-Encoding": "gzip",
      // "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
      crossDomain: true,
    },
    isFormData = false,
    isRefresh = false,
    isAdminAction = false
  ) => {
    const absUrl = new URL(`${this._apiBase}${url}`);
    const token = localStorage.getItem("accessToken");
    const refresh = localStorage.getItem("refreshToken");
    const userToken = localStorage.getItem("userToken");
    if (query) {
      Object.keys(query).forEach((key) =>
        absUrl.searchParams.append(key, query[key])
      );
    }

    if (body) {
      if (!isFormData) {
        body = JSON.stringify(body);
        headers["Content-Type"] = "application/json";
      } else {
        const formData = new FormData();
        const names = Object.keys(body);
        names.map(
          (el) =>
            el !== "media" &&
            el !== "photos" &&
            el !== "newPhotos" &&
            formData.append(el, body[el])
        );
        if (body?.media) {
          body.media.map((img) => formData.append("media", img));
        }
        if (body?.photos) {
          body.photos.map((img) => formData.append("photos", img));
        }
        if (body?.newPhotos) {
          body.newPhotos.map((img) => formData.append("newPhotos", img));
        }
        body = formData;
      }
    }
    // if (token && Object.keys(headers).length === 0) {
    if (token && !headers?.Authorization && !isRefresh && !isAdminAction) {
      headers.Authorization = `${token}`;
    } else if (
      token &&
      !headers?.Authorization &&
      !isRefresh &&
      isAdminAction
    ) {
      headers.Authorization = `${userToken}`;
    }

    const res = await fetch(absUrl, {
      "Access-Control-Allow-Credentials": true,
      withCredentials: true,
      credentials: "include",
      method,
      body,
      headers: {
        ...headers,
      },
    });

    const checkRes = async (translate) => {
      const resData = await res.json();

      if (resData.status === "ok") return resData;
      // if (resData.status === 'ok' || res.status === 200) return resData;

      if (
        (resData?.info?.error === "Unauthorized" &&
          resData?.info?.message !== "Invalid email or password") ||
        resData?.info === "Token is not valid" ||
        resData?.message === "Authentication failed" ||
        resData?.info?.result === "Unauthorized"
      ) {
        localStorage.removeItem("accessToken");
        // const newRefresh = await fetch(
        //   `${window.config.REACT_APP_API_HOST}/refresh`,
        //   {
        const newRefresh = await fetch(
          `${window.location.protocol}//${window.location.hostname}/v1/auth/refresh`,
          {
            method: "POST",
            body: JSON.stringify({ refreshToken: refresh }),
            // eslint-disable-next-line
            headers: { ["Content-Type"]: "application/json" },
          }
        );
        const refreshToken = await newRefresh.json();

        if (newRefresh.status === 200) {
          localStorage.setItem(
            "accessToken",
            `Bearer ${refreshToken.accessToken}`
          );
          localStorage.setItem("refreshToken", `${refreshToken.refreshToken}`);

          const newTry = await fetch(absUrl, {
            "Access-Control-Allow-Credentials": true,
            withCredentials: true,
            credentials: "include",
            method,
            body,
            headers: {
              ...headers,
              Authorization: `${localStorage.getItem("accessToken")}`,
            },
          });
          const newResData = await newTry.json();
          if (newResData.status === "ok") {
            return newResData;
          }
          throw errorHandler(resData, translate);
        } else {
          // localStorage.removeItem('accessToken');
          // localStorage.removeItem('refreshToken');
          // // const newRefreshTwo = await fetch(`${window.config.REACT_APP_API_HOST}/refresh`, {
          // const newRefreshTwo = await fetch(
          //   `${window.location.protocol}//${window.location.hostname}/v1/auth/refresh`,
          //   {
          //     method: 'POST',
          //     body: JSON.stringify({ refreshToken: refresh.substring(7) }),
          //     // eslint-disable-next-line
          //     headers: { ['Content-Type']: 'application/json' }
          //   }
          // );
          // const refreshTokens = await newRefreshTwo.json();
          // if (newRefreshTwo.status === 200) {
          //   localStorage.setItem('accessToken', `Bearer ${refreshTokens.accessToken}`);
          //   localStorage.setItem('refreshToken', `Bearer ${refreshTokens.refreshToken}`);

          //   const newTryTwo = await fetch(absUrl, {
          //     method,
          //     body,
          //     headers: {
          //       ...headers,
          //       Authorization: `${localStorage.getItem('accessToken')}`
          //     }
          //   });
          //   const newResDataTwo = await newTryTwo.json();
          //   if (newResDataTwo.status === 'ok') {
          //     return newResDataTwo;
          //   }
          //   throw errorHandler(resData, translate);
          // } else {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          window.location.href = "/";
          // }

          // window.location.href = '/login';
        }
      } else {
        throw errorHandler(resData, translate);
      }

      // if (!res.ok) {
      //   throw errorHandler(resData, this._translate);
      // }
      // return null;
    };
    return checkRes(this._tanslate);
  };

  getAll = async (query) => this.getResource("", query, "GET", null);
  getItemById = async (id, query) => this.getResource(`/${id}`, query);
  updateDataID = async (id, body) =>
    this.getResource(`/${id}`, null, "POST", body);
  deleteItem = async (id) => this.getResource(`/${id}`, null, "DELETE");
  deleteItemAdmin = async (id) =>
    this.getResource(`/${id}`, null, "DELETE", null, {}, false, false, true);
  deleteItemQuery = async (query) => this.getResource(``, query, "DELETE");
  deleteBody = async (body) => this.getResource(``, null, "DELETE", body);
  sendRequest = async (body, isFormData) =>
    this.getResource("", null, "POST", body, {}, isFormData);
  resetPass = async (query) => this.getResource("", query, "PUT", null);
  updateData = async (body, isFormData) =>
    this.getResource("", null, "PUT", body, {}, isFormData);
  updateDataAdmin = async (body, isFormData) =>
    this.getResource("", null, "PUT", body, {}, isFormData, false, true);
  updateDataQuery = async (query) => this.getResource("", query, "PUT", null);
  updateDataQueryId = async (id, query) =>
    this.getResource(`/${id}`, query, "PUT", null);
  updateDataQueryPost = async (query) =>
    this.getResource("", query, "POST", null);
  updateDataQueryPostBody = async (query, body) =>
    this.getResource("", query, "POST", body);
  postDataQuery = async (query) => this.getResource("", query, "POST", null);
  authRequest = async (headers) =>
    this.getResource("", null, "POST", null, headers);
  refreshRequest = async (body, headers) =>
    this.getResource("", null, "POST", body, headers, false, true);
}
