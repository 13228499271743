import { createSlice } from "@reduxjs/toolkit";

const mainReducer = createSlice({
  name: "mainReducer",
  initialState: {
    userData: {},
    isOpenSearchBar: false,
    isOpenStatusSetterModal: {
      isOpen: false,
      func: null,
      id: null,
      status: "",
    },
    isOpenPremiumSetterModal: {
      isOpen: false,
      func: null,
      id: null,
      isPremium: false,
      endDate: null,
    },
    isOpenDeleteModal: {
      isOpen: false,
      text: "",
      func: null,
      id: null,
      creatorId: null,
    },
    isOpenBlockModal: {
      isOpen: false,
      text: "",
      func: null,
      id: null,
      // creatorId: null,
    },
    isOpenUserEditModal: false,
    isOpenSupportAnswerModal: { isOpen: false, data: null },
    isOpenServiceModal: { isOpen: false, data: null, func: null },
    isOpenPostModal: { isOpen: false, data: null, func: null },
    isOpenImageCropModal: false,
  },
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setIsOpenSearchBar: (state, action) => {
      state.isOpenSearchBar = action.payload;
    },
    setIsOpenStatusSetterModal: (state, action) => {
      state.isOpenStatusSetterModal = action.payload;
    },
    setIsOpenPremiumSetterModal: (state, action) => {
      state.isOpenPremiumSetterModal = action.payload;
    },
    setIsOpenDeleteModal: (state, action) => {
      state.isOpenDeleteModal = action.payload;
    },
    setIsOpenBlockModal: (state, action) => {
      state.isOpenBlockModal = action.payload;
    },
    setIsOpenUserEditModal: (state, action) => {
      state.isOpenUserEditModal = action.payload;
    },
    setIsOpenSupportAnswerModal: (state, action) => {
      state.isOpenSupportAnswerModal = action.payload;
    },
    setIsOpenServiceModal: (state, action) => {
      state.isOpenServiceModal = action.payload;
    },
    setIsOpenPostModal: (state, action) => {
      state.isOpenPostModal = action.payload;
    },
    setIsOpenImageCropModal: (state, action) => {
      state.isOpenImageCropModal = action.payload;
    },
  },
});

export const {
  setUserData,
  setIsOpenSearchBar,
  setIsOpenDeleteModal,
  setIsOpenBlockModal,
  setIsOpenUserEditModal,
  setIsOpenServiceModal,
  setIsOpenPostModal,
  setIsOpenImageCropModal,
  setIsOpenPremiumSetterModal,
  setIsOpenStatusSetterModal,
  setIsOpenSupportAnswerModal,
} = mainReducer.actions;
//
export default mainReducer.reducer;
