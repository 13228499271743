/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import mainReducer from "./redux/mainReducer";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";
import {
  applyMiddleware,
  combineReducers,
  createStore,
} from "@reduxjs/toolkit";

import "./index.css";
import { SnackbarProvider } from "notistack";
import { Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const container = document.getElementById("app");
const root = createRoot(container);
const reducers = combineReducers(Object.assign({}, { global: mainReducer }));
const store = createStore(reducers, applyMiddleware(thunk));
const snackRef = React.createRef();

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <SnackbarProvider
          ref={snackRef}
          action={(id) => (
            <CloseIcon
              sx={{
                cursor: "pointer",
                fontSize: 18,
                width: "20px",
                height: "20px",
                padding: "2px",
                ":hover": {
                  backgroundColor: "#fdfdfd1a",
                  borderRadius: "10px",
                },
              }}
              onClick={() => snackRef.current.closeSnackbar(id)}
            />
          )}
          maxSnack={5}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          TransitionComponent={Slide}
          autoHideDuration={2000}
        >
          {" "}
          <App />
        </SnackbarProvider>
      </MaterialUIControllerProvider>
    </BrowserRouter>
  </Provider>
);
