/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect, useMemo, useState } from "react";

// react-router components
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

// @mui material components
import CssBaseline from "@mui/material/CssBaseline";
// eslint-disable-next-line
import Icon from "@mui/material/Icon";
import { ThemeProvider } from "@mui/material/styles";

// Material Dashboard 2 React components
// eslint-disable-next-line
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Configurator from "examples/Configurator";
import Sidenav from "examples/Sidenav";

// Material Dashboard 2 React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import rtlPlugin from "stylis-plugin-rtl";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import {
  setMiniSidenav,
  setOpenConfigurator,
  useMaterialUIController,
} from "context";

// Images
import brandDark from "assets/images/logo-ct-dark.png";
import brandWhite from "assets/images/logo-ct.png";
import { connect, useSelector } from "react-redux";
// import ApiService from "./helpers/apiHelper";
import { base } from "./redux/thunk";
import DeleteModal from "components/DeleteModal/DeleteModal";
import ServiceModal from "components/ServiceEditModal/ServiceModal";
import PostModal from "components/PostEditModal/PostModal";
import BlockModal from "components/BlockModal/BlockModal";
import PremiumSetterModal from "components/modals/PremiumSetterModal";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/uk";
import StatusSetterModal from "components/modals/StatusSetterModal";
import SupportAnswerModal from "components/modals/SupportAnswerModal";

function App({ getUserDataThunk }) {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const userData = useSelector((state) => state.global.userData);
  const isOpenStatusSetterModal = useSelector(
    (state) => state.global.isOpenStatusSetterModal
  );
  const isOpenPremiumSetterModal = useSelector(
    (state) => state.global.isOpenPremiumSetterModal
  );
  const isOpenDeleteModal = useSelector(
    (state) => state.global.isOpenDeleteModal
  );
  const isOpenBlockModal = useSelector(
    (state) => state.global.isOpenBlockModal
  );
  const isOpenServiceModal = useSelector(
    (state) => state.global.isOpenServiceModal
  );
  const isOpenPostModal = useSelector((state) => state.global.isOpenPostModal);
  const isOpenSupportAnswerModal = useSelector(
    (state) => state.global.isOpenSupportAnswerModal
  );
  const navigate = useNavigate();

  // const authApi = new ApiService("auth/profile");

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  // eslint-disable-next-line
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route !== "/sign-in") {
        return (
          <Route
            exact
            path={route.route}
            element={
              userData && userData?.id ? (
                route.roleAccess.includes(userData?.role) ? (
                  route.component
                ) : null
              ) : (
                <Navigate to={"/sign-in"} replace exact />
              )
            }
            key={route.key}
          />
        );
      }
      if (route.route === "/sign-in") {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      // if (!userData) {
      //   if (route.route === "/sign-in") {
      //     return (
      //       <Route
      //         exact
      //         path={route.route}
      //         element={route.component}
      //         key={route.key}
      //       />
      //     );
      //   }
      // }

      return null;
    });

  // const configsButton = (
  //   <MDBox
  //     display="flex"
  //     justifyContent="center"
  //     alignItems="center"
  //     width="3.25rem"
  //     height="3.25rem"
  //     bgColor="white"
  //     shadow="sm"
  //     borderRadius="50%"
  //     position="fixed"
  //     right="2rem"
  //     bottom="2rem"
  //     zIndex={99}
  //     color="dark"
  //     sx={{ cursor: "pointer" }}
  //     onClick={handleConfiguratorOpen}
  //   >
  //     <Icon fontSize="small" color="inherit">
  //       settings
  //     </Icon>
  //   </MDBox>
  // );
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    dayjs.locale("uk");
    if ((!userData && token) || (Object.keys(userData).length === 0 && token)) {
      getUserDataThunk(false, navigate);

      // .catch((error) => enqueueSnackbar('Потрібно авторизуватись', { variant: 'error' }));
    }

    // eslint-disable-next-line
  }, [userData]);

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <LocalizationProvider adapterLocale={"uk"} dateAdapter={AdapterDayjs}>
          {isOpenStatusSetterModal.isOpen && (
            <StatusSetterModal
              isOpenStatusSetterModal={isOpenStatusSetterModal}
            />
          )}
          {isOpenPremiumSetterModal.isOpen && (
            <PremiumSetterModal
              isOpenPremiumSetterModal={isOpenPremiumSetterModal}
            />
          )}
          {isOpenSupportAnswerModal.isOpen && (
            <SupportAnswerModal
              isOpenSupportAnswerModal={isOpenSupportAnswerModal}
            />
          )}
          {isOpenDeleteModal.isOpen && (
            <DeleteModal
              isOpenModal={isOpenDeleteModal.isOpen}
              text={isOpenDeleteModal.text}
              func={isOpenDeleteModal.func}
              id={isOpenDeleteModal.id}
              creator={isOpenDeleteModal.creatorId}
            />
          )}
          {isOpenBlockModal.isOpen && (
            <BlockModal
              isOpenModal={isOpenBlockModal.isOpen}
              text={isOpenBlockModal.text}
              func={isOpenBlockModal.func}
              id={isOpenBlockModal.id}
              // creator={isOpenBlockModal.creatorId}
            />
          )}
          {isOpenServiceModal.isOpen && (
            <ServiceModal
              isOpenModal={isOpenServiceModal.isOpen}
              data={isOpenServiceModal.data}
              refreshData={isOpenServiceModal.func}
            />
          )}
          {isOpenPostModal.isOpen && (
            <PostModal
              isOpenModal={isOpenPostModal.isOpen}
              data={isOpenPostModal.data}
              refreshData={isOpenPostModal.func}
            />
          )}
          <CssBaseline />
          {layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={
                  (transparentSidenav && !darkMode) || whiteSidenav
                    ? brandDark
                    : brandWhite
                }
                brandName="FinLab Адмінпанель"
                routes={routes.filter((route) =>
                  route?.roleAccess.includes(userData?.role)
                )}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              {/* <Configurator /> */}
              {/* {configsButton} */}
            </>
          )}
          {/* {layout === "vr" && <Configurator />} */}
          <Routes>
            {getRoutes(routes)}
            <Route path="*" element={<Navigate to="/sign-in" />} />
          </Routes>
        </LocalizationProvider>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <LocalizationProvider adapterLocale={"uk"} dateAdapter={AdapterDayjs}>
        {isOpenStatusSetterModal.isOpen && (
          <StatusSetterModal
            isOpenStatusSetterModal={isOpenStatusSetterModal}
          />
        )}
        {isOpenPremiumSetterModal.isOpen && (
          <PremiumSetterModal
            isOpenPremiumSetterModal={isOpenPremiumSetterModal}
          />
        )}
        {isOpenSupportAnswerModal.isOpen && (
          <SupportAnswerModal
            isOpenSupportAnswerModal={isOpenSupportAnswerModal}
          />
        )}
        {isOpenDeleteModal.isOpen && (
          <DeleteModal
            isOpenModal={isOpenDeleteModal.isOpen}
            text={isOpenDeleteModal.text}
            func={isOpenDeleteModal.func}
            id={isOpenDeleteModal.id}
            creator={isOpenDeleteModal.creatorId}
          />
        )}
        {isOpenBlockModal.isOpen && (
          <BlockModal
            isOpenModal={isOpenBlockModal.isOpen}
            text={isOpenBlockModal.text}
            func={isOpenBlockModal.func}
            id={isOpenBlockModal.id}
            // creator={isOpenBlockModal.creatorId}
          />
        )}
        {isOpenServiceModal.isOpen && (
          <ServiceModal
            isOpenModal={isOpenServiceModal.isOpen}
            data={isOpenServiceModal.data}
            refreshData={isOpenServiceModal.func}
          />
        )}
        {isOpenPostModal.isOpen && (
          <PostModal
            isOpenModal={isOpenPostModal.isOpen}
            data={isOpenPostModal.data}
            refreshData={isOpenPostModal.func}
          />
        )}
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={
                (transparentSidenav && !darkMode) || whiteSidenav
                  ? brandDark
                  : brandWhite
              }
              brandName="Адмінпанель"
              routes={routes.filter((route) =>
                route?.roleAccess.includes(userData?.role)
              )}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            {/* <Configurator /> */}
            {/* {configsButton} */}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/sign-in" />} />
        </Routes>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
const mapStateToProps = (state) => {
  return {
    userData: state.global.userData,
  };
};
export default connect(mapStateToProps, {
  getUserDataThunk: base.getUserDataThunk,
})(App);
