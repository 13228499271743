export const UACities = [
  {
    region: "Вінницька",
    cities: [
      "Агрономічне",
      "Адамівка",
      "Андрушівка",
      "Антонівка",
      "Бабин",
      "Бабинці",
      "Багринівці",
      "Байраківка",
      "Балабанівка",
      "Балин",
      "Бартошівка",
      "Березина",
      "Березівка",
      "Берестівка",
      "Бистрик",
      "Біла",
      "Білашки",
      "Білозерівка",
      "Білозірка",
      "Бірків",
      "Боблів",
      "Богданівка",
      "Бондурівка",
      "Борисівка",
      "Борсків",
      "Борщагівка",
      "Бохоники",
      "Брицьке",
      "Бруслинів",
      "Бруслинівка",
      "Бугаївка",
      "Будки",
      "Булаї",
      "Бурківці",
      "Бухни",
      "В’язовиця",
      "Василівка",
      "Васильківці",
      "Вахнівка",
      "Велика Бушинка",
      "Велика Ростівка",
      "Великі Крушлинці",
      "Вербівка",
      "Вернянка",
      "Веселівка",
      "Вишенька",
      "Вишнівка",
      "Війтівці",
      "Вікентіївка",
      "Вінниківці",
      "Вінницькі Хутори",
      "Вінниця",
      "Вовчок",
      "Воловодівка",
      "Володимирівка",
      "Воробіївка",
      "Вороновиця",
      "Ворошилівка",
      "Гавришівка",
      "Ганнівка",
      "Гвоздів",
      "Глинянець",
      "Гнівань",
      "Головеньки",
      "Гоноратка",
      "Гончарівка",
      "Гопчиця",
      "Горбанівка",
      "Горбівці",
      "Гордіївка",
      "Городище",
      "Гостинне",
      "Григорівка",
      "Грижинці",
      "Гришівці",
      "Громадське",
      "Гуменне",
      "Гунька",
      "Гута-Шершнівська",
      "Данилки",
      "Даньківка",
      "Дашів",
      "Дашківці",
      "Демидівка",
      "Десна",
      "Дзвониха",
      "Дзюньків",
      "Дібровинці",
      "Дівочина",
      "Довгалівка",
      "Довгополівка",
      "Довжок",
      "Дорожне",
      "Дубина",
      "Дубмаслівка",
      "Дубовець",
      "Дяківці",
      "Жабелівка",
      "Жадани",
      "Жахнівка",
      "Животівка",
      "Жорнище",
      "Журава",
      "Задорожнє",
      "Закриниччя",
      "Залужне",
      "Зарванка",
      "Зарванці",
      "Заруддя",
      "Зарудинці",
      "Збаржівка",
      "Зеленянка",
      "Зозів",
      "Зозівка",
      "Іванівка",
      "Іванківці",
      "Іваньки",
      "Івонівці",
      "Івча",
      "Іллінецьке",
      "Іллінці",
      "Ільківка",
      "Іскриня",
      "Іскрівка",
      "Йосипенки",
      "Кабатня",
      "Каленівка",
      "Кальнишівка",
      "Кам’янка",
      "Кам’яногірка",
      "Канава",
      "Кароліна",
      "Кільянівка",
      "Кліщів",
      "Кобелецьке",
      "Кобильня",
      "Ковалівка",
      "Кожанка",
      "Козаківка",
      "Козинці",
      "Колюхів",
      "Комарів",
      "Конюшівка",
      "Кордишівка",
      "Коровайна",
      "Королівка",
      "Косаківка",
      "Костянтинівка",
      "Коханівка",
      "Кошлани",
      "Красне",
      "Красненьке",
      "Краснянка",
      "Криківці",
      "Круги",
      "Круподеринці",
      "Ксаверівка",
      "Кудлаї",
      "Кулешів",
      "Кулига",
      "Кур’янці",
      "Курники",
      "Кусиківці",
      "Лаврівка",
      "Лани",
      "Левківка",
      "Липовець",
      "Лиса Гора",
      "Лисогора",
      "Лисянка",
      "Лінеччина",
      "Літин",
      "Літинка",
      "Літинські Хутори",
      "Ліщинці",
      "Лозувата",
      "Лопатинка",
      "Лугова",
      "Лука",
      "Лука-Мелешківська",
      "Лукашівка",
      "Люлинці",
      "Майдан",
      "Майдан-Чапельський",
      "Мала Біла",
      "Мала Бушинка",
      "Мала Ростівка",
      "Малинівка",
      "Малинки",
      "Малі Крушлинці",
      "Мар’янівка",
      "Марківка",
      "Махнівка",
      "Маянів",
      "Медвежа",
      "Медвеже Вушко",
      "Медвідка",
      "Медівка",
      "Межигірка",
      "Мервин",
      "Миколаївка",
      "Микулинці",
      "Михайлівка",
      "Мізяківські Хутори",
      "Могилівка",
      "Монастирок",
      "Мончин",
      "Морозівка",
      "Мухівці",
      "Надросся",
      "Нападівка",
      "Нарцизівка",
      "Некрасове",
      "Неменка",
      "Немирів",
      "Никифорівці",
      "Нова Миколаївка",
      "Нова Прилука",
      "Нове",
      "Нове Місто",
      "Новоживотів",
      "Новоселиця",
      "Новофастів",
      "Обідне",
      "Обозівка",
      "Озерна",
      "Озерне",
      "Озеро",
      "Олександрівка",
      "Олексіївка",
      "Оленівка",
      "Онитківці",
      "Оратів",
      "Оратівка",
      "Ординці",
      "Осична",
      "Осолинка",
      "Остапківці",
      "Очеретня",
      "Очитків",
      "Павлівка",
      "Паріївка",
      "Парпурівці",
      "Пархомівка",
      "Педоси",
      "Пеньківка",
      "Переорки",
      "Перепеличчя",
      "Петрик",
      "Петрівка",
      "Пилипенкове",
      "Пилява",
      "Пирогів",
      "Писарівка",
      "Підвисоке",
      "Підлісне",
      "Пісочин",
      "Плисків",
      "Побережне",
      "Погребище",
      "Погребище Друге",
      "Погребище Перше",
      "Подільське",
      "Попівка",
      "Попівці",
      "Потоки",
      "Потуш",
      "Прибережне",
      "Приборівка",
      "Прибузьке",
      "Пултівці",
      "Райки",
      "Рахни-Польові",
      "Рижавка",
      "Рівець",
      "Ріжок",
      "Рожична",
      "Розкопане",
      "Романово-Хутір",
      "Росоша",
      "Рубань",
      "Сабарівка",
      "Садове",
      "Сажки",
      "Саражинці",
      "Свитинці",
      "Селевинці",
      "Селище",
      "Сиваківці",
      "Синарна",
      "Скала",
      "Скибин",
      "Скибинці",
      "Скитка",
      "Скоморошки",
      "Славна",
      "Славне",
      "Слобідка",
      "Слобода-Дашковецька",
      "Смаржинці",
      "Сніжна",
      "Соболівка",
      "Сокиринці",
      "Сокілець",
      "Соколинці",
      "Соколівка",
      "Сологубівка",
      "Сопин",
      "Сорока",
      "Сорокотяжинці",
      "Сосни",
      "Соснівка",
      "Сосонка",
      "Спичинці",
      "Сподахи",
      "Стадниця",
      "Станилівка",
      "Стара Прилука",
      "Старостинці",
      "Степанівка",
      "Степанки",
      "Степове",
      "Стрижавка",
      "Стрижаків",
      "Стрільчинці",
      "Строїнці",
      "Струтинка",
      "Студениця",
      "Ступки",
      "Супрунів",
      "Супрунівка",
      "Сутиски",
      "Талалаї",
      "Тарасівка",
      "Теклинівка",
      "Тиврів",
      "Травневе",
      "Трибухи",
      "Тростянець",
      "Троща",
      "Турбів",
      "Тютьки",
      "Тютюнники",
      "Тягун",
      "Угарове",
      "Українка",
      "Уланівка",
      "Улянівка",
      "Урожайне",
      "Уяринці",
      "Федорівка",
      "Філютка",
      "Фронтівка",
      "Хижинці",
      "Хмельове",
      "Хороша",
      "Хрінівка",
      "Цвіжин",
      "Чагів",
      "Чагівське",
      "Чаульське",
      "Червоне",
      "Черемошне",
      "Чернявка",
      "Човновиця",
      "Чуків",
      "Чупринівка",
      "Шевченка",
      "Шевченкове",
      "Шендерів",
      "Шендерівка",
      "Шершні",
      "Ширмівка",
      "Широка Гребля",
      "Шолудьки",
      "Щаслива",
      "Щітки",
      "Юнашки",
      "Юшківці",
      "Яблунівка",
      "Яблуновиця",
      "Язвинки",
      "Якимівка",
      "Якубівка",
      "Якушинці",
      "Яришівка",
      "Ясенецьке",
      "Ясенки",
      "Анютине",
      "Баланівка",
      "Басаличівка",
      "Бджільна",
      "Бережанка",
      "Бережне",
      "Берізки-Бершадські",
      "Берізки-Чечельницькі",
      "Бершадь",
      "Бирлівка",
      "Білий Камінь",
      "Білки",
      "Бондурі",
      "Борсуки",
      "Бритавка",
      "Брідок",
      "Бубнівка",
      "Буди",
      "Бур’яни",
      "Важне",
      "Велика Киріївка",
      "Велика Мочулка",
      "Велика Стратіївка",
      "Вербка",
      "Верхівка",
      "Верхівське",
      "Вища Кропивна",
      "Війтівка",
      "Волошкове",
      "Гайсин",
      "Глибочанське",
      "Глибочок",
      "Глинське",
      "Гнатівка",
      "Голдашівка",
      "Городниця",
      "Городок",
      "Гранів",
      "Грузьке",
      "Губник",
      "Гунча",
      "Гута",
      "Демівка",
      "Демківка",
      "Демківське",
      "Джулинка",
      "Джуринці",
      "Дмитренки",
      "Дохно",
      "Дубівка",
      "Дяківка",
      "Жабокричка",
      "Жерденівка",
      "Завадівка",
      "Завітне",
      "Залужжя",
      "Заозерне",
      "Заріччя",
      "Зелений Довжок",
      "Зятківці",
      "Іванів",
      "Ілляшівка",
      "Кавкули",
      "Кальник",
      "Кам’янки",
      "Кантелина",
      "Капустяни",
      "Карабелівка",
      "Карбівка",
      "Карбівське",
      "Каташин",
      "Кивачівка",
      "Кидрасівка",
      "Кизими",
      "Кисляк",
      "Китайгород",
      "Кіблич",
      "Кожухівка",
      "Комарівка",
      "Копіївка",
      "Коржів",
      "Коржівка",
      "Косанове",
      "Костюківка",
      "Кочурів",
      "Кошаринці",
      "Красногірка",
      "Краснопілка",
      "Красносілка",
      "Криштопівка",
      "Криштопівське",
      "Крутогорб",
      "Крушинівка",
      "Кублич",
      "Кузьминці",
      "Куна",
      "Кунка",
      "Купчинці",
      "Куренівка",
      "Кущинці",
      "Ладижин",
      "Ладижинське",
      "Ладижинські Хутори",
      "Летківка",
      "Леухи",
      "Лісна Поляна",
      "Лісниче",
      "Лозовата",
      "Луги",
      "Любомирка",
      "М’якохід",
      "Мала Киріївка",
      "Мала Мочулка",
      "Мала Стратіївка",
      "Маньківка",
      "Мелешків",
      "Мельниківці",
      "Метанівка",
      "Митків",
      "Митківка",
      "Мишарівка",
      "Мітлинці",
      "Млинки",
      "Нараївка",
      "Нижча Кропивна",
      "Нова Ободівка",
      "Нові Обиходи",
      "Новоселівка",
      "Новоукраїнка",
      "Носівці",
      "Ободівка",
      "Огіївка",
      "Ольгопіль",
      "Оляниця",
      "Ометинці",
      "Орлівка",
      "Осіївка",
      "П’ятківка",
      "Панчишине",
      "Партизанське",
      "Первомайське",
      "Петрашівка",
      "Петро-Марківка",
      "Побірка",
      "Погоріла",
      "Пологи",
      "Попова Гребля",
      "Поташня",
      "Привільне",
      "Привітне",
      "Райгород",
      "Рахни",
      "Рахнівка",
      "Рогізка",
      "Розівка",
      "Розкошівка",
      "Романівка",
      "Росоховата",
      "Рубіжне",
      "Ружицьке",
      "Савинці",
      "Салинці",
      "Самчинці",
      "Саша",
      "Северинівка",
      "Семенки",
      "Семирічка",
      "Серебрія",
      "Серединка",
      "Ситківці",
      "Слободище",
      "Сокиряни",
      "Сокільці",
      "Ставки",
      "Степашки",
      "Стражгород",
      "Стратіївка",
      "Сумівка",
      "Тартак",
      "Теофілівка",
      "Теплик",
      "Тернівка",
      "Тимар",
      "Тирлівка",
      "Тишківка",
      "Тишківська Слобода",
      "Тополівка",
      "Торканівка",
      "Тростянчик",
      "Трубочка",
      "Удич",
      "Устя",
      "Флорине",
      "Харпачка",
      "Хмарівка",
      "Цвіліхівка",
      "Цибулівка",
      "Червона Долина",
      "Червоний Кут",
      "Чернятка",
      "Четвертинівка",
      "Чечелівка",
      "Чечельник",
      "Чортория",
      "Шабельня",
      "Шевченківка",
      "Шиманівка",
      "Шляхова",
      "Шумилів",
      "Шура-Бондурівська",
      "Шура-Мітлинецька",
      "Щурівці",
      "Юрківці",
      "Яланець",
      "Ярмолинці",
      "Яструбинці",
      "Андріївка",
      "Аристівка",
      "Бабина Долина",
      "Балки",
      "Бар",
      "Барок",
      "Біликівці",
      "Біличин",
      "Борівське",
      "Борщі",
      "Браїлів",
      "Бригидівка",
      "Будне",
      "Будьки",
      "Буцні",
      "Варжинка",
      "Васютинці",
      "Верешки",
      "Вознівці",
      "Войнашівка",
      "Володимирка",
      "Володіївці",
      "Гайове",
      "Гармаки",
      "Гибалівка",
      "Глинянка",
      "Голинчинці",
      "Голубівка",
      "Горяни",
      "Грабівці",
      "Грелівка",
      "Губачівка",
      "Гулі",
      "Гута-Мовчанська",
      "Демків",
      "Деребчин",
      "Дерев’янки",
      "Джурин",
      "Діброва",
      "Дубинки",
      "Дубова",
      "Жмеринка",
      "Жуківці",
      "Журавлівка",
      "Заможне",
      "Затоки",
      "Зведенівка",
      "Зелене",
      "Зоринці",
      "Зоряне",
      "Іванівці",
      "Івановецьке",
      "Івашківці",
      "Йосипівці",
      "Калинівка",
      "Калитинка",
      "Каноницьке",
      "Каришків",
      "Кармалюкове",
      "Кацмазів",
      "Квітка",
      "Киянівка",
      "Клекотина",
      "Козарівка",
      "Козлівка",
      "Колосівка",
      "Комарівці",
      "Конатківці",
      "Копай",
      "Копайгород",
      "Копистирин",
      "Коростівці",
      "Котова",
      "Кропивня",
      "Кудіївці",
      "Курилівці",
      "Леляки",
      "Лисогірка",
      "Лісничівка",
      "Лісове",
      "Лозова",
      "Лопатинці",
      "Лугове",
      "Лука-Барська",
      "Лука-Мовчанська",
      "Людавка",
      "Лядова",
      "Майдан-Головчинський",
      "Мала Деребчинка",
      "Мала Жмеринка",
      "Малі Коростівці",
      "Мальовниче",
      "Мальчівці",
      "Маньківці",
      "Мартинівка",
      "Матейків",
      "Матейкове",
      "Межирів",
      "Мельники",
      "Мигалівці",
      "Мирне",
      "Митки",
      "Мишівське",
      "Міжлісся",
      "Мовчани",
      "Мурафа",
      "Мурашка",
      "Настасіївка",
      "Нові Хоменки",
      "Носиківка",
      "Носківці",
      "Окладне",
      "Оліхи",
      "Пасинки",
      "Переліски",
      "Перепільчинці",
      "Петрані",
      "Пилипи",
      "Підлісний Ялтушків",
      "Плебанівка",
      "Пляцина",
      "Повал",
      "Покутине",
      "Політанки",
      "Польове",
      "Поляна",
      "Почапинці",
      "Примощаниця",
      "Регентівка",
      "Рів",
      "Рожепи",
      "Роля",
      "Руданське",
      "Садківці",
      "Сапіжанка",
      "Семенівка",
      "Сербинівці",
      "Сеферівка",
      "Синьожупанники",
      "Сідава",
      "Слобода",
      "Слобода-Гулівська",
      "Слобода-Межирівська",
      "Слобода-Носковецька",
      "Слобода-Ходацька",
      "Слобода-Чернятинська",
      "Слобода-Шаргородська",
      "Слобода-Ялтушківська",
      "Станіславчик",
      "Стасюки",
      "Стодульці",
      "Супівка",
      "Сурогатка",
      "Сьомаки",
      "Теклівка",
      "Телелинці",
      "Терешки",
      "Токарівка",
      "Трудолюбівка",
      "Українське",
      "Хатки",
      "Ходаки",
      "Хоменки",
      "Чемериси-Барські",
      "Чемериське",
      "Черешневе",
      "Чернятин",
      "Шаргород",
      "Шипинки",
      "Широке",
      "Шпирки",
      "Щучинці",
      "Юхимівка",
      "Ялтушків",
      "Ярошенка",
      "Абрамівська Долина",
      "Бабчинці",
      "Балківка",
      "Бандишівка",
      "Бахтин",
      "Бахтинок",
      "Безводне",
      "Берлядка",
      "Бернашівка",
      "Біляни",
      "Блакитне",
      "Борівка",
      "Борщівці",
      "Бронниця",
      "Букатинка",
      "Буша",
      "Вазлуївка",
      "Велика Кісниця",
      "Вендичани",
      "Вербовець",
      "Весняне",
      "Вила-Ярузькі",
      "Виноградне",
      "Вишневе",
      "Вищеольчедаїв",
      "Вівчарня",
      "Вільне",
      "Вільшанка",
      "Вінож",
      "Вітрівка",
      "Воєводчинці",
      "Воронівці",
      "Галайківці",
      "Гальжбіївка",
      "Гамулівка",
      "Глибока Долина",
      "Гонорівка",
      "Гонтівка",
      "Горай",
      "Грабарівка",
      "Грабовець",
      "Грушка",
      "Дерешова",
      "Держанка",
      "Дзигівка",
      "Дзюброве",
      "Дігтярка",
      "Добрянка",
      "Долиняни",
      "Дорошівка",
      "Дружба",
      "Жван",
      "Жеребилівка",
      "Житники",
      "Знаменівка",
      "Золотогірка",
      "Іванків",
      "Івонівка",
      "Іракліївка",
      "Йосипівка",
      "Кам’янецькі Хутори",
      "Камінське",
      "Карпівка",
      "Качківка",
      "Клембівка",
      "Козлів",
      "Конева",
      "Конищів",
      "Коси",
      "Котлубаївка",
      "Котюжани",
      "Коштуля",
      "Кремінне",
      "Кривохижинці",
      "Кричанівка",
      "Криштофівка",
      "Кукавка",
      "Кукурівка",
      "Курашівці",
      "Липчани",
      "Лозове",
      "Ломазів",
      "Лужок",
      "Лучинець",
      "Лучинчик",
      "Мазурівка",
      "Майорщина",
      "Малий Обухів",
      "Мервинці",
      "Миронівка",
      "Михайлівці",
      "Могилів-Подільський",
      "Моївка",
      "Муровані Курилівці",
      "Нагоряни",
      "Наддністрянське",
      "Немерче",
      "Немія",
      "Нечуївка",
      "Нижчий Ольчедаїв",
      "Нишівці",
      "Нова Григорівка",
      "Нове Життя",
      "Новомикільськ",
      "Новосілка",
      "Обухів",
      "Одая",
      "Озаринці",
      "Оксанівка",
      "Пелинівка",
      "Перекоринці",
      "Петримани",
      "Підлісівка",
      "Плоске",
      "Попелюхи",
      "Пороги",
      "Посухів",
      "Придністрянське",
      "Прилужне",
      "Ратуш",
      "Регляшинці",
      "Рівне",
      "Роздолівка",
      "Ромашка",
      "Русава",
      "Рясне",
      "Садки",
      "Садова",
      "Саїнка",
      "Свидова",
      "Серебринці",
      "Сказинці",
      "Скалопіль",
      "Скорячий Яр",
      "Сліди",
      "Слобода-Бушанська",
      "Слобода-Підлісівська",
      "Слобода-Шлишковецька",
      "Слобода-Яришівська",
      "Снітків",
      "Сокіл",
      "Сонячне",
      "Степне",
      "Степок",
      "Струсове",
      "Суботівка",
      "Сугаки",
      "Трактове",
      "Тропове",
      "Туліїв",
      "Франківка",
      "Хмелівщина",
      "Хоньківці",
      "Цекинівка",
      "Чернівці",
      "Шлишківці",
      "Ягідне",
      "Ямпіль",
      "Яр",
      "Яришів",
      "Яруга",
      "Ясне",
      "Яструбна",
      "Андріяшівка",
      "Антопіль",
      "Анциполівка",
      "Білоусівка",
      "Благодатне",
      "Болган",
      "Бортники",
      "Брацлав",
      "Брохвичі",
      "Бугаків",
      "Бурдії",
      "Бушинка",
      "Вапнярка",
      "Вапнярки",
      "Велика Вулига",
      "Велика Русава",
      "Вербецьке",
      "Вербова",
      "Верхня Слобідка",
      "Вигнанка",
      "Вигода",
      "Вила",
      "Винокурня",
      "Висока Гребля",
      "Високе",
      "Вишківці",
      "Ганнопіль",
      "Гарячківка",
      "Гнатків",
      "Голубече",
      "Горби",
      "Горишківка",
      "Горишківське",
      "Городківка",
      "Гранітне",
      "Гриненки",
      "Гуральня",
      "Гута-Бушинська",
      "Гути",
      "Дахталія",
      "Джугастра",
      "Дмитрашківка",
      "Долинка",
      "Доярня",
      "Дранка",
      "Жабокрич",
      "Жолоби",
      "Забіляни",
      "Заболотне",
      "Забужжя",
      "Залісся",
      "Зарічне",
      "Затишне",
      "Зяньківці",
      "Іллівка",
      "Калинка",
      "Капки",
      "Касянівка",
      "Кинашів",
      "Кирнасівка",
      "Кислицьке",
      "Кісниця",
      "Клебань",
      "Кленове",
      "Княжа Криниця",
      "Козацьке",
      "Колоденка",
      "Комаргород",
      "Комаргородське",
      "Крижопіль",
      "Крикливець",
      "Кринички",
      "Крищинці",
      "Кукули",
      "Куниче",
      "Левків",
      "Левківці",
      "Леонівка",
      "Липівка",
      "Мала Вулига",
      "Мар’янівське",
      "Маркове",
      "Марусине",
      "Маяки",
      "Миролюбівка",
      "Монастирське",
      "Нестерварка",
      "Нетребівка",
      "Одаї",
      "П’ятихатка",
      "Паланка",
      "Палійове",
      "Пестеля",
      "Петрунівка",
      "Печера",
      "Пилипи-Борівські",
      "Піщанка",
      "Польова Слобідка",
      "Ракова",
      "Рахни-Лісові",
      "Рибки",
      "Рогізна",
      "Рожнятівка",
      "Рудник",
      "Рудницьке",
      "Рудниця",
      "Савчине",
      "Сільниця",
      "Скрицьке",
      "Сорокодуби",
      "Станіславка",
      "Стіна",
      "Стрільники",
      "Студена",
      "Суворовське",
      "Суха Долина",
      "Тиманівка",
      "Томашпіль",
      "Торків",
      "Трибусівка",
      "Трудове",
      "Тульчин",
      "Файгород",
      "Федьківка",
      "Хмельницьке",
      "Холодівка",
      "Чабанове",
      "Червона Поляна",
      "Чорномин",
      "Шарапанівка",
      "Шпиків",
      "Шпиківка",
      "Шуми",
      "Шура",
      "Шура-Копіївська",
      "Щурі",
      "Юліямпіль",
      "Юліямпільське",
      "Юрківка",
      "Яворівка",
      "Ярове",
      "Яструбиха",
      "Антонопіль",
      "Байківка",
      "Безіменне",
      "Бережани",
      "Березна",
      "Білий Рукав",
      "Білопілля",
      "Блажіївка",
      "Бродецьке",
      "Будків",
      "Велике",
      "Великий Митник",
      "Великий Острожок",
      "Великий Степ",
      "Великі Кутища",
      "Верболози",
      "Вернигородок",
      "Весела",
      "Вівсяники",
      "Вовчинець",
      "Воскодавинці",
      "Вугли",
      "Гарасимівка",
      "Глинськ",
      "Глухівці",
      "Голендри",
      "Голодьки",
      "Грушківці",
      "Гулівці",
      "Гурівці",
      "Гущинці",
      "Держанівка",
      "Дібрівка",
      "Дружелюбівка",
      "Дружне",
      "Дубові Махаринці",
      "Думенки",
      "Жежелів",
      "Жигалівка",
      "Журавне",
      "Журбинці",
      "Забара",
      "Загребельня",
      "Заливанщина",
      "Залізничне",
      "Збараж",
      "Зозулинці",
      "Іванопіль",
      "Калинівка Друга",
      "Качанівка",
      "Кашперівка",
      "Клітенка",
      "Кожухів",
      "Козятин",
      "Колибабинці",
      "Корделівка",
      "Коритувата",
      "Котюжинці",
      "Кривошиї",
      "Крижанівка",
      "Кропивна",
      "Крупин",
      "Крутнів",
      "Куманівка",
      "Куманівці",
      "Курилівка",
      "Кустівці",
      "Кустовецьке",
      "Кушелівка",
      "Лелітка",
      "Лемешівка",
      "Лип’ятин",
      "Листопадівка",
      "Лісне",
      "Лісова Лисіївка",
      "Лісогірка",
      "Лозівка",
      "Лозна",
      "Лопатин",
      "Майдан-Бобрик",
      "Мала Клітинка",
      "Малий Митник",
      "Малий Острожок",
      "Малишівка",
      "Малі Кутища",
      "Марківці",
      "Маркуші",
      "Матяшівка",
      "Махаринці",
      "Медведівка",
      "Митинці",
      "Михайлин",
      "Мізяків",
      "Мізяківська Слобідка",
      "Молодіжне",
      "Молотківці",
      "Мончинці",
      "Мухувата",
      "Мшанець",
      "Немиринці",
      "Непедівка",
      "Нова Гребля",
      "Нова Сулківка",
      "Ольгине",
      "Осічок",
      "Павленки",
      "Пагурці",
      "Панасівка",
      "Перемога",
      "Петриківці",
      "Пиків",
      "Пиківська Слобідка",
      "Пиковець",
      "Пляхова",
      "Подорожня",
      "Поличинці",
      "Польова Лисіївка",
      "Порик",
      "Прилуцьке",
      "Прушинка",
      "Пузирки",
      "Пустовійти",
      "Пустоха",
      "Радівка",
      "Рибчинці",
      "Рівнинне",
      "Рогинці",
      "Рубанка",
      "Сальник",
      "Сальниця",
      "Самгородок",
      "Семки",
      "Сербанівка",
      "Сестринівка",
      "Сигнал",
      "Скаржинці",
      "Слобода-Кустовецька",
      "Сміла",
      "Соколова",
      "Софіївка",
      "Сошанське",
      "Стара Гута",
      "Ступник",
      "Сулківка",
      "Тараски",
      "Терешпіль",
      "Теси",
      "Титусівка",
      "Торчин",
      "Туча",
      "Уладівка",
      "Уладівське",
      "Уланів",
      "Філіопіль",
      "Флоріанівка",
      "Хліборобне",
      "Хмільник",
      "Хомутинці",
      "Хутори-Кривошиїнецькі",
      "Чепелі",
      "Червоний Степ",
      "Черепашинці",
      "Чернички",
      "Чернятинці",
      "Чеснівка",
      "Чудинівці",
      "Шепіївка",
      "Юрівка",
      "Яблуневе",
    ],
  },
  {
    region: "Волинська",
    cities: [
      "Амбуків",
      "Бегета",
      "Березовичі",
      "Бермешів",
      "Білин",
      "Біличі",
      "Білопіль",
      "Благодатне",
      "Бобичі",
      "Бортнів",
      "Бубнів",
      "Будятичі",
      "Бужанка",
      "Бужковичі",
      "Великий Окорськ",
      "Верба",
      "Верхнів",
      "Війниця",
      "Вілька-Садівська",
      "Войнин",
      "Волиця",
      "Волиця-Морозовицька",
      "Володимир",
      "Володимирівка",
      "Воля-Свійчівська",
      "Ворчин",
      "Вощатин",
      "Гайки",
      "Галинівка",
      "Гевин",
      "Горичів",
      "Гранатів",
      "Грибовиця",
      "Грушів",
      "Гряди",
      "Губин",
      "Дарницьке",
      "Дігтів",
      "Долинка",
      "Дорогиничі",
      "Древині",
      "Жашковичі",
      "Житані",
      "Журавець",
      "Заболоття",
      "Заболотці",
      "Завидів",
      "Залужжя",
      "Залужне",
      "Замличі",
      "Замости",
      "Запуст",
      "Заріччя",
      "Заставне",
      "Затурці",
      "Защитів",
      "Заячиці",
      "Зимне",
      "Зоря",
      "Зубильне",
      "Іваничі",
      "Іванів",
      "Іванівка",
      "Ізов",
      "Квасовиця",
      "Кисилин",
      "Кладнів",
      "Клопочин",
      "Когильне",
      "Козлів",
      "Колона",
      "Колпитів",
      "Конюхи",
      "Коритниця",
      "Космівка",
      "Красностав",
      "Крать",
      "Кремеш",
      "Кречів",
      "Кропивщина",
      "Крухиничі",
      "Кути",
      "Ласків",
      "Лежниця",
      "Линів",
      "Литовеж",
      "Ліски",
      "Локачі",
      "Лугове",
      "Лудин",
      "Луковичі",
      "Льотниче",
      "Людмильпіль",
      "Малий Окорськ",
      "Маньків",
      "Марія-Воля",
      "Маркелівка",
      "Марковичі",
      "Маркостав",
      "Менчичі",
      "Микитичі",
      "Микуличі",
      "Милятин",
      "Михайлівка",
      "Михалє",
      "Мишів",
      "Міжгір’я",
      "Міжлісся",
      "Млинище",
      "Мовники",
      "Мовчанів",
      "Морозовичі",
      "Нехвороща",
      "Низкиничі",
      "Низькі Цевеличі",
      "Нова Лішня",
      "Новий Загорів",
      "Новини",
      "Нововолинськ",
      "Новосілки",
      "Овадне",
      "Овлочин",
      "Озютичі",
      "Октавин",
      "Орані",
      "Орищі",
      "Осмиловичі",
      "Острівок",
      "Охнівка",
      "П’ятидні",
      "П’ятикори",
      "Павлівка",
      "Павловичі",
      "Пархоменкове",
      "Переславичі",
      "Петрове",
      "Писарева Воля",
      "Підгайці",
      "Полум’яне",
      "Поничів",
      "Поромів",
      "Привітне",
      "Радовичі",
      "Риковичі",
      "Роговичі",
      "Рогожани",
      "Рокитниця",
      "Романівка",
      "Руда",
      "Русів",
      "Руснів",
      "Русовичі",
      "Садівські Дубини",
      "Самоволя",
      "Свійчів",
      "Селець",
      "Селіски",
      "Семеринське",
      "Сірнички",
      "Соснина",
      "Ставки",
      "Стара Лішня",
      "Старий Загорів",
      "Старий Порицьк",
      "Старосілля",
      "Стенжаричі",
      "Сусваль",
      "Суходоли",
      "Твердині",
      "Твориничі",
      "Тишковичі",
      "Топилище",
      "Тростянка",
      "Трубки",
      "Тумин",
      "Турівка",
      "Устилуг",
      "Фалемичі",
      "Федорівка",
      "Хворостів",
      "Хмелів",
      "Хмелівка",
      "Хобултова",
      "Холопичі",
      "Хорів",
      "Хотячів",
      "Хренів",
      "Хрипаличі",
      "Цевеличі",
      "Черчичі",
      "Чесний Хрест",
      "Чорників",
      "Шахтарське",
      "Шельвів",
      "Шистів",
      "Щенятин",
      "Юнівка",
      "Ягідне",
      "Яковичі",
      "Бережниця",
      "Березичі",
      "Березна Воля",
      "Бихів",
      "Бірки",
      "Боровне",
      "Брониця",
      "Будки",
      "Бузаки",
      "Бучин",
      "В’язівне",
      "Велика Ведмежка",
      "Велика Глуша",
      "Велика Яблунька",
      "Великий Курінь",
      "Великий Обзир",
      "Верхи",
      "Ветли",
      "Видерта",
      "Видричі",
      "Винімок",
      "Витуле",
      "Віл",
      "Вовчицьк",
      "Воєгоща",
      "Ворокомле",
      "Галузія",
      "Гірки",
      "Городок",
      "Граддя",
      "Градиськ",
      "Гречища",
      "Грудки",
      "Гута-Боровенська",
      "Гута-Камінська",
      "Гута-Лісівська",
      "Деревок",
      "Діброва",
      "Добре",
      "Довжиця",
      "Дольськ",
      "Дубровиця",
      "Житнівка",
      "Загорівка",
      "Залаззя",
      "Залазько",
      "Залізниця",
      "Залісся",
      "Замостя",
      "Запруддя",
      "Заріка",
      "Зарудчі",
      "Іваномисль",
      "Каливиця",
      "Кам’януха",
      "Камінь-Каширський",
      "Карасин",
      "Карпилівка",
      "Качин",
      "Клітицьк",
      "Козлиничі",
      "Колодії",
      "Комарове",
      "Костюхнівка",
      "Котуш",
      "Краснилівка",
      "Кримне",
      "Кукли",
      "Лахвичі",
      "Личини",
      "Лишнівка",
      "Лісове",
      "Лобна",
      "Люб’язь",
      "Любешів",
      "Любешівська Воля",
      "Люботин",
      "Майдан",
      "Мала Ведмежка",
      "Мала Глуша",
      "Мала Яблунька",
      "Малий Обзир",
      "Малі Голоби",
      "Маневичі",
      "Мельники-Мостище",
      "Міжгайці",
      "Мостище",
      "Мукошин",
      "Набруска",
      "Надрічне",
      "Невір",
      "Нова Руда",
      "Нові Березичі",
      "Нові Підцаревичі",
      "Нові Червища",
      "Нуйно",
      "Оконськ",
      "Олександрія",
      "Олексіївка",
      "Оленине",
      "Ольшани",
      "Осівці",
      "Підбороччя",
      "Підгаття",
      "Підкормілля",
      "Підріччя",
      "Підцир’я",
      "Піщане",
      "Пнівне",
      "Погулянка",
      "Пожог",
      "Полиці",
      "Прилісне",
      "Проходи",
      "Радошинка",
      "Раків Ліс",
      "Рудка",
      "Рудка-Червинська",
      "Сваловичі",
      "Северинівка",
      "Седлище",
      "Селісок",
      "Серхів",
      "Соснівка",
      "Софіянівка",
      "Сошичне",
      "Ставище",
      "Старі Червища",
      "Стобихва",
      "Стобихівка",
      "Судче",
      "Теклине",
      "Тоболи",
      "Троянівка",
      "Угриничі",
      "Фаринки",
      "Хотешів",
      "Хоцунь",
      "Хряськ",
      "Хутомир",
      "Цир",
      "Цміни",
      "Чарторийськ",
      "Череваха",
      "Черськ",
      "Черче",
      "Шлапань",
      "Щитинь",
      "Яловацьк",
      "Адамівка",
      "Адамчуки",
      "Арсеновичі",
      "Бабаці",
      "Байківці",
      "Бахів",
      "Бережці",
      "Береза",
      "Березники",
      "Битень",
      "Білашів",
      "Блаженик",
      "Бобли",
      "Боремщина",
      "Борзова",
      "Борове",
      "Боровуха",
      "Борщівка",
      "Брідки",
      "Броди",
      "Бродятине",
      "Брунетівка",
      "Бруховичі",
      "Будище",
      "Будники",
      "Буцинь",
      "Великий Порськ",
      "Велимче",
      "Велицьк",
      "Вербичне",
      "Вербівка",
      "Вербка",
      "Вигнанка",
      "Видраниця",
      "Вижгів",
      "Вижично",
      "Високе",
      "Висоцьк",
      "Височне",
      "Витень",
      "Вишнів",
      "Вишнівка",
      "Вівчицьк",
      "Відути",
      "Вілиця",
      "Вілька-Підгородненська",
      "Вільшанка",
      "Волошки",
      "Воля",
      "Воля-Ковельська",
      "Воля-Любитівська",
      "Ворона",
      "Вужиськ",
      "Гаївка",
      "Галина Воля",
      "Гаруша",
      "Гірники",
      "Гішин",
      "Глинянка",
      "Глухи",
      "Годинь",
      "Годовичі",
      "Голоби",
      "Головище",
      "Головне",
      "Голядин",
      "Гончий Брід",
      "Городилець",
      "Городище",
      "Городнє",
      "Гороховище",
      "Грабове",
      "Гредьки",
      "Грив’ятки",
      "Грушівка",
      "Гулівка",
      "Гуменці",
      "Гупали",
      "Гута",
      "Гуща",
      "Дарівка",
      "Датинь",
      "Довгоноси",
      "Дожва",
      "Долина",
      "Доманове",
      "Доротище",
      "Дошне",
      "Дроздні",
      "Дубечне",
      "Дубове",
      "Дуліби",
      "Жиричі",
      "Жмудче",
      "Журавлине",
      "Заброди",
      "Забужжя",
      "Задиби",
      "Задолина",
      "Заіванове",
      "Залисиця",
      "Заліси",
      "Залісці",
      "Залухів",
      "Залюття",
      "Замлиння",
      "Замшани",
      "Занивське",
      "Заозерне",
      "Запілля",
      "Запоківне",
      "Заприп’ять",
      "Застав’є",
      "Затишшя",
      "Зачернеччя",
      "Заячівка",
      "Згорани",
      "Здомишель",
      "Зелена",
      "Зілове",
      "Зоряне",
      "Калинівка",
      "Калиновник",
      "Кам’янка",
      "Кашівка",
      "Клевецьк",
      "Кличковичі",
      "Клюськ",
      "Ковель",
      "Козовата",
      "Колодниця",
      "Колодяжне",
      "Комарів",
      "Конище",
      "Кортеліси",
      "Косиці",
      "Коцюри",
      "Кошари",
      "Краска",
      "Красний Бір",
      "Красноволя",
      "Краснодуб’я",
      "Кривлин",
      "Кричевичі",
      "Кропивники",
      "Кругель",
      "Крушинець",
      "Кукуріки",
      "Кульчин",
      "Купичів",
      "Куснища",
      "Кустичі",
      "Кухарі",
      "Ладинь",
      "Лапні",
      "Липа",
      "Лисняки",
      "Лісняки",
      "Літин",
      "Ловища",
      "Локутки",
      "Ломачанка",
      "Луків",
      "Луківка",
      "Лучичі",
      "Любитів",
      "Люблинець",
      "Любомль",
      "Любохини",
      "Любче",
      "Лютка",
      "Маковичі",
      "Мале Оріхове",
      "Малий Порськ",
      "Мар’янівка",
      "Масловець",
      "Машів",
      "Межисить",
      "Мельники",
      "Мельники-Річицькі",
      "Мельниця",
      "Мизове",
      "Миловань",
      "Мильці",
      "Миляновичі",
      "Мирин",
      "Мировичі",
      "Мислина",
      "Млинове",
      "Мокре",
      "Мокрець",
      "Молодівка",
      "Мосир",
      "Мочалки",
      "Мощена",
      "Мшанець",
      "Нири",
      "Ниці",
      "Нова Вижва",
      "Нова Воля",
      "Новий Двір",
      "Новий Мосир",
      "Нові Кошари",
      "Новоугрузьке",
      "Нудиже",
      "Нужель",
      "Обенижі",
      "Облапи",
      "Озерне",
      "Озеряни",
      "Окачеве",
      "Окунин",
      "Олеськ",
      "Омельне",
      "Оса",
      "Осекрів",
      "Осереби",
      "Острів’я",
      "Осьмиговичі",
      "Охотники",
      "Паридуби",
      "Партизанське",
      "Перевали",
      "Перевісся",
      "Пересіка",
      "Перешпа",
      "Перковичі",
      "Пехи",
      "Під’язівні",
      "Підгір’я",
      "Підгородне",
      "Підліси",
      "Підлісся",
      "Підманове",
      "Підріжжя",
      "Підсинівка",
      "Піски-Річицькі",
      "Пісочне",
      "Піща",
      "Плоске",
      "Поворськ",
      "Погіньки",
      "Полапи",
      "Поліське",
      "Положеве",
      "Поляна",
      "Поповичі",
      "Поступель",
      "Почапи",
      "Прип’ять",
      "Приріччя",
      "Прохід",
      "Пулемець",
      "Пульмо",
      "Пустинка",
      "Радехів",
      "Радошин",
      "Растів",
      "Ратне",
      "Ревушки",
      "Римачі",
      "Рівне",
      "Річиця",
      "Рогові Смоляри",
      "Рокита",
      "Ростань",
      "Рудка-Миринська",
      "Рудня",
      "Ружин",
      "Самари",
      "Самари-Оріхові",
      "Самійличі",
      "Свидники",
      "Свинарин",
      "Світле",
      "Світязь",
      "Секунь",
      "Серебряниця",
      "Сереховичі",
      "Серкізів",
      "Сильне",
      "Синове",
      "Синявка",
      "Ситовичі",
      "Сільце",
      "Сільця-Млинівські",
      "Скиби",
      "Скрипиця",
      "Скулин",
      "Смідин",
      "Смоляри-Світязькі",
      "Смолярі",
      "Сокіл",
      "Соколище",
      "Солов’ї",
      "Соловичі",
      "Солотвин",
      "Сомин",
      "Ставок",
      "Стара Вижівка",
      "Стара Гута",
      "Старий Мосир",
      "Старі Кошари",
      "Старовойтове",
      "Старостине",
      "Стеблі",
      "Столинські Смоляри",
      "Сукачі",
      "Сушибаба",
      "Сьомаки",
      "Тагачин",
      "Текля",
      "Теребовичі",
      "Терехи",
      "Тойкут",
      "Торговище",
      "Туличів",
      "Тупали",
      "Тур",
      "Туричани",
      "Турійськ",
      "Турія",
      "Туровичі",
      "Туропин",
      "Угли",
      "Уховецьк",
      "Хабарище",
      "Хомичі",
      "Хотивель",
      "Хрипськ",
      "Чевель",
      "Черемошна Воля",
      "Черемошне",
      "Черемшанка",
      "Черкаси",
      "Чмикос",
      "Чорніїв",
      "Чорноплеси",
      "Шацьк",
      "Шкроби",
      "Шкурат",
      "Шменьки",
      "Штунь",
      "Щедрогір",
      "Щитинська Воля",
      "Язавні",
      "Якушів",
      "Яревище",
      "Ясне",
      "Антонівка",
      "Баїв",
      "Баківці",
      "Барвінок",
      "Башлики",
      "Башова",
      "Берегове",
      "Бережанка",
      "Березолуки",
      "Берестечко",
      "Берестяне",
      "Береськ",
      "Бистровиця",
      "Білосток",
      "Боголюби",
      "Богунівка",
      "Богушівка",
      "Богушівська Мар’янівка",
      "Бодячів",
      "Боратин",
      "Борисковичі",
      "Боровичі",
      "Борохів",
      "Борочиче",
      "Бортяхівка",
      "Ботин",
      "Брани",
      "Брище",
      "Бужани",
      "Буків",
      "Буркачі",
      "Буяни",
      "Валер’янівка",
      "Ватин",
      "Ватинець",
      "Велика Осниця",
      "Великий Омеляник",
      "Вербаїв",
      "Верхівка",
      "Веселе",
      "Веснянка",
      "Вигуричі",
      "Вишеньки",
      "Вікторяни",
      "Вільхівка",
      "Вітоніж",
      "Вічині",
      "Волиця-Дружкопільська",
      "Волиця-Лобачівська",
      "Ворончин",
      "Воротнів",
      "Воютин",
      "Всеволодівка",
      "Гайове",
      "Галичани",
      "Гаразджа",
      "Гать",
      "Гектари",
      "Гірка Полонка",
      "Годомичі",
      "Голишів",
      "Гораймівка",
      "Горзвин",
      "Горішнє",
      "Городині",
      "Горохів",
      "Горянівка",
      "Грем’яче",
      "Григоровичі",
      "Грузятин",
      "Губин Перший",
      "Гумнище",
      "Дачне",
      "Дерно",
      "Десятина",
      "Диковини",
      "Дідичі",
      "Дідовичі",
      "Дмитрівка",
      "Довгів",
      "Домашів",
      "Доросині",
      "Дубичанське",
      "Дубище",
      "Дубова Корчма",
      "Духче",
      "Єлизаветин",
      "Жабка",
      "Жабче",
      "Жидичин",
      "Жорнище",
      "Жуковець",
      "Журавичі",
      "Журавники",
      "Забара",
      "Забороль",
      "Завітне",
      "Загаї",
      "Залісоче",
      "Звиняче",
      "Звірів",
      "Звози",
      "Зелене",
      "Зеленолужне",
      "Зміїнець",
      "Знамирівка",
      "Іванчиці",
      "Кадище",
      "Квасів",
      "Квітневе",
      "Кияж",
      "Ківерці",
      "Клепачів",
      "Клубочин",
      "Княгининок",
      "Кобче",
      "Ковбань",
      "Козин",
      "Козятин",
      "Колки",
      "Колмів",
      "Колодеже",
      "Конопелька",
      "Копачівка",
      "Копилля",
      "Корсині",
      "Коршів",
      "Коршовець",
      "Котів",
      "Кошів",
      "Красів",
      "Кременець",
      "Крижівка",
      "Крижова",
      "Криничне",
      "Кроватка",
      "Крупа",
      "Куликовичі",
      "Кумовище",
      "Кутрів",
      "Лаврів",
      "Лемешів",
      "Линівка",
      "Липини",
      "Липляни",
      "Липне",
      "Личани",
      "Лички",
      "Лище",
      "Літогоща",
      "Лобачівка",
      "Луцьк",
      "Лучиці",
      "Майдан-Липненський",
      "Мала Осниця",
      "Малинівка",
      "Мартинівка",
      "Маруся",
      "Матейки",
      "Мерва",
      "Метельне",
      "Микове",
      "Милушин",
      "Милуші",
      "Мильськ",
      "Мирків",
      "Мирне",
      "Мирославка",
      "Мислині",
      "Михайлин",
      "Михлин",
      "Моташівка",
      "Мощаниця",
      "Мстишин",
      "Муравище",
      "Навіз",
      "Наталин",
      "Небіжка",
      "Незвір",
      "Немир",
      "Несвіч",
      "Ниви-Губинські",
      "Нічогівка",
      "Новий Зборишів",
      "Новокотів",
      "Новостав",
      "Новоукраїнка",
      "Носачевичі",
      "Носовичі",
      "Одеради",
      "Озденіж",
      "Оздів",
      "Озеро",
      "Озерце",
      "Озерці",
      "Олександрівка",
      "Оленівка",
      "Олешковичі",
      "Олика",
      "Ольганівка",
      "Острів",
      "Острови",
      "Охлопів",
      "Охотин",
      "Ощів",
      "Пальче",
      "Перемиль",
      "Переспа",
      "Печихвости",
      "Пильгани",
      "Підбереззя",
      "Підгірне",
      "Піддубці",
      "Підліски",
      "Пірванче",
      "Піски",
      "Пожарки",
      "Покащів",
      "Полонка",
      "Полюхне",
      "Прилуцьке",
      "Промінь",
      "Пустомити",
      "Путилівка",
      "Радомишль",
      "Раймісто",
      "Ратнів",
      "Рачин",
      "Ржищів",
      "Рованці",
      "Рожище",
      "Розничі",
      "Рокині",
      "Романів",
      "Ромашківка",
      "Рудка-Козинська",
      "Рудники",
      "Садів",
      "Сапогове",
      "Сарнівка",
      "Семки",
      "Сенкевичівка",
      "Сергіївка",
      "Сирники",
      "Ситниця",
      "Скабарівщина",
      "Скірче",
      "Скобелка",
      "Скреготівка",
      "Скригове",
      "Славне",
      "Словатичі",
      "Смолигів",
      "Смолява",
      "Сокиричі",
      "Софіївка",
      "Старики",
      "Старостав",
      "Стрільче",
      "Струмівка",
      "Студині",
      "Суськ",
      "Суховоля",
      "Тарасове",
      "Тельчі",
      "Терешківці",
      "Тертки",
      "Тихотин",
      "Топільне",
      "Торчин",
      "Трилісці",
      "Тристень",
      "Тростянець",
      "Угринів",
      "Ужова",
      "Уляники",
      "Уманці",
      "Усичі",
      "Усичівські Будки",
      "Хмельницьке",
      "Холоневичі",
      "Холонів",
      "Хопнів",
      "Хорлупи",
      "Хорохорин",
      "Хром’яків",
      "Цегів",
      "Цеперів",
      "Цумань",
      "Чаруків",
      "Чемерин",
      "Четвертня",
      "Човниця",
      "Чорниж",
      "Чорний Ліс",
      "Шепель",
      "Широке",
      "Шклинь",
      "Шклинь Другий",
      "Щурин",
      "Яблунівка",
      "Яківці",
      "Ярівка",
      "Яромель",
      "Ясенівка",
    ],
  },
  {
    region: "Дніпропетровська",
    cities: [
      "Авіаторське",
      "Андріївка",
      "Антонівка",
      "Аполлонівка",
      "Бабайківка",
      "Багате",
      "Балівка",
      "Барвінок",
      "Башмачка",
      "Безбородькове",
      "Березнуватівка",
      "Благовіщенка",
      "Богданівка",
      "Братське",
      "Бутовичівка",
      "Василівка",
      "Вербове",
      "Веселе",
      "Виноградне",
      "Вишневе",
      "Військове",
      "Вільне",
      "Вовніги",
      "Водяне",
      "Волоське",
      "Воронівка",
      "Гайдамацьке",
      "Ганно-Мусіївка",
      "Гаркушине",
      "Гнатівка",
      "Голубинівка",
      "Гончарка",
      "Горького",
      "Горянівське",
      "Гречане",
      "Григорівка",
      "Гроза",
      "Грушівка",
      "Дальнє",
      "Діброва",
      "Дмитрівка",
      "Дніпро",
      "Дніпрове",
      "Дніпровське",
      "Долинське",
      "Дороганівка",
      "Дороге",
      "Дослідне",
      "Драгівка",
      "Дружелюбівка",
      "Дубове",
      "Єгорине",
      "Єлизаветівка",
      "Залелія",
      "Заорілля",
      "Звонецьке",
      "Звонецький Хутір",
      "Зелений Гай",
      "Зоря",
      "Зубківка",
      "Іванівка",
      "Івано-Яризівка",
      "Іверське",
      "Калинівка",
      "Кам’яне",
      "Кам’янка",
      "Кам’яно-Зубилівка",
      "Карайкове",
      "Катеринівка",
      "Кашкарівка",
      "Квітуче",
      "Китайгород",
      "Клешнівка",
      "Костянтинівка",
      "Котлярівка",
      "Кравцівка",
      "Кринички",
      "Круте",
      "Кулішеве",
      "Куліші",
      "Курилівка",
      "Кущівка",
      "Лисківка",
      "Лобойківка",
      "Лозуватка",
      "Любимівка",
      "Любов",
      "Ляшківка",
      "Маївка",
      "Майорка",
      "Мала Калинівка",
      "Мала Петриківка",
      "Малинівка",
      "Малинове",
      "Малозахарине",
      "Матросове",
      "Маяк",
      "Межове",
      "Микільське",
      "Микільське-На-Дніпрі",
      "Миколаївка",
      "Миколо-Мусіївка",
      "Мирне",
      "Миропіль",
      "Михайлівка",
      "Могилів",
      "Молодіжне",
      "Надіївка",
      "Назаренки",
      "Наталівка",
      "Незабудине",
      "Ненадівка",
      "Нетесівка",
      "Нове",
      "Новоандріївка",
      "Нововасилівка",
      "Новомар’ївка",
      "Новомиколаївка",
      "Новоолександрівка",
      "Новопідкряж",
      "Новопокровка",
      "Новоселівка",
      "Новоспаське",
      "Новостроївка",
      "Новотарасівка",
      "Новотаромське",
      "Новотернуватка",
      "Обухівка",
      "Олександрівка",
      "Олександропіль",
      "Олексіївка",
      "Оріхове",
      "Орлівка",
      "Орлове",
      "Осипенко",
      "Павлівка",
      "Панькове",
      "Партизанське",
      "Пашена Балка",
      "Перемога",
      "Перше Травня",
      "Петриківка",
      "Петрівське",
      "Петро-Свистунове",
      "Пилипівка",
      "Письмечеве",
      "Підгородне",
      "Плавещина",
      "Помазанівка",
      "Преображенка",
      "Привільне",
      "Придніпрянське",
      "Приют",
      "Промінь",
      "Пропашне",
      "Проточі",
      "Прядівка",
      "Пшеничне",
      "Радісне",
      "Ракшівка",
      "Рибалки",
      "Розтання",
      "Рудка",
      "Рясне",
      "Салівка",
      "Святовасилівка",
      "Селянівка",
      "Семенівка",
      "Сергіївка",
      "Слобожанське",
      "Солоне",
      "Сонячне",
      "Сорочине",
      "Сотницьке",
      "Спаське",
      "Старі Кодаки",
      "Стародніпровське",
      "Степове",
      "Суданівка",
      "Судівка",
      "Супина",
      "Сурське",
      "Сурсько-Клевцеве",
      "Сурсько-Литовське",
      "Сурсько-Михайлівка",
      "Тарасівка",
      "Тарасо-Шевченківка",
      "Тихе",
      "Товариський Труд",
      "Томаківка",
      "Тракторне",
      "Тритузне",
      "Трудолюбівка",
      "Турове",
      "Улянівка",
      "Хижине",
      "Хутірське",
      "Хуторо-Губиниха",
      "Царичанка",
      "Цибульківка",
      "Чаплинка",
      "Червона Орілька",
      "Червоний Садок",
      "Червонокам’яне",
      "Чернігівка",
      "Чорнопарівка",
      "Чувилине",
      "Чумаки",
      "Шарівка",
      "Шевченко",
      "Шестипілля",
      "Широке",
      "Широкополе",
      "Шульгівка",
      "Щербинівка",
      "Юр’ївка",
      "Якимівка",
      "Авангард",
      "Авксенівка",
      "Адалимівка",
      "Адамівка",
      "Аули",
      "Байдаківка",
      "Байківка",
      "Балкове",
      "Березине",
      "Березняк",
      "Бикове",
      "Біленщина",
      "Благодатне",
      "Благословенна",
      "Богдано-Надеждівка",
      "Божедарівка",
      "Болтишка",
      "Боровківка",
      "Бородаївка",
      "Бородаївські Хутори",
      "Бузулуки",
      "Верхівцеве",
      "Верхньодніпровськ",
      "Верхньокам’яниста",
      "Вершинне",
      "Весела Долина",
      "Весела Роща",
      "Веселий Поділ",
      "Виноградівка",
      "Високе",
      "Вільні Хутори",
      "Вільногірськ",
      "Вітрівка",
      "Воєводівка",
      "Володимирівка",
      "Волочаївка",
      "Галина Лозуватка",
      "Ганнівка",
      "Грамівка",
      "Гранітне",
      "Гранове",
      "Гримуче",
      "Грузинівка",
      "Грушуватка",
      "Гуляйполе",
      "Демурино-Варварівка",
      "Діденкове",
      "Дідове",
      "Дніпровокам’янка",
      "Доброгірське",
      "Домоткань",
      "Дружба",
      "Єгорівка",
      "Жовте",
      "Жовті Води",
      "Жовтоолександрівка",
      "Заполички",
      "Запоріжжя",
      "Заріччя",
      "Затишне",
      "Зелена Долина",
      "Зелене",
      "Зелений Клин",
      "Зелений Кут",
      "Зелений Луг",
      "Зелений Яр",
      "Золотницьке",
      "Зуботрясівка",
      "Івашинівка",
      "Івашкове",
      "Іллінка",
      "Калужине",
      "Кам’янське",
      "Кам’янчани",
      "Карнаухівка",
      "Касинівка",
      "Катеринопіль",
      "Клин",
      "Ковалівка",
      "Козаче",
      "Козодуб",
      "Комісарівка",
      "Корнило-Наталівка",
      "Коробчине",
      "Красна Воля",
      "Красний Луг",
      "Красноіванівка",
      "Кривоносове",
      "Крута Балка",
      "Кудашівка",
      "Культура",
      "Кулябкине",
      "Кушнарівка",
      "Ликошине",
      "Липове",
      "Лихівка",
      "Лозуватське",
      "Лугове",
      "Любомирівка",
      "Людмилівка",
      "Маломихайлівка",
      "Малоолександрівка",
      "Малософіївка",
      "Малярщина",
      "Мар’янівка",
      "Матюченкове",
      "Милорадівка",
      "Миролюбівка",
      "Миронівка",
      "Мишурин Ріг",
      "Мости",
      "Мотронівка",
      "Надія",
      "Нерудсталь",
      "Нова Праця",
      "Новоганнівка",
      "Новогригорівка",
      "Новогурівка",
      "Новожитлівка",
      "Новозалісся",
      "Новоіванівка",
      "Новокалинівка",
      "Новомилорадівка",
      "Новопідгірне",
      "Новопушкарівка",
      "Новотроїцьке",
      "Новоукраїнка",
      "Одарівка",
      "Олександро-Григорівка",
      "Оленівка",
      "Осикувате",
      "П’ятихатки",
      "Павло-Григорівка",
      "Пальмирівка",
      "Первозванівка",
      "Первомайське",
      "Петрівка",
      "Підгірне",
      "Підлужжя",
      "Плоске",
      "Плоско-Таранівка",
      "Покровка",
      "Полівське",
      "Полтавобоголюбівка",
      "Поляна",
      "Попівка",
      "Посуньки",
      "Потоки",
      "Правобережне",
      "Прапор",
      "Привілля",
      "Пушкарівка",
      "Райдужне",
      "Ровеньки",
      "Рогівське",
      "Савро",
      "Саївка",
      "Саксаганське",
      "Саксагань",
      "Самоткань",
      "Світле",
      "Світлогірське",
      "Скелюватка",
      "Смоленка",
      "Соколівка",
      "Соколове",
      "Солов’ївка",
      "Сорокопанівка",
      "Степанівка",
      "Суворовське",
      "Суслівка",
      "Суха Балка",
      "Суханівка",
      "Сухий Хутір",
      "Теплівка",
      "Терно-Лозуватка",
      "Тернувате",
      "Троїцьке",
      "Трудове",
      "Удачне",
      "Українка",
      "Українське",
      "Цвіле",
      "Цівки",
      "Чепине",
      "Червона Балка",
      "Червона Гірка",
      "Червона Деріївка",
      "Червона Поляна",
      "Червоне",
      "Червоний Орлик",
      "Червоний Став",
      "Червоний Яр",
      "Червоноіванівка",
      "Черкаське",
      "Чернече",
      "Чигринівка",
      "Чистопіль",
      "Чкаловка",
      "Шмакове",
      "Яблуневе",
      "Яковлівка",
      "Ярок",
      "Авдотівка",
      "Анастасівка",
      "Андрусівка",
      "Апостолове",
      "Базавлучок",
      "Базарове",
      "Братсько-Семенівка",
      "Бурлацьке",
      "Вакулове",
      "Велика Костромка",
      "Весела Дача",
      "Веселе Поле",
      "Веселий Кут",
      "Веселий Став",
      "Веселі Чумаки",
      "Високе Поле",
      "Вільне Життя",
      "Вільний Посад",
      "Вільний Табір",
      "Ганно-Миколаївка",
      "Гейківка",
      "Гірницьке",
      "Глеюватка",
      "Гомельське",
      "Гончарове",
      "Городуватка",
      "Гречані Поди",
      "Грузька Григорівка",
      "Грузьке",
      "Грушки",
      "Данилівка",
      "Дачне",
      "Девладове",
      "Дем’янівка",
      "Дніпровка",
      "Довгівка",
      "Єлизаветпілля",
      "Зав’ялівка",
      "Запорізьке",
      "Запорожець",
      "Зелена Балка",
      "Зелене Поле",
      "Зелений Став",
      "Зеленодольськ",
      "Златопіль",
      "Златоустівка",
      "Золота Поляна",
      "Зоряне",
      "Ізлучисте",
      "Інгулець",
      "Казанківка",
      "Калашники",
      "Кам’яне Поле",
      "Карпівка",
      "Катерино-Наталівка",
      "Ковалеве",
      "Кодак",
      "Коломійцеве",
      "Кошове",
      "Кравці",
      "Красівське",
      "Красна Балка",
      "Красне",
      "Красний Під",
      "Кривбас",
      "Кривий Ріг",
      "Криничувате",
      "Кряжове",
      "Курганка",
      "Латівка",
      "Лісове",
      "Лісопитомник",
      "Лошкарівка",
      "Львів",
      "Любе",
      "Макарівка",
      "Макорти",
      "Мала Костромка",
      "Мар’є-Дмитрівка",
      "Мар’є-Костянтинівка",
      "Мар’ївка",
      "Мар’янське",
      "Михайло-Заводське",
      "Могилівка",
      "Мотина Балка",
      "Мусіївка",
      "Надеждівка",
      "Назарівка",
      "Недайвода",
      "Непереможне",
      "Нива Трудова",
      "Нова Зоря",
      "Нова Січ",
      "Нове Життя",
      "Новий Кременчук",
      "Новий Мир",
      "Новий Шлях",
      "Нові Ковна",
      "Нові Садки",
      "Нововітебське",
      "Новожитомир",
      "Новокурське",
      "Новолатівка",
      "Новолозуватка",
      "Новомайське",
      "Новомалинівка",
      "Новомар’янівка",
      "Новомар’янівське",
      "Новомихайлівка",
      "Новоолексіївка",
      "Новопетрівка",
      "Новопілля",
      "Новоподільське",
      "Новосеменівка",
      "Новоукраїнське",
      "Новохортиця",
      "Новоюлівка",
      "Одрадне",
      "Одрубок",
      "Озерне",
      "Олександрія",
      "Олександро-Білівка",
      "Ордо-Василівка",
      "Петрове",
      "Петропавлівка",
      "Пичугине",
      "Плугатар",
      "Подидар",
      "Подове",
      "Пологи",
      "Полтавка",
      "Потоцьке",
      "Радевичеве",
      "Радіонівка",
      "Радушне",
      "Раєво-Олександрівка",
      "Райполе",
      "Ранній Ранок",
      "Розівка",
      "Романівка",
      "Рудничне",
      "Садове",
      "Свистунове",
      "Слов’янка",
      "Солдатське",
      "Софіївка",
      "Спокойствіє",
      "Стародобровільське",
      "Суворовка",
      "Тарасо-Григорівка",
      "Тернівка",
      "Тернуватий Кут",
      "Тернуватка",
      "Тихий Став",
      "Тік",
      "Токівське",
      "Усть-Кам’янка",
      "Христофорівка",
      "Цвіткове",
      "Чабанове",
      "Червона Колона",
      "Червоне Поле",
      "Червоний Запорожець",
      "Червоний Ранок",
      "Червоний Тік",
      "Червоні Поди",
      "Шведове",
      "Шевченківське",
      "Шевченкове",
      "Шестірня",
      "Широка Дача",
      "Широка Долина",
      "Широчани",
      "Южне",
      "Яблунівка",
      "Явдотівка",
      "Явдохівка",
      "Баркове",
      "Бекетівка",
      "Борисівка",
      "Весела Федорівка",
      "Веселий Яр",
      "Виводове",
      "Високопіль",
      "Вищетарасівка",
      "Гарбузівка",
      "Глухе",
      "Головкове",
      "Голубівка",
      "Грушеве",
      "Добра Надія",
      "Жмерине",
      "Запорізька Балка",
      "Західне",
      "Звізда",
      "Змагання",
      "Капулівка",
      "Катьощине",
      "Кисличувата",
      "Китайгородка",
      "Краснопіль",
      "Крутеньке",
      "Крутий Берег",
      "Лебединське",
      "Лукіївка",
      "Максимівка",
      "Марганець",
      "Маринопіль",
      "Межуївка",
      "Менделєєвка",
      "Мирове",
      "Набережне",
      "Настасівка",
      "Нікополь",
      "Нова Балта",
      "Новокам’янка",
      "Новокатьощине",
      "Новокиївка",
      "Новокраснопіль",
      "Новопавлівка",
      "Новософіївка",
      "Охотниче",
      "Павлопілля",
      "Пахар",
      "Першотравневе",
      "Південне",
      "Покров",
      "Покровське",
      "Придніпровське",
      "Приміське",
      "Прогрес",
      "Путилівка",
      "Старозаводське",
      "Стрюківка",
      "Східне",
      "Таврійське",
      "Томаківське",
      "Топила",
      "Урожайне",
      "Хмельницьке",
      "Чайки",
      "Червоногригорівка",
      "Чкалове",
      "Чортомлик",
      "Шахтар",
      "Шишкине",
      "Шолохове",
      "Бузівка",
      "Варварівка",
      "Великокозирщина",
      "Веселий Гай",
      "Воскресенівка",
      "Всесвятське",
      "Гавришівка",
      "Гвардійське",
      "Герасимівка",
      "Грабки",
      "Губиниха",
      "Гупалівка",
      "Деконка",
      "Дмухайлівка",
      "Дубравка",
      "Дудківка",
      "Євдокіївка",
      "Євецько-Миколаївка",
      "Жданівка",
      "Заплавка",
      "Знаменівка",
      "Івано-Михайлівка",
      "Йосипівка",
      "Казначеївка",
      "Керносівка",
      "Кільчень",
      "Ковпаківка",
      "Козирщина",
      "Королівка",
      "Котовка",
      "Крамарка",
      "Краснопілля",
      "Кременівка",
      "Левенцівка",
      "Личкове",
      "Магдалинівка",
      "Малоандріївка",
      "Малокозирщина",
      "Меліоративне",
      "Минівка",
      "Мусієнкове",
      "Новомосковськ",
      "Новоскотувате",
      "Новостепанівка",
      "Новошандрівка",
      "Олянівка",
      "Орілька",
      "Орлівщина",
      "Очеретувате",
      "Панасівка",
      "Перещепине",
      "Першотравенка",
      "Підпільне",
      "Піщанка",
      "Поливанівка",
      "Попасне",
      "Почино-Софіївка",
      "Приорільське",
      "Рівне",
      "Свічанівка",
      "Січкарівка",
      "Скотувате",
      "Тарасове",
      "Топчине",
      "Хащеве",
      "Чернеччина",
      "Шандрівка",
      "Шевське",
      "Шевченківка",
      "Ягідне",
      "Білозерське",
      "Богуслав",
      "Бразолове",
      "Булахівка",
      "В’язівок",
      "В’язівське-Водяне",
      "Варламівка",
      "Вербки",
      "Вербське",
      "Вербуватівка",
      "Весела Гірка",
      "Голубівське",
      "Долина",
      "Домаха",
      "Жемчужне",
      "Жолобок",
      "Зарічне",
      "Івано-Межиріцьке",
      "Карабинівка",
      "Кіндратівка",
      "Кохівка",
      "Кочережки",
      "Левадки",
      "Лиманське",
      "Межиріч",
      "Мерцалівка",
      "Мінеральні Води",
      "Морозівське",
      "Нижнянка",
      "Нова Дача",
      "Нова Русь",
      "Нові Вербки",
      "Новов’язівське",
      "Новоіванівське",
      "Новоіларіонівське",
      "Новомиколаївське",
      "Новомосковське",
      "Новоолександрівське",
      "Новоселівське",
      "Новотимофіївське",
      "Новочорноглазівське",
      "Оженківка",
      "Орлівське",
      "Павлоград",
      "Писарівка",
      "Підлісне",
      "Поперечне",
      "Привовчанське",
      "Призове",
      "Пристень",
      "Самарське",
      "Свідівок",
      "Сокільське",
      "Солонці",
      "Степ",
      "Терни",
      "Федорівське",
      "Червона Долина",
      "Червона Нива",
      "Чернявщина",
      "Чорноглазівка",
      "Шахтарське",
      "Широка Балка",
      "Юр’ївське",
      "Аврамівка",
      "Андронівка",
      "Антонівське",
      "Артемівка",
      "Бабакове",
      "Бажани",
      "Бегма",
      "Березнуватка",
      "Березове",
      "Біляківка",
      "Богдано-Вербки",
      "Богинівка",
      "Богодарівка",
      "Богуславка",
      "Бондареве",
      "Бровки",
      "Бурханівка",
      "Василівка-На-Дніпрі",
      "Васильківка",
      "Васильківське",
      "Васюківка",
      "Великомихайлівка",
      "Великоолександрівка",
      "Вербівське",
      "Вербки-Осокорівка",
      "Вереміївка",
      "Вишневецьке",
      "Відрадне",
      "Відродження",
      "Вовчанське",
      "Вовче",
      "Водолазьке",
      "Возвратне",
      "Вознесенське",
      "Володимирівське",
      "Вороне",
      "Воронізьке",
      "Воронове",
      "Гаврилівка",
      "Гай",
      "Гапоно-Мечетне",
      "Георгіївка",
      "Гірки",
      "Гострий Камінь",
      "Гришаї",
      "Грушувато-Криничне",
      "Грякувате",
      "Дебальцеве",
      "Демурине",
      "Дерезувате",
      "Дібрівка",
      "Добринька",
      "Добровілля",
      "Добропасове",
      "Довге",
      "Дрозди",
      "Дубовики",
      "Дубо-Осокорівка",
      "Жукове",
      "Журавлинка",
      "Зайцеве",
      "Залізничне",
      "Заяче",
      "Зелена Роща",
      "Знаменівське",
      "Іванівське",
      "Іларіонове",
      "Калинівське",
      "Кам’януватка",
      "Каплистівка",
      "Кардаші",
      "Касаєве",
      "Катражка",
      "Київське",
      "Киричкове",
      "Кирпичне",
      "Кислянка",
      "Кобзар",
      "Кодацьке",
      "Козачий Гай",
      "Коломійці",
      "Колона-Межова",
      "Колоно-Миколаївка",
      "Копані",
      "Котлярівське",
      "Коханівка",
      "Красногорівка",
      "Краснощокове",
      "Кривобокове",
      "Криворізьке",
      "Крутоярівка",
      "Крутоярка",
      "Кунінова",
      "Левадне",
      "Лиса Балка",
      "Лісне",
      "Лозове",
      "Луб’янка",
      "Луб’янці",
      "Мажари",
      "Майське",
      "Мала Покровка",
      "Малієве",
      "Маліївка",
      "Маліївське",
      "Маломиколаївка",
      "Манвелівка",
      "Мар’ївське",
      "Мар’їна Роща",
      "Мар’їнка",
      "Мар’янка",
      "Медичне",
      "Межова",
      "Мечетне",
      "Миронове",
      "Надеждине",
      "Ненаситець",
      "Нечаївка",
      "Новий Посьолок",
      "Нововасильківка",
      "Нововербське",
      "Нововознесенка",
      "Нововоскресенівка",
      "Новогеоргіївка",
      "Новогніде",
      "Новодмитрівка",
      "Новолозуватівка",
      "Новоолександропіль",
      "Новопавлоградське",
      "Новопідгородне",
      "Новопричепилівка",
      "Новоскелювате",
      "Новотерсянське",
      "Новохорошевське",
      "Новочернігівське",
      "Носачі",
      "Обоянівське",
      "Олефірівка",
      "Орестопіль",
      "Орли",
      "Осадче",
      "Осокорівка",
      "Остапівське",
      "Отрішки",
      "Парне",
      "Партизани",
      "Перевальське",
      "Перепеляче",
      "Першозванівка",
      "Першотравенськ",
      "Петриків",
      "Петрикове",
      "Писанці",
      "Письменне",
      "Підгаврилівка",
      "Піщане",
      "Полтавське",
      "Польове",
      "Попове",
      "Попутне",
      "Правда",
      "Преображенське",
      "Пришиб",
      "Просяна",
      "Раївка",
      "Ракове",
      "Роздолля",
      "Роздори",
      "Романки",
      "Росішки",
      "Рубанівське",
      "Рудево-Миколаївка",
      "Русакове",
      "Сад",
      "Свиридове",
      "Сидоренко",
      "Синельникове",
      "Славгород",
      "Славне",
      "Сонцеве",
      "Соснівка",
      "Старий Колодязь",
      "Старовишневецьке",
      "Старокасянівське",
      "Старолозуватка",
      "Суха Калина",
      "Сухарева Балка",
      "Таранове",
      "Тернове",
      "Терса",
      "Товсте",
      "Токове",
      "Третяківка",
      "Тургенєвка",
      "Успенівка",
      "Філія",
      "Хвилі",
      "Хороше",
      "Хорошеве",
      "Хуторо-Чаплине",
      "Циганівка",
      "Чаплине",
      "Чаус",
      "Чорненкове",
      "Чугуєве",
      "Шев’якине",
      "Шевченка",
      "Широкосмоленка",
      "Январське",
      "Ясне",
    ],
  },
  {
    region: "Донецька",
    cities: [
      "Андріївка",
      "Бахмут",
      "Бахмутське",
      "Берестове",
      "Берхівка",
      "Білогорівка",
      "Благодатне",
      "Богданівка",
      "Бондарне",
      "Валентинівка",
      "Василівка",
      "Васюківка",
      "Верхньокам’янське",
      "Вершина",
      "Весела Долина",
      "Веселе",
      "Виїмка",
      "Відродження",
      "Воздвиженка",
      "Володимирівка",
      "Гладосове",
      "Голубівка",
      "Григорівка",
      "Дача",
      "Дачне",
      "Диліївка",
      "Діброва",
      "Доломітне",
      "Дронівка",
      "Дружба",
      "Дубово-Василівка",
      "Зайцеве",
      "Залізне",
      "Залізнянське",
      "Званівка",
      "Зеленопілля",
      "Іванград",
      "Іванівське",
      "Івано-Дар’ївка",
      "Калинівка",
      "Клинове",
      "Кліщіївка",
      "Кодема",
      "Красна Гора",
      "Краснополівка",
      "Кримське",
      "Криничне",
      "Кузьминівка",
      "Курдюмівка",
      "Леонідівка",
      "Липівка",
      "Липове",
      "Лозове",
      "Луганське",
      "Миколаївка",
      "Миколаївка Друга",
      "Миронівка",
      "Миронівський",
      "Мідна Руда",
      "Міньківка",
      "Нагірне",
      "Неліпівка",
      "Никифорівка",
      "Нова Кам’янка",
      "Новолуганське",
      "Новоселівка",
      "Нью-Йорк",
      "Одрадівка",
      "Озарянівка",
      "Опитне",
      "Оріхово-Василівка",
      "Пазено",
      "Парасковіївка",
      "Переїзне",
      "Петрівка",
      "Пилипчатине",
      "Південне",
      "Північне",
      "Підгородне",
      "Платонівка",
      "Покровське",
      "Привілля",
      "Різниківка",
      "Роздолівка",
      "Розсадки",
      "Роти",
      "Сакко І Ванцетті",
      "Світлодарськ",
      "Свято-Покровське",
      "Семигір’я",
      "Серебрянка",
      "Сіверськ",
      "Соледар",
      "Спірне",
      "Стряпівка",
      "Суха Балка",
      "Торецьк",
      "Травневе",
      "Трипілля",
      "Федорівка",
      "Федорівка Друга",
      "Хромівка",
      "Хромове",
      "Часів Яр",
      "Шуми",
      "Щербинівка",
      "Юр’ївка",
      "Ягідне",
      "Яковлівка",
      "Анадоль",
      "Багатир",
      "Бахчовик",
      "Березове",
      "Благовіщенка",
      "Ближнє",
      "Богоявленка",
      "Бугас",
      "Бурлацьке",
      "Валер’янівка",
      "Велика Новосілка",
      "Вікторівка",
      "Вільне",
      "Вільне Поле",
      "Водяне",
      "Волноваха",
      "Володине",
      "Воскресенка",
      "Времівка",
      "Вугледар",
      "Георгіївка",
      "Голубицьке",
      "Гранітне",
      "Графське",
      "Грушівське",
      "Діанівка",
      "Дмитрівка",
      "Дніпроенергія",
      "Донське",
      "Дружне",
      "Євгенівка",
      "Єгорівка",
      "Завітне Бажання",
      "Запоріжжя",
      "Запорізьке",
      "Затишне",
      "Зачатівка",
      "Зелене Поле",
      "Зелений Гай",
      "Зелений Кут",
      "Зірка",
      "Златоустівка",
      "Знаменівка",
      "Золота Нива",
      "Іванівка",
      "Іскра",
      "Калинове",
      "Кам’янка",
      "Керменчик",
      "Кирилівка",
      "Ключове",
      "Комар",
      "Комишуваха",
      "Костянтинопіль",
      "Красна Поляна",
      "Кропивницьке",
      "Лазарівка",
      "Лідине",
      "Лісне",
      "Макарівка",
      "Максимівка",
      "Малий Керменчик",
      "Малинівка",
      "Маловодне",
      "Малогнатівка",
      "Микільське",
      "Мирне",
      "Нескучне",
      "Новий Комар",
      "Новоандріївка",
      "Новоапостолівка",
      "Новогнатівка",
      "Новогригорівка",
      "Новодонецьке",
      "Новомайорське",
      "Новомиколаївка",
      "Новоолексіївка",
      "Новоочеретувате",
      "Новопавлівка",
      "Новопетриківка",
      "Новопіль",
      "Новоселівка Друга",
      "Новосілка",
      "Новотатарівка",
      "Новотроїцьке",
      "Новоукраїнка",
      "Новохатське",
      "Обільне",
      "Одрадне",
      "Олександроград",
      "Олексіївка",
      "Ольгинка",
      "Орлинське",
      "Павлівка",
      "Перебудова",
      "Передове",
      "Петрівське",
      "Петропавлівка",
      "Піддубне",
      "Пільне",
      "Полкове",
      "Пречистівка",
      "Привільне",
      "Прохорівка",
      "Рибинське",
      "Рівнопіль",
      "Роздольне",
      "Розлив",
      "Свободне",
      "Скудне",
      "Слов’янка",
      "Солодке",
      "Сонячне",
      "Старогнатівка",
      "Старомайорське",
      "Старомар’ївка",
      "Старомлинівка",
      "Степанівка",
      "Степне",
      "Сторожеве",
      "Стрітенка",
      "Тарамчук",
      "Тарасівка",
      "Толстой",
      "Трудівське",
      "Трудове",
      "Улакли",
      "Урожайне",
      "Хлібодарівка",
      "Шахтарське",
      "Шевченко",
      "Ялинське",
      "Ялта",
      "Ясна Поляна",
      "Авіловка",
      "Балка",
      "Балочне",
      "Бражине",
      "Булавине",
      "Булавинське",
      "Бунге",
      "Велика Шишівка",
      "Верхній Кут",
      "Верхня Кринка",
      "Вікторія",
      "Вільхівка",
      "Вінницьке",
      "Вуглегірськ",
      "Гірне",
      "Гірницьке",
      "Гольмівський",
      "Горлівка",
      "Грабове",
      "Грозне",
      "Данилове",
      "Дебальцеве",
      "Дебальцівське",
      "Дібрівка",
      "Дорофієнко",
      "Дубове",
      "Єнакієве",
      "Жданівка",
      "Залісне",
      "Зарічне",
      "Зарощенське",
      "Захарченко",
      "Зрубне",
      "Іллінка",
      "Каютине",
      "Кищенко",
      "Кожевня",
      "Комишатка",
      "Контарне",
      "Корсунь",
      "Красний Луч",
      "Кринички",
      "Круглик",
      "Кумшацьке",
      "Латишеве",
      "Лиманчук",
      "Лобанівка",
      "Логвинове",
      "Малоорлівка",
      "Мануйлівка",
      "Маринівка",
      "Михайлівка",
      "Молодецьке",
      "Молодий Шахтар",
      "Молчалине",
      "Московське",
      "Нижнє Лозове",
      "Никифорове",
      "Нікішине",
      "Новоорлівка",
      "Озерянівка",
      "Олександрівське",
      "Оленівка",
      "Ольховатка",
      "Орлово-Іванівка",
      "П’ятихатки",
      "Пантелеймонівка",
      "Пелагіївка",
      "Первомайське",
      "Первомайський",
      "Передерієве",
      "Побєда",
      "Польове",
      "Прибережне",
      "Путепровід",
      "Рівне",
      "Рідкодуб",
      "Розівка",
      "Розсипне",
      "Рясне",
      "Савелівка",
      "Садове",
      "Санжарівка",
      "Саурівка",
      "Сердите",
      "Сєверне",
      "Славне",
      "Сніжне",
      "Софіївка",
      "Ставки",
      "Старопетрівське",
      "Стіжківське",
      "Стіжкове",
      "Стрюкове",
      "Ступакове",
      "Сухівське",
      "Тарани",
      "Тернове",
      "Тимофіївка",
      "Хрестівка",
      "Червона Зоря",
      "Чистякове",
      "Чугуно-Крепинка",
      "Чумаки",
      "Шапошникове",
      "Шахтарськ",
      "Широка Балка",
      "Щебенка",
      "Агрономічне",
      "Алмазне",
      "Амвросіївка",
      "Артемівка",
      "Бирюки",
      "Білоярівка",
      "Бондаревське",
      "Ведмеже",
      "Велике Мішкове",
      "Велике Оріхове",
      "Вербівка",
      "Вербова Балка",
      "Верхньоєланчик",
      "Верхньоосикове",
      "Виноградне",
      "Виселки",
      "Високе",
      "Вільхівчик",
      "Водобуд",
      "Вугляр",
      "Горбачево-Михайлівка",
      "Грабське",
      "Гришки",
      "Грузько-Зорянське",
      "Грузько-Ломівка",
      "Гусельське",
      "Дзеркальне",
      "Донецьк",
      "Дубівка",
      "Єлизавето-Миколаївка",
      "Житенко",
      "Жукова Балка",
      "Зелене",
      "Землянки",
      "Золотарівка",
      "Зугрес",
      "Зуївка",
      "Іловайськ",
      "Карпово-Надеждинка",
      "Катеринівка",
      "Каштанове",
      "Квашине",
      "Киселівка",
      "Кисличе",
      "Кленівка",
      "Кобзарі",
      "Колосникове",
      "Копані",
      "Кошарне",
      "Красна Зоря",
      "Кринична",
      "Крута Балка",
      "Кутейникове",
      "Ларине",
      "Леб’яже",
      "Липське",
      "Лисиче",
      "Макіївка",
      "Мала Шишівка",
      "Манич",
      "Маяк",
      "Межове",
      "Мережки",
      "Металіст",
      "Мінеральне",
      "Многопілля",
      "Мокроєланчик",
      "Молочарка",
      "Монахове",
      "Моспине",
      "Нижньокринське",
      "Нижня Кринка",
      "Новий Світ",
      "Новоамвросіївське",
      "Новодвірське",
      "Новоєланчик",
      "Новоіванівка",
      "Новоклинівка",
      "Новомар’ївка",
      "Новомосковське",
      "Новопелагіївка",
      "Новоукраїнське",
      "Обрізне",
      "Овочеве",
      "Олександрівка",
      "Олексіївське",
      "Ольгинське",
      "Оріхове",
      "П’ятипілля",
      "Павлівське",
      "Павлоградське",
      "Петренки",
      "Півче",
      "Покровка",
      "Полтавське",
      "Придорожнє",
      "Родники",
      "Рубашкине",
      "Русько-Орлівка",
      "Свистуни",
      "Семенівське",
      "Сергієве-Кринка",
      "Сіятель",
      "Спартак",
      "Старомихайлівка",
      "Степано-Кринка",
      "Темрюк",
      "Трепельне",
      "Третяки",
      "Троїцько-Харцизьк",
      "Улянівське",
      "Успенка",
      "Харківське",
      "Харцизьк",
      "Холмисте",
      "Холодне",
      "Цупки",
      "Червоносільське",
      "Шахтне",
      "Широке",
      "Ясинівка",
      "Ясинувата",
      "Азов",
      "Безіменне",
      "Берегове",
      "Бессарабка",
      "Біла Кам’янка",
      "Білокриничне",
      "Бойківське",
      "Бурне",
      "Ванюшкине",
      "Веденське",
      "Верезамське",
      "Верхньошироківське",
      "Верхокам’янка",
      "Вершинівка",
      "Вишневе",
      "Вітава",
      "Вознесенка",
      "Воля",
      "Глинка",
      "Горбатенко",
      "Греково-Олександрівка",
      "Гусельщикове",
      "Дерсове",
      "Докучаєвськ",
      "Доля",
      "Запорожець",
      "Зернове",
      "Зорі",
      "Кальміуське",
      "Кам’яне",
      "Каплани",
      "Качкарське",
      "Кипуча Криниця",
      "Клинкине",
      "Ковське",
      "Козацьке",
      "Козлівка",
      "Колоски",
      "Конькове",
      "Котляревське",
      "Краснопілля",
      "Кремінець",
      "Кузнеці",
      "Кузнецово-Михайлівка",
      "Куликове",
      "Культура",
      "Кумачове",
      "Лавринове",
      "Лужки",
      "Лукове",
      "Любівка",
      "Майорове",
      "Малинове",
      "Манжиків Кут",
      "Мар’янівка",
      "Маркине",
      "Менчугове",
      "Митьково-Качкарі",
      "Мічуріне",
      "Молодіжне",
      "Набережне",
      "Нова Мар’ївка",
      "Нова Оленівка",
      "Новоазовськ",
      "Новобешеве",
      "Новозар’ївка",
      "Новокатеринівка",
      "Новоласпа",
      "Новомихайлівка",
      "Новоолександрівка",
      "Обрив",
      "Осикове",
      "Патріотичне",
      "Підгірне",
      "Піщане",
      "Порохня",
      "Приморське",
      "Прохорівське",
      "Ребрикове",
      "Родникове",
      "Роза",
      "Садки",
      "Самійлове",
      "Самсонове",
      "Сарабаш",
      "Саханка",
      "Світле",
      "Світлий Луч",
      "Сєдове",
      "Сєдово-Василівка",
      "Сигнальне",
      "Сонцеве",
      "Соснівське",
      "Старобешеве",
      "Староласпа",
      "Стила",
      "Строїтель",
      "Тавричеське",
      "Тернівка",
      "Ужівка",
      "Українське",
      "Хомутове",
      "Хрещатицьке",
      "Червоне",
      "Черевківське",
      "Чирилянське",
      "Чумак",
      "Шмідта",
      "Щербак",
      "Ясне",
      "Адамівка",
      "Ашуркове",
      "Беззаботівка",
      "Безім’яне",
      "Березівка",
      "Бересток",
      "Билбасівка",
      "Біла Гора",
      "Біленьке",
      "Білокузьминівка",
      "Богородичне",
      "Брусівка",
      "Бузинівка",
      "Варварівка",
      "Василівська Пустош",
      "Васютинське",
      "Весела Гора",
      "Високопілля",
      "Віролюбівка",
      "Вовчий Яр",
      "Водяне Друге",
      "Глибока Макатиха",
      "Гнатівка",
      "Громова Балка",
      "Дерилове",
      "Дмитро-Дар’ївка",
      "Дмитроколине",
      "Довга Балка",
      "Долина",
      "Донецьке",
      "Дробишеве",
      "Дружківка",
      "Дружківське",
      "Єлизаветівка",
      "Закітне",
      "Запаро-Мар’ївка",
      "Зелена Долина",
      "Зелений Брід",
      "Золоті Пруди",
      "Зоря",
      "Іванопілля",
      "Іверське",
      "Іжевка",
      "Іллінівка",
      "Каленики",
      "Карпівка",
      "Кіндратівка",
      "Клебан-Бик",
      "Колодязі",
      "Коровій Яр",
      "Костянтинівка",
      "Краматорськ",
      "Красний Кут",
      "Красноторка",
      "Крива Лука",
      "Кримки",
      "Криниці",
      "Курицине",
      "Куроїдівка",
      "Куртівка",
      "Левадне",
      "Лиман",
      "Львівка",
      "Мазанівка",
      "Майдан",
      "Майське",
      "Малотаранівка",
      "Мар’ївка",
      "Маркове",
      "Маяки",
      "Маячка",
      "Миколайпілля",
      "Мирна Долина",
      "Надеждівка",
      "Некременне",
      "Никонорівка",
      "Нова Полтавка",
      "Нове",
      "Новий Кавказ",
      "Новобахметьєве",
      "Новодмитрівка",
      "Новознаменівка",
      "Новоіверське",
      "Новомаркове",
      "Новооленівка",
      "Новопетрівка",
      "Новополтавка",
      "Новопригоже",
      "Новосадове",
      "Новосамарське",
      "Новосергіївка",
      "Новостепанівка",
      "Новоявленка",
      "Озерне",
      "Олександро-Калинове",
      "Олександропіль",
      "Олександро-Шультине",
      "Олексієво-Дружківка",
      "Оріхуватка",
      "Очеретине",
      "Пасічне",
      "Першомар’ївка",
      "Петрівка Друга",
      "Петрівка Перша",
      "Пискунівка",
      "Плещіївка",
      "Подільське",
      "Полтавка",
      "Попасне",
      "Попів Яр",
      "Предтечине",
      "Прелесне",
      "Пришиб",
      "Приют",
      "Райгородок",
      "Рай-Олександрівка",
      "Райське",
      "Роганське",
      "Роздолля",
      "Розкішне",
      "Романівка",
      "Рубці",
      "Русин Яр",
      "Самарське",
      "Самійлівка",
      "Святогірськ",
      "Селезнівка",
      "Семенівка",
      "Сергіївка",
      "Середнє",
      "Сидорове",
      "Слов’янськ",
      "Соснове",
      "Софіїно-Лиман",
      "Спасько-Михайлівка",
      "Стара Миколаївка",
      "Старий Караван",
      "Староварварівка",
      "Стародубівка",
      "Старорайське",
      "Стінки",
      "Ступочки",
      "Терни",
      "Тетянівка",
      "Тихонівка",
      "Торець",
      "Торецьке",
      "Торське",
      "Троїцьке",
      "Хрестище",
      "Черкаське",
      "Шабельківка",
      "Шаврове",
      "Шандриголове",
      "Шнурки",
      "Шостаківка",
      "Щурове",
      "Юрківка",
      "Яблунівка",
      "Ямпіль",
      "Ямполівка",
      "Ярова",
      "Ясногірка",
      "Яцьківка",
      "Агробаза",
      "Азовське",
      "Асланове",
      "Бабах-Тарама",
      "Бердянське",
      "Білосарайська Коса",
      "Бойове",
      "Бурякова Балка",
      "Глибоке",
      "Гнутове",
      "Дем’янівка",
      "Заїченко",
      "Захарівка",
      "Зелений Яр",
      "Кальчик",
      "Кальчинівка",
      "Касянівка",
      "Келерівка",
      "Комишувате",
      "Кременівка",
      "Ксенівка",
      "Лебединське",
      "Ломакине",
      "Лугове",
      "Македонівка",
      "Малоянисоль",
      "Мангуш",
      "Маріуполь",
      "Мелекіне",
      "Назарівка",
      "Нікольське",
      "Новокраснівка",
      "Новороманівка",
      "Новоянисоль",
      "Огороднє",
      "Орловське",
      "Павлопіль",
      "Паннівка",
      "Перемога",
      "Пищевик",
      "Пікузи",
      "Портівське",
      "Приазовське",
      "Приміське",
      "Приовражне",
      "Республіка",
      "Рибацьке",
      "Сартана",
      "Сопине",
      "Старий Крим",
      "Суженка",
      "Талаківка",
      "Тополине",
      "Труженка",
      "Українка",
      "Урзуф",
      "Херсонес",
      "Чермалик",
      "Черненко",
      "Широкине",
      "Авдіївка",
      "Антонівка",
      "Архангельське",
      "Балаган",
      "Баранівка",
      "Бердичі",
      "Берестки",
      "Бетманове",
      "Білицьке",
      "Білозерське",
      "Благодать",
      "Бойківка",
      "Бокове",
      "Верхньоторецьке",
      "Веселе Поле",
      "Веселий Гай",
      "Весна",
      "Вірівка",
      "Вовкове",
      "Вовче",
      "Вовченка",
      "Водянське",
      "Восход",
      "Галицинівка",
      "Ганнівка",
      "Гігант",
      "Гірник",
      "Горіхове",
      "Гостре",
      "Гришине",
      "Гродівка",
      "Грузьке",
      "Гуліве",
      "Дальнє",
      "Даченське",
      "Добропілля",
      "Долинівка",
      "Дорожнє",
      "Желанне",
      "Желанне Друге",
      "Желанне Перше",
      "Жовте",
      "Журавка",
      "Завидо-Борзенка",
      "Завидо-Кудашеве",
      "Завітне",
      "Звірове",
      "Зеленівка",
      "Золотий Колодязь",
      "Зоряне",
      "Ізмайлівка",
      "Карлівка",
      "Керамік",
      "Комишівка",
      "Коптєве",
      "Костянтинопольське",
      "Котлине",
      "Котлярівка",
      "Красний Лиман",
      "Красний Яр",
      "Красногорівка",
      "Красноподілля",
      "Красноярське",
      "Кремінна Балка",
      "Криворіжжя",
      "Крутий Яр",
      "Курахівка",
      "Курахове",
      "Кутузовка",
      "Кучерів Яр",
      "Ласточкине",
      "Лисичне",
      "Лисівка",
      "Лісівка",
      "Лозуватське",
      "Максимільянівка",
      "Мар’їнка",
      "Матяшеве",
      "Мемрик",
      "Мирноград",
      "Миролюбівка",
      "Муравка",
      "Надеждинка",
      "Надія",
      "Невельське",
      "Нетайлове",
      "Никанорівка",
      "Нове Шахове",
      "Новий Донбас",
      "Новий Труд",
      "Новобахмутівка",
      "Нововасилівка",
      "Нововікторівка",
      "Нововодяне",
      "Новогришине",
      "Новогродівка",
      "Новоекономічне",
      "Новоєлизаветівка",
      "Новожеланне",
      "Новокалинове",
      "Новокриворіжжя",
      "Новопокровське",
      "Новопустинка",
      "Новоселидівка",
      "Новоселівка Перша",
      "Новоторецьке",
      "Новофедорівка",
      "Орлівка",
      "Острівське",
      "Панківка",
      "Перше Травня",
      "Піски",
      "Покровськ",
      "Преображенка",
      "Прогрес",
      "Промінь",
      "Птиче",
      "Пустинка",
      "Пушкіне",
      "Разіне",
      "Ракша",
      "Ріг",
      "Родинське",
      "Рубіжне",
      "Свиридонівка",
      "Святогорівка",
      "Селидове",
      "Скучне",
      "Сокіл",
      "Соловйове",
      "Солоне",
      "Сонцівка",
      "Срібне",
      "Старі Терни",
      "Степове",
      "Суворове",
      "Сухецьке",
      "Сухий Яр",
      "Сухі Яли",
      "Тоненьке",
      "Троянда",
      "Удачне",
      "Українськ",
      "Уманське",
      "Успенівка",
      "Цукурине",
      "Чунишине",
      "Шахове",
      "Шевченко Перше",
      "Шилівка",
      "Янтарне",
      "Ясенове",
      "Яснобродівка",
    ],
  },
  {
    region: "Житомирська",
    cities: [
      "Агатівка",
      "Андріяшівка",
      "Андрушівка",
      "Антопіль",
      "Безпечна",
      "Бенедівка",
      "Бердичів",
      "Березянка",
      "Берізки",
      "Бистрик",
      "Бистріївка",
      "Білилівка",
      "Богданівка",
      "Бровки Другі",
      "Бровки Перші",
      "Бурківці",
      "Буряки",
      "Велика П’ятигірка",
      "Великі Гадомці",
      "Великі Мошківці",
      "Великі Низгірці",
      "Вербівка",
      "Верхівня",
      "Вишневе",
      "Вільнопілля",
      "Волосів",
      "Вчорайше",
      "Гальчин",
      "Гарапівка",
      "Гардишівка",
      "Гвіздава",
      "Глинівці",
      "Голубівка",
      "Городище",
      "Городківка",
      "Городок",
      "Городоцьке",
      "Града",
      "Гришківці",
      "Демчин",
      "Дерганівка",
      "Дубівка",
      "Дубова",
      "Жерделі",
      "Жеребки",
      "Житинці",
      "Журбинці",
      "Забара",
      "Закутинці",
      "Заріччя",
      "Зарубинці",
      "Зарудинці",
      "Звиняче",
      "Зелене",
      "Зоряне",
      "Іванківці",
      "Іванопіль",
      "Йосипівка",
      "Камені",
      "Карабчиїв",
      "Катеринівка",
      "Кикишівка",
      "Княжики",
      "Кордонівка",
      "Костянтинівка",
      "Котелянка",
      "Котівка",
      "Красівка",
      "Краснопіль",
      "Крилівка",
      "Кукільня",
      "Кустин",
      "Лебединці",
      "Лемеші",
      "Лихосілка",
      "Лісівка",
      "Лісова Слобідка",
      "Лісове",
      "Любимівка",
      "Любомирка",
      "Мала П’ятигірка",
      "Мала Чернявка",
      "Малі Гадомці",
      "Малі Мошківці",
      "Малі Низгірці",
      "Малосілка",
      "Мар’янівка",
      "Маркуші",
      "Мартинівка",
      "Медведиха",
      "Мирне",
      "Мирославка",
      "Міньківці",
      "Мовчанівка",
      "Мовчанівське",
      "Молочки",
      "Мостове",
      "Мотрунки",
      "Мусіївка",
      "Немиринці",
      "Нехворощ",
      "Никонівка",
      "Нова Котельня",
      "Нова Олександрівка",
      "Нова Чорнорудка",
      "Новий Солотвин",
      "Носівки",
      "Обухівка",
      "Огіївка",
      "Озадівка",
      "Осикове",
      "Павелки",
      "Певна",
      "Першотравневе",
      "Підгородне",
      "Плоска",
      "Половецьке",
      "Польова Слобідка",
      "Поштове",
      "Прибережне",
      "Причепівка",
      "Радісне",
      "Райгородок",
      "Райки",
      "Рея",
      "Рогачі",
      "Романівка",
      "Роставиця",
      "Ружин",
      "Садки",
      "Сахни",
      "Семенівка",
      "Сингаївка",
      "Скаківка",
      "Скраглівка",
      "Слободище",
      "Старий Солотвин",
      "Степок",
      "Стетківці",
      "Суслівка",
      "Сьомаки",
      "Тарасівка",
      "Терехове",
      "Топори",
      "Трубіївка",
      "Хажин",
      "Хмелище",
      "Хмельове",
      "Червоне",
      "Черемуха",
      "Чехова",
      "Чорнорудка",
      "Швайківка",
      "Шпичинці",
      "Ягнятин",
      "Ярешки",
      "Яроповичі",
      "Ярославка",
      "Авратин",
      "Андріївка",
      "Андрушки",
      "Антонівка",
      "Бабичівка",
      "Бабушки",
      "Барашівка",
      "Бартуха",
      "Бежів",
      "Бейзимівка",
      "Березина",
      "Березівка",
      "Березова Гать",
      "Березці",
      "Биків",
      "Биківка",
      "Бистрі",
      "Бичева",
      "Біла Криниця",
      "Білка",
      "Білки",
      "Більківці",
      "Бобрик",
      "Болярка",
      "Болячів",
      "Бондарці",
      "Борок",
      "Борушківці",
      "Борщів",
      "Борятин",
      "Браженець",
      "Браженка",
      "Брід",
      "Брусилів",
      "Бубни",
      "Буглаки",
      "Будилівка",
      "Будичани",
      "Будище",
      "Будо-Рижани",
      "Буймир",
      "Буки",
      "Булдичів",
      "Буряківка",
      "Бухалівщина",
      "В’юнки",
      "В’язовець",
      "Вакуленчук",
      "Валки",
      "Василівка",
      "Велика Волиця",
      "Велика Горбаша",
      "Велика Козара",
      "Велика Рача",
      "Велика Чернявка",
      "Великий Браталів",
      "Великий Луг",
      "Великі Деревичі",
      "Великі Коровинці",
      "Великі Кошарища",
      "Великі Лісівці",
      "Великобраталівське",
      "Веприн",
      "Вереси",
      "Верлок",
      "Вертокиївка",
      "Вершина",
      "Веселе",
      "Веселка",
      "Веснянка",
      "Вигнанка",
      "Вигода",
      "Видибор",
      "Видумка",
      "Вила",
      "Виноградівка",
      "Вирва",
      "Висока Піч",
      "Високе",
      "Високий Камінь",
      "Вихля",
      "Вишевичі",
      "Вишняківка",
      "Вишпіль",
      "Вівче",
      "Відродження",
      "Віленька",
      "Вільня",
      "Вільнянка",
      "Вільськ",
      "Вільха",
      "Вільшанка",
      "Вільшка",
      "Водотиї",
      "Волиця",
      "Волосівка",
      "Волянщина",
      "Врублівка",
      "Гадзинка",
      "Гай",
      "Гайки",
      "Галинівка",
      "Галіївка",
      "Ганнопіль",
      "Гацьківка",
      "Гвіздярня",
      "Гізівщина",
      "Глезне",
      "Глибочиця",
      "Глибочок",
      "Глиниця",
      "Глухів Другий",
      "Глухів Перший",
      "Годиха",
      "Головенка",
      "Головине",
      "Голуб’ятин",
      "Голубин",
      "Горбулів",
      "Гордіївка",
      "Горіхове",
      "Городенка",
      "Городське",
      "Городчин",
      "Горопаї",
      "Грабівка",
      "Грабняк",
      "Грем’яче",
      "Гринівці",
      "Гришківка",
      "Громада",
      "Грубське",
      "Грузливець",
      "Грушки",
      "Гуйва",
      "Гуменники",
      "Гута-Забілоцька",
      "Гута-Потіївка",
      "Гута-Юстинівка",
      "Давидівка",
      "Данилівка",
      "Дацьки",
      "Дашинка",
      "Двірець",
      "Дворище",
      "Демківці",
      "Дениші",
      "Дертка",
      "Дехтярка",
      "Дивин",
      "Дібрівка",
      "Дівочки",
      "Дідківці",
      "Дітинець",
      "Довбиші",
      "Довжик",
      "Долинівка",
      "Дорогунь",
      "Дослідне",
      "Дреники",
      "Дриглів",
      "Дубище",
      "Дубовець",
      "Дубовик",
      "Дубрівка",
      "Єлизаветівка",
      "Єрчики",
      "Жадьки",
      "Житомир",
      "Жовтий Брід",
      "Жовтнівка",
      "Журавлинка",
      "Забілоччя",
      "Заболоть",
      "Забріддя",
      "Заздрівка",
      "Закомірня",
      "Залізня",
      "Залужне",
      "Заміри",
      "Заможне",
      "Заньки",
      "Западня",
      "Зарічани",
      "Здвижка",
      "Зелена Діброва",
      "Зелена Поляна",
      "Зелений Гай",
      "Знам’янка",
      "Зороків",
      "Зубринка",
      "Іванівка",
      "Іванівщина",
      "Іванків",
      "Івановичі",
      "Івниця",
      "Ірша",
      "Ісаківка",
      "Кайтанівка",
      "Калинівка",
      "Калиновий Гай",
      "Кам’яний Брід",
      "Кам’янка",
      "Камінь",
      "Карабачин",
      "Карвинівка",
      "Карпівці",
      "Кашперівка",
      "Квітневе",
      "Кириївка",
      "Кихті",
      "Кичкирі",
      "Кілки",
      "Клен",
      "Клітище",
      "Клітчин",
      "Кмитів",
      "Княжин",
      "Коваленки",
      "Ковганівка",
      "Кодня",
      "Козак",
      "Козіївка",
      "Колодіївка",
      "Колодяжне",
      "Колодязьки",
      "Комарівка",
      "Копанівка",
      "Копелянка",
      "Коритище",
      "Корнин",
      "Корнинське",
      "Королівка",
      "Коростелівка",
      "Коростишів",
      "Коростки",
      "Короченки",
      "Корчак",
      "Корчівка",
      "Корчмище",
      "Костівці",
      "Котлярка",
      "Кочерів",
      "Кошелівка",
      "Кошляки",
      "Краївщина",
      "Краківщина",
      "Красилівка",
      "Краснобірка",
      "Красноволиця",
      "Красногірка",
      "Краснорічка",
      "Красносілка",
      "Криве",
      "Кримок",
      "Кропивенка",
      "Кропивня",
      "Крук",
      "Крути",
      "Крученець",
      "Ксаверівка",
      "Кулішівка",
      "Курганці",
      "Курне",
      "Кутище",
      "Лазарівка",
      "Левків",
      "Леонівка",
      "Лизник",
      "Липівка",
      "Липки",
      "Липне",
      "Лисівка",
      "Лісівщина",
      "Ліски",
      "Лісна Рудня",
      "Ліщин",
      "Лука",
      "Луковець",
      "Лутівка",
      "Лучин",
      "Любар",
      "М’ясківка",
      "Макалевичі",
      "Макарівка",
      "Мала Волиця",
      "Мала Горбаша",
      "Мала Деревичка",
      "Мала Козара",
      "Мала Рача",
      "Мала Токарівка",
      "Малий Браталів",
      "Малинівка",
      "Малі Коровинці",
      "Малі Кошарища",
      "Мамрин",
      "Мані",
      "Маркова Волиця",
      "Машина",
      "Меделівка",
      "Межирічка",
      "Меленці",
      "Меньківка",
      "Микільськ",
      "Минійки",
      "Миролюбівка",
      "Миропіль",
      "Михайленки",
      "Михайлівка",
      "Мишерине",
      "Мірча",
      "Містечко",
      "Млинище",
      "Моделів",
      "Мокренщина",
      "Молодіжне",
      "Монастирок",
      "Морогівка",
      "Морозівка",
      "Мотовилівка",
      "Мохначка",
      "Мошківка",
      "Муравня",
      "Нарцизівка",
      "Науменка",
      "Небіж",
      "Неборівка",
      "Невирівка",
      "Негребівка",
      "Некраші",
      "Нераж",
      "Нивна",
      "Нова Борова",
      "Нова Буда",
      "Нова Василівка",
      "Нова Вигода",
      "Нова Мар’янівка",
      "Нова Рудня",
      "Нова Чортория",
      "Нова Юрівка",
      "Новий Завод",
      "Новий Любар",
      "Новини",
      "Нові Жадьки",
      "Нові Озеряни",
      "Нові Сали",
      "Новогородецьке",
      "Новогуйвинське",
      "Новоівницьке",
      "Новопаволоцьке",
      "Новопіль",
      "Новопрутівка",
      "Новоселиця",
      "Новосілка",
      "Новохатки",
      "Облітки",
      "Озера",
      "Озерне",
      "Озерянка",
      "Окілок",
      "Олександрівка",
      "Олізарка",
      "Оліївка",
      "Олішівка",
      "Ольшанка",
      "Омелівка",
      "Омильне",
      "Онишпіль",
      "Осиковий Копець",
      "Осички",
      "Осів",
      "Осівка",
      "Осівці",
      "Осники",
      "Очеретянка",
      "П’ятка",
      "Павленківка",
      "Павлівка",
      "Паволоч",
      "Паволочка",
      "Панасівка",
      "Парипси",
      "Пединка",
      "Пекарщина",
      "Переміжжя",
      "Перемога",
      "Перетік",
      "Перлявка",
      "Печанівка",
      "Пилипівка",
      "Пилиповичі",
      "Пилипо-Кошара",
      "Пилипонка",
      "Писарівка",
      "Підлісне",
      "Піски",
      "Піщанка",
      "Плехів",
      "Подолянці",
      "Покостівка",
      "Покришів",
      "Полівська Гута",
      "Попільня",
      "Поплавка",
      "Поромівка",
      "Поташня",
      "Потіївка",
      "Почуйки",
      "Прибуток",
      "Привітів",
      "Привороття",
      "Провалівка",
      "Продубіївка",
      "Прутівка",
      "Пряжів",
      "Пулини",
      "Пулино-Гута",
      "Пустельники",
      "Радецька Болярка",
      "Радецьке Будище",
      "Радичі",
      "Радівка",
      "Радомишль",
      "Разіне",
      "Раївка",
      "Раковичі",
      "Рачки",
      "Рижани",
      "Рижів",
      "Рогізна",
      "Роздольне",
      "Розкидайлівка",
      "Романів",
      "Росівка",
      "Руденька",
      "Рудка",
      "Рудківка",
      "Рудня",
      "Рудня-Гацьківка",
      "Рудня-Городецька",
      "Рудня-Городище",
      "Рудня-Пошта",
      "Рудня-Фасова",
      "Рудня-Шляхова",
      "Рудокопи",
      "Ружки",
      "Русанівка",
      "Саверці",
      "Садове",
      "Сали",
      "Сарнівка",
      "Свидя",
      "Світин",
      "Северинівка",
      "Селець",
      "Селянщина",
      "Синява",
      "Сінгури",
      "Сколобів",
      "Скоморохи",
      "Скочище",
      "Славів",
      "Сліпчиці",
      "Слобідка",
      "Слобода-Селець",
      "Смиківка",
      "Смолівка",
      "Соболівка",
      "Сокільча",
      "Соколів",
      "Соколів Брід",
      "Соловіївка",
      "Солодирі",
      "Сонячне",
      "Соснівка",
      "Спірне",
      "Ставецьке",
      "Ставище",
      "Ставки",
      "Став-Слобода",
      "Станишівка",
      "Станіславівка",
      "Стара Буда",
      "Стара Гребля",
      "Стара Котельня",
      "Стара Олександрівка",
      "Стара Рудня",
      "Стара Чортория",
      "Старий Бобрик",
      "Старий Любар",
      "Старий Майдан",
      "Старицьке",
      "Старосілля",
      "Старосільці",
      "Старочуднівська Гута",
      "Старошийка",
      "Стебниця",
      "Стирти",
      "Стовпів",
      "Стрибіж",
      "Стрижівка",
      "Строків",
      "Струцівка",
      "Студениця",
      "Судачівка",
      "Сульжинівка",
      "Суховоля",
      "Сущанка",
      "Таборище",
      "Тартачок",
      "Тевеліївка",
      "Теклянівка",
      "Теньківка",
      "Теренці",
      "Теснівка",
      "Тетерівка",
      "Тетірка",
      "Товсте",
      "Товща",
      "Томашівка",
      "Топорище",
      "Торчин",
      "Травневе",
      "Тригір’я",
      "Трикопці",
      "Троковичі",
      "Троща",
      "Троянів",
      "Трудове",
      "Турбівка",
      "Туровець",
      "Турчинівка",
      "Турчинка",
      "Тютюнники",
      "Ужівка",
      "Улашанівка",
      "Улянівка",
      "Фасова",
      "Федорівка",
      "Филинці",
      "Філонівка",
      "Харитонівка",
      "Харліївка",
      "Хижинці",
      "Химрич",
      "Хичів",
      "Ходори",
      "Ходорівка",
      "Ходорків",
      "Хом’янка",
      "Хомівка",
      "Хомутець",
      "Хорошів",
      "Царівка",
      "Цвітянка",
      "Цеберка",
      "Чайківка",
      "Червоний Гай",
      "Червоний Ровець",
      "Червоний Степок",
      "Червоні Хатки",
      "Червоногранітне",
      "Червоносілка",
      "Черемошне",
      "Чернявка",
      "Черняхів",
      "Чехівці",
      "Човнова",
      "Чудин",
      "Чуднів",
      "Шадура",
      "Шахворостівка",
      "Шевченка",
      "Шереметів",
      "Щеніїв",
      "Щербини",
      "Щигліївка",
      "Юлянівка",
      "Юрівка",
      "Яблунівка",
      "Ягодинка",
      "Ягодинка Друга",
      "Ялинівка",
      "Ясенівка",
      "Ясна Поляна",
      "Ясногірка",
      "Ясногород",
      "Ясопіль",
      "Яструбенька",
      "Яструбна",
      "Абрамок",
      "Адамівка",
      "Адамове",
      "Анастасівка",
      "Андрієвичі",
      "Анета",
      "Аполлонівка",
      "Багате",
      "Баранівка",
      "Бараші",
      "Барвинівка",
      "Бастова Рудня",
      "Берегове",
      "Березники",
      "Берестівка",
      "Бобрицька Болярка",
      "Бобриця",
      "Борисівка",
      "Брідок",
      "Броники",
      "Броницька Гута",
      "Брониця",
      "Будисько",
      "Будища",
      "Будо-Бобриця",
      "Варварівка",
      "Велика Глумча",
      "Велика Деражня",
      "Велика Цвіля",
      "Великий Молодьків",
      "Великий Яблунець",
      "Верби",
      "Вересівка",
      "Вересна",
      "Вершниця",
      "Вироби",
      "Вишківка",
      "Вишнівка",
      "Вікторівка",
      "Вірівка",
      "Вірля",
      "Владин",
      "Володимирівка",
      "Ганнівка",
      "Гірки",
      "Глинянка",
      "Горбове",
      "Городниця",
      "Гриньки",
      "Груд",
      "Гульськ",
      "Гута-Бобрицька",
      "Гута-Зеленицька",
      "Деревищина",
      "Дібрівське",
      "Дідовичі",
      "Довбиш",
      "Дорогань",
      "Дублинки",
      "Дубники",
      "Дуга",
      "Євгенівка",
      "Єлизаветпіль",
      "Ємільчине",
      "Жари",
      "Жеребилівка",
      "Жовте",
      "Жолобне",
      "Забаро-Давидівка",
      "Закриниччя",
      "Запруда",
      "Заровенка",
      "Звягель",
      "Здоровець",
      "Зелениця",
      "Зеремля",
      "Зірка",
      "Зорянка",
      "Зосимівка",
      "Зрубок",
      "Зятинець",
      "Івашківка",
      "Ілляшівка",
      "Кам’яний Майдан",
      "Кам’яногірка",
      "Кануни",
      "Карпилівка",
      "Катюха",
      "Кикова",
      "Киселівка",
      "Киянка",
      "Кленова",
      "Климентіївка",
      "Кожушки",
      "Колодянка",
      "Коритища",
      "Косенів",
      "Косяк",
      "Кочичине",
      "Крайня Деражня",
      "Красилівське",
      "Красуля",
      "Крем’янка",
      "Кривотин",
      "Куліші",
      "Курчицька Гута",
      "Курчиця",
      "Кущове",
      "Лагульськ",
      "Лебедівка",
      "Лебідь",
      "Липине",
      "Лідівка",
      "Лісова Поляна",
      "Лучиця",
      "Льонівка",
      "Любарська Гута",
      "Майдан",
      "Майстрів",
      "Майстрова Воля",
      "Маковиці",
      "Мала Анастасівка",
      "Мала Глумча",
      "Мала Цвіля",
      "Малий Кривотин",
      "Малий Яблунець",
      "Малоглумчанка",
      "Маринівка",
      "Марківка",
      "Марушівка",
      "Медведеве",
      "Миколаївка",
      "Мирославль",
      "Михіївка",
      "Млини",
      "Мойсіївка",
      "Мокляки",
      "Мокре",
      "Нараївка",
      "Наталівка",
      "Наталія",
      "Неділище",
      "Немильня",
      "Непізнаничі",
      "Несолонь",
      "Нитине",
      "Нова Романівка",
      "Нові Серби",
      "Новозелене",
      "Новоолександрівка",
      "Ожгів",
      "Ольхівка",
      "Омелуша",
      "Орепи",
      "Осичне",
      "Осова",
      "Острожок",
      "Паранине",
      "Партизанське",
      "Перелісок",
      "Перелісянка",
      "Першотравенськ",
      "Підлуби",
      "Піщів",
      "Повчине",
      "Покощеве",
      "Поліянівка",
      "Полоничеве",
      "Полянка",
      "Прихід",
      "Просіка",
      "Радулин",
      "Рихальське",
      "Рогачів",
      "Рогівка",
      "Рудня-Іванівська",
      "Рудня-Миколаївка",
      "Рясне",
      "Серби",
      "Сербо-Слобідка",
      "Сергіївка",
      "Середи",
      "Середня",
      "Середня Деражня",
      "Симони",
      "Синявка",
      "Ситисько",
      "Ситне",
      "Сімаківка",
      "Слобода-Романівська",
      "Смолдирів",
      "Смолка",
      "Сорочень",
      "Спаське",
      "Стара Гута",
      "Старий Хмерин",
      "Старі Непізнаничі",
      "Старі Серби",
      "Степанівка",
      "Степове",
      "Стовпи",
      "Стриєва",
      "Суємці",
      "Сусли",
      "Табори",
      "Тайки",
      "Тальки",
      "Тальківка",
      "Таращанка",
      "Тартак",
      "Тернівка",
      "Токарів",
      "Тупальці",
      "Турова",
      "Ужачин",
      "Українське",
      "Усолуси",
      "Хижівка",
      "Ходурки",
      "Хотиж",
      "Хутір-Мокляки",
      "Червона Воля",
      "Червоний Бір",
      "Червонодвірка",
      "Чижівка",
      "Чміль",
      "Шевченкове",
      "Яблуневе",
      "Яблунець",
      "Явне",
      "Яворівка",
      "Ялишів",
      "Яменець",
      "Ярунь",
      "Антоновичі",
      "Артинськ",
      "Бабиничі",
      "Базар",
      "Базарівка",
      "Барвінки",
      "Барвінкове",
      "Барди",
      "Батьківщина",
      "Бацеве",
      "Бережесть",
      "Березине",
      "Березневе",
      "Березовий Груд",
      "Берестовець",
      "Бехи",
      "Бігунь",
      "Білий Берег",
      "Білокамінка",
      "Білокоровичі",
      "Білошиці",
      "Бірківське",
      "Бобричі",
      "Бовсуни",
      "Болотниця",
      "Бондарі",
      "Бондарівка",
      "Боровиця",
      "Броди",
      "Бродник",
      "Буда",
      "Буда-Голубієвичі",
      "Будки",
      "Будницьке",
      "Будо-Вороб’ї",
      "Будо-Літки",
      "Булахівка",
      "Булів",
      "Бучки",
      "Бучмани",
      "В’юнище",
      "В’язівка",
      "Васьківці",
      "Васьковичі",
      "Велень",
      "Велика Фосня",
      "Велика Хайча",
      "Велика Чернігівка",
      "Великий Дивлин",
      "Великий Кобилин",
      "Великий Ліс",
      "Великі Міньки",
      "Великі Мошки",
      "Верпа",
      "Верхня Рудня",
      "Веселівка",
      "Вигів",
      "Видень",
      "Винарівка",
      "Виступовичі",
      "Вишів",
      "Вишнянка",
      "Візня",
      "Вільхова",
      "Владівка",
      "Возлякове",
      "Возничі",
      "Волошине",
      "Вороб’ївщина",
      "Вороневе",
      "Ворсівка",
      "Гаєвичі",
      "Гамарня",
      "Гладковицька Кам’янка",
      "Гладковичі",
      "Глухова",
      "Головки",
      "Голубієвичі",
      "Горбачі",
      "Горинь",
      "Городець",
      "Горщик",
      "Гошів",
      "Граби",
      "Гранітне",
      "Грезля",
      "Грозине",
      "Губенкове",
      "Гулянка",
      "Гуничі",
      "Гусарівка",
      "Гуска",
      "Гута-Добринь",
      "Гута-Ксаверівська",
      "Гута-Логанівська",
      "Гуто-Мар’ятин",
      "Гутянське",
      "Давидки",
      "Держанівка",
      "Десятини",
      "Джерело",
      "Діброва",
      "Дівошин",
      "Дідковичі",
      "Добринь",
      "Довгиничі",
      "Домолоч",
      "Дружба",
      "Дружбівка",
      "Дружне",
      "Дуби",
      "Дубовий Гай",
      "Думинське",
      "Єлівка",
      "Ємилівка",
      "Жабоч",
      "Жабче",
      "Жерев",
      "Жеревці",
      "Жубровичі",
      "Жупанівка",
      "Журжевичі",
      "Забороче",
      "Забране",
      "Заводне",
      "Загребля",
      "Задорожок",
      "Закусили",
      "Заліски",
      "Залісся",
      "Замисловичі",
      "Запілля",
      "Зарічка",
      "Зарубинка",
      "Заськи",
      "Збраньки",
      "Збраньківці",
      "Зибин",
      "Злобичі",
      "Зольня",
      "Зубівщина",
      "Зубковичі",
      "Ігнатпіль",
      "Іллімка",
      "Іршанськ",
      "Іскорость",
      "Каленське",
      "Кам’яна Гірка",
      "Кам’яна Гора",
      "Кам’янівка",
      "Кирдани",
      "Кишин",
      "Клинець",
      "Клітня",
      "Клочеве",
      "Клочки",
      "Ковалі",
      "Ковалівка",
      "Ковалівщина",
      "Кованка",
      "Ковбащина",
      "Кожухівка",
      "Козулі",
      "Колосівка",
      "Копище",
      "Коптівщина",
      "Кораки",
      "Коренівка",
      "Корма",
      "Коростень",
      "Корощине",
      "Косня",
      "Кошечки",
      "Красностав",
      "Кремне",
      "Крупчатка",
      "Крушники",
      "Ксаверів",
      "Купеч",
      "Купище",
      "Ласки",
      "Латаші",
      "Левковицький Млинок",
      "Левковичі",
      "Липляни",
      "Липлянщина",
      "Липники",
      "Листвин",
      "Листвинівка",
      "Личмани",
      "Лісна Колона",
      "Лісобуда",
      "Літки",
      "Лозниця",
      "Лопатичі",
      "Лугини",
      "Лугинки",
      "Лугове",
      "Луки",
      "Лукішки",
      "Лумля",
      "Лучанки",
      "Любарка",
      "Любовичі",
      "Магдин",
      "Майданівка",
      "Майдан-Копищенський",
      "Маклаївка",
      "Мала Зубівщина",
      "Мала Фосня",
      "Мала Хайча",
      "Мала Чернігівка",
      "Малахівка",
      "Малий Дивлин",
      "Малий Кобилин",
      "Малин",
      "Малі Мошки",
      "Мамеч",
      "Мар’ятин",
      "Мацьки",
      "Мединівка",
      "Межиліска",
      "Мелені",
      "Миролюбів",
      "Мишковичі",
      "Млинок",
      "Можари",
      "Мотійки",
      "Мочульня",
      "Мощаниця",
      "Нагоряни",
      "Народичі",
      "Невгоди",
      "Недашки",
      "Недашківка",
      "Немирівка",
      "Нивки",
      "Нижня Рудня",
      "Нова Гута",
      "Нова Діброва",
      "Нова Радча",
      "Нова Рутвянка",
      "Нова Ушиця",
      "Новаки",
      "Нове Життя",
      "Новий Дорогинь",
      "Новина",
      "Нові Білокоровичі",
      "Нові Велідники",
      "Нові Вороб’ї",
      "Нові Новаки",
      "Новобратське",
      "Новоозерянка",
      "Новосілки",
      "Ноздрище",
      "Норинськ",
      "Норинці",
      "Нянівка",
      "Обиходи",
      "Обиходівка",
      "Обище",
      "Ободівка",
      "Овруч",
      "Одруби",
      "Озеряни",
      "Олевськ",
      "Оленичі",
      "Омелянівка",
      "Оржів",
      "Осика",
      "Осни",
      "Остапи",
      "Острів",
      "Острови",
      "Охотівка",
      "П’ятидуб",
      "Павловичі",
      "Павлюківка",
      "Папірня",
      "Перга",
      "Переброди",
      "Пиріжки",
      "Підвелідники",
      "Підостапи",
      "Підруддя",
      "Піщаниця",
      "Плещівка",
      "Побичі",
      "Повч",
      "Покалів",
      "Покровське",
      "Поліське",
      "Полохачів",
      "Потаповичі",
      "Пояски",
      "Прибитки",
      "Привар",
      "Привітне",
      "Прилуки",
      "Пристанційне",
      "Пугачівка",
      "Путиловичі",
      "Радовель",
      "Радогоща",
      "Радча",
      "Радчиці",
      "Раківщина",
      "Репище",
      "Ришавка",
      "Різня",
      "Ровба",
      "Розівка",
      "Розсохівське",
      "Розтяжин",
      "Рокитне",
      "Рубанка",
      "Рубежівка",
      "Рудня-Базарська",
      "Рудня-Бистра",
      "Рудня-Білківська",
      "Рудня-Вороб’ївська",
      "Рудня-Городищенська",
      "Рудня-Жеревці",
      "Рудня-Замисловицька",
      "Рудня-Калинівка",
      "Рудня-Кам’янка",
      "Рудня-Озерянська",
      "Рудня-Перганська",
      "Рудня-Повчанська",
      "Рудня-Радовельська",
      "Рудня-Ушомирська",
      "Рудня-Хочинська",
      "Рутвянка",
      "Савлуки",
      "Савченки",
      "Садибне",
      "Сантарка",
      "Сарновичі",
      "Свиридівка",
      "Селезівка",
      "Семени",
      "Сердюки",
      "Середня Рудня",
      "Сингаї",
      "Сирківщина",
      "Сирниця",
      "Сичівка",
      "Скребеличі",
      "Скурати",
      "Славенщина",
      "Славковиці",
      "Слобода",
      "Слобода-В’язівка",
      "Слобода-Новоселицька",
      "Слобода-Шоломківська",
      "Словечне",
      "Смоляне",
      "Снитище",
      "Сновидовичі",
      "Сокорики",
      "Солов’ї",
      "Сорокопень",
      "Станційне",
      "Стара Радча",
      "Старий Дорогинь",
      "Старий Кужіль",
      "Старики",
      "Старі Велідники",
      "Старі Вороб’ї",
      "Старі Новаки",
      "Стасева",
      "Сташки",
      "Стовпинка",
      "Стремигород",
      "Стримівщина",
      "Струмок",
      "Стугівщина",
      "Студень",
      "Субине",
      "Сухарівка",
      "Сушки",
      "Сущани",
      "Теклівка",
      "Тепениця",
      "Тичків",
      "Тишів",
      "Товкачі",
      "Топільня",
      "Тростяниця",
      "Трудолюбівка",
      "Тхорин",
      "Українка",
      "Усове",
      "Устинівка",
      "Ушиця",
      "Ушомир",
      "Фортунатівка",
      "Хлупляни",
      "Хмелівка",
      "Ходаки",
      "Ходачки",
      "Холосне",
      "Хотинівка",
      "Хочине",
      "Христинівка",
      "Чабан",
      "Червона Волока",
      "Червоний Лан",
      "Червонка",
      "Черевки",
      "Черепин",
      "Черепинки",
      "Чоповичі",
      "Шатрище",
      "Шебедиха",
      "Шершні",
      "Шоломки",
      "Щербатівка",
      "Юрове",
      "Яжберень",
      "Ялцівка",
      "Ярочище",
      "Ясенець",
      "Яцковичі",
    ],
  },
  {
    region: "Закарпатська",
    cities: [
      "Арданово",
      "Астей",
      "Бадалово",
      "Бадів",
      "Бакош",
      "Балажер",
      "Баркасово",
      "Батрадь",
      "Батьово",
      "Бене",
      "Берегове",
      "Берегуйфалу",
      "Богаревиця",
      "Боржава",
      "Боржавське",
      "Ботар",
      "Букове",
      "Вари",
      "Велика Бакта",
      "Велика Бийгань",
      "Велика Копаня",
      "Велика Паладь",
      "Великі Береги",
      "Великі Ком’яти",
      "Вербове",
      "Вербовець",
      "Верхні Ремети",
      "Веряця",
      "Вилок",
      "Виноградів",
      "Воловиця",
      "Галабор",
      "Гать",
      "Гетен",
      "Гетиня",
      "Геча",
      "Горбки",
      "Горонглаб",
      "Гудя",
      "Гуняді",
      "Гут",
      "Данилівка",
      "Дийда",
      "Дротинці",
      "Дунковиця",
      "Дюла",
      "Запсонь",
      "Затисівка",
      "Затишне",
      "Кам’янське",
      "Карачин",
      "Каштаново",
      "Квасово",
      "Кідьош",
      "Королево",
      "Косонь",
      "Мала Бийгань",
      "Мала Копаня",
      "Мале Попово",
      "Матійово",
      "Мідяниця",
      "Мочола",
      "Мужієво",
      "Неветленфолу",
      "Нижні Ремети",
      "Нове Клинове",
      "Нове Село",
      "Новоселиця",
      "Оклі",
      "Оклі Гедь",
      "Олешник",
      "Онок",
      "Оросієво",
      "Перехрестя",
      "Пийтерфолво",
      "Підвиноградів",
      "Попово",
      "Притисянське",
      "Пушкіно",
      "Рафайново",
      "Руська Долина",
      "Сасово",
      "Свобода",
      "Серне",
      "Сільце",
      "Теково",
      "Тисобикень",
      "Тросник",
      "Фанчиково",
      "Фертешолмаш",
      "Форголань",
      "Хижа",
      "Хмільник",
      "Холмовець",
      "Чепа",
      "Черна",
      "Четфалва",
      "Чикош-Горонда",
      "Чома",
      "Чорний Потік",
      "Чорнотисів",
      "Шаланки",
      "Широке",
      "Шом",
      "Яноші",
      "Абранка",
      "Бабичі",
      "Барбово",
      "Бенедиківці",
      "Березинка",
      "Бистриця",
      "Біласовиця",
      "Бобовище",
      "Брестів",
      "Буковець",
      "Буковинка",
      "Великі Лучки",
      "Верб’яж",
      "Верхні Ворота",
      "Верхній Коропець",
      "Верхня Визниця",
      "Верхня Грабівниця",
      "Вільховиця",
      "Вінкове",
      "Вовчий",
      "Воловець",
      "Гандеровиця",
      "Ганьковиця",
      "Герцівці",
      "Голубине",
      "Горбок",
      "Горонда",
      "Грабово",
      "Грибівці",
      "Гукливий",
      "Дерцен",
      "Ділок",
      "Домбоки",
      "Доробратово",
      "Драгиня",
      "Драчино",
      "Дубино",
      "Дусино",
      "Жборівці",
      "Жденієво",
      "Жнятино",
      "Жуково",
      "Завадка",
      "Завидово",
      "Задільське",
      "Залужжя",
      "Збини",
      "Зняцьово",
      "Зубівка",
      "Іванівці",
      "Ільківці",
      "Кайданово",
      "Кальник",
      "Канора",
      "Карпати",
      "Кінлодь",
      "Кічерний",
      "Кленовець",
      "Клочки",
      "Ключарки",
      "Клячаново",
      "Кольчино",
      "Коноплівці",
      "Копинівці",
      "Косино",
      "Котельниця",
      "Крите",
      "Кузьмино",
      "Кучава",
      "Куштановиця",
      "Лавки",
      "Лази",
      "Лалово",
      "Латірка",
      "Лецовиця",
      "Лісарня",
      "Лопушанка",
      "Лохово",
      "Макарьово",
      "Мала Мартинка",
      "Медведівці",
      "Микулівці",
      "Мукачево",
      "Негрово",
      "Неліпино",
      "Нижні Ворота",
      "Нижній Коропець",
      "Нове Давидково",
      "Обава",
      "Оленьово",
      "Павлово",
      "Павшино",
      "Пасіка",
      "Пашківці",
      "Перехресний",
      "Підполоззя",
      "Пістрялово",
      "Плав’я",
      "Плоскановиця",
      "Плоске",
      "Плоский Потік",
      "Поляна",
      "Пузняківці",
      "Ракошино",
      "Родниківка",
      "Родникова Гута",
      "Розтока",
      "Ромочевиця",
      "Росош",
      "Ростов’ятиця",
      "Руська Кучава",
      "Руське",
      "Сасівка",
      "Свалява",
      "Синяк",
      "Скотарське",
      "Солочин",
      "Софія",
      "Станово",
      "Старе Давидково",
      "Страбичово",
      "Стройне",
      "Сусково",
      "Тибава",
      "Тишів",
      "Тростяниця",
      "Уклин",
      "Форнош",
      "Чабин",
      "Червеньово",
      "Череївці",
      "Черник",
      "Чинадійово",
      "Чопівці",
      "Шенборн",
      "Шкуратівці",
      "Щасливе",
      "Щербовець",
      "Яблунів",
      "Яківське",
      "Ялове",
      "Білин",
      "Богдан",
      "Бребоя",
      "Великий Бичків",
      "Верхнє Водяне",
      "Видричка",
      "Вільховатий",
      "Водиця",
      "Говерла",
      "Ділове",
      "Кваси",
      "Кобилецька Поляна",
      "Косівська Поляна",
      "Костилівка",
      "Круглий",
      "Лазещина",
      "Луг",
      "Луги",
      "Плаюць",
      "Рахів",
      "Розтоки",
      "Росішка",
      "Сітний",
      "Стебний",
      "Стримба",
      "Тростянець",
      "Хмелів",
      "Чорна Тиса",
      "Ясіня",
      "Бедевля",
      "Біла Церква",
      "Біловарці",
      "Бобове",
      "Буштино",
      "Велика Уголька",
      "Вишній Дубовець",
      "Вишоватий",
      "Вільхівці",
      "Вільхівці-Лази",
      "Вільхівчик",
      "Вонігове",
      "Ганичі",
      "Глибокий Потік",
      "Глиняний",
      "Груники",
      "Грушово",
      "Дібрівка",
      "Добрік",
      "Добрянське",
      "Дубове",
      "Дулово",
      "Калини",
      "Колодне",
      "Красна",
      "Крива",
      "Кричово",
      "Лопухів",
      "Мала Уголька",
      "Нересниця",
      "Нижній Дубовець",
      "Нижня Апша",
      "Німецька Мокра",
      "Новобарово",
      "Округла",
      "Петрушів",
      "Пещера",
      "Підплеша",
      "Подішор",
      "Пригідь",
      "Ракове",
      "Рівне",
      "Руня",
      "Руська Мокра",
      "Руське Поле",
      "Середнє Водяне",
      "Солотвино",
      "Тарасівка",
      "Теребля",
      "Тересва",
      "Терново",
      "Тисалово",
      "Топчино",
      "Тячів",
      "Тячівка",
      "Угля",
      "Усть-Чорна",
      "Фонтиняси",
      "Чумальово",
      "Широкий Луг",
      "Андріївка",
      "Анталовці",
      "Баранинці",
      "Барвінок",
      "Батфа",
      "Бегендяцька Пастіль",
      "Ботфалва",
      "Буківцьово",
      "Велика Добронь",
      "Великий Березний",
      "Великі Геївці",
      "Великі Лази",
      "Верхня Солотвина",
      "Верховина-Бистра",
      "Вишка",
      "Вільшинки",
      "Вовкове",
      "Волосянка",
      "Ворочово",
      "Гайдош",
      "Галоч",
      "Глибоке",
      "Гусний",
      "Гута",
      "Демечі",
      "Довге Поле",
      "Домашин",
      "Дубриничі",
      "Дубрівка",
      "Есень",
      "Жорнава",
      "Забрідь",
      "Завбуч",
      "Завосина",
      "Загорб",
      "Зарічово",
      "Ірлява",
      "Кам’яниця",
      "Кибляри",
      "Кінчеш",
      "Княгиня",
      "Концово",
      "Коритняни",
      "Костева Пастіль",
      "Кострина",
      "Костринська Розтока",
      "Липовець",
      "Лікіцари",
      "Лінці",
      "Лубня",
      "Лумшори",
      "Люта",
      "Ляхівці",
      "Мала Добронь",
      "Малий Березний",
      "Малі Геївці",
      "Малі Селменці",
      "Маюрки",
      "Минай",
      "Мирча",
      "Мокра",
      "Невицьке",
      "Нижнє Солотвино",
      "Оноківці",
      "Оріховиця",
      "Паладь-Комарівці",
      "Палло",
      "Пастілки",
      "Пацканьово",
      "Перечин",
      "Петрівка",
      "Підгорб",
      "Полянська Гута",
      "Порошково",
      "Раково",
      "Ратівці",
      "Розівка",
      "Розтоцька Пастіль",
      "Руський Мочар",
      "Руські Геївці",
      "Руські Комарівці",
      "Свалявка",
      "Середнє",
      "Сіль",
      "Сімер",
      "Сімерки",
      "Смереково",
      "Соловка",
      "Соломоново",
      "Ставне",
      "Сторожниця",
      "Стрипа",
      "Стричава",
      "Стужиця",
      "Сухий",
      "Сюрте",
      "Тарнівці",
      "Тийглаш",
      "Тисаагтелек",
      "Тисаашвань",
      "Тисауйфалу",
      "Тихий",
      "Тур’ї Ремети",
      "Тур’я Пасіка",
      "Тур’я Поляна",
      "Тур’я-Бистра",
      "Туриця",
      "Турички",
      "Ужгород",
      "Ужок",
      "Холмець",
      "Холмок",
      "Худльово",
      "Циганівці",
      "Чабанівка",
      "Часлівці",
      "Червоне",
      "Чертеж",
      "Чомонин",
      "Чоп",
      "Чорноголова",
      "Шишлівці",
      "Ярок",
      "Береги",
      "Березники",
      "Березово",
      "Білки",
      "Боронява",
      "Брід",
      "Бронька",
      "Велика Розтока",
      "Великий Раковець",
      "Велятино",
      "Вертеп",
      "Верхній Бистрий",
      "Верхній Студений",
      "Вишково",
      "Вільхівка",
      "Вільшани",
      "Вучкове",
      "Голятин",
      "Гонцош",
      "Горб",
      "Горінчово",
      "Гребля",
      "Данилово",
      "Дешковиця",
      "Діл",
      "Довге",
      "Драгово",
      "Дуби",
      "Забереж",
      "Заболотне",
      "Заверхня Кичера",
      "Завийка",
      "Загаття",
      "Залом",
      "Запереділля",
      "Зарічне",
      "Заріччя",
      "Золотарьово",
      "Івашковиця",
      "Іза",
      "Ізки",
      "Ільниця",
      "Імстичово",
      "Іршава",
      "Каллів",
      "Карповтлаш",
      "Келечин",
      "Керецьки",
      "Кіреші",
      "Кічерели",
      "Климовиця",
      "Кобалевиця",
      "Колочава",
      "Копашново",
      "Косів Верх",
      "Кошельово",
      "Крайнє",
      "Крайниково",
      "Крайня Мартинка",
      "Кривий",
      "Кутлаш",
      "Кушниця",
      "Липецька Поляна",
      "Липча",
      "Лисичово",
      "Лісковець",
      "Лоза",
      "Лозянський",
      "Локіть",
      "Лопушне",
      "Луково",
      "Лунка",
      "Майдан",
      "Мала Розтока",
      "Малий Раковець",
      "Медвежий",
      "Мерешор",
      "Міжгір’я",
      "Модьорош",
      "Монастирець",
      "Нанково",
      "Негровець",
      "Нижнє Болотне",
      "Нижнє Селище",
      "Нижній Бистрий",
      "Нижній Студений",
      "Облаз",
      "Ожоверх",
      "Олександрівка",
      "Осава",
      "Осій",
      "Пилипець",
      "Підгірне",
      "Підчумаль",
      "Подобовець",
      "Посіч",
      "Потік",
      "Поточок",
      "Приборжавське",
      "Присліп",
      "Противень",
      "Ракош",
      "Рекіти",
      "Репинне",
      "Річка",
      "Рокосово",
      "Ряпідь",
      "Синевир",
      "Синевирська Поляна",
      "Слоповий",
      "Смологовиця",
      "Собатин",
      "Сойми",
      "Сокирниця",
      "Сопки",
      "Становець",
      "Стеблівка",
      "Стригальня",
      "Суха",
      "Сюрюк",
      "Тітківці",
      "Тополин",
      "Торунь",
      "Тюшка",
      "Хуст",
      "Хустець",
      "Чертіж",
      "Шаян",
      "Яблунівка",
    ],
  },
  {
    region: "Запорізька",
    cities: [
      "Азов",
      "Азовське",
      "Андрівка",
      "Андріївка",
      "Балашівка",
      "Банівка",
      "Бердянськ",
      "Бердянське",
      "Берестове",
      "Благодатне",
      "Богданівка",
      "Бойове",
      "Болгарка",
      "Борисівка",
      "Верхній Токмак",
      "Верхній Токмак Другий",
      "Верхній Токмак Перший",
      "Владівка",
      "Вячеславка",
      "Глодове",
      "Гюнівка",
      "Дахно",
      "Деревецьке",
      "Дмитрівка",
      "Довбине",
      "Довге",
      "Долинське",
      "Єлизаветівка",
      "Єлисеївка",
      "Замістя",
      "Зелений Яр",
      "Зеленівка",
      "Зоря",
      "Іванівка",
      "Ільїне",
      "Інзівка",
      "Калайтанівка",
      "Калинівка",
      "Кам’янка",
      "Квіткове",
      "Коза",
      "Комишуватка",
      "Котлярівка",
      "Красне",
      "Крижчене",
      "Кримка",
      "Куликівське",
      "Ланкове",
      "Лозанівка",
      "Лозуватка",
      "Малинівка",
      "Мануйлівка",
      "Маринівка",
      "Миколаївка",
      "Могиляни",
      "Мокрий Став",
      "Набережне",
      "Нельгівка",
      "Нижній Токмак",
      "Нововасилівка",
      "Новоіванівка",
      "Новоказанкувате",
      "Новомихайлівка",
      "Новоолексіївка",
      "Новопавлівка",
      "Новопетрівка",
      "Новополтавка",
      "Новосільське",
      "Новосолдатське",
      "Новотроїцьке",
      "Обіточне",
      "Олександрівка",
      "Оленівка",
      "Орлівка",
      "Осипенко",
      "Петрівка",
      "Петропавлівка",
      "Підгірне",
      "Пірчине",
      "Подспор’є",
      "Полоузівка",
      "Преслав",
      "Приморськ",
      "Просторе",
      "Радивонівка",
      "Радолівка",
      "Райнівка",
      "Роза",
      "Розівка",
      "Салтичія",
      "Сахно",
      "Сачки",
      "Софіївка",
      "Старопетрівка",
      "Степове",
      "Стульневе",
      "Тарасівка",
      "Троїцьке",
      "Трояни",
      "Успенівка",
      "Хмельницьке",
      "Червоне Поле",
      "Чернігівка",
      "Чернігово-Токмачанськ",
      "Шевченка",
      "Шевченкове",
      "Широкий Яр",
      "Шовкове",
      "Юр’ївка",
      "Абрикосівка",
      "Балки",
      "Барвінівка",
      "Благовіщенка",
      "Бурчак",
      "Василівка",
      "Велика Білозерка",
      "Велика Знам’янка",
      "Верхня Криниця",
      "Виноградівка",
      "Високе",
      "Вишневе",
      "Вишнівка",
      "Відножине",
      "Вовківка",
      "Водне",
      "Водяне",
      "Володимирівка",
      "Гладке",
      "Грозове",
      "Гуртове",
      "Дніпровка",
      "Дніпрорудне",
      "Долинка",
      "Енергодар",
      "Жереб’янки",
      "Завітне",
      "Заповітне",
      "Зелена Балка",
      "Зелений Гай",
      "Златопіль",
      "Кавунівка",
      "Кам’янка-Дніпровська",
      "Кам’янське",
      "Качкарівка",
      "Коновалова",
      "Кохане",
      "Лиманівка",
      "Лісне",
      "Лобкове",
      "Лугове",
      "Лук’янівське",
      "Любимівка",
      "Мала Білозерка",
      "Малі Щербаки",
      "Маячка",
      "Михайлівка",
      "Мічуріна",
      "Нове Поле",
      "Новобілозерка",
      "Нововодяне",
      "Нововолодимирівка",
      "Новогорівка",
      "Новодніпровка",
      "Новолюбимівка",
      "Новоукраїнка",
      "Орлянське",
      "П’ятихатки",
      "Павлівка",
      "Переможне",
      "Першотравневе",
      "Плавні",
      "Подове",
      "Примірне",
      "Приморське",
      "Пришиб",
      "Рівне",
      "Роздол",
      "Садове",
      "Скельки",
      "Слов’янка",
      "Смиренівка",
      "Солов’ївка",
      "Степногірськ",
      "Суворе",
      "Таврія",
      "Тарсалак",
      "Тернувате",
      "Тимошівка",
      "Тополине",
      "Тракторне",
      "Трудовик",
      "Трудолюбимівка",
      "Українка",
      "Улянівка",
      "Цвіткове",
      "Чорноземне",
      "Широке",
      "Шляхове",
      "Ясна Поляна",
      "Августинівка",
      "Аграфенівка",
      "Антонівка",
      "Бабурка",
      "Балабине",
      "Бекарівка",
      "Біленьке",
      "Біленьке Перше",
      "Біляївка",
      "Благовіщенське",
      "Блакитне",
      "Богатирівка",
      "Богунівка",
      "Бойкове",
      "Василівське",
      "Васильківське",
      "Василькове",
      "Великодубове",
      "Вербове",
      "Веселе",
      "Веселий Гай",
      "Веселівське",
      "Веселотернувате",
      "Веселянка",
      "Високогірне",
      "Вишняки",
      "Вівчарне",
      "Відрадне",
      "Вікторівка",
      "Вільне",
      "Вільноандріївка",
      "Вільногрушівське",
      "Вільнокур’янівське",
      "Вільноуланівське",
      "Вільнянка",
      "Вільнянськ",
      "Володимирівське",
      "Воскресенка",
      "Гарасівка",
      "Гасанівка",
      "Геленджик",
      "Георгіївське",
      "Гнаровське",
      "Голубкове",
      "Горлицьке",
      "Граничне",
      "Григорівка",
      "Григорівське",
      "Грізне",
      "Грушівка",
      "Губенське",
      "Гурського",
      "Данилівка",
      "Дерезівка",
      "Дніпрельстан",
      "Дніпрові Хвилі",
      "Долинівка",
      "Дружелюбівка",
      "Дружне",
      "Дубовий Гай",
      "Дудникове",
      "Жовта Круча",
      "Жовтеньке",
      "Задорожнє",
      "Заливне",
      "Запасне",
      "Запоріжжя",
      "Запорізьке",
      "Запорожець",
      "Зарічне",
      "Зелена Діброва",
      "Зелене",
      "Зеленопілля",
      "Зірниця",
      "Значкове",
      "Зорівка",
      "Зоряне",
      "Івангород",
      "Іванівське",
      "Івано-Ганнівка",
      "Кам’яне",
      "Кам’янувате",
      "Канівське",
      "Канцерівка",
      "Каштанівка",
      "Київське",
      "Кирпотине",
      "Козаківське",
      "Козаче",
      "Колос",
      "Комишуваха",
      "Косівцеве",
      "Крилівське",
      "Криничне",
      "Кринівка",
      "Круглик",
      "Крутий Яр",
      "Купріянівка",
      "Кушугум",
      "Кущове",
      "Лежине",
      "Лемешинське",
      "Лисогірка",
      "Листівка",
      "Лукашеве",
      "Любицьке",
      "Любомирівка",
      "Люцерна",
      "Магдалинівка",
      "Максимівка",
      "Мала Купріянівка",
      "Малишівка",
      "Малокатеринівка",
      "Мар’ївка",
      "Мар’янівка",
      "Матвіївка",
      "Микільське",
      "Миколаївка Друга",
      "Миколай-Поле",
      "Мирівка",
      "Миролюбівка",
      "Михайлівське",
      "Михайло-Лукашеве",
      "Морозівка",
      "Московка",
      "Нагірне",
      "Надія",
      "Наталівка",
      "Нижня Хортиця",
      "Ніженка",
      "Нове Запоріжжя",
      "Новобойківське",
      "Нововасилівське",
      "Нововікторівка",
      "Нововознесенка",
      "Новогригорівка",
      "Новогупалівка",
      "Новоіванівське",
      "Новоіванківка",
      "Новокасянівка",
      "Новомиколаївка",
      "Новомиргородка",
      "Новомихайлівське",
      "Новоолександрівка",
      "Новооленівка",
      "Новорозівка",
      "Новоселище",
      "Новоселівка",
      "Новосергіївка",
      "Новослобідка",
      "Новосолоне",
      "Новософіївка",
      "Новостепнянське",
      "Новотавричеське",
      "Новофедорівка",
      "Новояковлівка",
      "Одарівка",
      "Олексіївка",
      "Орлівське",
      "Островське",
      "Павлівське",
      "Перун",
      "Першозванівка",
      "Петрівське",
      "Петро-Михайлівка",
      "Петропавлівське",
      "Петропіль",
      "Петро-Свистунове",
      "Поди",
      "Привільне",
      "Привітне",
      "Придніпровське",
      "Придолинівка",
      "Придорожнє",
      "Райське",
      "Резедівка",
      "Рибальське",
      "Різдвянка",
      "Річне",
      "Родинське",
      "Роздолля",
      "Розумівка",
      "Ростуще",
      "Ручаївка",
      "Самійлівка",
      "Світанок",
      "Світла Долина",
      "Семененкове",
      "Сергіївка",
      "Скелювате",
      "Славне",
      "Смоляне",
      "Смородине",
      "Соколівка",
      "Солоне",
      "Сонячне",
      "Сорочине",
      "Спасівка",
      "Степне",
      "Сторчове",
      "Таврійське",
      "Тернівка",
      "Терсянка",
      "Троянди",
      "Трудове",
      "Трудолюбівка",
      "Трудооленівка",
      "Уділенське",
      "Укромне",
      "Федорівка",
      "Хортиця",
      "Червоний Яр",
      "Червонодніпровка",
      "Черепівське",
      "Шевченківське",
      "Щасливе",
      "Юльївка",
      "Юрківка",
      "Яворницьке",
      "Якимівка",
      "Якимівське",
      "Яковлеве",
      "Ясинувате",
      "Авангард",
      "Арабка",
      "Астраханка",
      "Атманай",
      "Бабанівка",
      "Берегове",
      "Бесідівка",
      "Білівське",
      "Білорічанське",
      "Богатир",
      "Ботієве",
      "Братолюбівка",
      "Братське",
      "В’язівка",
      "Велика Тернівка",
      "Верховина",
      "Весняне",
      "Відродження",
      "Вовчанське",
      "Вовче",
      "Вознесенка",
      "Волна",
      "Волошкове",
      "Восход",
      "Гамівка",
      "Ганнівка",
      "Ганно-Опанлинка",
      "Гвардійське",
      "Георгіївка",
      "Гірсівка",
      "Гоголівка",
      "Громівка",
      "Давидівка",
      "Далеке",
      "Данило-Іванівка",
      "Дівнинське",
      "Добрівка",
      "Добровольчеське",
      "Домузли",
      "Дружба",
      "Дунаївка",
      "Зелений Луг",
      "Зеленчук",
      "Зернове",
      "Зірка",
      "Золота Долина",
      "Зразкове",
      "Ігорівка",
      "Кирилівка",
      "Кирпичне",
      "Корніївка",
      "Косих",
      "Костянтинівка",
      "Красавич",
      "Лазурне",
      "Лиманське",
      "Маківка",
      "Максима Горького",
      "Мала Михайлівка",
      "Мала Тернівка",
      "Малий Утлюг",
      "Маяк",
      "Мелітополь",
      "Менчикури",
      "Мирне",
      "Миронівка",
      "Молодіжне",
      "Мордвинівка",
      "Мусіївка",
      "Надеждине",
      "Нечкине",
      "Новгородківка",
      "Нове",
      "Новобогданівка",
      "Новоданилівка",
      "Новокостянтинівка",
      "Новопилипівка",
      "Новопокровка",
      "Новоспаське",
      "Новоуспенівка",
      "Новоякимівка",
      "Обільне",
      "Озерне",
      "Оріхівка",
      "Орлове",
      "Охрімівка",
      "Перемога",
      "Першостепанівка",
      "Південне",
      "Північне",
      "Піскошине",
      "Піщанське",
      "Плодородне",
      "Показне",
      "Полянівка",
      "Приазовське",
      "Прилуківка",
      "Приморський Посад",
      "Промінь",
      "Прудентове",
      "Радісне",
      "Ромашки",
      "Світлодолинське",
      "Свободне",
      "Семенівка",
      "Солодке",
      "Соснівка",
      "Спаське",
      "Старобогданівка",
      "Степанівка Друга",
      "Степанівка Перша",
      "Строганівка",
      "Східне",
      "Тамбовка",
      "Тащенак",
      "Терпіння",
      "Тимофіївка",
      "Тихонівка",
      "Травневе",
      "Удачне",
      "Українське",
      "Фруктове",
      "Червоне",
      "Чкалове",
      "Шелюги",
      "Широкий Лан",
      "Ясне",
      "Андріївське",
      "Багате",
      "Багатівка",
      "Балкове",
      "Балочки",
      "Басань",
      "Бережне",
      "Березівка",
      "Біловеж",
      "Білогір’я",
      "Білоцерківка",
      "Більманка",
      "Бурлацьке",
      "Варварівка",
      "Васинівка",
      "Верхівка",
      "Верхньодрагунське",
      "Верхня Терса",
      "Вершина",
      "Вершина Друга",
      "Веселоіванівське",
      "Виноградне",
      "Вишнювате",
      "Воздвижівка",
      "Гірке",
      "Гришине",
      "Грузьке",
      "Гуляйпільське",
      "Гуляйполе",
      "Гусарка",
      "Діброва",
      "Дмитрівське",
      "Добропілля",
      "Долина",
      "Дорожнянка",
      "Дубове",
      "Єгорівка",
      "Загірне",
      "Залізничне",
      "Заможне",
      "Затишшя",
      "Зеленопіль",
      "Золота Поляна",
      "Івана Франка",
      "Ільченкове",
      "Інженерне",
      "Кам'янка",
      "Кінські Роздори",
      "Кобильне",
      "Козолугівка",
      "Комиш-Зоря",
      "Копані",
      "Красногірське",
      "Красноселівка",
      "Кузнецівка",
      "Курошани",
      "Кутузівка",
      "Лагідне",
      "Ланцеве",
      "Левадне",
      "Листвянка",
      "Лозове",
      "Луганське",
      "Лугівка",
      "Лугівське",
      "Магедове",
      "Мала Токмачка",
      "Маринопіль",
      "Марфопіль",
      "Межиріч",
      "Мирське",
      "Могутнє",
      "Молочанськ",
      "Мостове",
      "Надійне",
      "Нестерянка",
      "Нова Дача",
      "Новгород",
      "Новоандріївка",
      "Новодарівка",
      "Новодворівка",
      "Новозлатопіль",
      "Новокам’янка",
      "Новокарлівка",
      "Новомлинівка",
      "Новопрокопівка",
      "Новосолошине",
      "Новоукраїнське",
      "Новоуспенівське",
      "Обратне",
      "Обще",
      "Ожерельне",
      "Оленокостянтинівка",
      "Ольгівське",
      "Омельник",
      "Оріхів",
      "Остриківка",
      "Охотниче",
      "Очеретувате",
      "Покровське",
      "Пологи",
      "Полтавка",
      "Преображенка",
      "Прилуки",
      "Приютне",
      "Пробудження",
      "Пшеничне",
      "Ремівка",
      "Решетилівське",
      "Рибалівка",
      "Рибне",
      "Рівнопілля",
      "Роботине",
      "Розкішне",
      "Романівське",
      "Руденка",
      "Світле",
      "Свобода",
      "Святопетрівка",
      "Святотроїцьке",
      "Смирнове",
      "Сміле",
      "Снігурівка",
      "Солодка Балка",
      "Солодководне",
      "Староукраїнка",
      "Степанівка",
      "Темирівка",
      "Тернове",
      "Титове",
      "Токмак",
      "Труженка",
      "Ударник",
      "Урожайне",
      "Фабричне",
      "Форойс",
      "Харкове",
      "Хліборобне",
      "Чарівне",
      "Червона Криниця",
      "Червоне Озеро",
      "Червоногірка",
      "Червоноселівка",
      "Черешневе",
      "Чистопілля",
      "Чкалова",
      "Чумацьке",
      "Щербаки",
      "Яблукове",
    ],
  },
  {
    region: "Івано-Франківська",
    cities: [
      "Барвінків",
      "Бережниця",
      "Бистрець",
      "Біла Річка",
      "Білоберізка",
      "Буковець",
      "Буркут",
      "Великий Ходак",
      "Верхній Ясенів",
      "Верховина",
      "Вигода",
      "Віпче",
      "Волова",
      "Голови",
      "Голошина",
      "Грамотне",
      "Гринява",
      "Дземброня",
      "Довгополе",
      "Замагора",
      "Зелене",
      "Ільці",
      "Кохан",
      "Красник",
      "Красноїлля",
      "Кривопілля",
      "Криворівня",
      "Перехресне",
      "Полянки",
      "Пробійнівка",
      "Рівня",
      "Сеньківське",
      "Стаїще",
      "Стебні",
      "Стовпні",
      "Топільче",
      "Устеріки",
      "Хороцеве",
      "Черемошна",
      "Черетів",
      "Чорна Річка",
      "Яблуниця",
      "Явірник",
      "Антонівка",
      "Бабухів",
      "Бабче",
      "Беньківці",
      "Березівка",
      "Бишів",
      "Більшівці",
      "Блюдники",
      "Бовшів",
      "Богородчани",
      "Богрівка",
      "Бойки",
      "Бортники",
      "Братишів",
      "Братківці",
      "Бринь",
      "Будзин",
      "Букачівська Слобода",
      "Букачівці",
      "Буківна",
      "Бурштин",
      "Бушкалик",
      "Васючин",
      "Вербилівці",
      "Верхня Липиця",
      "Вигівка",
      "Височанка",
      "Виспа",
      "Витань",
      "Вишнів",
      "Вікняни",
      "Вікторів",
      "Вільне",
      "Вільхова",
      "Вільшаниця",
      "Вовчинець",
      "Водники",
      "Ворониця",
      "Воронів",
      "Воскресинці",
      "Гавриляк",
      "Галич",
      "Ганнівці",
      "Ганнусівка",
      "Гарасимів",
      "Глибівка",
      "Глибоке",
      "Гоноратівка",
      "Гончарів",
      "Гончарівка",
      "Городиська",
      "Горохолин Ліс",
      "Горохолина",
      "Гостів",
      "Грабичанка",
      "Грабовець",
      "Григорів",
      "Григорівська Слобода",
      "Гринівка",
      "Гринівці",
      "Грушка",
      "Гута",
      "Данильче",
      "Дегова",
      "Делева",
      "Делієве",
      "Дем’янів",
      "Демешківці",
      "Дзвиняч",
      "Дитятин",
      "Дички",
      "Діброва",
      "Добринів",
      "Добровляни",
      "Довге",
      "Долина",
      "Долиняни",
      "Дорогів",
      "Драгомирчани",
      "Дубівці",
      "Єзупіль",
      "Жабокруки",
      "Жалибори",
      "Живачів",
      "Жовчів",
      "Жуків",
      "Журавеньки",
      "Жураки",
      "Журів",
      "Забережжя",
      "Загвіздя",
      "Загір’я",
      "Загір’я-Кукільницьке",
      "Задністрянське",
      "Заланів",
      "Заливки",
      "Залип’я",
      "Залужжя",
      "Залуква",
      "Зеленів",
      "Золота Липа",
      "Зруб",
      "Іваниківка",
      "Івано-Франківськ",
      "Ісаків",
      "Йосипівка",
      "Кам’янка",
      "Камінне",
      "Кінашів",
      "Кінчаки",
      "Кліщівна",
      "Клубівці",
      "Клузів",
      "Княгиничі",
      "Козарі",
      "Козина",
      "Колінці",
      "Колодіїв",
      "Колодіївка",
      "Колоколин",
      "Комарів",
      "Конюшки",
      "Копачівка",
      "Королівка",
      "Коростовичі",
      "Корчунок",
      "Космач",
      "Красилівка",
      "Кремидів",
      "Кривець",
      "Кривня",
      "Крилос",
      "Крихівці",
      "Кричка",
      "Кукільники",
      "Куничі",
      "Купелів",
      "Курипів",
      "Курів",
      "Куропатники",
      "Кутище",
      "Кутці",
      "Лани",
      "Ластівці",
      "Лесівка",
      "Липівка",
      "Лиса Гора",
      "Лисець",
      "Лісова",
      "Локітка",
      "Лопушня",
      "Луг",
      "Луквиця",
      "Луковець-Вишнівський",
      "Луковець-Журівський",
      "Луковище",
      "Лучинці",
      "Любша",
      "Майдан",
      "Малий Заланів",
      "Малинівка",
      "Манява",
      "Маріямпіль",
      "Марківці",
      "Маркова",
      "Мединя",
      "Медуха",
      "Межигаї",
      "Межигірці",
      "Мельна",
      "Мельники",
      "Микитинці",
      "Милування",
      "Міжгір’я",
      "Монастирчани",
      "Мостище",
      "Набережне",
      "Надорожна",
      "Нараївка",
      "Насташине",
      "Нивочин",
      "Нижнів",
      "Нижня Липиця",
      "Німшин",
      "Нова Гута",
      "Нова Липівка",
      "Новий Мартинів",
      "Нові Кривотули",
      "Нові Скоморохи",
      "Новосілка",
      "Обельниця",
      "Обертин",
      "Одаї",
      "Одаїв",
      "Озерце",
      "Озеряни",
      "Олеша",
      "Олешів",
      "Олещин",
      "Остриня",
      "Острів",
      "Павлівка",
      "Палагичі",
      "Перенівка",
      "Перлівці",
      "Петрилів",
      "Петрів",
      "Пилипівці",
      "Підбір’я",
      "Підвербці",
      "Підвиння",
      "Підгір’я",
      "Підгороддя",
      "Підкамінь",
      "Підлісся",
      "Підлужжя",
      "Підмихайлівці",
      "Підпечери",
      "Підшумлянці",
      "Побережжя",
      "Погоня",
      "Погребівка",
      "Поділля",
      "Помонята",
      "Попелів",
      "Поплавники",
      "Пороги",
      "Посвірж",
      "Посіч",
      "Потік",
      "Похівка",
      "Прибилів",
      "Придністров’я",
      "Приозерне",
      "Пужники",
      "Пукасівці",
      "Пуків",
      "Путятинці",
      "Пшеничники",
      "Радча",
      "Раковець",
      "Рибне",
      "Різдвяни",
      "Рогатин",
      "Росільна",
      "Рошнів",
      "Руда",
      "Саджава",
      "Садки",
      "Сапогів",
      "Сарники",
      "Світанок",
      "Сілець",
      "Скобичівка",
      "Слобідка",
      "Слобідка Більшівцівська",
      "Слобода",
      "Смерклів",
      "Сокирчин",
      "Сокіл",
      "Соколівка",
      "Солотвин",
      "Стара Гута",
      "Старий Лисець",
      "Старий Мартинів",
      "Старі Богородчани",
      "Старі Кривотули",
      "Старі Скоморохи",
      "Старуня",
      "Стебник",
      "Стефанівка",
      "Стратин",
      "Стриганці",
      "Студинець",
      "Суботів",
      "Суходіл",
      "Тарасівка",
      "Темирівці",
      "Тенетники",
      "Терновиця",
      "Тисмениця",
      "Тисменичани",
      "Тлумач",
      "Тумир",
      "Тустань",
      "Тязів",
      "Угорники",
      "Угринів",
      "Узин",
      "Уїзд",
      "Фрага",
      "Хмелівка",
      "Хом’яківка",
      "Хотимир",
      "Хохонів",
      "Хриплин",
      "Ценжів",
      "Чагрів",
      "Чернів",
      "Черніїв",
      "Черче",
      "Чесники",
      "Чорнолізці",
      "Чукалівка",
      "Шевченкове",
      "Юнашків",
      "Яблунів",
      "Яблунька",
      "Явче",
      "Яглуш",
      "Яківка",
      "Ямниця",
      "Ангелівка",
      "Бабин-Зарічний",
      "Бабське",
      "Белеїв",
      "Берлоги",
      "Боднарів",
      "Болехів",
      "Болохів",
      "Брошнів",
      "Брошнів-Осада",
      "Бубнище",
      "Велика Тур’я",
      "Вербівка",
      "Верхній Струтинь",
      "Верхня",
      "Вигодівка",
      "Вилки",
      "Витвиця",
      "Вишків",
      "Вільхівка",
      "Вістова",
      "Войнилів",
      "Гериня",
      "Голинь",
      "Гошів",
      "Грабів",
      "Грабівка",
      "Гриньків",
      "Гузіїв",
      "Гуменів",
      "Довге-Калуське",
      "Довгий Войнилів",
      "Довжка",
      "Довпотів",
      "Дуба",
      "Дубовиця",
      "Дубшари",
      "Завадка",
      "Завій",
      "Закерничне",
      "Заріччя",
      "Збора",
      "Зелений Яр",
      "Іванівка",
      "Іванкова",
      "Ілемня",
      "Кадобна",
      "Калуш",
      "Кальна",
      "Камінь",
      "Княжолука",
      "Князівське",
      "Козаківка",
      "Копанки",
      "Красне",
      "Креховичі",
      "Крива",
      "Кропивник",
      "Кудлатівка",
      "Кузьминець",
      "Кулинка",
      "Лази",
      "Лецівка",
      "Липа",
      "Липовиця",
      "Ловаги",
      "Лолин",
      "Лоп’янка",
      "Луги",
      "Лужки",
      "Лука",
      "Максимівка",
      "Мала Тур’я",
      "Мислів",
      "Мислівка",
      "Міжріччя",
      "Мошківці",
      "Надіїв",
      "Небилів",
      "Негівці",
      "Нижній Струтинь",
      "Новий Мізунь",
      "Новиця",
      "Новичка",
      "Новоселиця",
      "Новошин",
      "Оболоння",
      "Осмолода",
      "Павликівка",
      "Пациків",
      "Перевозець",
      "Перегінське",
      "Перекоси",
      "Петранка",
      "Підбережжя",
      "Підліски",
      "Підмихайля",
      "Підсухи",
      "Пійло",
      "Погар",
      "Погорілець",
      "Поляниця",
      "Раків",
      "Рахиня",
      "Ріп’янка",
      "Ріпне",
      "Рожнятів",
      "Розточки",
      "Рошняте",
      "Сваричів",
      "Сенечів",
      "Середній Бабин",
      "Середній Угринів",
      "Середня",
      "Сівка-Войнилівська",
      "Сівка-Калуська",
      "Сливки",
      "Слобода-Болехівська",
      "Слобода-Долинська",
      "Слобода-Небилівська",
      "Слобода-Рівнянська",
      "Солуків",
      "Спас",
      "Станківці",
      "Станькова",
      "Старий Мізунь",
      "Старий Угринів",
      "Степанівка",
      "Студінка",
      "Сукіль",
      "Танява",
      "Тисів",
      "Томашівці",
      "Топільське",
      "Тростянець",
      "Тужилів",
      "Турівка",
      "Тяпче",
      "Цвітова",
      "Церковна",
      "Цінева",
      "Черепина",
      "Яворів",
      "Яворівка",
      "Якубів",
      "Ясеновець",
      "Ясень",
      "Баб’янка",
      "Балинці",
      "Белелуя",
      "Берем’яни",
      "Білка",
      "Богородичин",
      "Борщів",
      "Борщівська Турка",
      "Будилів",
      "Бучачки",
      "Велика Кам’янка",
      "Великий Ключів",
      "Вербівці",
      "Верхній Вербіж",
      "Видинів",
      "Виноград",
      "Вишнівка",
      "Вікно",
      "Вільхівці",
      "Вовчківці",
      "Ворона",
      "Ганнів",
      "Ганьківці",
      "Гвіздець",
      "Глибока",
      "Глушків",
      "Годи-Добровідка",
      "Голосків",
      "Горішнє Залуччя",
      "Городенка",
      "Городниця",
      "Грабич",
      "Грушів",
      "Гуцулівка",
      "Далешове",
      "Дебеславці",
      "Джурів",
      "Джурків",
      "Долішнє Залуччя",
      "Драгасимів",
      "Дубки",
      "Жукотин",
      "Заболотів",
      "Завалля",
      "Загайпіль",
      "Задубрівці",
      "Закрівці",
      "Залуччя",
      "Замулинці",
      "Запруття",
      "Зібранівка",
      "Іванівці",
      "Іллінці",
      "Казанів",
      "Келихів",
      "Кіданч",
      "Княждвір",
      "Княже",
      "Кобилець",
      "Ковалівка",
      "Колінки",
      "Коломия",
      "Копачинці",
      "Корнич",
      "Корнів",
      "Корости",
      "Коршів",
      "Котиківка",
      "Красноставці",
      "Кривоброди",
      "Кринички",
      "Кропивище",
      "Кубаївка",
      "Кулачківці",
      "Кунисівці",
      "Липники",
      "Ліски",
      "Лісна Слобідка",
      "Лісний Хлібичин",
      "Любківці",
      "Мала Кам’янка",
      "Малий Гвіздець",
      "Малий Ключів",
      "Марківка",
      "Матеївці",
      "Михалків",
      "Михальче",
      "Мишин",
      "Молодилів",
      "Молодятин",
      "Монастирок",
      "Назірна",
      "Незвисько",
      "Нижній Вербіж",
      "Нижня Велесниця",
      "Новоселівка",
      "Олешків",
      "Олієво-Корнів",
      "Олієво-Королівка",
      "Орелець",
      "Остапківці",
      "Острівець",
      "Отинія",
      "П’ядики",
      "Передівання",
      "Перерив",
      "Печеніжин",
      "Пилипи",
      "Пищаче",
      "Підвисоке",
      "Підгайчики",
      "Попельники",
      "Потічок",
      "Поточище",
      "Прикмище",
      "Пробабин",
      "Прутівка",
      "Раківчик",
      "Рашків",
      "Репужинці",
      "Рогиня",
      "Рожневі Поля",
      "Росохач",
      "Рудники",
      "Рунгури",
      "Русів",
      "Саджавка",
      "Семаківці",
      "Семенівка",
      "Серафинці",
      "Сідлище",
      "Скопівка",
      "Снятин",
      "Сопів",
      "Сороки",
      "Станіславівка",
      "Старий Гвіздець",
      "Стецева",
      "Стецівка",
      "Стрільче",
      "Струпків",
      "Студлів",
      "Тишківці",
      "Товмачик",
      "Топорівці",
      "Торговиця",
      "Трач",
      "Троїця",
      "Тростянка",
      "Трофанівка",
      "Тулова",
      "Тулуків",
      "Турка",
      "Тучапи",
      "Уніж",
      "Устя",
      "Фатовець",
      "Хвалибога",
      "Хлібичин",
      "Хмелева",
      "Хоросна",
      "Хутір-Будилів",
      "Ценява",
      "Цуцулин",
      "Черемхів",
      "Чернелиця",
      "Чернятин",
      "Чехова",
      "Чортовець",
      "Шепарівці",
      "Якубівка",
      "Ясенів-Пільний",
      "Ясінки",
      "Акрешори",
      "Бабин",
      "Баня-Березів",
      "Брустури",
      "Великий Рожин",
      "Вербовець",
      "Вижній Березів",
      "Город",
      "Кобаки",
      "Косів",
      "Кути",
      "Люча",
      "Лючки",
      "Малий Рожин",
      "Нижній Березів",
      "Пістинь",
      "Прокурава",
      "Річка",
      "Рожнів",
      "Розтоки",
      "Середній Березів",
      "Смодна",
      "Снідавка",
      "Старий Косів",
      "Старі Кути",
      "Стопчатів",
      "Текуча",
      "Тюдів",
      "Уторопи",
      "Хімчин",
      "Черганівка",
      "Шепіт",
      "Шешори",
      "Бистриця",
      "Битків",
      "Білі Ослави",
      "Білозорина",
      "Букове",
      "Верхній Майдан",
      "Вишнівці",
      "Волосів",
      "Вороненко",
      "Ворохта",
      "Гаврилівка",
      "Гвізд",
      "Глинки",
      "Делятин",
      "Добротів",
      "Згари",
      "Зелена",
      "Климпуші",
      "Красна",
      "Ланчин",
      "Лісна Велесниця",
      "Лісна Тарновиця",
      "Лоєва",
      "Максимець",
      "Микуличин",
      "Мирне",
      "Млини",
      "Мозолівка",
      "Молодків",
      "Надвірна",
      "Назавизів",
      "Парище",
      "Пасічна",
      "Перерісль",
      "Пнів",
      "Постоята",
      "Причіл",
      "Середній Майдан",
      "Соколовиця",
      "Стримба",
      "Татарів",
      "Фитьків",
      "Цуцилів",
      "Черник",
      "Чорний Потік",
      "Чорні Ослави",
      "Яремче",
    ],
  },
  {
    region: "Київська",
    cities: [
      "Андріївка",
      "Антонів",
      "Антонівка",
      "Бакали",
      "Бакумівка",
      "Безпечна",
      "Безугляки",
      "Березна",
      "Бесідка",
      "Бикова Гребля",
      "Бирюки",
      "Біла Церква",
      "Біліївка",
      "Блощинці",
      "Бовкун",
      "Богатирка",
      "Бране Поле",
      "Брилівка",
      "Буда",
      "Буки",
      "Бурківці",
      "Бушеве",
      "Василиха",
      "Василів",
      "Велика Березянка",
      "Велика Вовнянка",
      "Великі Єрчики",
      "Великополовецьке",
      "Вербова",
      "Веселе",
      "Веселий Кут",
      "Веснянка",
      "Винарівка",
      "Високе",
      "Вишківське",
      "Вільна Тарасівка",
      "Вільшанська Новоселиця",
      "Вінницькі Стави",
      "Владиславка",
      "Володарка",
      "Володимирівка",
      "Гайворон",
      "Гайок",
      "Галайки",
      "Гейсиха",
      "Глибочка",
      "Глушки",
      "Голодьки",
      "Горобіївка",
      "Городище",
      "Городище-Косівське",
      "Городище-Пустоварівське",
      "Горошків",
      "Гостра Могила",
      "Гребінки",
      "Григорівка",
      "Григорівська Слобода",
      "Гута",
      "Денихівка",
      "Дзвеняче",
      "Дібрівка",
      "Дмитренки",
      "Домантівка",
      "Дослідницьке",
      "Дрозди",
      "Дружба",
      "Дубина",
      "Дубівка",
      "Дулицьке",
      "Дунайка",
      "Житні Гори",
      "Журавлиха",
      "Завадівка",
      "Закутинці",
      "Запруддя",
      "Затиша",
      "Золотуха",
      "Зрайки",
      "Іванівка",
      "Йосипівка",
      "Каленна",
      "Калинівка",
      "Калинове",
      "Кам’яна Гребля",
      "Капустинці",
      "Кашперівка",
      "Квітневе",
      "Кирдани",
      "Кислівка",
      "Кищинці",
      "Ківшовата",
      "Кленове",
      "Клочки",
      "Клюки",
      "Київ",
      "Ковалівка",
      "Коженики",
      "Колесникове",
      "Кононівка",
      "Коржиха",
      "Коржівка",
      "Коряківка",
      "Косівка",
      "Косяківка",
      "Кошів",
      "Красилівка",
      "Красне",
      "Красногородка",
      "Красноліси",
      "Краснянка",
      "Красюки",
      "Крива",
      "Кривець",
      "Кривошиїнці",
      "Круті Горби",
      "Ксаверівка",
      "Ксаверівка Друга",
      "Лаврики",
      "Лихачиха",
      "Лісовичі",
      "Лобачів",
      "Логвин",
      "Лосятин",
      "Луб’янка",
      "Лук’янівка",
      "Лука",
      "Любка",
      "Любча",
      "Людвинівка",
      "Мазепинці",
      "Маківка",
      "Макіївка",
      "Маковецьке",
      "Мала Антонівка",
      "Мала Березянка",
      "Мала Вільшанка",
      "Мала Вовнянка",
      "Мала Михайлівка",
      "Мала Сквирка",
      "Малі Єрчики",
      "Малі Лисівці",
      "Малоберезанське",
      "Мар’янівка",
      "Мармуліївка",
      "Матвіїха",
      "Матюші",
      "Медвин",
      "Межове",
      "Миньківці",
      "Митаївка",
      "Михайлівка",
      "Мовчанівка",
      "Молочне",
      "Надросівка",
      "Насташка",
      "Ненадиха",
      "Нова Маківка",
      "Нова Пустоварівка",
      "Нове Життя",
      "Новий Шлях",
      "Одайпіль",
      "Одноріг",
      "Ожегівка",
      "Озерна",
      "Олійникова Слобода",
      "Ольшаниця",
      "Оріховець",
      "Острів",
      "Острійки",
      "П’ятигори",
      "Павлівка",
      "Паляничинці",
      "Пархомівка",
      "Перше Травня",
      "Першотравневе",
      "Петрашівка",
      "Петрівка",
      "Пилипча",
      "Пищики",
      "Пінчуки",
      "Піщана",
      "Плоске",
      "Побережка",
      "Погреби",
      "Полковниче",
      "Пологи",
      "Поправка",
      "Попружна",
      "Потіївка",
      "Потоки",
      "Пугачівка",
      "Пустоварівка",
      "Пшеничне",
      "Ратуш",
      "Рачки",
      "Рибчинці",
      "Ріденьке",
      "Ріжки",
      "Рогізна",
      "Розаліївка",
      "Розкішна",
      "Розумниця",
      "Рокитне",
      "Ромашки",
      "Росішки",
      "Рубченки",
      "Руда",
      "Руде Село",
      "Савинці",
      "Саврань",
      "Саливонки",
      "Салиха",
      "Самгородок",
      "Северинівка",
      "Селезенівка",
      "Сидори",
      "Синява",
      "Сквира",
      "Скибинці",
      "Скребиші",
      "Сніжки",
      "Соколівка",
      "Сорокотяги",
      "Софійка",
      "Софіпіль",
      "Ставище",
      "Стадниця",
      "Станишівка",
      "Станіславчик",
      "Степанівка",
      "Степове",
      "Степок",
      "Стрижавка",
      "Сухий Яр",
      "Сухоліси",
      "Таборів",
      "Тадіївка",
      "Тайниця",
      "Тарасівка",
      "Тараща",
      "Тарган",
      "Телешівка",
      "Теліжинці",
      "Терезине",
      "Терешки",
      "Тетіїв",
      "Токарівка",
      "Томилівка",
      "Торчицький Степок",
      "Торчиця",
      "Троїцьке",
      "Тростинська Новоселиця",
      "Трушки",
      "Тхорівка",
      "Узин",
      "Улашівка",
      "Улянівка",
      "Устимівка",
      "Фастівка",
      "Фесюри",
      "Фурси",
      "Хмелівка",
      "Храпачі",
      "Цапіївка",
      "Чепиліївка",
      "Чепіжинці",
      "Червоне",
      "Червоні Яри",
      "Черепин",
      "Черепинка",
      "Черкас",
      "Чернин",
      "Чмирівка",
      "Чубинці",
      "Чупира",
      "Шаліївка",
      "Шамраївка",
      "Шапіївка",
      "Шарки",
      "Шевченкове",
      "Шкарівка",
      "Щербаки",
      "Щербашинці",
      "Юрківка",
      "Юшків Ріг",
      "Яблунівка",
      "Ями",
      "Ясенівка",
      "Артемівка",
      "Безуглівка",
      "Богданівка",
      "Божки",
      "Бориспіль",
      "Васильки",
      "Велика Каратуль",
      "Велика Олександрівка",
      "Велика Стариця",
      "Виповзки",
      "Вишеньки",
      "Вінинці",
      "Вовчків",
      "Воронівщина",
      "Вороньків",
      "Воскресенське",
      "Гайшин",
      "Гензерівка",
      "Гланишів",
      "Глибоке",
      "Гнідин",
      "Годунівка",
      "Головурів",
      "Гора",
      "Горбані",
      "Гребля",
      "Гречаники",
      "Гречанівка",
      "Двірківщина",
      "Дем’янці",
      "Дениси",
      "Дзюбівка",
      "Дівички",
      "Добраничівка",
      "Довга Гребля",
      "Дударків",
      "Єрківці",
      "Жереб’ятин",
      "Жоравка",
      "Займище",
      "Заострів",
      "Засупоївка",
      "Затишне",
      "Іванків",
      "Кавказ",
      "Кайнари",
      "Кийлів",
      "Кириївщина",
      "Ковалин",
      "Козлів",
      "Коптевичівка",
      "Кулябівка",
      "Кучаків",
      "Лебедин",
      "Лебедівка",
      "Леляки",
      "Лемешівка",
      "Лецьки",
      "Лозовий Яр",
      "Лукомщина",
      "Любарці",
      "Мазінки",
      "Мала Каратуль",
      "Мала Олександрівка",
      "Мала Стариця",
      "Малі Єрківці",
      "Мартусівка",
      "Мирне",
      "Натягайлівка",
      "Ничипорівка",
      "Озерне",
      "Панфили",
      "Перегуди",
      "Переяслав",
      "Переяславське",
      "Петропавлівське",
      "Плескачі",
      "Плужники",
      "Пологи-Вергуни",
      "Пологи-Чобітки",
      "Пологи-Яненки",
      "Положаї",
      "Помоклі",
      "Пристроми",
      "Проліски",
      "Проців",
      "Райківщина",
      "Ревне",
      "Рогозів",
      "Розумівка",
      "Світанок",
      "Семенівка",
      "Сеньківка",
      "Соколівщина",
      "Сомкова Долина",
      "Соснівка",
      "Соснова",
      "Сотниківка",
      "Сошників",
      "Старе",
      "Стовп’яги",
      "Строкова",
      "Студеники",
      "Сулимівка",
      "Супоївка",
      "Ташань",
      "Травневе",
      "Трубівщина",
      "Тужилів",
      "Фарбоване",
      "Федорівка",
      "Харківці",
      "Хоцьки",
      "Циблі",
      "Червоне Заріччя",
      "Черкасівка",
      "Черняхівка",
      "Чирське",
      "Чопилки",
      "Чубинське",
      "Щасливе",
      "Яготин",
      "Аркадіївка",
      "Баришівка",
      "Бервиця",
      "Березань",
      "Бзів",
      "Бобрик",
      "Борщів",
      "Бровари",
      "Велика Димерка",
      "Великий Крупіль",
      "Веселинівка",
      "Вишневе",
      "Вільне",
      "Власівка",
      "Вознесенське",
      "Войтове",
      "Володимирське",
      "Волошинівка",
      "Гайове",
      "Гоголів",
      "Горбачівка",
      "Гостролуччя",
      "Гребельки",
      "Гречана Гребля",
      "Дернівка",
      "Дубове",
      "Жердова",
      "Жуківка",
      "Заворичі",
      "Зазим’я",
      "Залісся",
      "Захарівка",
      "Згурівка",
      "Зелене",
      "Зоря",
      "Іллінське",
      "Калита",
      "Княжичі",
      "Коржі",
      "Корніївка",
      "Кулажинці",
      "Левченкове",
      "Лехнівка",
      "Лизогубова Слобода",
      "Літки",
      "Літочки",
      "Лукаші",
      "Любомирівка",
      "Майське",
      "Мала Березанка",
      "Мала Супоївка",
      "Мала Тарасівка",
      "Малий Крупіль",
      "Масківці",
      "Мокрець",
      "Морозівка",
      "Недра",
      "Нова Олександрівка",
      "Нова Оржиця",
      "Олександринівка",
      "Олексіївка",
      "Опанасів",
      "Пайки",
      "Паришків",
      "Пасічна",
      "Пасківщина",
      "Перемога",
      "Переможець",
      "Пилипче",
      "Підлісся",
      "Поділля",
      "Покровське",
      "Пухівка",
      "Рожівка",
      "Рожни",
      "Рудницьке",
      "Рудня",
      "Русанів",
      "Садове",
      "Світильня",
      "Свобода",
      "Сезенків",
      "Селичівка",
      "Селище",
      "Семиполки",
      "Середівка",
      "Скибин",
      "Соболівка",
      "Софіївка",
      "Стара Оржиця",
      "Терлещина",
      "Требухів",
      "Турівка",
      "Урсалівка",
      "Усівка",
      "Устинкова Гребля",
      "Хлопків",
      "Хмельовик",
      "Черевки",
      "Швачиха",
      "Шовкове",
      "Яблуневе",
      "Ярешки",
      "Бабинці",
      "Березівка",
      "Берестянка",
      "Білогородка",
      "Блиставиця",
      "Бобриця",
      "Бондарня",
      "Борівка",
      "Бородянка",
      "Буда-Бабинецька",
      "Бузова",
      "Буча",
      "Вабля",
      "Великий Карашин",
      "Великий Ліс",
      "Вишеград",
      "Вишняки",
      "Вітрівка",
      "Волиця",
      "Волосінь",
      "Ворзель",
      "Вороньківка",
      "Гаврилівка",
      "Гавронщина",
      "Гай",
      "Галинка",
      "Гнатівка",
      "Горбовичі",
      "Гореничі",
      "Горенка",
      "Гостомель",
      "Гурівщина",
      "Діброва",
      "Дмитрівка",
      "Дружня",
      "Забуччя",
      "Забуяння",
      "Завалівка",
      "Загальці",
      "Здвижівка",
      "Зурівка",
      "Ірпінь",
      "Капітанівка",
      "Качали",
      "Клавдієво-Тарасове",
      "Коблицький Ліс",
      "Коблиця",
      "Кодра",
      "Козинці",
      "Колонщина",
      "Комарівка",
      "Копилів",
      "Копіївка",
      "Королівка",
      "Коцюбинське",
      "Красний Ріг",
      "Крюківщина",
      "Липівка",
      "Лисиця",
      "Личанка",
      "Лісне",
      "Лозовик",
      "Любимівка",
      "Майданівка",
      "Макарів",
      "Макарівська Буда",
      "Маковище",
      "Малий Карашин",
      "Мигалки",
      "Миколаївка",
      "Микуличі",
      "Мила",
      "Мироцьке",
      "Мирча",
      "Михайленків",
      "Михайлівка-Рубежівка",
      "Мотижин",
      "Мощун",
      "Мрія",
      "Музичі",
      "Наливайківка",
      "Небелиця",
      "Небрат",
      "Неграші",
      "Немішаєве",
      "Ніжиловичі",
      "Нова Буда",
      "Нова Гребля",
      "Нове Залісся",
      "Новий Корогод",
      "Новомирівка",
      "Озера",
      "Озерщина",
      "Пашківка",
      "Петропавлівська Борщагівка",
      "Петрушки",
      "Пилиповичі",
      "Пісківка",
      "Плахтянка",
      "Пороскотень",
      "Поташня",
      "Почепин",
      "Раківка",
      "Раска",
      "Рожів",
      "Садки-Строївка",
      "Святопетрівське",
      "Синяк",
      "Ситняки",
      "Софіївська Борщагівка",
      "Стара Буда",
      "Стоянка",
      "Тальське",
      "Тарасівщина",
      "Торф’яне",
      "Фасівочка",
      "Фасова",
      "Ферма",
      "Хмільна",
      "Чайки",
      "Червона Гірка",
      "Червона Слобода",
      "Шибене",
      "Шнурів Ліс",
      "Шпитьки",
      "Юрів",
      "Язвинка",
      "Абрамівка",
      "Білий Берег",
      "Блідча",
      "Богдани",
      "Боденьки",
      "Болотня",
      "Буда Вовчківська",
      "Буда-Полідарівська",
      "Буда-Радинська",
      "Варівськ",
      "Вахівка",
      "Вересня",
      "Верхолісся",
      "Вишгород",
      "Вища Дубечня",
      "Воропаїв",
      "Воропаївка",
      "Глібівка",
      "Горностайпіль",
      "Городещина",
      "Губин",
      "Гута-Катюжанська",
      "Гута-Межигірська",
      "Демидів",
      "Димарка",
      "Димер",
      "Дитятки",
      "Доманівка",
      "Дубова",
      "Дудки",
      "Жерева",
      "Жеревпілля",
      "Жміївка",
      "Жукин",
      "Залишани",
      "Запрудка",
      "Заруддя",
      "Зелена Поляна",
      "Зимовище",
      "Зірка",
      "Зорин",
      "Каменка",
      "Карпилівка",
      "Катюжанка",
      "Козаровичі",
      "Коленці",
      "Коленцівське",
      "Красятичі",
      "Кропивня",
      "Круги",
      "Кухарі",
      "Лапутьки",
      "Левковичі",
      "Леонівка",
      "Литвинівка",
      "Лісове",
      "Луговики",
      "Любидва",
      "Лютіж",
      "Макарівка",
      "Максимовичі",
      "Мала Макарівка",
      "Міхлівщина",
      "Млачівка",
      "Мокра Корма",
      "Мусійки",
      "Нижча Дубечня",
      "Нівецьке",
      "Нові Макалевичі",
      "Нові Петрівці",
      "Нові Соколи",
      "Новосілки",
      "Обуховичі",
      "Овдієва Нива",
      "Олива",
      "Олізарівка",
      "Омелянівка",
      "Оране",
      "Осещина",
      "Осовець",
      "Пилява",
      "Пироговичі",
      "Підгайне",
      "Пірнове",
      "Піски",
      "Полідарівка",
      "Поталіївка",
      "Прибірськ",
      "Рагівка",
      "Радинка",
      "Рахвалівка",
      "Рикунь",
      "Ритні",
      "Рихта",
      "Ровжі",
      "Рови",
      "Розважів",
      "Розтісне",
      "Рокитна Слобода",
      "Романівка",
      "Рудня-Димерська",
      "Рудня-Левківська",
      "Рудня-Сидорівська",
      "Рудня-Тальська",
      "Рудня-Шпилівська",
      "Русаки",
      "Савенки",
      "Сидоровичі",
      "Сичівка",
      "Славутич",
      "Слобода-Кухарська",
      "Ставрівка",
      "Станішівка",
      "Стара Марківка",
      "Старий Міст",
      "Старі Петрівці",
      "Старі Соколи",
      "Старовичі",
      "Стещина",
      "Стовпне",
      "Страхолісся",
      "Сувид",
      "Сукачі",
      "Сухолуччя",
      "Термахівка",
      "Тетерівське",
      "Толокунь",
      "Феневичі",
      "Фрузинівка",
      "Хотянівка",
      "Хочева",
      "Черемошна",
      "Чкаловка",
      "Шкнева",
      "Шпилі",
      "Ясногородка",
      "Яхнівка",
      "Балико-Щучинка",
      "Барахти",
      "Безіменне",
      "Безп’ятне",
      "Бендюгівка",
      "Березове",
      "Біївці",
      "Богуслав",
      "Бородані",
      "Бурти",
      "Васильків",
      "Вахутинці",
      "Ведмедівка",
      "Велика Бугаївка",
      "Велика Вільшанка",
      "Великий Букрин",
      "Великі Дмитровичі",
      "Великі Пріцьки",
      "Верем’я",
      "Виселка",
      "Виселкове",
      "Витачів",
      "Вікторівка",
      "Вільховець",
      "Воронівка",
      "Гвоздів",
      "Германівка",
      "Горохівське",
      "Горохове",
      "Горохуватка",
      "Гребені",
      "Грушів",
      "Гулі",
      "Гусачівка",
      "Демівщина",
      "Дерев’яна",
      "Деремезна",
      "Дешки",
      "Дибинці",
      "Долина",
      "Дударі",
      "Ємчиха",
      "Жуківці",
      "Заріччя",
      "Застугна",
      "Здорівка",
      "Зелений Яр",
      "Зеленьки",
      "Землянка",
      "Зікрачі",
      "Зозулі",
      "Зорівка",
      "Іванковичі",
      "Івки",
      "Ісайки",
      "Кагарлик",
      "Кадомка",
      "Капустяна",
      "Карандинці",
      "Карапиші",
      "Киданівка",
      "Кип’ячка",
      "Кодаки",
      "Козин",
      "Козіївка",
      "Конюша",
      "Копачів",
      "Коритище",
      "Красна Слобідка",
      "Красне Перше",
      "Кременище",
      "Креничі",
      "Круглик",
      "Кузьминці",
      "Кулешів",
      "Кулі",
      "Кулібаба",
      "Кутелів",
      "Ленди",
      "Липовець",
      "Липовий Ріг",
      "Лісники",
      "Ліщинка",
      "Лютарі",
      "Македони",
      "Малий Букрин",
      "Малі Дмитровичі",
      "Малі Прицьки",
      "Маслівка",
      "Матвіївка",
      "Матяшівка",
      "Микитяни",
      "Мирівка",
      "Миронівка",
      "Мисайлівка",
      "Митниця",
      "Москаленки",
      "Нещерів",
      "Нова Миронівка",
      "Нові Безрадичі",
      "Обухів",
      "Олександрівка",
      "Онацьки",
      "Оріхове",
      "Очеретяне",
      "П’ятихатка",
      "Панікарча",
      "Паращина",
      "Перегонівка",
      "Переселення",
      "Півці",
      "Підгірці",
      "Пії",
      "Плюти",
      "Половецьке",
      "Польове",
      "Потік",
      "Пустовіти",
      "Расавка",
      "Ржищів",
      "Розкопанці",
      "Романків",
      "Росава",
      "Рославичі",
      "Саварка",
      "Салів",
      "Світле",
      "Семигори",
      "Синиця",
      "Слобода",
      "Стави",
      "Стайки",
      "Старі Безрадичі",
      "Стрітівка",
      "Сущани",
      "Таценки",
      "Тептіївка",
      "Тернівка",
      "Трипілля",
      "Тростинка",
      "Тулинці",
      "Туники",
      "Українка",
      "Уляники",
      "Фролівка",
      "Халеп’я",
      "Халча",
      "Ходорів",
      "Ходосівка",
      "Хотів",
      "Хохітва",
      "Центральне",
      "Червоне Поле",
      "Черняхів",
      "Шандра",
      "Шевченківка",
      "Шпендівка",
      "Шубівка",
      "Шупики",
      "Щербанівка",
      "Юхни",
      "Юшки",
      "Яхни",
      "Яцьки",
      "Яцюки",
      "Багрин",
      "Березенщина",
      "Бишів",
      "Борисів",
      "Борова",
      "Бортники",
      "Боярка",
      "Варовичі",
      "Василівка",
      "Велика Мотовилівка",
      "Велика Офірна",
      "Велика Снітинка",
      "Велика Солтанівка",
      "Великі Гуляки",
      "Веприк",
      "Весела Слобідка",
      "Вишня",
      "Вільшанська Нива",
      "Віта-Поштова",
      "Гатне",
      "Гвардійське",
      "Глеваха",
      "Грузьке",
      "Данилівка",
      "Деминівка",
      "Дерев’янки",
      "Дзвінкове",
      "Дідівщина",
      "Дорогинка",
      "Єлизаветівка",
      "Жорнівка",
      "Забір’я",
      "Зайців",
      "Залізне",
      "Зелений Бір",
      "Клехівка",
      "Кобці",
      "Кожанка",
      "Кожухівка",
      "Козичанка",
      "Конопельки",
      "Кончаки",
      "Кощіївка",
      "Крушинка",
      "Крячки",
      "Липовий Скиток",
      "Лишня",
      "Лубське",
      "Мала Бугаївка",
      "Мала Офірна",
      "Мала Снітинка",
      "Мала Солтанівка",
      "Малополовецьке",
      "Малютянка",
      "Мархалівка",
      "Млинок",
      "Мостище",
      "Мотовилівка",
      "Мотовилівська Слобідка",
      "Нове",
      "Нові Опачичі",
      "Оленівка",
      "Осикове",
      "Перевіз",
      "Пилипівка",
      "Півні",
      "Плесецьке",
      "Порадівка",
      "Пришивальня",
      "Путрівка",
      "Руликів",
      "Скригалівка",
      "Скрипки",
      "Ставки",
      "Тарасенки",
      "Томашівка",
      "Триліси",
      "Фастів",
      "Фастівець",
      "Хлепча",
      "Чабани",
      "Чорногородка",
      "Юрівка",
      "Ярошівка",
    ],
  },
  {
    region: "Кіровоградська",
    cities: [
      "Бандурове",
      "Березівка",
      "Березова Балка",
      "Берестяги",
      "Благовіщенське",
      "Богданове",
      "Борщова",
      "Бугове",
      "Бузникувате",
      "Великі Трояни",
      "Вербівка",
      "Вербове",
      "Вівсяники",
      "Вікнина",
      "Вільхове",
      "Вільховецьке",
      "Вільшанка",
      "Владиславка",
      "Вовча Балка",
      "Володимирівка",
      "Вукитичеве",
      "Гайворон",
      "Ганнівка",
      "Голованівськ",
      "Голубенка",
      "Грузьке",
      "Грузянка",
      "Грушка",
      "Давидівка",
      "Данилова Балка",
      "Дельфінове",
      "Диковичеве",
      "Добре",
      "Добрянка",
      "Долинівка",
      "Дорожинка",
      "Ємилівка",
      "Жакчик",
      "Жеванівка",
      "Журавлинка",
      "Журівка",
      "Завалля",
      "Завітне",
      "Залізничне",
      "Зелена Балка",
      "Змійове",
      "Зрубанці",
      "Іванівка",
      "Йосипівка",
      "Кагарлик",
      "Казавчин",
      "Калинівка",
      "Калмазове",
      "Кальниболота",
      "Кам’яна Криниця",
      "Кам’янече",
      "Кам’яний Брід",
      "Капітанка",
      "Кіндратівка",
      "Кленове",
      "Клинове",
      "Ковалівка",
      "Комишеве",
      "Копенкувате",
      "Коритно-Забузьке",
      "Костянтинівка",
      "Кошаро-Олександрівка",
      "Красногірка",
      "Краснопілля",
      "Кринички",
      "Крутеньке",
      "Куца Балка",
      "Лебединка",
      "Левківка",
      "Лещівка",
      "Липняги",
      "Липовеньке",
      "Лісне",
      "Лозувата",
      "Лозуватка",
      "Луполове",
      "Люшнювате",
      "Мала Вільшанка",
      "Мала Мазниця",
      "Манжурка",
      "Маньківське",
      "Мар’янівка",
      "Маринопіль",
      "Мартинівка",
      "Матвіївка",
      "Межирічка",
      "Мечиславка",
      "Могильне",
      "Молдовка",
      "Мощене",
      "Надеждівка",
      "Надлак",
      "Наливайка",
      "Небелівка",
      "Нерубайка",
      "Низове",
      "Новоархангельськ",
      "Новоголованівськ",
      "Новопетрівка",
      "Новоселиця",
      "Новосілка",
      "Одая",
      "Олександрівка",
      "Олексіївка",
      "Орлове",
      "Осички",
      "Перегонівка",
      "Переямпіль",
      "Петрівка",
      "Підвисоке",
      "Плоско-Забузьке",
      "Побузьке",
      "Покотилове",
      "Покровка",
      "Покровське",
      "Полонисте",
      "Приют",
      "Прогрес",
      "Пушкове",
      "Роздол",
      "Розкішне",
      "Розношенське",
      "Розсохуватець",
      "Сабатинівка",
      "Сабове",
      "Садове",
      "Салькове",
      "Свердликове",
      "Свірневе",
      "Семидуби",
      "Сергіївка",
      "Синицівка",
      "Синьки",
      "Синюха",
      "Скалева",
      "Скалівські Хутори",
      "Солгутове",
      "Солдатське",
      "Соломія",
      "Станіславівка",
      "Станіславове",
      "Станкувате",
      "Степанівка",
      "Сухий Ташлик",
      "Табанове",
      "Тарасівка",
      "Таужне",
      "Ташлик",
      "Тернівка",
      "Тернове",
      "Тимофіївка",
      "Тополі",
      "Торговиця",
      "Тракт",
      "Троянка",
      "Хащувате",
      "Христофорове",
      "Цвіткове",
      "Чемерпіль",
      "Червінка",
      "Червоне",
      "Червоні Маяки",
      "Чистопілля",
      "Шамраєве",
      "Шевченка",
      "Шепилове",
      "Шляхове",
      "Ясне",
      "Ятрань",
      "Аврамівка",
      "Аджамка",
      "Андросове",
      "Антонівка",
      "Апрелівка",
      "Апрелівське",
      "Барвінівка",
      "Безводня",
      "Бережинка",
      "Березнегувате",
      "Березуватка",
      "Береславка",
      "Білозерне",
      "Білопіль",
      "Біляївка",
      "Бірки",
      "Благодатне",
      "Бобринець",
      "Бобринка",
      "Бовтишка",
      "Богданівка",
      "Богодарівка",
      "Бокове",
      "Борисівка",
      "Братерське",
      "Братолюбівка",
      "Братський Посад",
      "Бредихине",
      "Брусівка",
      "Бузова",
      "Букварка",
      "Бурякове",
      "Буховецьке",
      "Варварівка",
      "Варламівка",
      "Василівка",
      "Васине",
      "Велика Северинка",
      "Велика Чечеліївка",
      "Великодрюкове",
      "Верблюжка",
      "Вереміївка",
      "Верхівці",
      "Верхньоінгульське",
      "Вершино-Кам’янка",
      "Веселе",
      "Веселий Кут",
      "Веселі Боковеньки",
      "Веселівка",
      "Виноградівка",
      "Високі Байраки",
      "Височанове",
      "Витязівка",
      "Вишневе",
      "Вишнівка",
      "Вишняківка",
      "Вищі Верещаки",
      "Вільне",
      "Водяне",
      "Водяно-Михайлівка",
      "Волошки",
      "Воронцівка",
      "Гаївка",
      "Гайове",
      "Ганнинське",
      "Ганно-Леонтовичеве",
      "Ганнопіль",
      "Ганно-Требинівка",
      "Гарманівка",
      "Глибока Балка",
      "Голикове",
      "Голубієвичі",
      "Гординівка",
      "Гордіївка",
      "Горіхівка",
      "Гостинне",
      "Григорівка",
      "Грізне",
      "Громадське",
      "Губівка",
      "Гурівка",
      "Гутницька",
      "Дар’ївка",
      "Демешкове",
      "Димине",
      "Дібрівка",
      "Дмитрівка",
      "Докучаєве",
      "Долина",
      "Долино-Кам’янка",
      "Долинська",
      "Дончине",
      "Дружба",
      "Дубівка",
      "Дубровине",
      "Єлизаветградка",
      "Живанівка",
      "Завадівка",
      "Завтурове",
      "Заломи",
      "Західне",
      "Згода",
      "Зелене",
      "Зелений Гай",
      "Златопілля",
      "Знам’янка",
      "Знам’янка Друга",
      "Золотницьке",
      "Зоря",
      "Зоряне",
      "Івангород",
      "Іванківці",
      "Івано-Благодатне",
      "Інгуло-Кам’янка",
      "Інгульське",
      "Інженерівка",
      "Казарня",
      "Кандаурове",
      "Карлівка",
      "Катеринівка",
      "Кетрисанівка",
      "Китайгород",
      "Клинці",
      "Козирівка",
      "Козлівське",
      "Компаніївка",
      "Коневе",
      "Копані",
      "Корбомиколаївка",
      "Коржеве",
      "Корлюгівка",
      "Коротяк",
      "Костомарівка",
      "Кохане",
      "Коханівка",
      "Красносілка",
      "Красносілля",
      "Кременчувате",
      "Кривоносове",
      "Кримки",
      "Криничне",
      "Криничувате",
      "Криничуватка",
      "Кропивницький",
      "Крутоярка",
      "Куцівка",
      "Кучерівка",
      "Лаврівка",
      "Лебедине",
      "Липівка",
      "Липове",
      "Лісове",
      "Лужок",
      "Любовичка",
      "Любомирка",
      "Любо-Надеждівка",
      "Майське",
      "Макариха",
      "Макове",
      "Маковіївка",
      "Мала Ганнівка",
      "Маловодяне",
      "Малоконеве",
      "Мальовниче",
      "Мальчевське",
      "Мар’ївка",
      "Маріуполь",
      "Марфівка",
      "Медвежа Балка",
      "Медерове",
      "Медове",
      "Миколаївка",
      "Миколаївські Сади",
      "Миколо-Бабанка",
      "Мирне",
      "Миронівка",
      "Митрофанівка",
      "Михайлівка",
      "Мілова Балка",
      "Могилів Курінь",
      "Могутнє",
      "Молодецьке",
      "Молодіжне",
      "Морквина",
      "Мошорине",
      "Мюдівка",
      "Нагірне",
      "Наглядівка",
      "Назарівка",
      "Нариманівка",
      "Настине",
      "Неопалимівка",
      "Несваткове",
      "Нечаївка",
      "Нижчі Верещаки",
      "Никифорівка",
      "Никонорівка",
      "Нова Осота",
      "Нова Павлівка",
      "Новгородка",
      "Нове",
      "Новоандріївка",
      "Нововодяне",
      "Нововолодимирівка",
      "Новогомельське",
      "Новоградівка",
      "Новогригорівка",
      "Новогригорівка Друга",
      "Новогригорівка Перша",
      "Новоданилівка",
      "Новоігорівка",
      "Новокиївка",
      "Новомиколаївка",
      "Новомихайлівка",
      "Новоолександрівка",
      "Новопокровка",
      "Новополяна",
      "Новороманівка",
      "Новосавицьке",
      "Новосамара",
      "Новотрепівка",
      "Новоустинівка",
      "Новочигиринка",
      "Новошевченкове",
      "Обертасове",
      "Обланка",
      "Обознівка",
      "Овсяниківка",
      "Оленівка",
      "Оленівське",
      "Олено-Косогорівка",
      "Ольгівка",
      "Омельгород",
      "Орлова Балка",
      "Осикувате",
      "Оситняжка",
      "Очеретяне",
      "П’ятихатки",
      "Павлівка",
      "Павлогірківка",
      "Павло-Миколаївка",
      "Паращине Поле",
      "Пенькове",
      "Первозванівка",
      "Переможне",
      "Перше Травня",
      "Першотравенка",
      "Першотравневе",
      "Петрове",
      "Петрокорбівка",
      "Писанка",
      "Пишне",
      "Підгайці",
      "Підлісне",
      "Піщане",
      "Плішки",
      "Плоске",
      "Полтавка",
      "Полум’яне",
      "Польове",
      "Поляна",
      "Попівка",
      "Поселянівка",
      "Привілля",
      "Проскурівка",
      "Просторе",
      "Рибчине",
      "Родниківка",
      "Рожнятівка",
      "Роздолля",
      "Розтичеве",
      "Розумівка",
      "Ромашки",
      "Рощахівка",
      "Ружичеве",
      "Ручайки",
      "Саблине",
      "Садки",
      "Сасівка",
      "Світова Зірка",
      "Северинівка",
      "Седнівка",
      "Селіванове",
      "Семенівка",
      "Ситаєве",
      "Славне",
      "Созонівка",
      "Сокільники",
      "Соколівське",
      "Солонцюватка",
      "Сонцеве",
      "Сонячне",
      "Сорочанове",
      "Соснівка",
      "Сотницьке",
      "Софіївка",
      "Спасове",
      "Спасо-Мажарівка",
      "Спільне",
      "Ставидла",
      "Стара Осота",
      "Степівка",
      "Степове",
      "Стримівка",
      "Суботці",
      "Сугокліївка",
      "Суходільське",
      "Тернова Балка",
      "Тирлова Балка",
      "Топило",
      "Травневе",
      "Трепівка",
      "Третє",
      "Триліси",
      "Трудолюбівка",
      "Ударне",
      "Українка",
      "Улянівка",
      "Устинівка",
      "Фалькове",
      "Федіївка",
      "Федорівка",
      "Федоро-Шулічине",
      "Хайнівка",
      "Цвітне",
      "Цибулеве",
      "Чарівне",
      "Червона Долина",
      "Червона Слобода",
      "Червоне Озеро",
      "Червоний Кут",
      "Червоний Яр",
      "Червоновершка",
      "Червонопілля",
      "Черняхівка",
      "Чигиринське",
      "Шевченкове",
      "Широка Балка",
      "Шостаківка",
      "Южне",
      "Юр’ївка",
      "Юхимове",
      "Яблучко",
      "Ясинове",
      "Ясинуватка",
      "Анатолівка",
      "Андріївка",
      "Арепівка",
      "Арсенівка",
      "Берегове",
      "Бирзулове",
      "Бровкове",
      "Бурти",
      "В’юнки",
      "Варваро-Олександрівка",
      "Велика Виска",
      "Веснянка",
      "Виноградне",
      "Висоцьке",
      "Вись",
      "Вікняне",
      "Вільні Луки",
      "Вірне",
      "Войнівка",
      "Вороб’ївка",
      "Воронівка",
      "Глиняне",
      "Глодоси",
      "Гнатівка",
      "Громуха",
      "Далеке",
      "Добровеличківка",
      "Добротимофіївка",
      "Дорофіївка",
      "Дружелюбівка",
      "Єгорівка",
      "Запашка",
      "Заповідне",
      "Заріччя",
      "Захарівка",
      "Защита",
      "Звірівка",
      "Злинка",
      "Іванівці",
      "Калаколове",
      "Кам’яний Міст",
      "Кам’янка",
      "Кам’януватка",
      "Каніж",
      "Капітанівка",
      "Караказелівка",
      "Карбівка",
      "Квітка",
      "Кирилівка",
      "Козакова Балка",
      "Коколове",
      "Комишувате",
      "Копанки",
      "Коробчине",
      "Котівка",
      "Краснопілка",
      "Крикунка",
      "Кропивницьке",
      "Куликова Балка",
      "Лев-Балка",
      "Леонтовичеве",
      "Липняжка",
      "Листопадове",
      "Лікареве",
      "Ліски",
      "Лутківка",
      "Мала Виска",
      "Мала Помічна",
      "Мала Тимошівка",
      "Мануйлівка",
      "Мар’янопіль",
      "Маркове",
      "Мартоноша",
      "Матусівка",
      "Межове",
      "Микільське",
      "Миролюбівка",
      "Миропіль",
      "Міжколодяжне",
      "Моргунівка",
      "Мостове",
      "Нерубаївка",
      "Нова Ковалівка",
      "Новаківка",
      "Нововікторівка",
      "Нововознесенка",
      "Новоглиняне",
      "Новодобрянка",
      "Новоєгорівка",
      "Новокрасне",
      "Новолутківка",
      "Новомиргород",
      "Новоодеса",
      "Новооникієве",
      "Новопавлівка",
      "Новоподимка",
      "Новоселівка",
      "Новостанівка",
      "Новостанкувата",
      "Новотишківка",
      "Новоукраїнка",
      "Олександро-Акацатове",
      "Олександро-Завадське",
      "Оникієве",
      "Осикове",
      "Оситна",
      "Острівка",
      "Паліївка",
      "Панчеве",
      "Пасічне",
      "Пенькине",
      "Первомайськ",
      "Первомайське",
      "Перемога",
      "Перчунове",
      "Петроострів",
      "Писарівка",
      "Піддубне",
      "Піщаний Брід",
      "Піщанське",
      "Плетений Ташлик",
      "Подимка",
      "Показове",
      "Полохівка",
      "Помічна",
      "Прищепівка",
      "Пурпурівка",
      "Радісне",
      "Рівне",
      "Розлива",
      "Розсохуватка",
      "Рубаний Міст",
      "Семенасте",
      "Скопіївка",
      "Сміливе",
      "Смоліне",
      "Сотницька Балка",
      "Сухолітівка",
      "Схід",
      "Тишківка",
      "Трояни",
      "Троянове",
      "Турія",
      "Успенівка",
      "Фурманівка",
      "Хмельове",
      "Червона Поляна",
      "Червоний Розділ",
      "Червоний Ташлик",
      "Шишкине",
      "Шпакове",
      "Шутеньке",
      "Щасливка",
      "Щербатівка",
      "Яблунівка",
      "Якимівка",
      "Аудиторівка",
      "Бабичівка",
      "Байдакове",
      "Балахівка",
      "Бандурівка",
      "Баштине",
      "Браїлівка",
      "Братське",
      "Бугруватка",
      "Бутівське",
      "Велика Андрусівка",
      "Велика Скельова",
      "Веселка",
      "Видне",
      "Вишнівці",
      "Вищепанівка",
      "Вільний Посад",
      "Власівка",
      "Гайок",
      "Глибоке",
      "Глинськ",
      "Головківка",
      "Головківське",
      "Гулевичі",
      "Дача",
      "Деріївка",
      "Диківка",
      "Діброви",
      "Дівоче Поле",
      "Добронадіївка",
      "Долинське",
      "Занфірівка",
      "Запоріжжя",
      "Звенигородка",
      "Зелений Барвінок",
      "Зибкове",
      "Золотарівка",
      "Ізмайлівка",
      "Іскрівка",
      "Калантаїв",
      "Калачівка",
      "Камбурліївка",
      "Квітневе",
      "Кобзарівка",
      "Козацьке",
      "Королівка",
      "Косівка",
      "Куколівка",
      "Куцеволівка",
      "Лани",
      "Лелеківка",
      "Лікарівка",
      "Луганка",
      "Любівка",
      "Мала Березівка",
      "Мала Скельова",
      "Малинівка",
      "Марто-Іванівка",
      "Млинок",
      "Морозівка",
      "Недогарки",
      "Нова Прага",
      "Новий Стародуб",
      "Новозолотарівка",
      "Новомануйлівка",
      "Новоолексіївка",
      "Новоулянівка",
      "Новофедорівка",
      "Оврагове",
      "Озера",
      "Олександрійське",
      "Олександрія",
      "Олександродар",
      "Олександро-Мар’ївка",
      "Олександро-Пащенкове",
      "Олександро-Степанівка",
      "Оліївка",
      "Олімпіадівка",
      "Омельник",
      "Онуфріївка",
      "П’ятомиколаївка",
      "Павлиш",
      "Павловолуйськ",
      "Пантазіївка",
      "Пантаївка",
      "Пахарівка",
      "Перехрестівка",
      "Петрозагір’я",
      "Подорожнє",
      "Попельнасте",
      "Приютівка",
      "Протопопівка",
      "Пустельникове",
      "Рожеве",
      "Рядове",
      "Сабадашеве",
      "Світловодськ",
      "Світлопіль",
      "Семигір’я",
      "Серебряне",
      "Сніжкова Балка",
      "Сніжкове",
      "Солов’ївка",
      "Сонине",
      "Сургани",
      "Талова Балка",
      "Тарасово-Шевченкове",
      "Трудівка",
      "Успенка",
      "Федірки",
      "Червона Кам’янка",
      "Червоний Поділ",
      "Червонокостянтинівка",
      "Червоносілля",
      "Чечеліївка",
      "Шарівка",
      "Щасливе",
      "Ягідне",
      "Ялинівка",
      "Яремівка",
    ],
  },
  {
    region: "АР Крим",
    cities: [
      "Айвове",
      "Актачи",
      "Албат",
      "Андріївка",
      "Аромат",
      "Ароматне",
      "Багата Ущелина",
      "Бахчисарай",
      "Баштанівка",
      "Берегове",
      "Білокам’яне",
      "Богатир",
      "Брянське",
      "Велике Садове",
      "Верхньосадове",
      "Верхоріччя",
      "Високе",
      "Вишневе",
      "Відрадне",
      "Вікторівка",
      "Віліне",
      "Гірка",
      "Глибокий Яр",
      "Голубинка",
      "Гончарне",
      "Дальнє",
      "Дачне",
      "Дібрівка",
      "Долинне",
      "Дорожнє",
      "Завітне",
      "Залізничне",
      "Залісне",
      "Зелене",
      "Зубакіне",
      "Інкерман",
      "Казанки",
      "Камишли",
      "Кача",
      "Каштани",
      "Кизилове",
      "Кочергіне",
      "Красна Зоря",
      "Красний Мак",
      "Кудрине",
      "Мале Садове",
      "Малинівка",
      "Маловидне",
      "Машине",
      "Многоріччя",
      "Мостове",
      "Нагірне",
      "Научний",
      "Некрасовка",
      "Нижня Голубинка",
      "Новеньке",
      "Новобобрівське",
      "Нововасилівка",
      "Новопавлівка",
      "Новопілля",
      "Озерне",
      "Орлине",
      "Орлівка",
      "Осипенко",
      "Отарчик",
      "Павлівка",
      "Передове",
      "Передущельне",
      "Пироговка",
      "Підгірне",
      "Піщане",
      "Плодове",
      "Плотинне",
      "Поворотне",
      "Полюшко",
      "Поляна",
      "Поштове",
      "Приятне Свідання",
      "Прохладне",
      "Путилівка",
      "Резервне",
      "Рєпіне",
      "Рідне",
      "Річне",
      "Родниківське",
      "Розове",
      "Розсадне",
      "Розсошанка",
      "Ростуче",
      "Самохвалове",
      "Севастянівка",
      "Синапне",
      "Сирень",
      "Скалисте",
      "Соколине",
      "Сонячний",
      "Сонячносілля",
      "Стальне",
      "Суворове",
      "Табачне",
      "Танкове",
      "Тернівка",
      "Тилове",
      "Тінисте",
      "Тополі",
      "Трудолюбівка",
      "Тургенєвка",
      "Углове",
      "Узунджи",
      "Фронтове",
      "Фруктове",
      "Ходжа Сала",
      "Холмівка",
      "Шевченкове",
      "Широке",
      "Щасливе",
      "Алексєєвка",
      "Аннівка",
      "Багате",
      "Баланове",
      "Балки",
      "Барабанове",
      "Біла Скеля",
      "Білогірськ",
      "Бочала",
      "Буревісник",
      "Бюйтен",
      "Василівка",
      "Великосілля",
      "Верхні Орішники",
      "Вишенне",
      "Владиславівка",
      "Володимирівка",
      "Голованівка",
      "Горлинка",
      "Дворіччя",
      "Дворове",
      "Дивне",
      "Дозорне",
      "Долинівка",
      "Дрофине",
      "Желябовка",
      "Жемчужина",
      "Заливне",
      "Заріччя",
      "Зеленогірське",
      "Земляничне",
      "Зибини",
      "Зоркіне",
      "Зуя",
      "Іванівка",
      "Ізобільне",
      "Карасівка",
      "Кизилівка",
      "Кирпичне",
      "Кирсанівка",
      "Кісточківка",
      "Коврове",
      "Корінне",
      "Красна Слобода",
      "Красногірське",
      "Красноселівка",
      "Кривцове",
      "Кримська Роза",
      "Криничне",
      "Кукурудзяне",
      "Кунцеве",
      "Курортне",
      "Курське",
      "Листвинне",
      "Литвиненкове",
      "Лінійне",
      "Лічебне",
      "Ломоносове",
      "Лугове",
      "Лужки",
      "Любимівка",
      "Межове",
      "Мелехове",
      "Мельники",
      "Мельничне",
      "Миронівка",
      "Митрофанівка",
      "Михайлівка",
      "Міжгір’я",
      "Мічурінське",
      "Муромське",
      "Некрасове",
      "Нижні Орішники",
      "Нижньогірський",
      "Ніжинське",
      "Новогригорівка",
      "Новожилівка",
      "Новоіванівка",
      "Новокленове",
      "Новоолександрівка",
      "Овражки",
      "Олександрівка",
      "Оленівка",
      "Омелянівка",
      "Опитне",
      "Охотське",
      "Пасічне",
      "Петрове",
      "Піни",
      "Прирічне",
      "Пролом",
      "Пчолине",
      "Пшеничне",
      "Радісне",
      "Родники",
      "Розливи",
      "Русаківка",
      "Руське",
      "Садове",
      "Сєверне",
      "Сєрове",
      "Синьокам’янка",
      "Сім’яне",
      "Сінне",
      "Слив’янка",
      "Степанівка",
      "Стрепетове",
      "Султан-Сарай",
      "Тамбовка",
      "Тарасівка",
      "Тополівка",
      "Тургенєве",
      "Уварівка",
      "Українка",
      "Учебне",
      "Уютне",
      "Хлібне",
      "Цвіточне",
      "Цвітуще",
      "Червоне",
      "Черемисівка",
      "Чкалове",
      "Чорнопілля",
      "Яблучне",
      "Якимівка",
      "Яковлівка",
      "Яструбки",
      "Азовське",
      "Антонівка",
      "Апрелівка",
      "Арбузівка",
      "Армійське",
      "Бай-Кончек",
      "Благодатне",
      "Ближнє",
      "Ближньогородське",
      "Болотне",
      "Бородіно",
      "Веселе",
      "Випасне",
      "Вільне",
      "Вітвисте",
      "Володине",
      "Джадра-Шейх-Елі",
      "Джанкой",
      "Дмитрівка",
      "Дніпровка",
      "Єрмакове",
      "Жилине",
      "Житомирське",
      "Зарічне",
      "Зелений Яр",
      "Зернове",
      "Ізумрудне",
      "Істочне",
      "Калинівка",
      "Кіндратове",
      "Ковильне",
      "Колоски",
      "Конурча",
      "Копані",
      "Костянтинівка",
      "Краснодольне",
      "Кримка",
      "Кучук-Алкали",
      "Лобанове",
      "Луганське",
      "Майське",
      "Мар’їне",
      "Мартинівка",
      "Маслове",
      "Медведівка",
      "Мирнівка",
      "Мисове",
      "Митюрине",
      "Мілководне",
      "Мічурінівка",
      "Мішен-Найман",
      "Многоводне",
      "Нахідка",
      "Нижні Отрожки",
      "Низинне",
      "Нове Життя",
      "Новокостянтинівка",
      "Новокримське",
      "Новосільцеве",
      "Новостепове",
      "Новофедорівка",
      "Овочеве",
      "Озерки",
      "Орак-Аджи",
      "Орденоносне",
      "Пахарівка",
      "Передмістне",
      "Перепілкине",
      "Побєдне",
      "Польове",
      "Придорожнє",
      "Пробудження",
      "Прозрачне",
      "Просторне",
      "Пушкіне",
      "Рисакове",
      "Розкішне",
      "Рощине",
      "Рубинівка",
      "Рюмшине",
      "Світле",
      "Сєрноводське",
      "Слов’янка",
      "Слов’янське",
      "Солоне Озеро",
      "Солонцеве",
      "Стефанівка",
      "Стовпове",
      "Суміжне",
      "Тархан-Сунак",
      "Тимофіївка",
      "Тімірязєве",
      "Томашівка",
      "Тутове",
      "Федорівка",
      "Цілинне",
      "Чайкине",
      "Ярке",
      "Ярке Поле",
      "Ясне",
      "Яснополянське",
      "Яструбці",
      "Абрикосівка",
      "Багайли",
      "Валентинове",
      "Велике",
      "Вересаєве",
      "Вершинне",
      "Веселівка",
      "Виноградове",
      "Вітине",
      "Вітрівка",
      "Владне",
      "Внукове",
      "Вогневе",
      "Водопійне",
      "Воробйове",
      "Гаршине",
      "Геройське",
      "Глінка",
      "Громове",
      "Громовка",
      "Далеке",
      "Добрушине",
      "Долинка",
      "Євпаторія",
      "Єлизаветове",
      "Жайворонки",
      "Жовтокам’янка",
      "Журавлі",
      "Журавлівка",
      "Задорне",
      "Зайцеве",
      "Заозерне",
      "Знам’янське",
      "Зоряне",
      "Ігорівка",
      "Ізвесткове",
      "Іллінка",
      "Каменоломня",
      "Кар’єрне",
      "Кольцове",
      "Коп-Аран",
      "Крайнє",
      "Красна Поляна",
      "Красносільське",
      "Красноярське",
      "Криловка",
      "Кримське",
      "Кузнецьке",
      "Куликівка",
      "Ленське",
      "Лиманне",
      "Листове",
      "Ліснівка",
      "Лушине",
      "Мамут-Бай",
      "Маяк",
      "Медведеве",
      "Мирний",
      "Митяєве",
      "Міжводне",
      "Молочне",
      "Наташине",
      "Наумівка",
      "Нива",
      "Низівка",
      "Новоозерне",
      "Новосільське",
      "Озерівка",
      "Окунівка",
      "Оріхове",
      "Орлянка",
      "Охотникове",
      "Попівка",
      "Порфирівка",
      "Прибережне",
      "Привітне",
      "Ромашкине",
      "Рунне",
      "Саки",
      "Сизівка",
      "Сніжне",
      "Солдатське",
      "Степове",
      "Суворовське",
      "Тарханкут",
      "Тока",
      "Трудове",
      "Тунельне",
      "Хмельове",
      "Хуторок",
      "Чоботарка",
      "Чорноморське",
      "Шалаші",
      "Шишкине",
      "Шовковичне",
      "Штормове",
      "Яни Кьогенеш",
      "Багерове",
      "Батальне",
      "Бєлінське",
      "Бондаренкове",
      "Борисівка",
      "В’язникове",
      "Верхньозаморське",
      "Виноградне",
      "Вулканівка",
      "Глазівка",
      "Горностаївка",
      "Єгорове",
      "Єди-Кую",
      "Єрофєєве",
      "Заводське",
      "Затишне",
      "Золоте",
      "Кам’янське",
      "Кара-Кую",
      "Карса",
      "Катирлез",
      "Керч",
      "Китай",
      "Корольове",
      "Костиріне",
      "Красногірка",
      "Львове",
      "Мар’ївка",
      "Марфівка",
      "Мелек-Чесме",
      "Набережне",
      "Нижньозаморське",
      "Нововідрадне",
      "Новомиколаївка",
      "Новоселівка",
      "Огоньки",
      "Осовини",
      "Останіне",
      "Пісочне",
      "Полтавське",
      "Приозерне",
      "Прудникове",
      "Пташкине",
      "Романове",
      "Семенівка",
      "Семисотка",
      "Соляне",
      "Станційне",
      "Тасунове",
      "Уварове",
      "Фонтан",
      "Челядінове",
      "Чистопілля",
      "Щолкіне",
      "Южне",
      "Юркине",
      "Яковенкове",
      "Ячмінне",
      "Абрикосове",
      "Азов",
      "Айбар",
      "Амурське",
      "Арбузове",
      "Бешуйлі-Іляк",
      "Біюк-Бораш",
      "Біюк-Онлар",
      "Борангар",
      "Боташ",
      "Братське",
      "Видне",
      "Вишняківка",
      "Відкрите",
      "Володимирове",
      "Восход",
      "Гвардійське",
      "Григорівка",
      "Гришине",
      "Давчи",
      "Девлет-Алі",
      "Дер-Емес",
      "Дібрівське",
      "Докучаєве",
      "Доходне",
      "Звіздне",
      "Знам’янка",
      "Зоря",
      "Іраде",
      "Іскра",
      "Кам’янка",
      "Карпівка",
      "Каштанівка",
      "Кир-Байлар",
      "Кият",
      "Клепиніне",
      "Климове",
      "Колодязне",
      "Комарівка",
      "Кормове",
      "Котельникове",
      "Красна Долина",
      "Краснодарка",
      "Кремнівка",
      "Крестянівка",
      "Курганне",
      "Курман",
      "Кучук-Бораш",
      "Левітанівка",
      "Макарівка",
      "Мар’янівка",
      "Матвіївка",
      "Менделєєве",
      "Миролюбівка",
      "Мускатне",
      "Найдьонівка",
      "Нахімове",
      "Невське",
      "Нейшпроцунг",
      "Нова Деревня",
      "Новодолинка",
      "Новоестонія",
      "Новозуївка",
      "Новокатеринівка",
      "Новомикільське",
      "Новоолексіївка",
      "Новопокровка",
      "Новосільці",
      "Олексіївка",
      "Орман-Аджи",
      "П’ятихатка",
      "Панфіловка",
      "Первомайське",
      "Петрівка",
      "Плодородне",
      "Побєдине",
      "Пологи",
      "Полтавка",
      "Привільне",
      "Проточне",
      "Пряме",
      "Радужне",
      "Рівне",
      "Рогове",
      "Салгирка",
      "Сари-Баш",
      "Симоненко",
      "Снігурівка",
      "Старий Млин",
      "Степне",
      "Сусаніне",
      "Тимашівка",
      "Тимошенко",
      "Тихонівка",
      "Трактове",
      "Удачне",
      "Умют",
      "Упорне",
      "Холмове",
      "Цвіткове",
      "Чернове",
      "Щербакове",
      "Янтарне",
      "Яструбівка",
      "Армянськ",
      "Ас",
      "Бахчівка",
      "Березівка",
      "Богачівка",
      "Ботанічне",
      "Вишнівка",
      "Вітрянка",
      "Вогні",
      "Воїнка",
      "Волочаївка",
      "Волошине",
      "Воронки",
      "Воронцівка",
      "Городнє",
      "Зелена Нива",
      "Зимине",
      "Ішунь",
      "Каракуль",
      "Карпова Балка",
      "Кереїт",
      "Коджалак",
      "Комишне",
      "Кріпке",
      "Кропоткіне",
      "Кукушкіне",
      "Кумове",
      "Магазинка",
      "Максимівка",
      "Мирза-Кояш",
      "Надеждине",
      "Новорибацьке",
      "Новоселівське",
      "Овражне",
      "Орлівське",
      "Перекоп",
      "Портове",
      "Почетне",
      "Рилєєвка",
      "Рисове",
      "Роздольне",
      "Руч’ї",
      "Сватове",
      "Серебрянка",
      "Сінокісне",
      "Славне",
      "Смушкине",
      "Соколи",
      "Стерегуще",
      "Тавкель-Найман",
      "Таврійське",
      "Татиш-Конрат",
      "Уткіне",
      "Філатівка",
      "Чернишове",
      "Чехове",
      "Шатри",
      "Яни Капу",
      "Аграрне",
      "Аерофлотський",
      "Айкаван",
      "Акрополіс",
      "Ана-Юрт",
      "Андрусове",
      "Аркадіївка",
      "Бакачик-Кият",
      "Білоглинка",
      "Бітумне",
      "Бура",
      "Верхньокурганне",
      "Вінницьке",
      "Водне",
      "Гресівський",
      "Грушеве",
      "Давидове",
      "Дем’янівка",
      "Денисівка",
      "Джолман",
      "Дмитрове",
      "Добре",
      "Донське",
      "Дружне",
      "Дубки",
      "Живописне",
      "Залісся",
      "Каштанове",
      "Кленівка",
      "Клинівка",
      "Ключі",
      "Ключове",
      "Кольчугине",
      "Комишинка",
      "Красна Зорька",
      "Красне",
      "Краснівка",
      "Краснолісся",
      "Кубанське",
      "Купріне",
      "Левадки",
      "Лікарственне",
      "Лісносілля",
      "Лозове",
      "Мазанка",
      "Маленьке",
      "Миколаївка",
      "Мирне",
      "Міжгірне",
      "Молодіжне",
      "Мраморне",
      "Нижньокурганне",
      "Новий Мир",
      "Новий Сад",
      "Новоандріївка",
      "Новозбур’ївка",
      "Обрив",
      "Опушки",
      "Партизанське",
      "Перевальне",
      "Перове",
      "Петропавлівка",
      "Пожарське",
      "Пролітне",
      "Прудове",
      "Рівнопілля",
      "Родникове",
      "Роздолля",
      "Сімферополь",
      "Скворцове",
      "Солов’ївка",
      "Сонячне",
      "Софіївка",
      "Спокійне",
      "Сторожеве",
      "Строгонівка",
      "Сумське",
      "Сухоріччя",
      "Темир-Ага",
      "Тепле",
      "Теплівка",
      "Топольне",
      "Трипрудне",
      "Трудолюбове",
      "Укромне",
      "Урожайне",
      "Ферсманове",
      "Фонтани",
      "Харитонівка",
      "Чайковське",
      "Чистеньке",
      "Шафранне",
      "Шкільне",
      "Айвазовське",
      "Алмазне",
      "Бабенкове",
      "Багатівка",
      "Варварівка",
      "Василькове",
      "Відважне",
      "Відродження",
      "Ворон",
      "Восточне",
      "Вузлове",
      "Георгіївка",
      "Герценберг",
      "Громівка",
      "Грушівка",
      "Деппар-Юрт",
      "Добролюбовка",
      "Дятлівка",
      "Жемчужина Криму",
      "Журавки",
      "Золоте Поле",
      "Ізюмівка",
      "Іслям-Терек",
      "Ічкі",
      "Кайгадор",
      "Кайнаш",
      "Киянли",
      "Коктебель",
      "Коломенське",
      "Корніївка",
      "Краснокам’янка",
      "Кринички",
      "Лебединка",
      "Лісне",
      "Лохівка",
      "Лучове",
      "Льговське",
      "Маківка",
      "Маківське",
      "Маркове",
      "Матросівка",
      "Миндальне",
      "Міжріччя",
      "Морське",
      "Надежда",
      "Нанікове",
      "Насипне",
      "Новий Світ",
      "Новий Цюріхталь",
      "Оріхівка",
      "Партизани",
      "Перевалівка",
      "Приморський",
      "Присивашне",
      "Пруди",
      "Пчільники",
      "Ровенка",
      "Синицине",
      "Сонячна Долина",
      "Спасівка",
      "Старий Крим",
      "Судак",
      "Токарєве",
      "Тутівка",
      "Феодосія",
      "Холодівка",
      "Черкез-Тобай",
      "Чорноземне",
      "Шахтине",
      "Шубине",
      "Щебетовка",
      "Алупка",
      "Алушта",
      "Верхня Кутузовка",
      "Виноградний",
      "Високогірне",
      "Гаспра",
      "Генеральське",
      "Гірне",
      "Голуба Затока",
      "Гурзуф",
      "Данилівка",
      "Долосси",
      "Запрудне",
      "Зеленогір’я",
      "Ісар",
      "Кацівелі",
      "Кипарисне",
      "Кореїз",
      "Курпати",
      "Лаванда",
      "Лаврове",
      "Лазурне",
      "Лівадія",
      "Лучисте",
      "Малий Маяк",
      "Малоріченське",
      "Масандра",
      "Нижнє Запрудне",
      "Нижня Кутузовка",
      "Нікіта",
      "Олива",
      "Оползневе",
      "Ореанда",
      "Охотниче",
      "Паркове",
      "Партеніт",
      "Понизівка",
      "Рибаче",
      "Розовий",
      "Санаторне",
      "Семидвір’я",
      "Сімеїз",
      "Сонячногірське",
      "Утьос",
      "Форос",
      "Чайка",
      "Ялта",
    ],
  },
  {
    region: "Луганська",
    cities: [
      "Адріанопіль",
      "Алмазна",
      "Алчевськ",
      "Байрачки",
      "Бердянка",
      "Березівське",
      "Біле",
      "Богданівка",
      "Боржиківка",
      "Брянка",
      "Бугаївка",
      "Вергулівка",
      "Веселогорівка",
      "Весняне",
      "Ганнівка",
      "Глибокий",
      "Говоруха",
      "Голубівка",
      "Голубівське",
      "Городище",
      "Городнє",
      "Дачне",
      "Депрерадівка",
      "Довге",
      "Донецький",
      "Замостя",
      "Зарічне",
      "Зимогір’я",
      "Знам’янка",
      "Зоринськ",
      "Іванівське",
      "Ірміно",
      "Кадіївка",
      "Калинове",
      "Калинове-Борщувате",
      "Кам’янка",
      "Карпати",
      "Кипуче",
      "Комісарівка",
      "Красний Лиман",
      "Криворіжжя",
      "Криничанське",
      "Криничне",
      "Круглик",
      "Лозівський",
      "Ломуватка",
      "Малоіванівка",
      "Малокостянтинівка",
      "Мамушеве",
      "Михайлівка",
      "Міус",
      "Молодіжне",
      "Надарівка",
      "Новий",
      "Новогригорівка",
      "Новодачне",
      "Новоселівка",
      "Оленівка",
      "Пахалівка",
      "Первомайськ",
      "Перевальськ",
      "Петрівка",
      "Петровеньки",
      "Польове",
      "Пришиб",
      "Родакове",
      "Селезнівка",
      "Селезнівське",
      "Сентянівка",
      "Слов’яносербськ",
      "Сміле",
      "Софіївка",
      "Старе",
      "Степанівка",
      "Степове",
      "Суходіл",
      "Тавричанське",
      "Тімірязєве",
      "Троїцьке",
      "Уткине",
      "Фащівка",
      "Хороше",
      "Центральний",
      "Червоний Лиман",
      "Чорногорівка",
      "Чорнухине",
      "Южна Ломуватка",
      "Юр’ївка",
      "Яснодольськ",
      "Ящикове",
      "Ананьївка",
      "Антракоп",
      "Астахове",
      "Батир",
      "Берегове",
      "Березівка",
      "Біленьке",
      "Білоскелювате",
      "Бобриківка",
      "Братське",
      "Вальянівське",
      "Валянівськ",
      "Ведмеже",
      "Великий Суходіл",
      "Великокам’янка",
      "Верхньогарасимівка",
      "Верхньодеревечка",
      "Верхньотузлове",
      "Верхньошевирівка",
      "Власівка",
      "Водоток",
      "Вознесенівка",
      "Габун",
      "Давидо-Микільське",
      "Дар’ївка",
      "Дар’їно-Єрмаківка",
      "Довжанськ",
      "Довжанське",
      "Дружне",
      "Дубове",
      "Західний",
      "Зеленопілля",
      "Зимівники",
      "Іванівка",
      "Іващенко",
      "Ізварине",
      "Іллівка",
      "Калинівка",
      "Калинник",
      "Карпове-Кріпенське",
      "Киселеве",
      "Кленовий",
      "Кондрюче",
      "Коробкине",
      "Королівка",
      "Краснодарський",
      "Кружилівка",
      "Кундрюче",
      "Куряче",
      "Липове",
      "Любиме",
      "Макарів Яр",
      "Малий Суходіл",
      "Маловедмеже",
      "Малокалинове",
      "Мар’ївка",
      "Матвіївка",
      "Маяк",
      "Медвежанка",
      "Микишівка",
      "Миколаївка",
      "Нагірне",
      "Нагольно-Тарасівка",
      "Нижньодеревечка",
      "Нижня Гарасимівка",
      "Нижня Шевирівка",
      "Новоборовиці",
      "Новодар’ївка",
      "Новокиївка",
      "Огульчанськ",
      "Олександрівка",
      "Павлівка",
      "Пантеліївка",
      "Панченкове",
      "Підгірне",
      "Покровка",
      "Попівка",
      "Поріччя",
      "Провалля",
      "Прохладне",
      "Радісне",
      "Ритикове",
      "Світличне",
      "Сєверний",
      "Сєверо-Гундорівський",
      "Сорокине",
      "Суходільськ",
      "Тепле",
      "Урало-Кавказ",
      "Устинівка",
      "Федорівка",
      "Хмельницький",
      "Хорошилове",
      "Хрящівка",
      "Черемшине",
      "Шахтарське",
      "Андріївка",
      "Білоріченський",
      "Бокове",
      "Буран",
      "Бурчак-Михайлівка",
      "Валіївка",
      "Велика Мартинівка",
      "Великий Лог",
      "Верхня Краснянка",
      "Верхня Оріхівка",
      "Весела Гора",
      "Весела Тарасівка",
      "Видно-Софіївка",
      "Вишневий Діл",
      "Волнухине",
      "Врубівський",
      "Гайове",
      "Георгіївка",
      "Гірне",
      "Глафірівка",
      "Глибоке",
      "Горіхова Балка",
      "Дубівка",
      "Жовте",
      "Західне",
      "Збірне",
      "Земляне",
      "Зразкове",
      "Іллірія",
      "Кам’яний Пласт",
      "Катеринівка",
      "Ключове",
      "Комишуваха",
      "Красне",
      "Красний Яр",
      "Крута Гора",
      "Лиман",
      "Лисе",
      "Лісне",
      "Лобачеве",
      "Луганськ",
      "Лутугине",
      "Македонівка",
      "Мала Мартинівка",
      "Мала Юр’ївка",
      "Марія",
      "Металіст",
      "Мирне",
      "Молодогвардійськ",
      "Новобулахівка",
      "Новоганнівка",
      "Новоолександрівка",
      "Новопавлівка",
      "Новосвітлівка",
      "Новосімейкіне",
      "Новофедорівка",
      "Обозне",
      "Олександрівськ",
      "Оріхівка",
      "П’ятигорівка",
      "Паліївка",
      "Паньківка",
      "Переможне",
      "Першозванівка",
      "Петро-Миколаївка",
      "Привітне",
      "Придорожне",
      "Раївка",
      "Розкішне",
      "Сабівка",
      "Самсонівка",
      "Світле",
      "Сімейкине",
      "Стукалова Балка",
      "Талове",
      "Тепличне",
      "Тернове",
      "Успенка",
      "Ушаківка",
      "Фабричне",
      "Христове",
      "Хрящувате",
      "Цвітні Піски",
      "Челюскінець",
      "Шимшинівка",
      "Широке",
      "Шишкове",
      "Шовкова Протока",
      "Ясне",
      "Антрацит",
      "Баштевич",
      "Благівка",
      "Бобрикове",
      "Боково-Платове",
      "Боково-Хрустальне",
      "Буткевич",
      "Вербівка",
      "Вергулівське",
      "Верхній Нагольчик",
      "Вишневе",
      "Воскресенівка",
      "Гірник",
      "Грибуваха",
      "Грушове",
      "Давидівка",
      "Дубівський",
      "Дякове",
      "Єгорівка",
      "Єлизаветівка",
      "Єсаулівка",
      "Жереб’яче",
      "Залізничне",
      "Запоріжжя",
      "Зелений Гай",
      "Зелений Курган",
      "Зеленодільське",
      "Іллінка",
      "Індустрія",
      "Кам’яне",
      "Картушине",
      "Клуникове",
      "Княгинівка",
      "Ковильне",
      "Ковпакове",
      "Козаківка",
      "Комендантське",
      "Корінне",
      "Кошари",
      "Красний Кут",
      "Краснолуцький",
      "Кріпенський",
      "Курган",
      "Леонове",
      "Леськине",
      "Лобівські Копальні",
      "Лози",
      "Любимівка",
      "Маломиколаївка",
      "Мельникове",
      "Мечетка",
      "Микитівка",
      "Міусинськ",
      "Нижній Нагольчик",
      "Новоєлизаветівка",
      "Новокраснівка",
      "Новоукраїнка",
      "Оріхове",
      "Орлівське",
      "Петрово-Красносілля",
      "Платонівка",
      "Рафайлівка",
      "Ребрикове",
      "Ровеньки",
      "Садовий",
      "Садово-Хрустальненський",
      "Сонячне",
      "Софіївський",
      "Тамара",
      "Тацине",
      "Трубний",
      "Улянівка",
      "Урожайне",
      "Христофорівка",
      "Хрустальне",
      "Хрустальний",
      "Червона Поляна",
      "Широкий",
      "Штерівка",
      "Щотове",
      "Ясенівський",
      "Аношкине",
      "Арапівка",
      "Бабичеве",
      "Багачка",
      "Бараниківка",
      "Бараничеве",
      "Барикине",
      "Білокуракине",
      "Богородицьке",
      "Бунчуківка",
      "Верхня Дуванка",
      "Вестатівка",
      "Високе",
      "Вівчарове",
      "Вільшани",
      "Воєводське",
      "Володимирівка",
      "Гладкове",
      "Глотівка",
      "Головкове",
      "Гончарівка",
      "Греківка",
      "Грицаївка",
      "Дем’янівка",
      "Демино-Олександрівка",
      "Джерельне",
      "Дуванка",
      "Жовтневе",
      "Заводянка",
      "Загір’я",
      "Заїківка",
      "Зайцеве",
      "Залиман",
      "Зміївка",
      "Калинівське",
      "Калинова Балка",
      "Караїчне",
      "Кармазинівка",
      "Картамишеве",
      "Кашкарне",
      "Климівка",
      "Клинуватка",
      "Ковалівка",
      "Козарик",
      "Коломийчиха",
      "Коноплянівка",
      "Коржове",
      "Коченове",
      "Кочине-Розпасіївка",
      "Кошелівка",
      "Красногригорівка",
      "Красноріченське",
      "Кривошиївка",
      "Кругле",
      "Куземівка",
      "Куликівка",
      "Куплювате",
      "Курячівка",
      "Лагідне",
      "Лантратівка",
      "Лебедівка",
      "Лизине",
      "Лозне",
      "Лозно-Олександрівка",
      "Луб’янка",
      "Лугове",
      "Ляшківка",
      "М’ясожарівка",
      "Макіївка",
      "Максимівка",
      "Малоолександрівка",
      "Маньківка",
      "Маслакове",
      "Маслівка",
      "Мілуватка",
      "Містки",
      "Надія",
      "Наугольне",
      "Невське",
      "Нежурине",
      "Нещеретове",
      "Нижня Дуванка",
      "Новоандріївка",
      "Нововодяне",
      "Новоєгорівка",
      "Новознам’янка",
      "Новолюбівка",
      "Новомикільське",
      "Новониканорівка",
      "Новопокровка",
      "Новопреображенне",
      "Новоселівське",
      "Новочервоне",
      "Нянчине",
      "Оборотнівка",
      "Озеро",
      "Олександропіль",
      "Олексапіль",
      "Олексіївка",
      "Осикове",
      "Паталахівка",
      "Пилипівка",
      "Підкуйчанськ",
      "Плахо-Петрівка",
      "Площанка",
      "Покровське",
      "Полтава",
      "Полтавське",
      "Попасне",
      "Преображенне",
      "Привілля",
      "Приходьківка",
      "Промінь",
      "Просторе",
      "Райгородка",
      "Роднички",
      "Розпасіївка",
      "Розсипне",
      "Романівка",
      "Рудівка",
      "Рудове",
      "Сватове",
      "Свистунівка",
      "Світленьке",
      "Сергіївка",
      "Сиротине",
      "Солідарне",
      "Солонці",
      "Сосновий",
      "Стативчине",
      "Стельмахівка",
      "Сторожівка",
      "Судьбинка",
      "Тарасівка",
      "Твердохлібове",
      "Тимонове",
      "Тимошине",
      "Тополі",
      "Травневе",
      "Трудродительське",
      "Федосіївка",
      "Хоменкове Друге",
      "Хоменкове Перше",
      "Хомівка",
      "Царівка",
      "Цілуйкове",
      "Чабанове",
      "Чапліївка",
      "Чепигівка",
      "Шапарівка",
      "Шарівка",
      "Шатківка",
      "Шахове",
      "Шовкунівка",
      "Яблунівка",
      "Ями",
      "Біла Гора",
      "Білогорівка",
      "Боброве",
      "Борівське",
      "Боровеньки",
      "Булгаківка",
      "Варварівка",
      "Верхньокам’янка",
      "Вискрива",
      "Вікторівка",
      "Вовчоярівка",
      "Воєводівка",
      "Воронове",
      "Врубівка",
      "Гаврилівка",
      "Гірське",
      "Глинокар’єр",
      "Голикове",
      "Діброва",
      "Дружба",
      "Єпіфанівка",
      "Житлівка",
      "Жолобок",
      "Затишне",
      "Золотарівка",
      "Золоте",
      "Кремінна",
      "Кримське",
      "Крутеньке",
      "Кудряшівка",
      "Кузьмине",
      "Лисичанськ",
      "Лисичанський",
      "Лісна Дача",
      "Лоскутівка",
      "Малорязанцеве",
      "Метьолкіне",
      "Мирна Долина",
      "Нижнє",
      "Нижній Суходіл",
      "Ниркове",
      "Нова Астрахань",
      "Новодружеськ",
      "Новозванівка",
      "Новоіванівка",
      "Новокраснянка",
      "Новотошківське",
      "Олександропілля",
      "Осколонівка",
      "Павлоград",
      "Півневе",
      "Підлісне",
      "Піщане",
      "Попасна",
      "Пристине",
      "Причепилівка",
      "Прогрес",
      "Пурдівка",
      "Пшеничне",
      "Рай-Олександрівка",
      "Рубіжне",
      "Сєвєродонецьк",
      "Синецький",
      "Скаргівка",
      "Смолянинове",
      "Сокільники",
      "Стара Краснянка",
      "Суровцівка",
      "Тополівка",
      "Тошківка",
      "Чабанівка",
      "Червонопопівка",
      "Шевченко",
      "Шипилівка",
      "Антонівка",
      "Байдівка",
      "Балакирівка",
      "Березове",
      "Біловодськ",
      "Білолуцьк",
      "Благодатне",
      "Бондареве",
      "Бондарівка",
      "Бондарне",
      "Брусівка",
      "Булавинівка",
      "Бутківка",
      "Бутове",
      "Валуйки",
      "Великоцьк",
      "Верхня Покровка",
      "Веселе",
      "Виноградне",
      "Височинівка",
      "Вітрогон",
      "Водянолипове",
      "Ганусівка",
      "Гармашівка",
      "Гераськівка",
      "Гераськівське",
      "Гончарове",
      "Данилівка",
      "Деркулове",
      "Джемільне",
      "Донцівка",
      "Дубовівка",
      "Євсуг",
      "Журавське",
      "Заайдарівка",
      "Закотне",
      "Залісне",
      "Запорізьке",
      "Зелеківка",
      "Зориківка",
      "Зоринівка",
      "Ікове",
      "Кабичівка",
      "Калмиківка",
      "Караван-Солодкий",
      "Караяшник",
      "Каськівка",
      "Кирносове",
      "Козлове",
      "Кононівка",
      "Копані",
      "Костянтинівка",
      "Красне Поле",
      "Крейдяне",
      "Кризьке",
      "Кринички",
      "Крупчанське",
      "Кубань",
      "Левадне",
      "Лимарівка",
      "Лисогорівка",
      "Литвинівка",
      "Литвинове",
      "Лісна Поляна",
      "Лобасове",
      "Лозове",
      "Лозовівка",
      "Лозуватка",
      "Макартетине",
      "Малохатка",
      "Маринівка",
      "Марківка",
      "Марківське",
      "Микільське",
      "Мілове",
      "Можняківка",
      "Морозівка",
      "Мусіївка",
      "Нижньобараниківка",
      "Нижньопокровка",
      "Нова Україна",
      "Новоастраханське",
      "Новобіла",
      "Новоборове",
      "Новодеркул",
      "Новодонбаське",
      "Новолимарівка",
      "Новоомелькове",
      "Новопсков",
      "Новорозсош",
      "Новоспасівка",
      "Новострільцівка",
      "Ноздрівка",
      "Омелькове",
      "Орлівка",
      "Осинове",
      "Павленкове",
      "Пантюхине",
      "Парневе",
      "Пелагіївка",
      "Первомайське",
      "Петрівське",
      "Писарівка",
      "Півнівка",
      "Підгорівка",
      "Піски",
      "Плугатар",
      "Половинкине",
      "Привільне",
      "Проїждже",
      "Проказине",
      "Просяне",
      "Рання Зоря",
      "Риб’янцеве",
      "Рогове",
      "Роздолля",
      "Роздольне",
      "Розсохувате",
      "Садки",
      "Семикозівка",
      "Сенькове",
      "Синельникове",
      "Сичанське",
      "Сичівка",
      "Скородна",
      "Солоне",
      "Соснівка",
      "Старобільськ",
      "Степне",
      "Стрільцівка",
      "Суханівка",
      "Танюшівка",
      "Тарабани",
      "Тев’яшеве",
      "Тернівка",
      "Тецьке",
      "Титарівка",
      "Тишківка",
      "Трембачеве",
      "Третяківка",
      "Узлісся",
      "Фартуківка",
      "Федчине",
      "Хворостяне",
      "Хворостянівка",
      "Хоминське",
      "Чмирівка",
      "Шапран",
      "Шелестівка",
      "Шпотине",
      "Шуліківка",
      "Шульгинка",
      "Ярське",
      "Яснопромінське",
      "Айдар-Миколаївка",
      "Артема",
      "Бахмутівка",
      "Безгинове",
      "Благовіщенка",
      "Болотене",
      "Валуйське",
      "Велика Чернігівка",
      "Верхній Мінченок",
      "Верхньобогданівка",
      "Верхня Вільхова",
      "Вільне",
      "Вільхове",
      "Вовкодаєве",
      "Войтове",
      "Гарасимівка",
      "Геївка",
      "Гречишкине",
      "Деменкове",
      "Денежникове",
      "Деркульське",
      "Дмитрівка",
      "Капітанове",
      "Ковпаки",
      "Козачий",
      "Колесниківка",
      "Колядівка",
      "Комишне",
      "Красна Талівка",
      "Красний Деркул",
      "Крепи",
      "Кряківка",
      "Лопаскине",
      "Макарове",
      "Малинове",
      "Маловенделівка",
      "Михайлюки",
      "Муратове",
      "Нижній Мінченок",
      "Нижньотепле",
      "Нижня Вільхова",
      "Новоайдар",
      "Новоохтирка",
      "Окнине",
      "Оріхове-Донецьке",
      "Передільське",
      "Петренкове",
      "Петропавлівка",
      "Плотина",
      "Побєда",
      "Путилине",
      "Розквіт",
      "Середньотепле",
      "Сизе",
      "Сотенне",
      "Співаківка",
      "Станиця Луганська",
      "Старий Айдар",
      "Степний Яр",
      "Трудове",
      "Трьохізбенка",
      "Чистопілля",
      "Чугинка",
      "Штормове",
      "Щастя",
      "Юганівка",
    ],
  },
  {
    region: "Львівська",
    cities: [
      "Биків",
      "Бистрий",
      "Бистриця",
      "Бистриця-Гірська",
      "Бійничі",
      "Болехівці",
      "Борислав",
      "Брониця",
      "Верхні Гаї",
      "Верхній Дорожів",
      "Винники",
      "Волоща",
      "Воля Якубова",
      "Вороблевичі",
      "Глинне",
      "Головське",
      "Городківка",
      "Грушів",
      "Гута",
      "Далява",
      "Дережичі",
      "Добрівляни",
      "Доброгостів",
      "Довге",
      "Долішній Лужок",
      "Дрогобич",
      "Жданівка",
      "Зади",
      "Залокоть",
      "Залужани",
      "Зимівки",
      "Зубриця",
      "Коритище",
      "Коросниця",
      "Котоване",
      "Кринтята",
      "Ластівка",
      "Летня",
      "Літиня",
      "Лішня",
      "Майдан",
      "Медвежа",
      "Меденичі",
      "Михайлевичі",
      "Модричі",
      "Мокряни",
      "Монастир-Дережицький",
      "Монастир-Лішнянський",
      "Нагуєвичі",
      "Нижні Гаї",
      "Нове Село",
      "Новий Кропивник",
      "Новошичі",
      "Опака",
      "Опори",
      "Орів",
      "Ортиничі",
      "Перепростиня",
      "Підбуж",
      "Підмонастирок",
      "Підсухе",
      "Попелі",
      "Почаєвичі",
      "Раневичі",
      "Рибник",
      "Рихтичі",
      "Рівне",
      "Ріпчиці",
      "Ролів",
      "Свидник",
      "Селець",
      "Смільна",
      "Снятинка",
      "Солонське",
      "Станиля",
      "Старе Село",
      "Старий Кропивник",
      "Стебник",
      "Сторона",
      "Ступниця",
      "Східниця",
      "Тинів",
      "Трускавець",
      "Уличне",
      "Унятичі",
      "Уріж",
      "Хатки",
      "Ясениця-Сільна",
      "Андріївка",
      "Антося",
      "Бажани",
      "Баймаки",
      "Балучин",
      "Батьків",
      "Бачка",
      "Безброди",
      "Берлин",
      "Бібщани",
      "Білий Камінь",
      "Білявці",
      "Бір",
      "Бовдури",
      "Богданівка",
      "Богутин",
      "Боложинів",
      "Бонишин",
      "Боратин",
      "Бордуляки",
      "Бортків",
      "Брахівка",
      "Броди",
      "Будиголош",
      "Бужок",
      "Буськ",
      "Бучина",
      "Велика Вільшаниця",
      "Великі Переліски",
      "Велин",
      "Вербівчик",
      "Вербляни",
      "Верхобуж",
      "Видра",
      "Висоцько",
      "Вовковатиця",
      "Волиця-Деревлянська",
      "Волуйки",
      "Вороняки",
      "Гавареччина",
      "Гаї",
      "Гаївське",
      "Гаї-Дітковецькі",
      "Гаї-Смоленські",
      "Гаї-Суходільські",
      "Глушин",
      "Гологірки",
      "Гологори",
      "Голосковичі",
      "Голубиця",
      "Гончарівка",
      "Горбалі",
      "Горбанівка",
      "Горбачі",
      "Городилів",
      "Грабина",
      "Грабова",
      "Грабово",
      "Грималівка",
      "Гумниська",
      "Гутисько-Тур’янське",
      "Гутище",
      "Дерев’янки",
      "Дітківці",
      "Дуб’є",
      "Дубина",
      "Дудин",
      "Думниця",
      "Дунів",
      "Єлиховичі",
      "Жарків",
      "Жуків",
      "Жуличі",
      "Журатин",
      "Заболотне",
      "Заболото",
      "Заболотці",
      "Забрід",
      "Заводське",
      "Загірці",
      "Загора",
      "Задвір’я",
      "Закомар’я",
      "Залісся",
      "Зарваниця",
      "Застав’є",
      "Зашків",
      "Збруї",
      "Звижень",
      "Зозулі",
      "Золочів",
      "Золочівка",
      "Йосипівка",
      "Кам’янисте",
      "Кийків",
      "Кіз’я",
      "Кізлів",
      "Клекотів",
      "Княже",
      "Кобилеччина",
      "Ковпин Ставок",
      "Козаки",
      "Колтів",
      "Комарівка",
      "Конюшків",
      "Копані",
      "Королівка",
      "Коропець",
      "Коропчик",
      "Корсів",
      "Косарщина",
      "Красне",
      "Красносільці",
      "Кропивна",
      "Кругів",
      "Кудирявці",
      "Кульби",
      "Купче",
      "Кути",
      "Кутище",
      "Куткір",
      "Лабач",
      "Лагодів",
      "Ланерівка",
      "Леньків",
      "Лешнів",
      "Липина",
      "Лісове",
      "Лісові",
      "Лісок",
      "Літовище",
      "Луг",
      "Лугове",
      "Лука",
      "Лукавець",
      "Лукаші",
      "Лучківці",
      "Майдан-Гологірський",
      "Мала Вільшанка",
      "Малинище",
      "Малі Переліски",
      "Мамчурі",
      "Маращанка",
      "Маркопіль",
      "Махнівці",
      "Межигори",
      "Микити",
      "Митниця",
      "Митулин",
      "Мідне",
      "Монастирок",
      "Надільне",
      "Накваша",
      "Нем’яч",
      "Нестюки",
      "Ниви",
      "Новий Милятин",
      "Новий Ріпнів",
      "Новичина",
      "Новоселище",
      "Новосілки",
      "Обертасів",
      "Ожидів",
      "Олесько",
      "Опаки",
      "Орани",
      "Орихівчик",
      "Осовиця",
      "Острів",
      "Острівчик-Пильний",
      "Павлики",
      "Паликорови",
      "Паньківці",
      "Панькова",
      "Папірня",
      "Пеняки",
      "Переволочна",
      "Переліски",
      "Петричі",
      "Підгір’я",
      "Підгірці",
      "Підгородне",
      "Підкамінь",
      "Підлипці",
      "Підлисся",
      "Підставки",
      "Піски",
      "Пісок",
      "Плугів",
      "Побіч",
      "Побужани",
      "Полоничі",
      "Полтва",
      "Поляни",
      "Поморяни",
      "Пониква",
      "Пониковиця",
      "Попівці",
      "Почапи",
      "Ражнів",
      "Ракобовти",
      "Ремезівці",
      "Рижани",
      "Ріпнів",
      "Розваж",
      "Роздоріжне",
      "Рокитне",
      "Руда",
      "Руда-Брідська",
      "Руда-Колтівська",
      "Русилів",
      "Салашка",
      "Сасів",
      "Сидинівка",
      "Сидори",
      "Скварява",
      "Скнилів",
      "Смільне",
      "Сновичі",
      "Соколівка",
      "Соколя",
      "Стадня",
      "Станіславчик",
      "Старий Милятин",
      "Стиборівка",
      "Стінка",
      "Стовпин",
      "Сторонибаби",
      "Стрихалюки",
      "Струтин",
      "Суховоля",
      "Суходоли",
      "Сухота",
      "Теребежі",
      "Тетильківці",
      "Топорів",
      "Торгів",
      "Тріщуки",
      "Тростянець",
      "Трудовач",
      "Тур’я",
      "Утішків",
      "Ушня",
      "Хватів",
      "Хильчиці",
      "Хмелева",
      "Хомець",
      "Циків",
      "Чаниж",
      "Чепелі",
      "Червоне",
      "Черемошня",
      "Черниця",
      "Чижів",
      "Чішки",
      "Чучмани",
      "Шишківці",
      "Шнирів",
      "Шпаки",
      "Шпиколоси",
      "Яблунівка",
      "Язлівчик",
      "Янгелівка",
      "Ясенів",
      "Ясенівці",
      "Яснище",
      "Андріянів",
      "Артасів",
      "Артищів",
      "Бабії",
      "Баківці",
      "Банюнин",
      "Бар",
      "Бартатів",
      "Басівка",
      "Батятичі",
      "Бачів",
      "Береги",
      "Бережани",
      "Березець",
      "Березина",
      "Бертишів",
      "Бесіди",
      "Бишків",
      "Бібрка",
      "Біле",
      "Білка",
      "Бірки",
      "Бір-Кунинський",
      "Бірче",
      "Благодатівка",
      "Блищиводи",
      "Боброїди",
      "Болотня",
      "Борове",
      "Борові",
      "Боршів",
      "Борщовичі",
      "Братковичі",
      "Брикун",
      "Брюховичі",
      "Буди",
      "Будьків",
      "Бучали",
      "Бучми",
      "В’язова",
      "Велика Калинка",
      "Велике Колодно",
      "Велике Передмістя",
      "Великий Дорошів",
      "Великий Любінь",
      "Великий Полюхів",
      "Великі Глібовичі",
      "Великі Грибовичі",
      "Великі Долини",
      "Великі Передримихи",
      "Великі Підліски",
      "Великосілки",
      "Верени",
      "Верхня Білка",
      "Вижняни",
      "Виннички",
      "Виписки",
      "Вирів",
      "Вислобоки",
      "Високофедорівка",
      "Вихопні",
      "Вишнівчик",
      "Відники",
      "Віднів",
      "Відродження",
      "Вільховець",
      "Вільшанка",
      "Вілявче",
      "Віняви",
      "Віхті",
      "Вовків",
      "Вовчухи",
      "Волиця",
      "Волове",
      "Волощина",
      "Воля-Бартатівська",
      "Воля-Висоцька",
      "Воля-Гомулецька",
      "Воля-Жовтанецька",
      "Гайок",
      "Галасі",
      "Галичани",
      "Гамаліївка",
      "Ганачівка",
      "Гійче",
      "Гіркани",
      "Гірки",
      "Глинськ",
      "Глиняни",
      "Глібовичі",
      "Глуховичі",
      "Годвишня",
      "Годовиця",
      "Голокам’янка",
      "Гончари",
      "Гончарів",
      "Гори",
      "Горішній",
      "Городжів",
      "Городиславичі",
      "Городок",
      "Горпин",
      "Горяни",
      "Грабине",
      "Грабник",
      "Грабовець",
      "Градівка",
      "Гребінці",
      "Гринів",
      "Гринчуки",
      "Грімне",
      "Грушка",
      "Гряда",
      "Гуменець",
      "Гуральня",
      "Гута Обединська",
      "Давидів",
      "Дальнич",
      "Дев’ятир",
      "Деревач",
      "Деревляни",
      "Деревня",
      "Дернів",
      "Дернівка",
      "Диб’янки",
      "Дібрівки",
      "Діброва",
      "Дідилів",
      "Дмитре",
      "Дмитровичі",
      "Добросин",
      "Добряни",
      "Добряничі",
      "Долиняни",
      "Дроздовичі",
      "Дуб’янка",
      "Дубаневичі",
      "Дубляни",
      "Дубрівка",
      "Думи",
      "Думичі",
      "Дунаїв",
      "Дусанів",
      "Желдець",
      "Женів",
      "Жирівка",
      "Жовква",
      "Жовтанці",
      "Журавники",
      "Забір’я",
      "Заболоття",
      "Забужжя",
      "Завади",
      "Завадів",
      "Заверешиця",
      "Завидовичі",
      "Загір’я",
      "Задубина",
      "Закривець",
      "Залози",
      "Залужжя",
      "Замок",
      "Замочок",
      "Запитів",
      "Зарище",
      "Зарудці",
      "Заставки-Яблунів",
      "Заставне",
      "Затемне",
      "Зашковичі",
      "Збиранка",
      "Звенигород",
      "Звертів",
      "Зелена Гута",
      "Зелений Гай",
      "Зимна Вода",
      "Зіболки",
      "Зубейки",
      "Зубів Міст",
      "Зубра",
      "Зушиці",
      "Іванівка",
      "Йоничі",
      "Казумин",
      "Кам’яна Гора",
      "Кам’янка-Бузька",
      "Кам’янопіль",
      "Камінна",
      "Капелюх",
      "Катериничі",
      "Качмарі",
      "Квітневе",
      "Керниця",
      "Кимир",
      "Кіпті",
      "Клебани",
      "Кліцько",
      "Кнісело",
      "Ков’ярі",
      "Ковалі",
      "Козулька",
      "Кологори",
      "Колоденці",
      "Комарно",
      "Конопниця",
      "Копанка",
      "Копань",
      "Кореличі",
      "Коропуж",
      "Коросно",
      "Косичі",
      "Косівець",
      "Костеїв",
      "Костенів",
      "Коцурів",
      "Кошелів",
      "Красічин",
      "Крехів",
      "Криве",
      "Кривичі",
      "Кропи",
      "Кротошин",
      "Крута Долина",
      "Кугаїв",
      "Кузубатиця",
      "Кукезів",
      "Куликів",
      "Кулиничі",
      "Кулява",
      "Кунин",
      "Курний",
      "Куровичі",
      "Кучерівка",
      "Лавриків",
      "Ладанці",
      "Лани",
      "Лапаївка",
      "Липівці",
      "Липник",
      "Липники",
      "Лисиничі",
      "Литовка",
      "Лівчиці",
      "Лінія",
      "Лісновичі",
      "Лоні",
      "Лонівка",
      "Лопушна",
      "Лосини",
      "Лужки",
      "Луцики",
      "Лущики",
      "Львів",
      "Льопи",
      "Любеля",
      "Любешка",
      "Любовичі",
      "Мавдрики",
      "Мавковичі",
      "Магерів",
      "Мазів",
      "Мазярка",
      "Малехів",
      "Малечковичі",
      "Малий",
      "Малий Дорошів",
      "Малий Любінь",
      "Малий Полюхів",
      "Малинівка",
      "Малі Грибовичі",
      "Малі Долини",
      "Малі Ланки",
      "Малі Нагірці",
      "Малі Передримихи",
      "Малі Підліски",
      "Мальованка",
      "Мацошин",
      "Мервичі",
      "Мерещів",
      "Мивсева",
      "Миклашів",
      "Миколаїв",
      "Милошевичі",
      "Мильчиці",
      "Миляво",
      "Милятин",
      "Милятичі",
      "Містки",
      "Могиляни",
      "Мокротин",
      "Молошки",
      "Монастирець",
      "Мости",
      "Мостище",
      "Мощана",
      "Муроване",
      "Мшана",
      "Наварія",
      "Нагірці",
      "Нагоряни",
      "Надичі",
      "Неділиська",
      "Неслухів",
      "Нижня Білка",
      "Никонковичі",
      "Нова Кам’янка",
      "Нова Лодина",
      "Нова Скварява",
      "Новий Став",
      "Новий Яричів",
      "Нові Стрілища",
      "Новосілка",
      "Обидів",
      "Оброшине",
      "Одиноке",
      "Окопи",
      "Оліярники",
      "Оплітна",
      "Орішківці",
      "Осталовичі",
      "П’ятничани",
      "Паланики",
      "Панчищини",
      "Пасіки-Зубрицькі",
      "Перегноїв",
      "Перемивки",
      "Перемишляни",
      "Переможне",
      "Печенія",
      "Печихвости",
      "Пикуловичі",
      "Пили",
      "Пирятин",
      "Під’ярків",
      "Підберізці",
      "Підбірці",
      "Підвисоке",
      "Підгайці",
      "Підгайчики",
      "Підгірне",
      "Підгородище",
      "Піддеревенка",
      "Підзвіринець",
      "Підлісся",
      "Підмогилка",
      "Підмонастир",
      "Підрясне",
      "Підсадки",
      "Підсмереки",
      "Підсоснів",
      "Підтемне",
      "Пільце",
      "Плеників",
      "Плетеничі",
      "Плоска",
      "Пнятин",
      "Побережне",
      "Повітно",
      "Погарисько",
      "Погорільці",
      "Подусів",
      "Подусільна",
      "Поляна",
      "Полянка",
      "Помлинів",
      "Попеляни",
      "Поріччя",
      "Поріччя Задвірне",
      "Поріччя-Грунтове",
      "Поршна",
      "Потелич",
      "Прибинь",
      "Прибужани",
      "Пришляки",
      "Пустомити",
      "Путятичі",
      "Рава-Руська",
      "Равське",
      "Раковець",
      "Рата",
      "Ременів",
      "Репехів",
      "Речичани",
      "Рівна",
      "Річки",
      "Родатичі",
      "Рожанка",
      "Розворяни",
      "Розсохи",
      "Романів",
      "Романівка",
      "Рубче",
      "Руда-Крехівська",
      "Руданці",
      "Руда-Сілецька",
      "Рудне",
      "Рясне-Руське",
      "Сапіжанка",
      "Сарнівка",
      "Свірж",
      "Селиська",
      "Селисько",
      "Семенівка",
      "Сенів",
      "Сердиця",
      "Серники",
      "Сивороги",
      "Синьковичі",
      "Ситихів",
      "Словіта",
      "Смереків",
      "Смереківка",
      "Сокіл",
      "Сокільники",
      "Соколів",
      "Солова",
      "Солонка",
      "Сопошин",
      "Сороки",
      "Сороки-Львівські",
      "Сорочі Лози",
      "Соснина",
      "Соснівка",
      "Спас",
      "Ставники",
      "Ставчани",
      "Станимир",
      "Стара Скварява",
      "Старий Яричів",
      "Старі Стрілища",
      "Стоділки",
      "Стоки",
      "Стрептів",
      "Стрілки",
      "Стронятин",
      "Сулимів",
      "Суходіл",
      "Сухоріччя",
      "Тадані",
      "Тарасівка",
      "Татаринів",
      "Тернів",
      "Тернівка",
      "Тершаків",
      "Товмач",
      "Товщів",
      "Трибоківці",
      "Тулиголове",
      "Туринка",
      "Туркотин",
      "Тучапи",
      "Тучне",
      "Убині",
      "Угри",
      "Унів",
      "Утіховичі",
      "Ушковичі",
      "Фійна",
      "Хитрейки",
      "Хишевичі",
      "Ходорківці",
      "Холодновідка",
      "Хомина",
      "Хоросно",
      "Хренів",
      "Цеперів",
      "Цитуля",
      "Чемеринці",
      "Черепин",
      "Черкаси",
      "Черляни",
      "Черлянське Передмістя",
      "Честині",
      "Чижиків",
      "Чистопілля",
      "Чишки",
      "Чорнії",
      "Чорнушовичі",
      "Чуловичі",
      "Чуперносів",
      "Шабельня",
      "Школярі",
      "Шоломиничі",
      "Шоломинь",
      "Шопки",
      "Шпильчина",
      "Шуфраганка",
      "Щирець",
      "Ягідня",
      "Якимів",
      "Якимчиці",
      "Якторів",
      "Ямне",
      "Ямпіль",
      "Яструбків",
      "Бабина",
      "Бабино",
      "Багнувате",
      "Баранівці",
      "Бачина",
      "Беньова",
      "Бережниця",
      "Бережок",
      "Березів",
      "Берестяни",
      "Библо",
      "Биличі",
      "Біличі",
      "Білоки",
      "Бірчиці",
      "Бісковичі",
      "Бітля",
      "Блажів",
      "Боберка",
      "Болозів",
      "Боневичі",
      "Бориня",
      "Боршевичі",
      "Букова",
      "Буковинка",
      "Буньковичі",
      "Бусовисько",
      "Ваньковичі",
      "Ваньовичі",
      "Велика Білина",
      "Велика Волосянка",
      "Велика Лінина",
      "Велика Озимина",
      "Велика Сушиця",
      "Велика Хвороща",
      "Велике",
      "Великосілля",
      "Вербівка",
      "Верхівці",
      "Верхнє",
      "Верхнє Висоцьке",
      "Верхнє Гусне",
      "Верхній Лужок",
      "Верхній Турів",
      "Верхня Яблунька",
      "Викоти",
      "Виців",
      "Вишня",
      "Вільшаник",
      "Вілюничі",
      "Вістовичі",
      "Владипіль",
      "Вовче",
      "Волошиново",
      "Воля",
      "Воля-Баранецька",
      "Воля-Блажівська",
      "Волянка",
      "Вощанці",
      "Воютичі",
      "Галівка",
      "Гвоздець",
      "Глибока",
      "Глибоч",
      "Головецько",
      "Гординя",
      "Городисько",
      "Городище",
      "Городовичі",
      "Грабівниця",
      "Грозьово",
      "Грушатичі",
      "Губичі",
      "Гуманець",
      "Дешичі",
      "Дністрик",
      "Дністрик-Дубовий",
      "Добромиль",
      "Долобів",
      "Жукотин",
      "Завадівка",
      "Завадка",
      "Задністря",
      "Задністряни",
      "Закичера",
      "Закіпці",
      "Зарайське",
      "Заріччя",
      "Засадки",
      "Звір",
      "Зворець",
      "Зоротовичі",
      "Іванів",
      "Івашківці",
      "Ільник",
      "Ісаї",
      "Калинів",
      "Канафости",
      "Карпатське",
      "Катина",
      "Кіндратів",
      "Климівщина",
      "Княжпіль",
      "Кобло",
      "Ковиничі",
      "Колбаєвичі",
      "Колонія",
      "Комарники",
      "Комаровичі",
      "Конів",
      "Конюшки-Королівські",
      "Конюшки-Тулиголівські",
      "Корналовичі",
      "Корничі",
      "Красниця",
      "Кривка",
      "Кропивник",
      "Кружики",
      "Круковець",
      "Кульчиці",
      "Купновичі",
      "Лаврів",
      "Лановичі",
      "Либохора",
      "Лібухова",
      "Ліктів",
      "Лімна",
      "Лопушанка",
      "Лопушанка-Хомина",
      "Лопушниця",
      "Лопушно",
      "Лосинець",
      "Лукавиця",
      "Луки",
      "Лютовиська",
      "Майнич",
      "Максимовичі",
      "Мала Білина",
      "Мала Вербівка",
      "Мала Волосянка",
      "Мала Озимина",
      "Мала Спринька",
      "Мала Хвороща",
      "Малинів",
      "Малі Баранівці",
      "Межигір’я",
      "Мельничне",
      "Мігово",
      "Міжгайці",
      "Міженець",
      "Містковичі",
      "Млин",
      "Морозовичі",
      "Мшанець",
      "Нагірне",
      "Надиби",
      "Недільна",
      "Нижанковичі",
      "Нижнє",
      "Нижнє Висоцьке",
      "Нижнє Гусне",
      "Нижній Турів",
      "Нижня Вовча",
      "Нижня Яблунька",
      "Никловичі",
      "Нове Місто",
      "Новий Калинів",
      "Новий Острів",
      "Нові Бірчиці",
      "Новосілки-Гостинні",
      "Орховичі",
      "П’яновичі",
      "П’ятниця",
      "Павлівка",
      "Пацьковичі",
      "Передільниця",
      "Пиняни",
      "Підмостичі",
      "Плоске",
      "Погірці",
      "Подільці",
      "Посада-Новоміська",
      "Потік",
      "Присліп",
      "Радич",
      "Райнова",
      "Ракова",
      "Ралівка",
      "Риків",
      "Ріп’яна",
      "Рогізно",
      "Рожеве",
      "Роздільне",
      "Розлуч",
      "Ропавське",
      "Росохи",
      "Рудки",
      "Рудня",
      "Садковичі",
      "Самбір",
      "Саночани",
      "Сигловате",
      "Сіде",
      "Скелівка",
      "Сливниця",
      "Слохині",
      "Смеречка",
      "Созань",
      "Солянуватка",
      "Сприня",
      "Стара Ропа",
      "Стара Сіль",
      "Старий Самбір",
      "Старява",
      "Стоділка",
      "Стороневичі",
      "Страшевичі",
      "Стрілковичі",
      "Стрільбичі",
      "Сусідовичі",
      "Сусолів",
      "Сушиця",
      "Сянки",
      "Тарава",
      "Тарнавка",
      "Тварі",
      "Терло",
      "Тернава",
      "Тершів",
      "Тисовиця",
      "Тиха",
      "Товарна",
      "Топільниця",
      "Торгановичі",
      "Торчиновичі",
      "Трояни",
      "Трушевичі",
      "Тур’є",
      "Турка",
      "Хащів",
      "Хирів",
      "Хлопчиці",
      "Чайковичі",
      "Чаплі",
      "Чернихів",
      "Черхава",
      "Чижки",
      "Чуква",
      "Шандровець",
      "Шептичі",
      "Штуковець",
      "Шум’яч",
      "Шумина",
      "Яблунів",
      "Явора",
      "Яворів",
      "Язи",
      "Яремків",
      "Ясениця",
      "Ясениця-Замкова",
      "Ясенка-Стецьова",
      "Антонівка",
      "Баня Лисовицька",
      "Берездівці",
      "Більче",
      "Болоня",
      "Бориничі",
      "Бородчиці",
      "Бортники",
      "Борусів",
      "Братківці",
      "Бринці-Загірні",
      "Бринці-Церковні",
      "Бродки",
      "Буковина",
      "Буянів",
      "Велика Воля",
      "Велика Горожанна",
      "Великі Дідушичі",
      "Вербиця",
      "Вербіж",
      "Верин",
      "Верхнє Синьовидне",
      "Верхня Лукавиця",
      "Верхня Рожанка",
      "Верхня Стинава",
      "Верхнячка",
      "Верчани",
      "Вибранівка",
      "Вівня",
      "Вільхівці",
      "Вовчатичі",
      "Волиця-Гніздичівська",
      "Володимирці",
      "Волосянка",
      "Воля-Довголуцька",
      "Воля-Задеревацька",
      "Воля-Облазницька",
      "Гайдучина",
      "Ганнівці",
      "Гірне",
      "Гірське",
      "Глухівець",
      "Гніздичів",
      "Голдовичі",
      "Голешів",
      "Голобутів",
      "Гонятичі",
      "Горішнє",
      "Городищенське",
      "Гранки-Кути",
      "Гребенів",
      "Грусятичі",
      "Дашава",
      "Дев’ятники",
      "Дем’янка-Лісна",
      "Дем’янка-Наддністрянська",
      "Демидів",
      "Демівка",
      "Демня",
      "Держів",
      "Довголука",
      "Довжки",
      "Долинівка",
      "Долішнє",
      "Дроговиж",
      "Дроховичі",
      "Дубравка",
      "Дуброва",
      "Дуліби",
      "Дунаєць",
      "Жидачів",
      "Жирівське",
      "Жирова",
      "Жулин",
      "Жупани",
      "Журавків",
      "Журавно",
      "Заболотівці",
      "Загірне",
      "Загірочко",
      "Заграбівка",
      "Загурщина",
      "Задеревач",
      "Задільське",
      "Заклад",
      "Заліски",
      "Заплатин",
      "Зарічне",
      "Іванівці",
      "Ілів",
      "Йосиповичі",
      "Кавське",
      "Кагуїв",
      "Калинівка",
      "Кальне",
      "Кам’яне",
      "Кам’янка",
      "Київець",
      "Климець",
      "Козьова",
      "Колодниця",
      "Колодруби",
      "Комарів",
      "Конюхів",
      "Корнелівка",
      "Коростів",
      "Корчин",
      "Корчівка",
      "Которини",
      "Красів",
      "Криниця",
      "Крупське",
      "Крушельниця",
      "Лавочне",
      "Лани-Соколівські",
      "Ланівка",
      "Лапшин",
      "Липиці",
      "Липівка",
      "Лисків",
      "Лисовичі",
      "Листв’яний",
      "Лисятичі",
      "Ліщини",
      "Лотатники",
      "Луб’яна",
      "Лучани",
      "Любинці",
      "Любша",
      "Лютинка",
      "Мазурівка",
      "Мала Воля",
      "Мала Горожанна",
      "Малі Дідушичі",
      "Маринка",
      "Матків",
      "Махлинець",
      "Межиброди",
      "Межиріччя",
      "Мельнич",
      "Миртюки",
      "Мита",
      "Млиниська",
      "Молодинче",
      "Молотів",
      "Моршин",
      "Мохнате",
      "Надітичі",
      "Нежухів",
      "Нижнє Синьовидне",
      "Нижня Лукавиця",
      "Нижня Рожанка",
      "Нижня Стинава",
      "Новий Розділ",
      "Новосілки-Опарські",
      "Новосільці",
      "Новошини",
      "Облазниця",
      "Олексичі",
      "Опорець",
      "Орява",
      "Орявчик",
      "Отиневичі",
      "Павуки",
      "Пила",
      "Підгородці",
      "Піддністряни",
      "Підліски",
      "Пісочна",
      "Піщани",
      "Плав’я",
      "Побук",
      "Повергів",
      "Погар",
      "Подорожнє",
      "Покрівці",
      "Протеси",
      "Пукеничі",
      "Пчани",
      "Пшонець",
      "Раделичі",
      "Райлів",
      "Ричагів",
      "Розвадів",
      "Розгірче",
      "Розділ",
      "Росохач",
      "Рудківці",
      "Рудники",
      "Садки",
      "Сайків",
      "Семигинів",
      "Сидорівка",
      "Сихів",
      "Сколе",
      "Славсько",
      "Слобідка",
      "Смогів",
      "Сможе",
      "Смоляний",
      "Сопіт",
      "Станків",
      "Станківці",
      "Стільсько",
      "Стриганці",
      "Стрий",
      "Стрілків",
      "Сугрів",
      "Сулятичі",
      "Суха Долина",
      "Сухий Потік",
      "Тейсарів",
      "Тернавка",
      "Тернопілля",
      "Тисовець",
      "Тишівниця",
      "Трудове",
      "Труханів",
      "Тужанівці",
      "Туради",
      "Тухля",
      "Тухолька",
      "Угерсько",
      "Угільня",
      "Урич",
      "Устя",
      "Фалиш",
      "Хащованя",
      "Хітар",
      "Ходовичі",
      "Ходорів",
      "Хромогорб",
      "Черемхів",
      "Чертіж",
      "Чижичі",
      "Чорний Острів",
      "Щасливе",
      "Юшківці",
      "Ялинкувате",
      "Ямельниця",
      "Ярушичі",
      "Ятвяги",
      "Адамівка",
      "Бабичі",
      "Барилів",
      "Батиїв",
      "Бебехи",
      "Белз",
      "Бендюга",
      "Бережне",
      "Березівка",
      "Бишів",
      "Боб’ятин",
      "Бодячів",
      "Борок",
      "Борятин",
      "Боянець",
      "Бояничі",
      "Бутини",
      "Ванів",
      "Варяж",
      "Великі Мости",
      "Вербове",
      "Верини",
      "Войславичі",
      "Волиця-Барилова",
      "Волсвин",
      "Воронів",
      "Вузлове",
      "Ганівка",
      "Гатківка",
      "Гірник",
      "Глухів",
      "Гоголів",
      "Горбків",
      "Грицеволя",
      "Гута-Скляна",
      "Двірці",
      "Дмитрів",
      "Добротвір",
      "Добрячин",
      "Долини",
      "Домашів",
      "Дубини",
      "Жвирка",
      "Жужеляни",
      "Забава",
      "Завидче",
      "Завишень",
      "Загатка",
      "Залижня",
      "Заріка",
      "Збоївська",
      "Зубків",
      "Ільковичі",
      "Карів",
      "Конотопи",
      "Копитів",
      "Корчів",
      "Кошаковські",
      "Куличків",
      "Купичволя",
      "Кустин",
      "Лешків",
      "Лещатів",
      "Лопатин",
      "Лубнівка",
      "Лучиці",
      "Мазярня-Каранська",
      "Маїки",
      "Матів",
      "Матяші",
      "Михайлівка",
      "Монастирок-Оглядівський",
      "Мукані",
      "Незнанів",
      "Немилів",
      "Нестаничі",
      "Нивиці",
      "Низи",
      "Ниновичі",
      "Нісмичі",
      "Новий Витків",
      "Новоставці",
      "Обортів",
      "Оглядів",
      "Опільсько",
      "Опліцько",
      "Ордів",
      "Острівок",
      "Павлів",
      "Перв’ятичі",
      "Перекалки",
      "Перемисловичі",
      "Переспа",
      "Перетоки",
      "Пиратин",
      "Піддовге",
      "Піддубне",
      "Підріка",
      "Пісочне",
      "Поздимир",
      "Полове",
      "Полонична",
      "Поториця",
      "Пристань",
      "Пустельники",
      "Равщина",
      "Радванці",
      "Радехів",
      "Раковище",
      "Реклинець",
      "Рогалі",
      "Розжалів",
      "Рокети",
      "Ромош",
      "Роятин",
      "Руденко",
      "Рудка",
      "Русин",
      "Сабанівка",
      "Савчин",
      "Свитазів",
      "Себечів",
      "Середпільці",
      "Синьків",
      "Сілець",
      "Скоморохи",
      "Смиків",
      "Сморжів",
      "Сокаль",
      "Спасів",
      "Стаївка",
      "Станин",
      "Старгород",
      "Старий Добротвір",
      "Старий Майдан",
      "Стенятин",
      "Стирківці",
      "Стоянів",
      "Стремільче",
      "Стремінь",
      "Стриганка",
      "Сушно",
      "Тартак",
      "Тартаків",
      "Теляж",
      "Тетевчиці",
      "Тичок",
      "Тишиця",
      "Тоболів",
      "Торки",
      "Трійця",
      "Трудолюбівка",
      "Тудорковичі",
      "Тяглів",
      "Увин",
      "Угнів",
      "Угринів",
      "Ульвівок",
      "Фусів",
      "Хлівчани",
      "Хмільно",
      "Хоробрів",
      "Цеблів",
      "Червоноград",
      "Шайноги",
      "Шарпанці",
      "Шихтарі",
      "Шишаки",
      "Щуровичі",
      "Яструбичі",
      "Арламівська Воля",
      "Баличі",
      "Бердихів",
      "Берегове",
      "Берці",
      "Боєвичі",
      "Божа Воля",
      "Боляновичі",
      "Боратичі",
      "Бориси",
      "Бортятин",
      "Брожки",
      "Буда",
      "Бунів",
      "Буховичі",
      "Буців",
      "Вахули",
      "Велика Діброва",
      "Великі Гори",
      "Великі Макари",
      "Великі Мокряни",
      "Великі Новосілки",
      "Великополе",
      "Верещиця",
      "Верхутка",
      "Висіч",
      "Вишенька",
      "Віжомля",
      "Війтівщина",
      "Вовча Гора",
      "Вовчищовичі",
      "Волостків",
      "Воля Любинська",
      "Воля-Добростанська",
      "Воля-Садківська",
      "Воля-Старицька",
      "Вороблячин",
      "Вороців",
      "Вуйковичі",
      "Ганьковичі",
      "Глинець",
      "Глиниці",
      "Годині",
      "Гораєць",
      "Гориславичі",
      "Гостинцеве",
      "Гусаків",
      "Дацьки",
      "Дебрі",
      "Дернаки",
      "Дидятичі",
      "Добощівка",
      "Добростани",
      "Довгомостиська",
      "Домажир",
      "Дрогомишль",
      "Дубинки",
      "Дубровиця",
      "Жорниська",
      "Зав’язанці",
      "Завада",
      "Заверхи",
      "Загорби",
      "Загороди",
      "Задебрі",
      "Зарубани",
      "Затока",
      "Зелів",
      "Золотковичі",
      "Іваники",
      "Івано-Франкове",
      "Іорданівка",
      "Калитяки",
      "Кам’янобрід",
      "Карачинів",
      "Карпи",
      "Кертинів",
      "Княгиничі",
      "Княжий Міст",
      "Когути",
      "Кожичі",
      "Колодка",
      "Колониці",
      "Конюшки",
      "Королин",
      "Корчунок",
      "Костильники",
      "Коти",
      "Коханівка",
      "Краковець",
      "Крив’яки",
      "Крисовичі",
      "Кропильники",
      "Крукеничі",
      "Кульматичі",
      "Лелехівка",
      "Липки",
      "Липовець",
      "Ліс",
      "Лісопотік",
      "Лозино",
      "Любині",
      "Мазури",
      "Максимці",
      "Макунів",
      "Мала Діброва",
      "Малі Мокряни",
      "Малі Новосілки",
      "Малнів",
      "Малнівська Воля",
      "Мальчиці",
      "Мартини",
      "Мелешки",
      "Мельники",
      "Мистичі",
      "Мишлятичі",
      "Молошковичі",
      "Мор’янці",
      "Мостиська",
      "Мостиська Другі",
      "Мочеради",
      "Мужиловичі",
      "Нагачів",
      "Наконечне Друге",
      "Наконечне Перше",
      "Немирів",
      "Ніговичі",
      "Новий Яр",
      "Новини",
      "Новояворівськ",
      "Озерське",
      "Оселя",
      "Острожець",
      "Пазиняки",
      "Паланки",
      "Передвір’я",
      "Петики",
      "Пихи",
      "Підгать",
      "Підлуби",
      "Пісоцький",
      "Плешевичі",
      "Пнікут",
      "Поповичі",
      "Поруби",
      "Поруденко",
      "Прилбичі",
      "Принада",
      "Раденичі",
      "Радохинці",
      "Рішин",
      "Рожаки",
      "Роснівка",
      "Руда-Краковецька",
      "Рулево",
      "Салаші",
      "Санники",
      "Сарни",
      "Свидниця",
      "Семирівка",
      "Середина",
      "Середкевичі",
      "Середній Горб",
      "Слабаш",
      "Слободяки",
      "Смолин",
      "Солиги",
      "Солтиси",
      "Солуки",
      "Ставки",
      "Стадники",
      "Старий Яр",
      "Старичі",
      "Стені",
      "Стоянці",
      "Страдч",
      "Стрілецьке",
      "Судковичі",
      "Судова Вишня",
      "Тамановичі",
      "Твіржа",
      "Терновиця",
      "Тишковичі",
      "Толуковичі",
      "Турича",
      "Тщенець",
      "Хідновичі",
      "Хлиплі",
      "Хляни",
      "Хоросниця",
      "Цетуля",
      "Ціпівки",
      "Черневе",
      "Чернилява",
      "Черчик",
      "Чижевичі",
      "Чолгині",
      "Чорнокунці",
      "Шаварі",
      "Шегині",
      "Шишоровичі",
      "Шкло",
      "Шутова",
      "Щеплоти",
      "Щиглі",
      "Ямельня",
      "Ясниська",
    ],
  },
  {
    region: "Миколаївська",
    cities: [
      "Анастасівка",
      "Андріївка",
      "Антонівка",
      "Архангельське",
      "Афанасіївка",
      "Баратівка",
      "Баштанка",
      "Безіменне",
      "Березнегувате",
      "Березнегуватське",
      "Біла Криниця",
      "Білівка",
      "Білоцерківка",
      "Богомази",
      "Бурханівка",
      "Бурячки",
      "Василівка",
      "Васильки",
      "Велике Артакове",
      "Великоолександрівка",
      "Великопілля",
      "Великофедорівка",
      "Вербове",
      "Весела Балка",
      "Веселе",
      "Веселий Кут",
      "Веселий Поділ",
      "Виноградівка",
      "Виноградне",
      "Висунськ",
      "Висунь",
      "Вівсянівка",
      "Вільна Поляна",
      "Вільне Запоріжжя",
      "Волна",
      "Володимирівка",
      "Володимиро-Павлівка",
      "Воля",
      "Галаганівка",
      "Ганнівка",
      "Горожене",
      "Горохівське",
      "Гранітне",
      "Гречанівка",
      "Григорівка",
      "Гуляйгородок",
      "Діброва",
      "Дмитрівка",
      "Дмитро-Білівка",
      "Добра Воля",
      "Добра Криниця",
      "Добре",
      "Добровільське",
      "Доброкам’янка",
      "Євгенівка",
      "Єлизаветівка",
      "Єрмолівка",
      "Єфремівка",
      "Загальна Користь",
      "Зелений Гай",
      "Зелений Клин",
      "Зелений Яр",
      "Зоря",
      "Івано-Кепине",
      "Інгулка",
      "Кавказ",
      "Казанка",
      "Калачеве",
      "Калинівка",
      "Калуга",
      "Кам’яне",
      "Катеринівка",
      "Каширівка",
      "Кашперо-Миколаївка",
      "Київське",
      "Кобзарці",
      "Козлівка",
      "Копані",
      "Костичі",
      "Костянтинівка",
      "Кротівка",
      "Лагодівка",
      "Лазарівка",
      "Лепетиха",
      "Лиманці",
      "Лисянка",
      "Лісове",
      "Лобріївка",
      "Лозове",
      "Лоцкине",
      "Лук’янівка",
      "Любарка",
      "Любимівка",
      "Любине",
      "Любомирівка",
      "Майорівка",
      "Майське",
      "Максимівка",
      "Мала Ганнівка",
      "Маліївка",
      "Маломихайлівське",
      "Малофедорівка",
      "Мар’ївка",
      "Мар’янівка",
      "Матроно-Василівка",
      "Миколаївка",
      "Миколо-Гулак",
      "Миролюбівка",
      "Михайлівка",
      "Мурахівка",
      "Неудачне",
      "Нова Висунь",
      "Нове",
      "Нове Життя",
      "Новий Буг",
      "Новий Шлях",
      "Новоантонівка",
      "Новобірзулівка",
      "Новоблакитне",
      "Нововасилівка",
      "Нововолодимирівка",
      "Новогеоргіївка",
      "Новогорожене",
      "Новогригорівка",
      "Новоданилівка",
      "Новодмитрівка",
      "Новоєгорівка",
      "Новоіванівка",
      "Новокондакове",
      "Новокостянтинівка",
      "Новолазарівка",
      "Новомар’ївка",
      "Новомиколаївка",
      "Новомихайлівка",
      "Новоолександрівка",
      "Новоочаків",
      "Новопавлівка",
      "Новопавлівське",
      "Новопетрівка",
      "Новополтавка",
      "Новорозанівка",
      "Новоросійське",
      "Новосевастополь",
      "Новоселівка",
      "Новосергіївка",
      "Новосілля",
      "Новоскелюватка",
      "Новософіївка",
      "Новотимофіївка",
      "Новоукраїнка",
      "Новофедорівка",
      "Новофонтанка",
      "Новохристофорівка",
      "Новочудневе",
      "Новоюр’ївка",
      "Одрадне",
      "Озерівка",
      "Олександрівка",
      "Павлівка",
      "Павло-Мар’янівка",
      "Панасівка",
      "Пархомівка",
      "Пелагіївка",
      "Перемога",
      "Першотравневе",
      "Петрівка",
      "Петрово-Висунське",
      "Петропавлівка",
      "Петропавлівське",
      "Пищевиця",
      "Піски",
      "Плющівка",
      "Показне",
      "Покровка",
      "Покровське",
      "Полтавка",
      "Поляна",
      "Привілля",
      "Привільне",
      "Привільнянське",
      "Пришиб",
      "Промінь",
      "Родники",
      "Розанівка",
      "Романівка",
      "Романово-Булгакове",
      "Ропове",
      "Садове",
      "Світла Дача",
      "Світлицьке",
      "Свобода",
      "Семенівка",
      "Сергіївка",
      "Симонівка",
      "Скобелеве",
      "Слобідка",
      "Снігурівка",
      "Соколівка",
      "Софіївка",
      "Станційне",
      "Старогорожене",
      "Старосолдатське",
      "Степанівка",
      "Степове",
      "Суворе",
      "Суходілля",
      "Тамарине",
      "Тарасівка",
      "Тернівка",
      "Тернове",
      "Тетянівка",
      "Троїцько-Сафонове",
      "Трояни",
      "Троянка",
      "Трудове",
      "Трудолюбівка",
      "Улянівка",
      "Утішне",
      "Федорівка",
      "Христофорівка",
      "Червона Долина",
      "Червона Новоселівка",
      "Червоний Став",
      "Червоний Яр",
      "Червонопілля",
      "Чернігівка",
      "Шевченко",
      "Шевченкове",
      "Широке",
      "Шляхове",
      "Щасливе",
      "Юр’ївка",
      "Явкине",
      "Яковлівка",
      "Ясна Поляна",
      "Акмечетські Ставки",
      "Актове",
      "Андрійчикове",
      "Анетівка",
      "Антонове",
      "Антонопіль",
      "Бакай",
      "Білоусівка",
      "Богданівка",
      "Богданівське",
      "Богодарівка",
      "Бондарівка",
      "Братолюбівка",
      "Братське",
      "Бугорок",
      "Бузоварове",
      "Бузьке",
      "Бузькі Пороги",
      "Варюшине",
      "Велика Солона",
      "Великосербулівка",
      "Велідарівка",
      "Веселий Роздол",
      "Веселинове",
      "Виноградний Сад",
      "Висока Гора",
      "Вікторівка",
      "Вільне",
      "Вільний Яр",
      "Водяне",
      "Водяно-Лорине",
      "Вознесенськ",
      "Вознесенське",
      "Возсіятське",
      "Вокзал",
      "Воронине",
      "Воронівка",
      "Глюгове",
      "Горянка",
      "Градівка",
      "Гражданка",
      "Грибоносове",
      "Григорівське",
      "Дарниця",
      "Дворянка",
      "Довжанка",
      "Довженки",
      "Доманівка",
      "Дорошівка",
      "Дружелюбівка",
      "Єгорівка",
      "Єланець",
      "Забари",
      "Зброшкове",
      "Звенигородка",
      "Зелене",
      "Іванівка",
      "Івано-Федорівка",
      "Ізбашівка",
      "Казаринське",
      "Кам’яна Балка",
      "Кам’янка",
      "Кам’яно-Костувате",
      "Кам’янопіль",
      "Кам’януватка",
      "Києво-Олександрівське",
      "Київ",
      "Ковалівка",
      "Козубівка",
      "Колосівка",
      "Копи",
      "Костувате",
      "Коштове",
      "Красноярка",
      "Кременівка",
      "Крива Пустош",
      "Криворучка",
      "Крутоярка",
      "Кудрявське",
      "Кузнецове",
      "Кутузівка",
      "Лідіївка",
      "Луб’янка",
      "Людмилівка",
      "Макарове",
      "Малодворянка",
      "Маложенівка",
      "Малосолоне",
      "Малоукраїнка",
      "Манне",
      "Маринівка",
      "Мартинівське",
      "Микільське",
      "Мирне",
      "Михайло-Жукове",
      "Мостове",
      "Надеждівка",
      "Новий Городок",
      "Новий Степ",
      "Новобілоусівка",
      "Нововоскресенка",
      "Новокантакузівка",
      "Новолікарське",
      "Новоолексіївка",
      "Новопристань",
      "Новосвітлівка",
      "Новосілка",
      "Новоявленка",
      "Обухівка",
      "Озеринівка",
      "Олександродар",
      "Ольгопіль",
      "Орлове Поле",
      "Очаківське",
      "П’ятихатки",
      "Павлодарівка",
      "Панкратове",
      "Первенець",
      "Першотравнівка",
      "Петрівка Друга",
      "Петрівка Перша",
      "Півні",
      "Поділля",
      "Подолянка",
      "Поріччя",
      "Прибужани",
      "Прибужжя",
      "Прищепівка",
      "Приют",
      "Птиче",
      "Райдолина",
      "Ракове",
      "Рацинська Дача",
      "Рюмівське",
      "Сергіївське",
      "Сила",
      "Солдатське",
      "Ставки",
      "Староолексіївка",
      "Суха Балка",
      "Таборівка",
      "Тимофіївка",
      "Тімірязєвка",
      "Токарівка",
      "Трикрати",
      "Трикратне",
      "Троїцьке",
      "Українець",
      "Улянове",
      "Уральське",
      "Урсолівка",
      "Федоро-Михайлівка",
      "Царедарівка",
      "Цвіткове",
      "Цибульки",
      "Червона Поляна",
      "Чорталка",
      "Широкі Криниці",
      "Штукар",
      "Щасливка",
      "Щербані",
      "Щербанівське",
      "Щуцьке",
      "Южноукраїнськ",
      "Ясногородка",
      "Яструбинове",
      "Анатолівка",
      "Андрієво-Зорине",
      "Баланове",
      "Баловне",
      "Безводне",
      "Березанка",
      "Березань",
      "Бессарабка",
      "Білозірка",
      "Благодарівка",
      "Благодатне",
      "Богородицьке",
      "Вавилове",
      "Весняна Квітка",
      "Весняне",
      "Водник",
      "Водокачка",
      "Воронцівка",
      "Воскресенське",
      "Галицинове",
      "Гамове",
      "Глибоке",
      "Горохівка",
      "Гребеники",
      "Грейгове",
      "Гур’ївка",
      "Данилівка",
      "Димівське",
      "Дільниче",
      "Дніпровське",
      "Добра Надія",
      "Елеваторне",
      "Журівка",
      "Зайве",
      "Зайчівське",
      "Зарічне",
      "Засілля",
      "Знам’янка",
      "Їжицьке",
      "Калабатине",
      "Кандибине",
      "Капустине",
      "Каравелове",
      "Карликівка",
      "Карлівка",
      "Каталине",
      "Квітневе",
      "Кир’яківка",
      "Киселівка",
      "Коблеве",
      "Козирка",
      "Комісарівка",
      "Корчине",
      "Котляреве",
      "Красне",
      "Краснопілля",
      "Крива Балка",
      "Криворіжжя",
      "Кринички",
      "Кубряки",
      "Куцуруб",
      "Лимани",
      "Лиманне",
      "Лиманське",
      "Лугове",
      "Лупареве",
      "Луч",
      "Люблине",
      "Малахове",
      "Маловарварівка",
      "Марківка",
      "Матіясове",
      "Матросівка",
      "Мефодіївка",
      "Миколаїв",
      "Миколаївське",
      "Михайло-Ларине",
      "Мішково-Погорілове",
      "Морське",
      "Надбузьке",
      "Нечаяне",
      "Нова Одеса",
      "Новоандріївка",
      "Новобогданівка",
      "Новоінгулка",
      "Новокатеринівка",
      "Новокиївка",
      "Новоматвіївське",
      "Новоолександрівське",
      "Новопетрівське",
      "Новоподілля",
      "Новоруське",
      "Новосафронівка",
      "Новошмідтівка",
      "Озерне",
      "Оленівка",
      "Ольшанське",
      "Осетрівка",
      "Остапівка",
      "Острівка",
      "Островське",
      "Очаків",
      "Партизанське",
      "Парутине",
      "Первомайське",
      "Пересадівка",
      "Петрово-Солониха",
      "Підлісне",
      "Піщаний Брід",
      "Подимове",
      "Полігон",
      "Половинки",
      "Попільне",
      "Прибузьке",
      "Прогресівка",
      "Радісний Сад",
      "Рибаківка",
      "Рівне",
      "Сапетня",
      "Святомиколаївка",
      "Себине",
      "Сеньчине",
      "Сільвестрівське",
      "Сливине",
      "Солончаки",
      "Сонячне",
      "Стара Богданівка",
      "Степова Долина",
      "Суворовка",
      "Сухий Єланець",
      "Суходіл",
      "Ташине",
      "Тернові Поди",
      "Тернувате",
      "Трихати",
      "Трихатське",
      "Тронка",
      "Тузли",
      "Українка",
      "Центральне",
      "Чемерлієве",
      "Червоне",
      "Червоне Парутине",
      "Червоне Поле",
      "Червоний Поділ",
      "Червоноволодимирівка",
      "Чорноморка",
      "Чумаки",
      "Широколанівка",
      "Шмідтівка",
      "Шмідтове",
      "Шостакове",
      "Шурине",
      "Яблуня",
      "Яселка",
      "Ясна Зоря",
      "Агрономія",
      "Адамівка",
      "Арбузинка",
      "Багачівка",
      "Бандурка",
      "Берізки",
      "Біляєве",
      "Бобрик",
      "Богемка",
      "Богословка",
      "Болгарка",
      "Болеславчик",
      "Брід",
      "Булацелове",
      "Бурилове",
      "Велика Мечетня",
      "Великовеселе",
      "Вербова Балка",
      "Веселий Лан",
      "Виноградний Яр",
      "Вишневе",
      "Воєводське",
      "Врадіївка",
      "Гаївське",
      "Генівка",
      "Гойдаї",
      "Голоскове",
      "Гражданівка",
      "Грушівка",
      "Доброжанівка",
      "Довга Пристань",
      "Єрмолаївка",
      "Захарівка",
      "Зелена Левада",
      "Зелена Поляна",
      "Зелені Кошари",
      "Зимницьке",
      "Зоряне",
      "Йосипівка",
      "Кавуни",
      "Кам’яний Міст",
      "Капітанка",
      "Катеринка",
      "Кінецьпіль",
      "Климівка",
      "Коломіївка",
      "Колос Добра",
      "Красненьке",
      "Краснівка",
      "Краснопіль",
      "Криве Озеро",
      "Криве Озеро Друге",
      "Кримка",
      "Кумарі",
      "Купріянівка",
      "Куріпчине",
      "Курячі Лози",
      "Лиса Гора",
      "Лозуватка",
      "Луканівка",
      "Лукашівка",
      "Львів",
      "Любоіванівка",
      "Мазурове",
      "Макієве",
      "Мала Багачівка",
      "Мала Врадіївка",
      "Мала Мечетня",
      "Мигія",
      "Михалкове",
      "Мічуріне",
      "Новий Ставок",
      "Новокрасне",
      "Новомиколаївське",
      "Новомихайлівське",
      "Ониськове",
      "Острогірське",
      "Очеретня",
      "Первомайськ",
      "Підгір’я",
      "Підгородна",
      "Полянка",
      "Романова Балка",
      "Рябоконеве",
      "Садибне",
      "Світоч",
      "Секретарка",
      "Синюхин Брід",
      "Сирове",
      "Сорочинка",
      "Станіславчик",
      "Старі Кошари",
      "Степківка",
      "Тридуби",
      "Филимонівка",
      "Чаусове",
      "Чаусове Друге",
      "Шкуратове",
    ],
  },
  {
    region: "Одеська",
    cities: [
      "Адамівка",
      "Амбарів",
      "Анатолівка",
      "Андрієво-Іванівка",
      "Антонівка",
      "Антонюки",
      "Армашівка",
      "Балайчук",
      "Баланини",
      "Баранове",
      "Бердинове",
      "Березівка",
      "Білка",
      "Бірюкове",
      "Блонське",
      "Богданівка",
      "Богунове",
      "Бранкованове",
      "Бузинове",
      "Буци",
      "Валентинівка",
      "Василівка",
      "Великий Буялик",
      "Великозименове",
      "Верхній Куяльник",
      "Веселе",
      "Веселинівка",
      "Виноград",
      "Виноградівка",
      "Виноградне",
      "Вишневе",
      "Вікторівка",
      "Вовкове",
      "Володимирівка",
      "Воробіївка",
      "Воробйове",
      "Ганно-Покровка",
      "Гвоздівка",
      "Гудевичеве",
      "Гуляївка",
      "Данилівка",
      "Демидове",
      "Джугастрове",
      "Добриднівка",
      "Докторове",
      "Донська Балка",
      "Донське",
      "Дружелюбівка",
      "Жовте",
      "Жукове",
      "Жуковське",
      "Журівка",
      "Заводівка",
      "Зброжківка",
      "Зеленопілля",
      "Зірка",
      "Златоустове",
      "Знам’янка",
      "Золочівське",
      "Іванівка",
      "Ісаєве",
      "Йосипівка",
      "Каїри",
      "Калинівка",
      "Капітанівка",
      "Каре",
      "Карнагорове",
      "Катерино-Платонівка",
      "Каховка",
      "Качулове",
      "Козлове",
      "Комарашеве",
      "Конопляне",
      "Копійкове",
      "Косівка",
      "Костянтинів",
      "Котовка",
      "Красне",
      "Крижанівка",
      "Кринички",
      "Кудрявка",
      "Курісове",
      "Ланове",
      "Левадівка",
      "Лідівка",
      "Люботаївка",
      "Макарове",
      "Мала Дворянка",
      "Малинівка",
      "Малігонове",
      "Малозименове",
      "Мар’ївка",
      "Мар’янівка",
      "Маринове",
      "Маркевичеве",
      "Мартинівка",
      "Марціянове",
      "Маслове",
      "Машенька",
      "Миколаївка",
      "Михайлівка",
      "Михайло-Олександрівка",
      "Михайлопіль",
      "Морозова",
      "Настасіївка",
      "Нейкове",
      "Нижній Куяльник",
      "Нова Григорівка",
      "Новакове",
      "Нове Селище",
      "Нові Маяки",
      "Новоандріївка",
      "Новогригорівка",
      "Новогуляївка",
      "Новоєлизаветівка",
      "Новокальчеве",
      "Новомиколаївка",
      "Новопавлівка",
      "Новопетрівка",
      "Новоподільське",
      "Новопокровка",
      "Новосвітівка",
      "Новоселівка",
      "Новостепанівка",
      "Новотроїцьке",
      "Новоукраїнка",
      "Одаї",
      "Одрадна Балка",
      "Олександрівка",
      "Олександро-Вовкове",
      "Олексіївка",
      "Онорівка",
      "Осинівка",
      "Основа",
      "Перемога",
      "Переселенці",
      "Петрівка",
      "Петровірівка",
      "Піщана Петрівка",
      "Подільці",
      "Поліно-Осипенкове",
      "Польове",
      "Преображенка",
      "Причепівка",
      "Прохорове",
      "Радісне",
      "Раухівка",
      "Ревова",
      "Рівне",
      "Роздол",
      "Розквіт",
      "Розкішне",
      "Романівка",
      "Руська Слобідка",
      "Ряснопіль",
      "Садове",
      "Самійлівка",
      "Сахарове",
      "Северинівка",
      "Семихатки",
      "Сербка",
      "Силівка",
      "Сирітське Друге",
      "Сирітське Перше",
      "Сиротинка",
      "Січневе",
      "Скосарівка",
      "Скосарівське",
      "Соколове",
      "Софіївка",
      "Ставкове",
      "Стара Єлизаветівка",
      "Старі Маяки",
      "Степанівка",
      "Стрюкове",
      "Суха Журівка",
      "Сухий Овраг",
      "Сухине",
      "Сухомлинове",
      "Танівка",
      "Тарасівка",
      "Тимофіївка",
      "Травневе",
      "Ульяновка",
      "Улянівка",
      "Успенка",
      "Цибулівка",
      "Чарівне",
      "Червоне",
      "Черняхівське",
      "Чигирин",
      "Чижове",
      "Чогодарівка",
      "Чорний Кут",
      "Чорногірка",
      "Чудське",
      "Шабельники",
      "Шевченка",
      "Шевченкове",
      "Шеметове",
      "Шерове",
      "Широке",
      "Ширяєве",
      "Шутове",
      "Юрашеве",
      "Юркове",
      "Якимів Яр",
      "Яринославка",
      "Яснопілля",
      "Абрикосове",
      "Авидівка",
      "Андріївка",
      "Базар’янка",
      "Балабанка",
      "Баштанівка",
      "Безім’янка",
      "Бикоза",
      "Білгород-Дністровський",
      "Біленьке",
      "Білолісся",
      "Благодатне",
      "Борисівка",
      "Бритівка",
      "Введенка",
      "Великомар’янівка",
      "Великорибальське",
      "Весела Балка",
      "Вигон",
      "Випасне",
      "Вільне",
      "Глибоке",
      "Гончарівка",
      "Дальнічень",
      "Дельжилер",
      "Дивізія",
      "Долинівка",
      "Долинка",
      "Жовтий Яр",
      "Забари",
      "Зарічне",
      "Затока",
      "Зелене",
      "Зеленівка",
      "Зоря",
      "Кам’янка",
      "Карналіївка",
      "Кароліно-Бугаз",
      "Козацьке",
      "Комишівка Перша",
      "Костянтинівка",
      "Кочкувате",
      "Красна Коса",
      "Крива Балка",
      "Крутоярівка",
      "Кулевча",
      "Курортне",
      "Лебедівка",
      "Лиман",
      "Лугове",
      "Маразліївка",
      "Миколаївка-Новоросійська",
      "Мирнопілля",
      "Міняйлівка",
      "Молдове",
      "Молога",
      "Монаші",
      "Над’ярне",
      "Надежда",
      "Негрове",
      "Нерушай",
      "Нова Іванівка",
      "Нова Олексіївка",
      "Нова Плахтіївка",
      "Нова Царичанка",
      "Нове",
      "Новомихайлівка",
      "Новоселиця",
      "Пасічне",
      "Петропавлівка",
      "Південне",
      "Підгірне",
      "Плахтіївка",
      "Полянка",
      "Попаздра",
      "Прибережне",
      "Привітне",
      "Приморське",
      "Пшеничне",
      "Рибальське",
      "Розівка",
      "Ройлянка",
      "Руськоіванівка",
      "Салгани",
      "Сарата",
      "Світлодолинське",
      "Семенівка",
      "Семисотка",
      "Сергіївка",
      "Спаське",
      "Стара Царичанка",
      "Старокозаче",
      "Старосілля",
      "Струмок",
      "Сухолужжя",
      "Татарбунари",
      "Трапівка",
      "Трихатки",
      "Тузли",
      "Успенівка",
      "Фараонівка",
      "Фуратівка",
      "Царичанка",
      "Чабанське",
      "Черкеси",
      "Чистоводне",
      "Шабо",
      "Ярославка",
      "Арциз",
      "Баннівка",
      "Березине",
      "Болград",
      "Бородіно",
      "Булатівка",
      "Весела Долина",
      "Веселий Кут",
      "Височанське",
      "Вишняки",
      "Владичень",
      "Вознесенка Друга",
      "Вознесенка Перша",
      "Ганнівка",
      "Главані",
      "Голиця",
      "Городнє",
      "Делень",
      "Дмитрівка",
      "Євгенівка",
      "Єлизаветівка",
      "Задунаївка",
      "Залізничне",
      "Зелена Балка",
      "Іванчанка",
      "Калачівка",
      "Калчева",
      "Кам’янське",
      "Каракурт",
      "Коса",
      "Криничне",
      "Кролівка",
      "Кубей",
      "Ламбрівка",
      "Лісне",
      "Лужанка",
      "Малоярославець Другий",
      "Малоярославець Перший",
      "Матильдівка",
      "Надеждівка",
      "Надрічне",
      "Нове Тарутине",
      "Новий Каракурт",
      "Нові Каплани",
      "Нові Трояни",
      "Новомирне",
      "Новоозерне",
      "Новосілка",
      "Новохолмське",
      "Оксамитне",
      "Оріхівка",
      "Павлівка",
      "Петрівськ",
      "Плачинда",
      "Плоцьк",
      "Прямобалка",
      "Роза",
      "Роща",
      "Серпневе",
      "Скриванівка",
      "Слобідка",
      "Сухувате",
      "Табаки",
      "Тарутине",
      "Теплиця",
      "Тополине",
      "Холмське",
      "Юр’ївка",
      "Ярове",
      "Багате",
      "Біле",
      "Броска",
      "Вилкове",
      "Десантне",
      "Дзинілор",
      "Долинське",
      "Дунайське",
      "Ізмаїл",
      "Каланчак",
      "Кирнички",
      "Кислиця",
      "Кілія",
      "Комишівка",
      "Котловина",
      "Ларжанка",
      "Лиманське",
      "Ліски",
      "Лощинівка",
      "Матроска",
      "Мирне",
      "Муравлівка",
      "Нагірне",
      "Нова Некрасівка",
      "Нова Покровка",
      "Новокаланчак",
      "Новокам’янка",
      "Новосільське",
      "Озерне",
      "Орлівка",
      "Острівне",
      "Першотравневе",
      "Плавні",
      "Помазани",
      "Приозерне",
      "Рені",
      "Саф’яни",
      "Стара Некрасівка",
      "Старі Трояни",
      "Суворове",
      "Трудове",
      "Утконосівка",
      "Фурманівка",
      "Червоний Яр",
      "Авангард",
      "Августівка",
      "Алтестове",
      "Балка",
      "Барабой",
      "Берегове",
      "Березань",
      "Біляївка",
      "Білярі",
      "Богатирівка",
      "Болгарка",
      "Букачі",
      "Булдинка",
      "Бурлача Балка",
      "Бутівка",
      "Важне",
      "Вапнярка",
      "Велика Балка",
      "Великий Дальник",
      "Великі Ламзаки",
      "Великодолинське",
      "Вигода",
      "Визирка",
      "Вовківське",
      "Воронівка",
      "Гвардійське",
      "Градениці",
      "Грибівка",
      "Григорівка",
      "Дальник",
      "Дачне",
      "Доброжанове",
      "Доброолександрівка",
      "Доброслав",
      "Дослідне",
      "Єгорівка",
      "Зоринове",
      "Зоря Труда",
      "Зоряне",
      "Іванове",
      "Ілічанка",
      "Іллінка",
      "Кагарлик",
      "Калаглія",
      "Касяни",
      "Кінне",
      "Ковалівка",
      "Кордон",
      "Корсунці",
      "Кошари",
      "Красносілка",
      "Кремидівка",
      "Кубанка",
      "Курган",
      "Латівка",
      "Лиманка",
      "Лібенталь",
      "Лізинка",
      "Любопіль",
      "Майори",
      "Мале",
      "Малодолинське",
      "Маринівка",
      "Маяки",
      "Міщанка",
      "Молодіжне",
      "Набережне",
      "Надлиманське",
      "Нерубайське",
      "Нова Вільшанка",
      "Нова Долина",
      "Нова Дофінівка",
      "Нова Еметівка",
      "Нова Ковалівка",
      "Нові Білярі",
      "Нові Шомполи",
      "Новоградківка",
      "Новокубанка",
      "Овідіополь",
      "Одеса",
      "Одрадове",
      "Ониськове",
      "Павлинка",
      "Паліївка",
      "Переможне",
      "Петрове",
      "Петродолинське",
      "Повстанське",
      "Порт",
      "Прилиманське",
      "Протопопівка",
      "Пшонянове",
      "Ранжеве",
      "Розселенець",
      "Роксолани",
      "Санжійка",
      "Світле",
      "Світлогірське",
      "Секретарівка",
      "Сичавка",
      "Созонівка",
      "Сонячне",
      "Ставки",
      "Стара Еметівка",
      "Старі Шомполи",
      "Степове",
      "Сухий Лиман",
      "Таїрове",
      "Теплодар",
      "Тилігульське",
      "Тихе",
      "Троїцьке",
      "Трояндове",
      "Удобне",
      "Усатове",
      "Фонтанка",
      "Хлібодарське",
      "Холодна Балка",
      "Хоминка",
      "Христо-Ботеве",
      "Червона Гірка",
      "Черевичне",
      "Чоботарівка",
      "Чорноморськ",
      "Чорноморське",
      "Шаманівка",
      "Шевченкове-Кут",
      "Широка Балка",
      "Южне",
      "Якова",
      "Яськи",
      "Агафіївка",
      "Агеївка",
      "Акулинівка",
      "Амури",
      "Ананьїв",
      "Андріяшівка",
      "Артирівка",
      "Арчепитівка",
      "Байбузівка",
      "Байтали",
      "Бакша",
      "Балта",
      "Баштанків",
      "Бендзари",
      "Білине",
      "Білоусівка",
      "Бобрик Другий",
      "Бобрик Перший",
      "Бобрицьке",
      "Бокове",
      "Бондарі",
      "Борсуки",
      "Борщі",
      "Бочманівка",
      "Боярка",
      "Будаївці",
      "Будеї",
      "Велика Василівка",
      "Велика Кіндратівка",
      "Велике Бокове",
      "Велике Бурилове",
      "Великий Фонтан",
      "Великобоярка",
      "Вербове",
      "Вестерничани",
      "Вижине",
      "Відрада",
      "Вільшанка",
      "Волова",
      "Волярка",
      "Гавиноси",
      "Галочі",
      "Гандрабури",
      "Гвоздавка Друга",
      "Гвоздавка Перша",
      "Гербине",
      "Гертопи",
      "Гетьманівка",
      "Гидерим",
      "Глибочок",
      "Гольма",
      "Гонората",
      "Гор’ївка",
      "Горячівка",
      "Грабове",
      "Гребенюки",
      "Грекове Друге",
      "Грекове Перше",
      "Гулянка",
      "Дем’янівка",
      "Демидівка",
      "Дібрівка",
      "Дігори",
      "Дмитрівське",
      "Дністровець",
      "Довжанка",
      "Домниця",
      "Дубинове",
      "Дубки",
      "Дубове",
      "Євтодія",
      "Єфросинівка",
      "Жеребкове",
      "Загнітків",
      "Заплази",
      "Затишшя",
      "Зелений Гай",
      "Зелений Кут",
      "Зеленогірське",
      "Івашків",
      "Ілля",
      "Казбеки",
      "Калини",
      "Калістратівка",
      "Кам’яне",
      "Канцурове",
      "Капустянка",
      "Кармалюківка",
      "Катеринівка Перша",
      "Качурівка",
      "Квітка",
      "Кирилівка",
      "Климентове",
      "Ковбасова Поляна",
      "Кодима",
      "Козаче",
      "Козачий Яр",
      "Комарівка",
      "Концеба",
      "Коритне",
      "Коси",
      "Коси-Слобідка",
      "Коханівка",
      "Кохівка",
      "Крижовлин",
      "Кричунове",
      "Круті",
      "Куяльник",
      "Лабушне",
      "Левантівка",
      "Липецьке",
      "Лисогірка",
      "Лісничівка",
      "Любашівка",
      "Любомирка",
      "Мала Василівка",
      "Мала Кіндратівка",
      "Мала Олександрівка",
      "Мала Петрівка",
      "Мала Слобідка",
      "Малаївці",
      "Малий Куяльник",
      "Малий Фонтан",
      "Мардарівка",
      "Миколаївка Перша",
      "Мирони",
      "Мошняги",
      "Мурована",
      "Неділкове",
      "Немирівське",
      "Нестерове",
      "Нестоїта",
      "Нова Волярка",
      "Нова Кульна",
      "Новий Мир",
      "Новий Орач",
      "Нововоздвиженка",
      "Новогеоргіївка",
      "Новодачне",
      "Новоіванівка",
      "Новокарбівка",
      "Новокрасне",
      "Новоолександрівка",
      "Новополь",
      "Новорозівка",
      "Новосамарка",
      "Новосеменівка",
      "Обжиле",
      "Оброчне",
      "Окни",
      "Оленівка",
      "Омелянівка",
      "Осички",
      "Острівка",
      "Падрецеве",
      "Пасат",
      "Пасицели",
      "Перейма",
      "Перельоти",
      "Перешори",
      "Пилипівка",
      "Пиріжна",
      "Писарівка",
      "Піщана",
      "Платонове",
      "Плоске",
      "Подільськ",
      "Познанка Друга",
      "Познанка Перша",
      "Покровка",
      "Полянецьке",
      "Поплавка",
      "Правда",
      "Пужайкове",
      "Ракулове",
      "Римарівка",
      "Розалівка",
      "Савранське",
      "Саврань",
      "Сагайдак",
      "Самарка",
      "Саражинка",
      "Селиванівка",
      "Семено-Карпівка",
      "Серби",
      "Сирівське",
      "Сінне",
      "Слюсареве",
      "Смолянка",
      "Соболівка",
      "Солтанівка",
      "Ставрове",
      "Станіславка",
      "Стара Кульна",
      "Стримба",
      "Струтинка",
      "Тимкове",
      "Ткаченка",
      "Топали",
      "Топик",
      "Точилове",
      "Тригради",
      "Трудолюбівка",
      "Унтилівка",
      "Федорівка",
      "Федосіївка",
      "Флора",
      "Флоринське",
      "Харитинівка",
      "Цеханівка",
      "Чабанівка",
      "Чайківка",
      "Чайківське",
      "Чернече",
      "Чорна",
      "Чубівка",
      "Шайтанка",
      "Шелехове",
      "Шершенці",
      "Шимкове",
      "Шкарбинка",
      "Шликареве",
      "Шляхове",
      "Шумилове",
      "Янишівка",
      "Яновка",
      "Ясенове Друге",
      "Ясенове Перше",
      "Ясинове",
      "Ангелінівка",
      "Андрієво-Іванове",
      "Андрусова",
      "Антоно-Ковач",
      "Багачеве",
      "Бакалове",
      "Балашове",
      "Балкове",
      "Бессарабка",
      "Бецилове",
      "Бірносове",
      "Богданове Перше",
      "Богнатове",
      "Бринівка",
      "Бугай",
      "Будячки",
      "Бурдівка",
      "Буцинівка",
      "Вакарське",
      "Вакулівка",
      "Велика Михайлівка",
      "Великокомарівка",
      "Великоплоске",
      "Велізарове",
      "Виноградар",
      "Водяне",
      "Войничеве",
      "Гаївка",
      "Галупове",
      "Гедеримове Перше",
      "Гетьманці",
      "Гіржове",
      "Гірківка",
      "Гірське",
      "Глибокояр",
      "Гребеники",
      "Грушка",
      "Давидівка",
      "Дементівка",
      "Дівоцьке",
      "Добрий Лук",
      "Дружба",
      "Дурбайли",
      "Єреміївка",
      "Єрмішкове",
      "Желепове",
      "Жигайлове",
      "Загір’я",
      "Заможне",
      "Захарівка",
      "Івано-Миколаївка",
      "Іринівка",
      "Калантаївка",
      "Капаклієве",
      "Карабанове",
      "Кардамичеве",
      "Карпівка",
      "Карпове",
      "Кістельниця",
      "Козакове",
      "Кошарка",
      "Краснопіль",
      "Кримпулька",
      "Кузьменка",
      "Кучурган",
      "Лозове",
      "Лучинське",
      "Майорське",
      "Мала Топорівка",
      "Малоплоске",
      "Малорошове",
      "Малоцебрикове",
      "Марківка",
      "Мартове",
      "Матишівка",
      "Мацкули",
      "Мигаєве",
      "Мигаї",
      "Міліардівка",
      "Муратове",
      "Надія",
      "Наливайкове",
      "Никомаврівка",
      "Нова Шибка",
      "Новий Гребеник",
      "Нові Бутори",
      "Нові Чобручі",
      "Новоантонівка",
      "Новоборисівка",
      "Новоградениця",
      "Новодмитрівка",
      "Новодмитрівка Друга",
      "Новозаріцьке",
      "Новокостянтинівка",
      "Новороманівка",
      "Новосавицьке",
      "Новосільці",
      "Олег",
      "Ольгинове",
      "Онилове",
      "Орел",
      "Парканівка",
      "Парканці",
      "Первомайське",
      "Перехрестове",
      "Перехрестове Перше",
      "Перше Травня",
      "Петро-Євдокіївка",
      "Плавневе",
      "Платонівка",
      "Полезне",
      "Полішпакове",
      "Понятівка",
      "Поташенкове",
      "Привілля",
      "Привільне",
      "Путилівка",
      "Райки",
      "Роздільна",
      "Росіянівка",
      "Савчинське",
      "Саханське",
      "Скинешори",
      "Слов’яносербка",
      "Сокорове",
      "Соше-Острівське",
      "Старокостянтинівка",
      "Старостине",
      "Стоянове",
      "Сухе",
      "Торосове",
      "Три Криниці",
      "Тростянець",
      "Трохимівка",
      "Труд-Гребеник",
      "Труд-Куток",
      "Трудомирівка",
      "Тятри",
      "Фрасине",
      "Цебрикове",
      "Червона Стінка",
      "Щербанка",
      "Юрківка",
      "Яковлівка",
    ],
  },
  {
    region: "Полтавська",
    cities: [
      "Андрійки",
      "Бабичівка",
      "Багни",
      "Бадьорівка",
      "Базалуки",
      "Байрак",
      "Бакумівка",
      "Балабушині Верби",
      "Битакове Озеро",
      "Білецьківка",
      "Білогуби",
      "Білоусівка",
      "Біляки",
      "Богданівка",
      "Бондарі",
      "Бориси",
      "Бреусівка",
      "Бригадирівка",
      "Броварки",
      "Брусове",
      "Бугаївка",
      "Булахи",
      "Бурбине",
      "Бурімка",
      "Бурти",
      "Бутенки",
      "Бутоярівка",
      "В’язівка",
      "Варакути",
      "Василенки",
      "Василівка",
      "Василяки",
      "Васьківка",
      "Велика Безуглівка",
      "Великі Кринки",
      "Великі Липняги",
      "Вербки",
      "Вереміївка",
      "Верхня Жужманівка",
      "Верхня Мануйлівка",
      "Весела Долина",
      "Веселий Поділ",
      "Винники",
      "Висока Вакулівка",
      "Вишеньки",
      "Вишняки",
      "Вільна Терешківка",
      "Вільне",
      "Вітки",
      "Воскобійники",
      "Гаївка",
      "Гайове",
      "Ганнівка",
      "Герасимівка",
      "Глибока Долина",
      "Глибоке",
      "Глобине",
      "Глушкове Друге",
      "Гора",
      "Горбані",
      "Горби",
      "Гориславці",
      "Горішні Плавні",
      "Горошине",
      "Градизьк",
      "Греблі",
      "Григоро-Бригадирівка",
      "Гриньки",
      "Грицаї",
      "Гуляйполе",
      "Гуньки",
      "Дабинівка",
      "Дем’янівка",
      "Демидівка",
      "Демченки",
      "Дзюбанівка",
      "Дмитрівка",
      "Довге",
      "Дяченки",
      "Єгорівка",
      "Єристівка",
      "Жорняки",
      "Жуки",
      "Загребелля",
      "Задовга",
      "Заїчинці",
      "Заможне",
      "Запсілля",
      "Зарічне",
      "Заруддя",
      "Землянки",
      "Зікранці",
      "Зубані",
      "Іванівка",
      "Іванове Селище",
      "Йосипівка",
      "Кагамлик",
      "Калашники",
      "Калинівка",
      "Кам’яні Потоки",
      "Канівщина",
      "Карпиха",
      "Карпівка",
      "Кащівка",
      "Квіти",
      "Келеберда",
      "Кирияківка",
      "Кияшки",
      "Кіндрівка",
      "Книшівка",
      "Кобелячок",
      "Ковалі",
      "Ковалівка",
      "Ковнірівщина",
      "Козельщина",
      "Колісники",
      "Коломицівка",
      "Комендантівка",
      "Кордубанове",
      "Корещина",
      "Коржівка",
      "Костівка",
      "Котляревське",
      "Крамаренки",
      "Красносілля",
      "Кременчук",
      "Крива Руда",
      "Кривуші",
      "Криничне",
      "Кузьменки",
      "Кукоби",
      "Куп’євате",
      "Курганне",
      "Ламане",
      "Липове",
      "Литвиненки",
      "Лізки",
      "Лозки",
      "Лубенщина",
      "Лукашівка",
      "Лутовинівка",
      "Майбородівка",
      "Майданівка",
      "Майорщина",
      "Максимівка",
      "Мала Кохнівка",
      "Маламівка",
      "Малики",
      "Малинівка",
      "Малі Липняги",
      "Мальці",
      "Манжелія",
      "Манилівське",
      "Мар’їне",
      "Мар’янівка",
      "Матвіївка",
      "Махнівка",
      "Миловидівка",
      "Миргородщина",
      "Мирне",
      "Мирони",
      "Михайленки",
      "Михайлики",
      "Мозоліївка",
      "Мотрине",
      "Набережне",
      "Найденівка",
      "Наріжжя",
      "Наталенки",
      "Недогарки",
      "Нижня Жужманівка",
      "Нижня Мануйлівка",
      "Нова Галещина",
      "Нова Знам’янка",
      "Нова Олександрівка",
      "Нова Петрівка",
      "Нова Україна",
      "Новий Виселок",
      "Новий Калкаїв",
      "Новобудова",
      "Новодорожнє",
      "Новомосковське",
      "Новоселиця",
      "Новоселівка",
      "Обознівка",
      "Оболонь",
      "Олександрівка",
      "Олександрівка Друга",
      "Олександрія",
      "Оленівка",
      "Олефірівка",
      "Ольгівка",
      "Омельник",
      "Омельниче",
      "Онищенки",
      "Опришки",
      "Осокори",
      "Остапці",
      "Очеретувате",
      "П’ятихатки",
      "Павлики",
      "Павлівка",
      "Панасівка",
      "Паніванівка",
      "Панівка",
      "Пашенівка",
      "Пашківка",
      "Пащенівка",
      "Пелехівщина",
      "Петрашівка",
      "Петрівка",
      "Пилипенки",
      "Пироги",
      "Писарщина",
      "Підгірне",
      "Підгорівка",
      "Піски",
      "Піщане",
      "Погреби",
      "Погребняки",
      "Поділ",
      "Попівка",
      "Порубаї",
      "Потоки",
      "Пригарівка",
      "Придніпрянське",
      "Приліпка",
      "Пришиб",
      "Пронозівка",
      "Проценки",
      "Пузикове",
      "Пузирі",
      "Пустовійтове",
      "Пустовіти",
      "Пухальщина",
      "Радалівка",
      "Радочини",
      "Ревівка",
      "Рибалки",
      "Роботівка",
      "Ройове",
      "Рокити",
      "Рокитне",
      "Рокитне-Донівка",
      "Романівка",
      "Романки",
      "Руда",
      "Садки",
      "Салівка",
      "Самусіївка",
      "Святилівка",
      "Семенівка",
      "Семимогили",
      "Середине",
      "Середпілля",
      "Сидори",
      "Сиротенки",
      "Сіренки",
      "Слюзівка",
      "Солониця",
      "Солонці",
      "Солошине",
      "Соснівка",
      "Стара Білецьківка",
      "Старий Калкаїв",
      "Старий Хутір",
      "Степанівка",
      "Степівка",
      "Степове",
      "Строкачі",
      "Струтинівка",
      "Сухий Кобелячок",
      "Сушки",
      "Тарасівка",
      "Тимошівка",
      "Товсте",
      "Троїцьке",
      "Тройняки",
      "Трудовик",
      "Тукали",
      "Турбаї",
      "Улинівка",
      "Устимівка",
      "Федоренки",
      "Федорівка",
      "Харченки",
      "Хмарине",
      "Хорішки",
      "Худоліївка",
      "Цибівка",
      "Чаплинці",
      "Червоний Лиман",
      "Черевані",
      "Черемушки",
      "Чечелеве",
      "Чечужине",
      "Чикалівка",
      "Чорноглазівка",
      "Шевченки",
      "Шепелівка",
      "Шушвалівка",
      "Щербаки",
      "Щербухи",
      "Юрівка",
      "Юрки",
      "Юрочки",
      "Ялинці",
      "Яремівка",
      "Яроші",
      "Андріївка",
      "Архемівка",
      "Барвінщина",
      "Барилівщина",
      "Барилове",
      "Березівка",
      "Березняки",
      "Березова Рудка",
      "Березоточа",
      "Бесідівщина",
      "Біївці",
      "Білоцерківці",
      "Бовбасівка",
      "Богодарівка",
      "Бочки",
      "Бубереве",
      "Бубни",
      "Бурлаки",
      "Бутівці",
      "В’язівок",
      "Ванжина Долина",
      "Варварівка",
      "Велика Круча",
      "Великоселецьке",
      "Вербине",
      "Вергуни",
      "Верхоярівка",
      "Вечірки",
      "Вили",
      "Висачки",
      "Високе",
      "Вишневе",
      "Вищий Булатець",
      "Відрадне",
      "Вікторія",
      "Вільшанка",
      "Вовча Долина",
      "Вовчик",
      "Войниха",
      "Воронинці",
      "Вороньки",
      "Гайки",
      "Галяве",
      "Гирине",
      "Гільці",
      "Гінці",
      "Голотівщина",
      "Горобії",
      "Городище",
      "Грабарівка",
      "Грабів",
      "Гребінка",
      "Григорівка",
      "Грушине",
      "Грушківка",
      "Губське",
      "Гулаківка",
      "Гурбинці",
      "Давидівка",
      "Дації",
      "Дейманівка",
      "Демина Балка",
      "Денисівка",
      "Дубове",
      "Духове",
      "Єнківці",
      "Єньки",
      "Єрківці",
      "Загребля",
      "Залужне",
      "Замостище",
      "Запорожчине",
      "Заріг",
      "Заріччя",
      "Засулля",
      "Золотухи",
      "Зубенки",
      "Іванці",
      "Іващенки",
      "Ісківці",
      "Ївженки",
      "Калайдинці",
      "Калинів Міст",
      "Каплинці",
      "Карпилівка",
      "Кейбалівка",
      "Кізлівка",
      "Клепачі",
      "Княжа Лука",
      "Ковтуни",
      "Козаче",
      "Козубівка",
      "Колодна",
      "Коломійцеве Озеро",
      "Кононівка",
      "Корніївка",
      "Короваї",
      "Костюки",
      "Красне",
      "Кревелівка",
      "Крем’янка",
      "Кривці",
      "Кроти",
      "Круподеринці",
      "Крутий Берег",
      "Крячківка",
      "Кузубівка",
      "Кулажинці",
      "Кулики",
      "Кулиничі",
      "Куп’єваха",
      "Курінька",
      "Куторжиха",
      "Лагодівка",
      "Лазірки",
      "Лазьки",
      "Левченки",
      "Леляки",
      "Литвяки",
      "Лісова Слобідка",
      "Лісянщина",
      "Лобкова Балка",
      "Лози",
      "Ломаки",
      "Лубни",
      "Луговики",
      "Луки",
      "Лукім’я",
      "Лутайка",
      "Лушники",
      "Макарівщина",
      "Максимівщина",
      "Мала Круча",
      "Мала Попівка",
      "Малий В’язівок",
      "Малоселецьке",
      "Малютинці",
      "Мар’їнське",
      "Мартинівка",
      "Матяшівка",
      "Мацківці",
      "Мацкова Лучка",
      "Маяківка",
      "Мгар",
      "Мелехи",
      "Мелюшки",
      "Меченки",
      "Миколаївка",
      "Михайлівка",
      "Михнівці",
      "Мищенки",
      "Могилівщина",
      "Мокіївка",
      "Мусіївка",
      "Назарівка",
      "Настасівка",
      "Наталівка",
      "Несено-Іржавець",
      "Нетратівка",
      "Нехристівка",
      "Нижній Булатець",
      "Нижній Іржавець",
      "Нова Діброва",
      "Нова Петрівщина",
      "Новаки",
      "Новачиха",
      "Нове",
      "Новий Байрак",
      "Новий Іржавець",
      "Нові Мартиновичі",
      "Новоаврамівка",
      "Новодар",
      "Новоіванівка",
      "Новооржицьке",
      "Овсюки",
      "Окіп",
      "Олексіївка",
      "Онішки",
      "Оржиця",
      "Оріхівка",
      "Оріхівщина",
      "Орликівщина",
      "Осавульщина",
      "Осняг",
      "Остапенки",
      "Остапівка",
      "П’ятигірці",
      "Павленки",
      "Павлівщина",
      "Падусі",
      "Пацали",
      "Першотравневе",
      "Петракіївка",
      "Пилиповичі",
      "Пирятин",
      "Пишне",
      "Пізники",
      "Піски-Удайські",
      "Плехів",
      "Повстин",
      "Покровська Багачка",
      "Покровське",
      "Покровщина",
      "Полуніївка",
      "Польове",
      "Постав-Мука",
      "Почаївка",
      "Приймівщина",
      "Пристань",
      "Прихідьки",
      "Пулинці",
      "Радьки",
      "Райозеро",
      "Ремівка",
      "Рибченки",
      "Рівне",
      "Роплянське",
      "Рудка",
      "Савинці",
      "Садове",
      "Сазонівка",
      "Саївка",
      "Сасинівка",
      "Світанок",
      "Свічківка",
      "Селюків",
      "Сербинівка",
      "Середнє",
      "Синяківщина",
      "Сімаки",
      "Скибинці",
      "Скочак",
      "Сліпорід",
      "Сліпорід-Іванівка",
      "Слободо-Петрівка",
      "Смотрики",
      "Снітин",
      "Снітине",
      "Сотницьке",
      "Софине",
      "Ставки",
      "Стадня",
      "Стайки",
      "Стара Мусіївка",
      "Старий Іржавець",
      "Староаврамівка",
      "Степурі",
      "Стукалівка",
      "Суха Лохвиця",
      "Суха Солониця",
      "Сухоносівка",
      "Тарандинці",
      "Тарасенкове",
      "Теплівка",
      "Теремецьке",
      "Терни",
      "Тернівщина",
      "Тимки",
      "Тишки",
      "Тополеве",
      "Тотчине",
      "Третякове",
      "Трубайці",
      "Усівка",
      "Халепці",
      "Харківці",
      "Харсіки",
      "Хвощівка",
      "Хейлівщина",
      "Хильківка",
      "Хитці",
      "Хоменки",
      "Хорол",
      "Хорошки",
      "Хоружівка",
      "Чайківщина",
      "Чаплинка",
      "Чевельча",
      "Червоне",
      "Червоні Пологи",
      "Черевки",
      "Чернече",
      "Чмихалове",
      "Чорнухи",
      "Чуднівці",
      "Чутівка",
      "Шарківщина",
      "Шершнівка",
      "Шинківщина",
      "Широке",
      "Шишаки",
      "Шкилі",
      "Шкурати",
      "Штомпелівка",
      "Яблуневе",
      "Ялосовецьке",
      "Яцини",
      "Яцюкове",
      "Андрущине",
      "Архипівка",
      "Бабичі",
      "Багачка Перша",
      "Бакути",
      "Балаклія",
      "Баланди",
      "Балюки",
      "Балясне",
      "Баранівка",
      "Безводівка",
      "Безсали",
      "Бербениці",
      "Березова Лука",
      "Берізки",
      "Бессараби",
      "Бехтерщина",
      "Бешти",
      "Бієве",
      "Білаші",
      "Біленченківка",
      "Білики",
      "Білогорілка",
      "Білоцерківка",
      "Бірки",
      "Бобрик",
      "Бодаква",
      "Булуки",
      "Буряківщина",
      "Бутова Долина",
      "Бутовичеське",
      "Бухалове",
      "Бухуни",
      "Васильки",
      "Велика Багачка",
      "Велика Бузова",
      "Велика Грем’яча",
      "Велика Обухівка",
      "Велике",
      "Великий Байрак",
      "Великий Перевіз",
      "Великі Будища",
      "Великі Сорочинці",
      "Величківка",
      "Величкове",
      "Вельбівка",
      "Венеславівка",
      "Венслави",
      "Веприк",
      "Вертелецьке",
      "Верхня Будаківка",
      "Верховина",
      "Веселе",
      "Ветхалівка",
      "Вечірчине",
      "Вирішальне",
      "Вишарі",
      "Вишняківка",
      "Вовки",
      "Вовківське",
      "Вовнянка",
      "Володимирівка",
      "Воля",
      "Воронівщина",
      "Воронянщина",
      "Гадяч",
      "Гаївщина",
      "Гамаліївка",
      "Гаркушинці",
      "Гарнокут",
      "Гасенки",
      "Герусівка",
      "Гиряві Ісківці",
      "Гірки",
      "Гнатенки",
      "Говори",
      "Гоголеве",
      "Гончарі",
      "Горішнє",
      "Гречанівка",
      "Григорівщина",
      "Грипаки",
      "Грянчиха",
      "Дакалівка",
      "Дачне",
      "Декабристів",
      "Дем’янки",
      "Дереківщина",
      "Деркачі",
      "Дзюбівщина",
      "Дібрівка",
      "Дібрівне",
      "Довгалеве",
      "Довгалівка",
      "Долинка",
      "Донцівщина",
      "Дрюківщина",
      "Дучинці",
      "Ємці",
      "Єрки",
      "Жабки",
      "Ждани",
      "Жоржівка",
      "Заводське",
      "Залізняки",
      "Заморіївка",
      "Западинці",
      "Запорожці",
      "Запсільське",
      "Затон",
      "Зелена Балка",
      "Зелене",
      "Зелений Кут",
      "Зірка",
      "Змажине",
      "Зозулі",
      "Зубівка",
      "Зуївці",
      "Калинівщина",
      "Качанове",
      "Кибинці",
      "Київське",
      "Кирпотівка",
      "Киселиха",
      "Кияшківське",
      "Кіблицьке",
      "Климове",
      "Клюшниківка",
      "Ковердина Балка",
      "Колодяжне",
      "Колосівка",
      "Коляди",
      "Комишня",
      "Коновалове",
      "Коноплянка",
      "Конюшеве",
      "Коптів",
      "Корнієнки",
      "Корсунівка",
      "Котлярі",
      "Кравченки",
      "Крамарщина",
      "Красна Лука",
      "Красногорівка",
      "Криворучки",
      "Криниця",
      "Кротівщина",
      "Кругле Озеро",
      "Круглик",
      "Кульбашне",
      "Купівщина",
      "Легейди",
      "Лещани",
      "Лещенки",
      "Лисівка",
      "Лихопілля",
      "Лісове",
      "Лободине",
      "Лохвиця",
      "Лугове",
      "Лука",
      "Лукаші",
      "Луці",
      "Лучка",
      "Любівщина",
      "Лютенька",
      "Мала Бузова",
      "Мала Грем’яча",
      "Мала Обухівка",
      "Мала Побиванка",
      "Мала Решетилівка",
      "Малий Перевіз",
      "Маликівщина",
      "Малі Будища",
      "Малі Сорочинці",
      "Маначинівка",
      "Мар’янське",
      "Мареничі",
      "Марченки",
      "Маслівці",
      "Мелешки",
      "Мехедівка",
      "Милашенкове",
      "Миргород",
      "Мірошники",
      "Млини",
      "Могилатів",
      "Мокрії",
      "Морозівщина",
      "Мостовівщина",
      "Науменки",
      "Нижні Яреськи",
      "Нижня Будаківка",
      "Низова Яковенщина",
      "Новооріхівка",
      "Носенки",
      "Носи",
      "Овдіївка",
      "Огирівка",
      "Озерне",
      "Онацьке",
      "Оріханове",
      "Осняги",
      "Осове",
      "Островерхівка",
      "Панченки",
      "Парницьке",
      "Педоричі",
      "Пелагеївка",
      "Перевіз",
      "Переводчикове",
      "Перекопівка",
      "Пестичевське",
      "Петрівка-Роменська",
      "Петрівці",
      "Петроселівка",
      "Пирятинщина",
      "Писарівка",
      "Писарівщина",
      "Підлуки",
      "Пісочки",
      "Пласківщина",
      "Плішивець",
      "Погарщина",
      "Показове",
      "Полив’яне",
      "Попове",
      "Порскалівка",
      "Потоцьківщина",
      "Принцеве",
      "Прокоповичі",
      "Псільське",
      "Пучківщина",
      "Пушкареве",
      "Радивонівка",
      "Радченки",
      "Радченкове",
      "Раївка",
      "Рашівка",
      "Рибальське",
      "Риги",
      "Римарівка",
      "Римиги",
      "Розбишівка",
      "Рокита",
      "Романиха",
      "Ромодан",
      "Рудиків",
      "Ручки",
      "Сагайдак",
      "Сажка",
      "Сакалівка",
      "Салимівщина",
      "Самари",
      "Саранчине",
      "Саранчова Долина",
      "Сари",
      "Сватки",
      "Свиридівка",
      "Семеренки",
      "Сенча",
      "Сергіївка",
      "Середняки",
      "Сидорівщина",
      "Синьощоки",
      "Скибівщина",
      "Скиданки",
      "Скоробагатьки",
      "Слобідка",
      "Сльозиха",
      "Сокириха",
      "Солдатове",
      "Сохацьке",
      "Степаненки",
      "Степне",
      "Степуки",
      "Стефанівщина",
      "Стінки",
      "Стовбине",
      "Ступки",
      "Сулими",
      "Суржки",
      "Тепле",
      "Тимофіївка",
      "Тищенки",
      "Токарі",
      "Травневе",
      "Тригубщина",
      "Трудолюб",
      "Трудолюбиве",
      "Тютюрівщина",
      "Устивиця",
      "Федунка",
      "Фуглі",
      "Харенки",
      "Хвальки",
      "Хвощове",
      "Ходосиха",
      "Хомутець",
      "Христанівка",
      "Христівка",
      "Хрулі",
      "Цикали",
      "Цимбалове",
      "Цисеве",
      "Ціпки",
      "Цьови",
      "Часниківка",
      "Червона Балка",
      "Червона Слобідка",
      "Червоний Кут",
      "Червоні Луки",
      "Черкащани",
      "Чернишівка",
      "Чижі",
      "Чорнобаї",
      "Шадурка",
      "Шарлаївка",
      "Шафранівка",
      "Шахворостівка",
      "Швадрони",
      "Шевченкове",
      "Шеки",
      "Шепелі",
      "Шипоші",
      "Широка Долина",
      "Шкадрети",
      "Шмиглі",
      "Шпакове",
      "Шпирни",
      "Штомпелі",
      "Шульги",
      "Юр’ївка",
      "Юсківці",
      "Яблунівка",
      "Якимове",
      "Яковенщина-Горова",
      "Яремівщина",
      "Яреськи",
      "Ярмаки",
      "Яхники",
      "Яшники",
      "Абазівка",
      "Абрамівка",
      "Андренки",
      "Андрушки",
      "Арсенівка",
      "Артелярщина",
      "Бабайкове",
      "Бабанське",
      "Базилівщина",
      "Бакай",
      "Балівка",
      "Батьки",
      "Безруки",
      "Безручки",
      "Бережнівка",
      "Березове",
      "Бершацьке",
      "Бечеве",
      "Білоконі",
      "Білоконівка",
      "Білухівка",
      "Більськ",
      "Біологічне",
      "Бобрівник",
      "Божки",
      "Божківське",
      "Божкове",
      "Бондури",
      "Борисівка",
      "Борівське",
      "Бочанівка",
      "Браїлки",
      "Братешки",
      "Брачківка",
      "Бридуни",
      "Бринзи",
      "Бричківка",
      "Бродщина",
      "Брунівка",
      "Будки",
      "Бузинівщина",
      "Бузова Пасківка",
      "Буланове",
      "Буняківка",
      "Бухалівка",
      "Вакулиха",
      "Валок",
      "Василе-Устимівка",
      "Василькове",
      "Васильці",
      "Васьки",
      "Ватажкове",
      "Ваці",
      "Велика Павлівка",
      "Велика Пожарня",
      "Велика Рублівка",
      "Велика Рудка",
      "Велике Болото",
      "Великий Кобелячок",
      "Великий Тростянець",
      "Великі Солонці",
      "Вербове",
      "Верхні Рівні",
      "Верхня Ланна",
      "Верхоли",
      "Веселівка",
      "Веселка",
      "Виноминівка",
      "Витівка",
      "Вищі Вільшани",
      "Вібли",
      "Вільний Степ",
      "Вільниця",
      "Вільхівщина",
      "Вільховатка",
      "Вільхове",
      "Вільховий Ріг",
      "Вільхуватка",
      "Вінтенці",
      "Вісичі",
      "Вітрова Балка",
      "Власівка",
      "Вовківка",
      "Вовча Балка",
      "Водолагівка",
      "Водяна Балка",
      "Водяне",
      "Войнівка",
      "Волошкове",
      "Ворони",
      "Гаврилки",
      "Гавронці",
      "Гаймарівка",
      "Галагурівка",
      "Галі-Горбатки",
      "Галійка",
      "Галущина Гребля",
      "Ганжі",
      "Ганжівка",
      "Гарбузівка",
      "Гвоздиківка",
      "Гетьманка",
      "Глибока Балка",
      "Глинське",
      "Глоби",
      "Глобівка",
      "Глоди",
      "Глухове",
      "Говтва",
      "Говтвянчик",
      "Гожули",
      "Голобородьківське",
      "Головач",
      "Головки",
      "Голуби",
      "Гольманівка",
      "Гонтарі",
      "Горбанівка",
      "Горбатівка",
      "Горобці",
      "Горянщина",
      "Грабинівка",
      "Грабівщина",
      "Грекопавлівка",
      "Гринівка",
      "Грицаївка",
      "Гришки",
      "Грякове",
      "Губарівка",
      "Гусаки",
      "Гутирівка",
      "Дадакалівка",
      "Дамаска",
      "Дашківка",
      "Дейкалівка",
      "Дейнеківка",
      "Дем’янці",
      "Деменки",
      "Деревки",
      "Деряги",
      "Диканька",
      "Діброва",
      "Дмитренки",
      "Довбнівка",
      "Довга Пустош",
      "Довжик",
      "Довжок",
      "Долина",
      "Дондасівка",
      "Драбинівка",
      "Драни",
      "Дрижина Гребля",
      "Дружба",
      "Дуб’яги",
      "Дуб’янщина",
      "Дубина",
      "Дубівка",
      "Дудкин Гай",
      "Дудникове",
      "Дячкове",
      "Єлизаветівка",
      "Ємцева Долина",
      "Жадани",
      "Жирки",
      "Жирківка",
      "Забаряни",
      "Забрідки",
      "Заворскло",
      "Загрунівка",
      "Заїки",
      "Заїченці",
      "Зайці",
      "Зайці Другі",
      "Залізничне",
      "Затурине",
      "Зачепилівка",
      "Зеленківка",
      "Зінці",
      "Зіньків",
      "Знаменка",
      "Золотарівка",
      "Зорівка",
      "Зоря",
      "Зуби",
      "Івашки",
      "Іскрівка",
      "Іщенківка",
      "Їжаківка",
      "Каленики",
      "Кальницьке",
      "Кам’янка",
      "Камінне",
      "Канави",
      "Кантемирівка",
      "Каплунівка",
      "Капустяни",
      "Карабазівка",
      "Кардашівка",
      "Карлівка",
      "Карнаухи",
      "Карпусі",
      "Касяни",
      "Кашубівка",
      "Квіткове",
      "Келебердівка",
      "Кирило-Ганнівка",
      "Кирякове",
      "Киселівка",
      "Кишеньки",
      "Кілочки",
      "Клименки",
      "Климівка",
      "Климківка",
      "Клюсівка",
      "Клюшники",
      "Княжева Слобода",
      "Кобеляки",
      "Коби",
      "Ковалеве",
      "Коваленківка",
      "Кованчик",
      "Кованьківка",
      "Ковжижа",
      "Козлівщина",
      "Козуби",
      "Кокозівка",
      "Коліньки",
      "Колісниківка",
      "Коломак",
      "Коломацьке",
      "Колотії",
      "Кольченки",
      "Комарівка",
      "Коновалівка",
      "Кононенки",
      "Копили",
      "Коржиха",
      "Коржі",
      "Корлюкове",
      "Короленківка",
      "Косточки",
      "Косяки",
      "Котельва",
      "Коханівка",
      "Кочубеївка",
      "Кошманівка",
      "Красногірка",
      "Кратова Говтва",
      "Кривки",
      "Крохмальці",
      "Кругле",
      "Крута Балка",
      "Крюкове",
      "Куклинці",
      "Кукобівка",
      "Куми",
      "Кунівка",
      "Кунцеве",
      "Курилехівка",
      "Кустолове",
      "Кустолове Перше",
      "Кустолові Кущі",
      "Кустолово-Суходілка",
      "Кучерівка",
      "Лабурівка",
      "Лаврики",
      "Лавринці",
      "Лагоди",
      "Ландарі",
      "Лани",
      "Ланна",
      "Латишівка",
      "Лахни",
      "Лебедине",
      "Леваневське",
      "Левенцівка",
      "Лелюхівка",
      "Лесинки",
      "Лиман Другий",
      "Лиман Перший",
      "Лип’янка",
      "Лисича",
      "Лисівщина",
      "Литвини",
      "Литвинівка",
      "Лихачівка",
      "Лівенське",
      "Лівобережна Сокілка",
      "Лісне",
      "Ліщинівка",
      "Лобачі",
      "Лозівка",
      "Лозуватка",
      "Лукищина",
      "Лучки",
      "Львівське",
      "Любимівка",
      "Любка",
      "Лютенські Будища",
      "Лютівка",
      "М’якеньківка",
      "Мазурівка",
      "Майорівка",
      "Макарцівка",
      "Макухи",
      "Макухівка",
      "Мала Нехвороща",
      "Мала Перещепина",
      "Мала Рублівка",
      "Мала Рудка",
      "Мале Ладижине",
      "Мале Микільське",
      "Малий Бакай",
      "Малий Кобелячок",
      "Малий Тростянець",
      "Малі Козуби",
      "Малі Солонці",
      "Маловидне",
      "Манилівка",
      "Маньківка",
      "Мар’ївка",
      "Марківка",
      "Матяші",
      "Мачухи",
      "Машівка",
      "Маячка",
      "Микілка",
      "Микільське",
      "Милорадове",
      "Мильці",
      "Минівка",
      "Миронівка",
      "Мисики",
      "Михайлівка Перша",
      "Михайлівське",
      "Михайлове",
      "Михнівка",
      "Мідянівка",
      "Міжгір’я",
      "Міські Млини",
      "Млинки",
      "Молодиківщина",
      "Морози",
      "Мушина Гребля",
      "Мушти",
      "Нагірне",
      "Надежда",
      "Надержинщина",
      "Назаренки",
      "Нелюбівка",
      "Нестеренки",
      "Нехвороща",
      "Нижні Вільшани",
      "Нижні Млини",
      "Нижні Рівні",
      "Нижня Ланна",
      "Никонорівка",
      "Нова Василівка",
      "Нова Диканька",
      "Нова Кочубеївка",
      "Нова Михайлівка",
      "Нова Павлівка",
      "Нове Грякове",
      "Нове Остапове",
      "Новий Тагамлик",
      "Нові Санжари",
      "Новофедорівка",
      "Носівка",
      "Огуївка",
      "Одарюківка",
      "Одрадівка",
      "Озера",
      "Олепіри",
      "Олефіри",
      "Олефірщина",
      "Олійники",
      "Онацьки",
      "Опішня",
      "Опішняни",
      "Орданівка",
      "Орлик",
      "Остап’є",
      "Охоче",
      "Очканівка",
      "Пальчиківка",
      "Паненки",
      "Панське",
      "Пасічне",
      "Пасічники",
      "Пасківка",
      "Паськівка",
      "Патлаївка",
      "Пащенки",
      "Пеленківщина",
      "Первозванівка",
      "Первомайське",
      "Перегонівка",
      "Переліски",
      "Петренки",
      "Петро-Ганнівка",
      "Петро-Давидівка",
      "Писаренки",
      "Пишненки",
      "Підварівка",
      "Підгір’я",
      "Підгора",
      "Підлепичі",
      "Підозірка",
      "Підок",
      "Пірки",
      "Піщанка",
      "Плавні",
      "Плоске",
      "Поводи",
      "Пожарна Балка",
      "Пологи",
      "Пологи-Низ",
      "Полтава",
      "Полузір’я",
      "Портнівка",
      "Потеряйки",
      "Потеряйки-Горові",
      "Потічок",
      "Правобережна Сокілка",
      "Пристанційне",
      "Прогрес",
      "Прокопівка",
      "Проні",
      "Проскури",
      "Просяниківка",
      "Прощуради",
      "Пругли",
      "Пудлівка",
      "Пустовари",
      "Райдужне",
      "Ревущине",
      "Редути",
      "Рекунівка",
      "Решетилівка",
      "Рожаївка",
      "Розпашне",
      "Розсошенці",
      "Розумівка",
      "Романи",
      "Рубани",
      "Рубанівка",
      "Руденки-Гончарі",
      "Руденківка",
      "Рунівщина",
      "Рябківка",
      "Ряське",
      "Самарщина",
      "Сапожине",
      "Саранчівка",
      "Сахнівщина",
      "Свистунівка",
      "Світлівщина",
      "Світлогірське",
      "Свічкареве",
      "Свічкарівщина",
      "Селещина",
      "Сем’янівка",
      "Сені",
      "Сердюки",
      "Сиверинівка",
      "Сивці",
      "Сидоряче",
      "Сінне",
      "Скибівка",
      "Скороходове",
      "Славки",
      "Славнівка",
      "Слинківщина",
      "Слиньків Яр",
      "Смородщина",
      "Снопове",
      "Собківка",
      "Соколівщина",
      "Соколова Балка",
      "Соломахівка",
      "Солона Балка",
      "Сонячне",
      "Сохацька Балка",
      "Ставкове",
      "Стадниця",
      "Стара Михайлівка",
      "Старицьківка",
      "Старі Санжари",
      "Стасі",
      "Стінка",
      "Стовбина Долина",
      "Сторожове",
      "Стрижівщина",
      "Стрілевщина",
      "Судівка",
      "Судіївка",
      "Супротивна Балка",
      "Супрунівка",
      "Сусідки",
      "Суха Маячка",
      "Сухе",
      "Сухинівка",
      "Сухорабівка",
      "Сягайли",
      "Таверівка",
      "Тагамлицьке",
      "Тахтаулове",
      "Твердохліби",
      "Терентіївка",
      "Терешки",
      "Терещенки",
      "Тимченки",
      "Тимченківка",
      "Тишенківка",
      "Тойбік",
      "Тополівка",
      "Тривайли",
      "Трирогове",
      "Трояни",
      "Троянівка",
      "Трудолюбівка",
      "Тури",
      "Тутаки",
      "Тютюнники",
      "Удовиченки",
      "Уманцівка",
      "Устименки",
      "Усть-Лип’янка",
      "Уханівка",
      "Федіївка",
      "Филенкове",
      "Фисуни",
      "Флорівка",
      "Хижняківка",
      "Хмарівка",
      "Холодне Плесо",
      "Хоружі",
      "Храпачів Яр",
      "Хрещате",
      "Хрипки",
      "Цвітове",
      "Цибулі",
      "Циганське",
      "Чалівка",
      "Червона Долина",
      "Червоні Квіти",
      "Чередники",
      "Черкасівка",
      "Чернечий Яр",
      "Чернещина",
      "Черняківка",
      "Чкалове",
      "Чоботарі",
      "Човно-Федорівка",
      "Чорбівка",
      "Чорняки",
      "Чумаки",
      "Чутове",
      "Шабельники",
      "Шамраївка",
      "Шапки",
      "Шарлаї",
      "Шевченка",
      "Шевченківка",
      "Шедієве",
      "Шенгаріївка",
      "Шенгури",
      "Шили",
      "Шилівка",
      "Шишацьке",
      "Шкурпели",
      "Шкурупії",
      "Шкурупіївка",
      "Шовкопляси",
      "Шостаки",
      "Шпортьки",
      "Шрамки",
      "Щасливе",
      "Щербані",
      "Юнаки",
      "Яблучне",
      "Ярохівка",
      "Ясне",
      "Яценки",
      "Яцине-Окарі",
      "Яцинова Слобідка",
    ],
  },
  {
    region: "Рівненська",
    cities: [
      "Антонівка",
      "Бабка",
      "Балаховичі",
      "Березина",
      "Берестівка",
      "Бишляк",
      "Біле",
      "Більська Воля",
      "Бір",
      "Борове",
      "Бродниця",
      "Бутове",
      "Вараш",
      "Великий Жолудськ",
      "Великі Телковичі",
      "Великі Цепцевичі",
      "Веретено",
      "Вичівка",
      "Вовчиці",
      "Володимирець",
      "Воронки",
      "Голубне",
      "Гориничі",
      "Городок",
      "Дібрівськ",
      "Діброва",
      "Дідівка",
      "Довговоля",
      "Дубівка",
      "Дубчиці",
      "Ждань",
      "Жовкині",
      "Журавлине",
      "Заболоття",
      "Задовже",
      "Заозер’я",
      "Зарічне",
      "Зелена Діброва",
      "Зелене",
      "Зелениця",
      "Іванчиці",
      "Іванчі",
      "Каноничі",
      "Кідри",
      "Комори",
      "Коник",
      "Котира",
      "Кошмаки",
      "Красносілля",
      "Кримне",
      "Кругле",
      "Кутин",
      "Кутинок",
      "Кухітська Воля",
      "Кухче",
      "Липне",
      "Лисичин",
      "Лозки",
      "Локниця",
      "Луко",
      "Любахи",
      "Любинь",
      "Малий Жолудськ",
      "Малі Телковичі",
      "Маюничі",
      "Млин",
      "Млинок",
      "Морочне",
      "Мостище",
      "Мульчиці",
      "Мутвиця",
      "Неньковичі",
      "Нетреба",
      "Ниговищі",
      "Нобель",
      "Новаки",
      "Новорічиця",
      "Новосілки",
      "Новосілля",
      "Озеро",
      "Озерці",
      "Олександрове",
      "Омит",
      "Острів",
      "Острівськ",
      "Острівці",
      "Перекалля",
      "Полиці",
      "Половлі",
      "Привітівка",
      "Прикладники",
      "Радижеве",
      "Радове",
      "Рафалівка",
      "Річиця",
      "Річки",
      "Ромейки",
      "Рудка",
      "Сенчиці",
      "Серники",
      "Собіщиці",
      "Соломир",
      "Сопачів",
      "Сошники",
      "Стара Рафалівка",
      "Степангород",
      "Суховоля",
      "Тиховиж",
      "Уріччя",
      "Хиночі",
      "Храпин",
      "Чаква",
      "Чернин",
      "Чудля",
      "Чучеве",
      "Щоків",
      "Адамівка",
      "Аршичин",
      "Баболоки",
      "Бакорин",
      "Балки",
      "Баранне",
      "Батьків",
      "Башарівка",
      "Безодня",
      "Берег",
      "Береги",
      "Березини",
      "Берестечко",
      "Білоберіжжя",
      "Білогорівка",
      "Білогородка",
      "Більче",
      "Бірки",
      "Богушівка",
      "Бокійма",
      "Бондарі",
      "Боратин",
      "Борбин",
      "Боремель",
      "Боремець",
      "Бортниця",
      "Боцянівка",
      "Бригадирівка",
      "Брищі",
      "Бугаївка",
      "Будераж",
      "Буди",
      "Буща",
      "Варковичі",
      "Велика Городниця",
      "Велике",
      "Великі Загірці",
      "Великі Сади",
      "Верба",
      "Вербень",
      "Веселе",
      "Вичавки",
      "Вишневе",
      "Війниця",
      "Владиславівка",
      "Вовковиї",
      "Вовничі",
      "Гаї-Лев’ятинські",
      "Гай",
      "Гайки",
      "Гайки-Ситенські",
      "Гірники",
      "Глибока Долина",
      "Гнатівка",
      "Гнильче",
      "Головчиці",
      "Голуби",
      "Гоноратка",
      "Гончариха",
      "Гранівка",
      "Грядки",
      "Гусари",
      "Демидівка",
      "Дитиничі",
      "Добривода",
      "Добрятин",
      "Довгалівка",
      "Довге Поле",
      "Довгошиї",
      "Долина",
      "Дружба",
      "Дубини",
      "Дубляни",
      "Дубно",
      "Дубовиця",
      "Дубрівка",
      "Дядьковичі",
      "Жорнів",
      "Забірки",
      "Заболотинці",
      "Завалля",
      "Загатинці",
      "Залав’я",
      "Залужжя",
      "Заміщина",
      "Замчисько",
      "Заруддя",
      "Засув",
      "Збитин",
      "Зборів",
      "Здовбиця",
      "Зелений Гай",
      "Злинець",
      "Золочівка",
      "Зоряне",
      "Іваниничі",
      "Іванівка",
      "Іванківці",
      "Іваннє",
      "Іващуки",
      "Ільпибоки",
      "Казмірі",
      "Калинівка",
      "Кам’яна Верба",
      "Кам’яниця",
      "Карпилівка",
      "Квітневе",
      "Клин",
      "Клинці",
      "Кліпець",
      "Кліщиха",
      "Клюки",
      "Княгинин",
      "Княгинине",
      "Коблин",
      "Козин",
      "Козирщина",
      "Комарівка",
      "Копани",
      "Копані",
      "Копань",
      "Кораблище",
      "Коритне",
      "Косареве",
      "Костянець",
      "Коти",
      "Красне",
      "Кривуха",
      "Крилів",
      "Круки",
      "Крупець",
      "Курсики",
      "Лебедянка",
      "Лев’ятин",
      "Липа",
      "Лисин",
      "Листвин",
      "Лихачівка",
      "Лішня",
      "Лопавше",
      "Лукарівка",
      "Майдан",
      "Малеве",
      "Малин",
      "Малі Гайки",
      "Малі Дорогостаї",
      "Малі Загірці",
      "Малі Сади",
      "Мальоване",
      "Мантин",
      "Мартинівка",
      "Маслянка",
      "Маяки",
      "Микитичі",
      "Миколаївка",
      "Мильча",
      "Миньківці",
      "Мирогоща Друга",
      "Мирогоща Перша",
      "Митниця",
      "Михайлівка",
      "Млинів",
      "Мокре",
      "Молодаво Друге",
      "Молодаво Перше",
      "Молодаво Третє",
      "Молодіжне",
      "Московщина",
      "Мошків",
      "Мятин",
      "Набережне",
      "Нагірне",
      "Нагоряни",
      "Надчиці",
      "Нараїв",
      "Немирівка",
      "Ниви-Золочівські",
      "Нова Миколаївка",
      "Нова Митниця",
      "Нова Носовиця",
      "Нова Пляшева",
      "Нове",
      "Новий Тік",
      "Новина-Добрятинська",
      "Новини",
      "Новоселівка",
      "Новоукраїнка",
      "Новоукраїнське",
      "Озеряни",
      "Озліїв",
      "Олибів",
      "Онишківці",
      "Опарипси",
      "Остріїв",
      "Острожець",
      "Охматків",
      "П’яннє",
      "Панталія",
      "Пасіки",
      "Пашева",
      "Певжа",
      "Пекалів",
      "Перевередів",
      "Перекалі",
      "Перемилівка",
      "Перенятин",
      "Переросля",
      "Пирятин",
      "Підбрусинь",
      "Підвисоке",
      "Підгай",
      "Підгайці",
      "Підзамче",
      "Підлипки",
      "Підлісці",
      "Підлозці",
      "Підлужжя",
      "Пітушків",
      "Плоска",
      "Пляшева",
      "Пляшівка",
      "Повча",
      "Полуничне",
      "Посників",
      "Привільне",
      "Привітне",
      "Придорожне",
      "Приски",
      "Птича",
      "Пугачівка",
      "Пустоіванне",
      "Радивилів",
      "Радихівщина",
      "Радів",
      "Рачин",
      "Рідків",
      "Рідкодуби",
      "Річище",
      "Рогізне",
      "Рудливе",
      "Рудня",
      "Савчуки",
      "Сапанівчик",
      "Сатиїв",
      "Свищів",
      "Семидуби",
      "Середнє",
      "Сестрятин",
      "Ситне",
      "Смига",
      "Смиків",
      "Смордва",
      "Солонів",
      "Соснівка",
      "Софіївка Друга",
      "Софіївка Перша",
      "Срібне",
      "Ставище",
      "Ставрів",
      "Станіслави",
      "Стара Миколаївка",
      "Стара Носовиця",
      "Старики",
      "Стовпець",
      "Стоморги",
      "Стоянівка",
      "Студянка",
      "Судобичі",
      "Табачуки",
      "Тараканів",
      "Терешів",
      "Теслугів",
      "Товпижин",
      "Топілля",
      "Торговиця",
      "Травневе",
      "Тростянець",
      "Тур’я",
      "Турковичі",
      "Тушебин",
      "Ужинець",
      "Уїздці",
      "Улянівка",
      "Хорупань",
      "Хотин",
      "Хрінники",
      "Чекно",
      "Черешнівка",
      "Шепетин",
      "Шибин",
      "Яблунівка",
      "Яловичі",
      "Ярославичі",
      "Ясинівка",
      "Андрусіїв",
      "Антопіль",
      "Бабин",
      "Бадівка",
      "Базальтове",
      "Балашівка",
      "Бармаки",
      "Батьківці",
      "Башине",
      "Березівка",
      "Березне",
      "Берестовець",
      "Бистричі",
      "Бичаль",
      "Біла Криниця",
      "Білашів",
      "Білів",
      "Білівські Хутори",
      "Білка",
      "Більчаки",
      "Богданівка",
      "Богдашів",
      "Богуші",
      "Бокшин",
      "Болотківці",
      "Борщівка",
      "Борщівка Друга",
      "Борщівка Перша",
      "Бочаниця",
      "Боянівка",
      "Бранів",
      "Бриків",
      "Бродів",
      "Бродівське",
      "Бронне",
      "Бронники",
      "Брюшків",
      "Бубнівка",
      "Бугрин",
      "Бухарів",
      "В’юнівщина",
      "Ведмедівка",
      "Велика Клецька",
      "Велика Купля",
      "Велика Любаша",
      "Велика Омеляна",
      "Велике Поле",
      "Великий Житин",
      "Великий Мидськ",
      "Великий Олексин",
      "Великий Стидин",
      "Великий Шпаків",
      "Великі Межирічі",
      "Великі Селища",
      "Вельбівно",
      "Вересневе",
      "Верхів",
      "Верхівськ",
      "Весняне",
      "Вигін",
      "Витків",
      "Вишеньки",
      "Вілія",
      "Вілля",
      "Вільгір",
      "Вільхівка",
      "Вітковичі",
      "Вовкошів",
      "Волиця",
      "Волосківці",
      "Волошки",
      "Воронів",
      "Воскодави",
      "Ганнівка",
      "Гвіздів",
      "Гільча Друга",
      "Гільча Перша",
      "Глажева",
      "Глибочок",
      "Глинки",
      "Глинськ",
      "Голичівка",
      "Голишів",
      "Головин",
      "Головниця",
      "Горбаків",
      "Горбів",
      "Гориньград Другий",
      "Гориньград Перший",
      "Городище",
      "Гоща",
      "Грабів",
      "Грем’яче",
      "Грозів",
      "Грушвиця Друга",
      "Грушвиця Перша",
      "Грушівка",
      "Грушівська Гута",
      "Губків",
      "Гуменники",
      "Гута",
      "Данилівка",
      "Даничів",
      "Данчиміст",
      "Дворовичі",
      "Деражне",
      "Дерев’яне",
      "Дерев’янче",
      "Дерманка",
      "Дермань Друга",
      "Дермань Перша",
      "Дивень",
      "Диків",
      "Дібрівка",
      "Дмитрівка",
      "Дорогобуж",
      "Дроздів",
      "Дружне",
      "Друхів",
      "Дуби",
      "Дуліби",
      "Дюксин",
      "Жаврів",
      "Жадківка",
      "Жалин",
      "Жалянка",
      "Жильжа",
      "Жобрин",
      "Жорнівка",
      "Забара",
      "Забороль",
      "Завидів",
      "Завизів",
      "Загора",
      "Загороща",
      "Загребля",
      "Залібівка",
      "Залізниця",
      "Залісся",
      "Замлинок",
      "Замостище",
      "Заріцьк",
      "Застав’я",
      "Збуж",
      "Звіздівка",
      "Здолбунів",
      "Зелений Дуб",
      "Зірне",
      "Злазне",
      "Зозів",
      "Золотолин",
      "Зоря",
      "Іваничі",
      "Івачків",
      "Іллін",
      "Ілляшівка",
      "Ільпінь",
      "Йосипівка",
      "Кам’яна Гора",
      "Кам’янка",
      "Караєвичі",
      "Карачун",
      "Квасилів",
      "Клевань",
      "Клопіт",
      "Князівка",
      "Козак",
      "Козлин",
      "Колесники",
      "Коловерти",
      "Колоденка",
      "Колодіївка",
      "Колодязне",
      "Копитів",
      "Копиткове",
      "Корець",
      "Користь",
      "Корнин",
      "Корчин",
      "Корчів’я",
      "Коршів",
      "Космачів",
      "Костопіль",
      "Котів",
      "Кошатів",
      "Країв",
      "Кривичі",
      "Кринички",
      "Кунин",
      "Кураж",
      "Кургани",
      "Курозвани",
      "Кустин",
      "Кутянка",
      "Лебеді",
      "Ледне",
      "Липки",
      "Лідаво",
      "Лінчин",
      "Лісопіль",
      "Люцинів",
      "Лючин",
      "М’ятин",
      "Майків",
      "Макотерти",
      "Мала Клецька",
      "Мала Любаша",
      "Мала Мощаниця",
      "Мала Совпа",
      "Малий Житин",
      "Малий Мидськ",
      "Малий Олексин",
      "Малий Стидин",
      "Малий Шпаків",
      "Малинівка",
      "Малинськ",
      "Малушка",
      "Малятин",
      "Мар’янівка",
      "Маринин",
      "Матіївка",
      "Маща",
      "Межиріч",
      "Метків",
      "Микулин",
      "Милостів",
      "Милятин",
      "Мирне",
      "Миротин",
      "Михалин",
      "Михалківці",
      "Мичів",
      "Мізоч",
      "Мізочок",
      "Мнишин",
      "Могиляни",
      "Моквин",
      "Моквинські Хутори",
      "Морозівка",
      "Мости",
      "Мочулки",
      "Мочулянка",
      "Мощаниця",
      "Мощони",
      "Невірків",
      "Нова Любомирка",
      "Нова Мощаниця",
      "Нова Українка",
      "Новий Берестовець",
      "Новий Корець",
      "Новий Моквин",
      "Новий Світ",
      "Новожуків",
      "Новомалин",
      "Новомильськ",
      "Новородчиці",
      "Новостав",
      "Новостав-Дальній",
      "Новоставці",
      "Обарів",
      "Оженин",
      "Озерко",
      "Озірці",
      "Олександрівка",
      "Олександрія",
      "Олексіївка",
      "Олишва",
      "Орестів",
      "Оржів",
      "Орлівка",
      "Осова",
      "Острог",
      "П’ятигори",
      "Пашуки",
      "Пеньків",
      "Переділи",
      "Перелисянка",
      "Пересопниця",
      "Перетоки",
      "Півче",
      "Підгайне",
      "Підгало",
      "Підгірці",
      "Підліски",
      "Підлужне",
      "Підцурків",
      "Пісків",
      "Плоске",
      "Подоляни",
      "Полівці",
      "Поліське",
      "Поляни",
      "Понебель",
      "Порозове",
      "Посива",
      "Постійне",
      "Посягва",
      "Почапки",
      "Прикордонне",
      "Прислуч",
      "Пустомити",
      "Пухова",
      "Радиславка",
      "Радужне",
      "Радухівка",
      "Ремель",
      "Решуцьк",
      "Рисв’янка",
      "Рівне",
      "Річечина",
      "Рогачів",
      "Розваж",
      "Рокитне",
      "Рубче",
      "Руда-Красна",
      "Русивель",
      "Рясники",
      "Садки",
      "Садове",
      "Самостріли",
      "Сапожин",
      "Світанок",
      "Святе",
      "Свяття",
      "Сергіївка",
      "Симонів",
      "Синів",
      "Сівки",
      "Сіянці",
      "Слобідка",
      "Сморжів",
      "Совпа",
      "Соломка",
      "Соснове",
      "Спасів",
      "Ставки",
      "Ставок",
      "Стадники",
      "Стара Мощаниця",
      "Старий Корець",
      "Старожуків",
      "Стеблівка",
      "Степанівка",
      "Стовпин",
      "Сторожів",
      "Ступно",
      "Суйми",
      "Суськ",
      "Сухівці",
      "Тайкури",
      "Теремне",
      "Терентіїв",
      "Тесів",
      "Тихе",
      "Тишиця",
      "Томахів",
      "Топча",
      "Точевики",
      "Три Копці",
      "Трубиці",
      "Тучин",
      "Угільці",
      "Углище",
      "Українка",
      "Урвенна",
      "Устя",
      "Федорівка",
      "Франівка",
      "Франкопіль",
      "Харалуг",
      "Хмелівка",
      "Ходоси",
      "Хорів",
      "Хрінів",
      "Цурків",
      "Черниця",
      "Черняхів",
      "Чудви",
      "Чудниця",
      "Шкарів",
      "Шлях",
      "Шостаків",
      "Шпанів",
      "Шубків",
      "Щекичин",
      "Ючин",
      "Яблунне",
      "Ялинівка",
      "Яполоть",
      "Яринівка",
      "Ясининичі",
      "Ясне",
      "Яснобір",
      "Яцьковичі",
      "Бережки",
      "Бережниця",
      "Березове",
      "Берестя",
      "Білаші",
      "Біловіж",
      "Більськ",
      "Білятичі",
      "Блажове",
      "Бродець",
      "Буда",
      "Будимля",
      "Будки-Кам’янські",
      "Будки-Сновидовицькі",
      "Бутейки",
      "Вежиця",
      "Велике Вербче",
      "Великий Черемель",
      "Великі Озера",
      "Велихів",
      "Велюнь",
      "Вербівка",
      "Вири",
      "Вирка",
      "Висове",
      "Висоцьк",
      "Вільне",
      "Волоша",
      "Глинне",
      "Глушицьке",
      "Глушиця",
      "Городець",
      "Грабунь",
      "Грані",
      "Гранітне",
      "Грицьки",
      "Гута-Перейма",
      "Двірець",
      "Дерть",
      "Довге",
      "Дроздинь",
      "Дубки",
      "Дубняки",
      "Дубровиця",
      "Єльне",
      "Жадень",
      "Залішани",
      "Зарів’я",
      "Заслуччя",
      "Зелень",
      "Зносичі",
      "Золоте",
      "Кам’яне",
      "Кам’яне-Случанське",
      "Карасин",
      "Катеринівка",
      "Кисоричі",
      "Клесів",
      "Колки",
      "Копище",
      "Корост",
      "Костянтинівка",
      "Кривиця",
      "Кричильськ",
      "Крупове",
      "Кузьмівка",
      "Купель",
      "Кураш",
      "Лісове",
      "Літвиця",
      "Лугове",
      "Любиковичі",
      "Людинь",
      "Лютинськ",
      "Люхча",
      "Мале Вербче",
      "Масевичі",
      "Маслопуща",
      "Мельниця",
      "Миляч",
      "Мочулище",
      "Мушні",
      "Немовичі",
      "Нивецьк",
      "Обірки",
      "Обсіч",
      "Одринки",
      "Озерськ",
      "Орв’яниця",
      "Осницьк",
      "Остки",
      "Партизанське",
      "Переброди",
      "Переходичі",
      "Підгірник",
      "Підлісне",
      "Познань",
      "Поляна",
      "Порубка",
      "Працюки",
      "Пугач",
      "Ремчиці",
      "Різки",
      "Рудня-Карпилівська",
      "Сарни",
      "Сварині",
      "Сварицевичі",
      "Селець",
      "Селище",
      "Смородськ",
      "Сновидовичі",
      "Соломіївка",
      "Старе Село",
      "Степань",
      "Страшеве",
      "Стрільськ",
      "Тинне",
      "Томашгород",
      "Трипутня",
      "Тріскині",
      "Труди",
      "Тумень",
      "Тутовичі",
      "Убереж",
      "Угли",
      "Удрицьк",
      "Узлісся",
      "Хилін",
      "Хміль",
      "Хочин",
      "Цепцевичі",
      "Чабель",
      "Чемерне",
      "Чудель",
      "Шахи",
      "Яблунька",
      "Ясинець",
      "Ясногірка",
    ],
  },
  {
    region: "Сумська",
    cities: [
      "Алтинівка",
      "Андріївське",
      "Антонівка",
      "Анютине",
      "Артюхове",
      "Атаманське",
      "Базилівка",
      "Безкровне",
      "Бережне",
      "Бистрик",
      "Біжівка",
      "Білогалиця",
      "Білогриве",
      "Білозерка",
      "Білоусівка",
      "Бобине",
      "Божок",
      "Болотівка",
      "Бондарі",
      "Боцманів",
      "Бочечки",
      "Бошівка",
      "Бояро-Лежачі",
      "Броди",
      "Бруски",
      "Бувалине",
      "Буйвалове",
      "Бунякине",
      "Бурики",
      "Буринь",
      "В’язенка",
      "В’язове",
      "В’ятка",
      "Васильківщина",
      "Вегерівка",
      "Великий Самбір",
      "Верхня Сагарівка",
      "Веселе",
      "Веселі Гори",
      "Вирівка",
      "Вишневе",
      "Вишневий Яр",
      "Вікторинівка",
      "Вільне",
      "Вовчик",
      "Вознесенка",
      "Волинцеве",
      "Волинцівське",
      "Волокитине",
      "Воргол",
      "Воронівка",
      "Воронцове",
      "Воскресенка",
      "Вощинине",
      "Гамаліївка",
      "Гатка",
      "Гвинтове",
      "Гірки",
      "Голуби",
      "Голубкове",
      "Горохове",
      "Гречкине",
      "Грибаньове",
      "Грузьке",
      "Губарівщина",
      "Гути",
      "Дептівка",
      "Дич",
      "Дідівщина",
      "Дмитрівка",
      "Добротове",
      "Дорошівка",
      "Дубинка",
      "Дубов’язівка",
      "Дубовичі",
      "Духанівка",
      "Дяківка",
      "Жабкине",
      "Жари",
      "Жигайлівка",
      "Жолдаки",
      "Жуківка",
      "Заболотове",
      "Заводське",
      "Загорівка",
      "Зазірки",
      "Залізничне",
      "Зарічне",
      "Заріччя",
      "Заруддя",
      "Землянка",
      "Зінове",
      "Зозулине",
      "Іванівка",
      "Іванівське",
      "Ігорівка",
      "Іллінське",
      "Кагань",
      "Калашинівка",
      "Калинівка",
      "Калишенкове",
      "Камінь",
      "Капітанівка",
      "Карабутове",
      "Кардаші",
      "Карпенкове",
      "Кащенкове",
      "Клепали",
      "Князівка",
      "Ковбасине",
      "Козацьке",
      "Козаче",
      "Козлівка",
      "Коновалове",
      "Конотоп",
      "Копилове",
      "Коренівка",
      "Корольки",
      "Котівка",
      "Коханівка",
      "Кочерги",
      "Кошари",
      "Кошарське",
      "Красне",
      "Кролевець",
      "Кружок",
      "Кубареве",
      "Кубахове",
      "Кубракове",
      "Кузьки",
      "Курдюмове",
      "Курилівка",
      "Лапшине",
      "Латишівка",
      "Лебедєве",
      "Леонтіївка",
      "Линове",
      "Лисогубівка",
      "Литвиновичі",
      "Лобківка",
      "Локня",
      "Луч",
      "Любитове",
      "Мазівка",
      "Майорівка",
      "Малий Самбір",
      "Малушине",
      "Манухівка",
      "Мар’янівка",
      "Марухи",
      "Мачулища",
      "Медведеве",
      "Мельня",
      "Миколаєнкове",
      "Миколаївка",
      "Мирне",
      "Михайлівка",
      "Михайло-Ганнівка",
      "Мишутине",
      "Мінакове",
      "Молодівка",
      "Морозівка",
      "Мостище",
      "Мутин",
      "Неровнине",
      "Нехаївка",
      "Нечаївка",
      "Нечаївське",
      "Нижня Сагарівка",
      "Ніжинське",
      "Нова Олександрівка",
      "Нова Слобода",
      "Нова Шарпівка",
      "Нове",
      "Новий Мир",
      "Нові Гончарі",
      "Новомутин",
      "Новоселиця",
      "Новоселівка",
      "Новослобідське",
      "Нотаріусівка",
      "Обтове",
      "Озаричі",
      "Окіп",
      "Олександрівка",
      "Оріхівка",
      "Отрохове",
      "Папкине",
      "Партизанське",
      "Пасіка",
      "Пасьовини",
      "Пекарі",
      "Перемога",
      "Пересипки",
      "Першотравневе",
      "Пиротчине",
      "Питомник",
      "Пищикове",
      "Підлипне",
      "Піски",
      "Пішкове",
      "Плахівка",
      "Плотникове",
      "Погорілівка",
      "Покровське",
      "Полтавка",
      "Попівка",
      "Почепці",
      "Привокзальне",
      "Прилужжя",
      "Присеймів’я",
      "Прогрес",
      "Пруди",
      "Путивль",
      "Раки",
      "Рев’якине",
      "Ретик",
      "Реутинці",
      "Рівне",
      "Рокитне",
      "Романчукове",
      "Ротівка",
      "Роща",
      "Руднєве",
      "Савойське",
      "Сажалки",
      "Салтикове",
      "Сапушине",
      "Сарнавщина",
      "Сафонівка",
      "Сахарове",
      "Сахни",
      "Свидня",
      "Свобода",
      "Селезнівка",
      "Селище",
      "Сиром’ятникове",
      "Сім’янівка",
      "Сімейкине",
      "Скуносове",
      "Слобода",
      "Сніжки",
      "Совинка",
      "Соловйове",
      "Соломашине",
      "Сонцеве",
      "Сорока",
      "Сорочинське",
      "Соснівка",
      "Спадщина",
      "Спаське",
      "Срібровщина",
      "Стара Шарпівка",
      "Старі Гончарі",
      "Степанівка",
      "Стрільники",
      "Суворове",
      "Суховерхівка",
      "Таранське",
      "Тарасівка",
      "Темне",
      "Терехове",
      "Тернівка",
      "Тимофіївка",
      "Товченикове",
      "Тополине",
      "Торговиця",
      "Трудове",
      "Тулиголове",
      "Тулушка",
      "Турутине",
      "Улянівка",
      "Успенка",
      "Уцькове",
      "Фесівка",
      "Харівка",
      "Хижки",
      "Ховзівка",
      "Хоменкове",
      "Хрещатик",
      "Чалищівка",
      "Чаплищі",
      "Червона Гірка",
      "Червона Слобода",
      "Червоне Озеро",
      "Червоний Яр",
      "Черепівка",
      "Чернеча Слобода",
      "Чорнобривкине",
      "Чорноплатове",
      "Чумакове",
      "Шаповалівка",
      "Шевченківське",
      "Шевченкове",
      "Ширяєве",
      "Шлях",
      "Шпокалка",
      "Шпотівка",
      "Шулешівка",
      "Щекине",
      "Щекинське",
      "Щербинівка",
      "Юр’єве",
      "Юрівка",
      "Ярове",
      "Ярославець",
      "Яцине",
      "Аврамківщина",
      "Артемо-Растівка",
      "Бакирівка",
      "Бандури",
      "Березівка",
      "Бідани",
      "Білка",
      "Боголюбове",
      "Борзівщина",
      "Боромля",
      "Братениця",
      "Братське",
      "Бугрувате",
      "Будне",
      "Буймер",
      "Буймерівка",
      "Буро-Рубанівка",
      "Бурячиха",
      "Василівка",
      "Велика Писарівка",
      "Велике Озеро",
      "Вербове",
      "Веселий Гай",
      "Виноградне",
      "Високе",
      "Вищевеселе",
      "Вовків",
      "Восьме Березня",
      "Всадки",
      "Гай-Мошенка",
      "Гнилиця",
      "Горяйстівка",
      "Градське",
      "Гребениківка",
      "Грінченкове",
      "Грунь",
      "Грунька",
      "Гусарщина",
      "Дернове",
      "Доброславівка",
      "Добрянське",
      "Довжик",
      "Дружба",
      "Духовниче",
      "Жолоби",
      "Журавне",
      "Закаблуки",
      "Залісне",
      "Залужани",
      "Золотарівка",
      "Зубівка",
      "Івахи",
      "Їздецьке",
      "Кам’янецьке",
      "Кам’янка",
      "Кардашівка",
      "Карпилівка",
      "Катанське",
      "Катеринівка",
      "Качанівка",
      "Кириківка",
      "Климентове",
      "Козятин",
      "Комарівка",
      "Комиші",
      "Коновалик",
      "Копійки",
      "Корабельське",
      "Крамчанка",
      "Криничне",
      "Кударі",
      "Кудряве",
      "Куземин",
      "Лантратівка",
      "Лимареве",
      "Лисе",
      "Литовка",
      "Лісне",
      "Лугівка",
      "Лукашівка",
      "Лутище",
      "Лучка",
      "Люджа",
      "Майське",
      "Мала Павлівка",
      "Манчичі",
      "Маракучка",
      "Мартинівка",
      "Машкове",
      "Мащанка",
      "Микитівка",
      "Михайленкове",
      "Мозкове",
      "Молодецьке",
      "Мошенка",
      "Набережне",
      "Неплатине",
      "Ницаха",
      "Новгородське",
      "Новопостроєне",
      "Новоукраїнка",
      "Оводівка",
      "Овчаренки",
      "Озера",
      "Олексине",
      "Оленинське",
      "Олешня",
      "Охтирка",
      "П’яткине",
      "Пархомівка",
      "Пасіки",
      "Перелуг",
      "Печини",
      "Пилівка",
      "Підлозіївка",
      "Пластюки",
      "Поділ",
      "Пожня",
      "Пологи",
      "Поляне",
      "Пономаренки",
      "Попелівщина",
      "Пристань",
      "Рибальське",
      "Риботень",
      "Рідне",
      "Розсохувате",
      "Розсоші",
      "Рубани",
      "Рябина",
      "Рябівка",
      "Савелове",
      "Садки",
      "Семереньки",
      "Сидорова Яруга",
      "Скелька",
      "Скрягівка",
      "Соборне",
      "Солдатське",
      "Сонячне",
      "Сосонка",
      "Софіївка",
      "Спірне",
      "Станичне",
      "Станова",
      "Стара Іванівка",
      "Стрілецька Пушкарка",
      "Тростянець",
      "Тучне",
      "Українка",
      "Хвощова",
      "Хухра",
      "Чернеччина",
      "Чупахівка",
      "Шабалтаєве",
      "Шевченків Гай",
      "Широкий Берег",
      "Шолудьки",
      "Шурове",
      "Щоми",
      "Яблучне",
      "Ямне",
      "Ясенове",
      "Авраменкове",
      "Анастасівка",
      "Андріївка",
      "Андріяшівка",
      "Антоненкове",
      "Артюхівка",
      "Аршуки",
      "Баба",
      "Бабакове",
      "Байрак",
      "Басівка",
      "Бацмани",
      "Беєве",
      "Березняки",
      "Берестівка",
      "Берізки",
      "Беседівка",
      "Бессарабка",
      "Біж",
      "Біловод",
      "Біловодське",
      "Білоярське",
      "Бобрик",
      "Бойкове",
      "Бороданове",
      "Борозенка",
      "Бродок",
      "Бугаївка",
      "Бурбине",
      "Бухалове",
      "В’юнне",
      "Вакулки",
      "Ведмеже",
      "Велика Бутівка",
      "Велика Діброва",
      "Велика Лука",
      "Велике",
      "Великий Ліс",
      "Великі Бубни",
      "Великі Будки",
      "Весела Долина",
      "Веселий Степ",
      "Весногірське",
      "Вільшана",
      "Віхове",
      "Вовківці",
      "Вовкове",
      "Володимирівка",
      "Волошнівка",
      "Воропаї",
      "Вощилиха",
      "Гаврилівка",
      "Гаї",
      "Гай",
      "Галаївець",
      "Галенкове",
      "Галка",
      "Герасимівка",
      "Глинськ",
      "Голінка",
      "Голубці",
      "Горове",
      "Городище",
      "Горькове",
      "Гострий Шпиль",
      "Грабщина",
      "Греки",
      "Гринівка",
      "Гришки",
      "Губське",
      "Гудими",
      "Дараганове",
      "Деркачівка",
      "Дзеркалька",
      "Діброва",
      "Дігтярівка",
      "Дігтярка",
      "Довга Лука",
      "Довгополівка",
      "Долина",
      "Дремове",
      "Дубина",
      "Житне",
      "Загребелля",
      "Заїзд",
      "Заклимок",
      "Закроївщина",
      "Закубанка",
      "Залатиха",
      "Залуцьке",
      "Засулля",
      "Зелене",
      "Зелений Гай",
      "Зеленівщина",
      "Зеленківка",
      "Зюзюки",
      "Іваниця",
      "Капустинці",
      "Карпці",
      "Кашпури",
      "Ківшик",
      "Кімличка",
      "Клин",
      "Клюси",
      "Козельне",
      "Колісники",
      "Колісникове",
      "Коломійцева Долина",
      "Колядинець",
      "Комишанка",
      "Коновали",
      "Кононенкове",
      "Кореневе",
      "Коржі",
      "Коритище",
      "Коровинці",
      "Королівщина",
      "Косарівщина",
      "Косенки",
      "Костяни",
      "Костянтинів",
      "Коцупіївка",
      "Кропивинці",
      "Кузьменкове",
      "Кулішівка",
      "Куплеваха",
      "Курмани",
      "Кушніри",
      "Лаврове",
      "Лахнівщина",
      "Левондівка",
      "Левченки",
      "Легуші",
      "Липівка",
      "Липівське",
      "Липова Долина",
      "Лікарівщина",
      "Лісківщина",
      "Лукашове",
      "Луки",
      "Луценкове",
      "Лучки",
      "Мазне",
      "Макіївське",
      "Мала Черепівка",
      "Мале",
      "Малі Бубни",
      "Малі Будки",
      "Малярівщина",
      "Марківське",
      "Маршали",
      "Матлахове",
      "Мелешківка",
      "Мельники",
      "Мельникове",
      "Миколаївське",
      "Мірки",
      "Мокіївка",
      "Москалівка",
      "Москівщина",
      "Московське",
      "Мухувате",
      "Недригайлів",
      "Нелени",
      "Немудруї",
      "Ненадіївка",
      "Нестеренки",
      "Нова Гребля",
      "Новицьке",
      "Новокалинівка",
      "Новопетрівка",
      "Новосеменівка",
      "Овеча",
      "Овлаші",
      "Озерне",
      "Олексіївка",
      "Олещенкове",
      "Омелькове",
      "П’ятидуб",
      "Панасівка",
      "Перекір",
      "Перекопівка",
      "Перетічки",
      "Перехрестівка",
      "Підставки",
      "Плавинище",
      "Плужникове",
      "Побиванка",
      "Погожа Криниця",
      "Погреби",
      "Подільки",
      "Польове",
      "Попівщина",
      "Посад",
      "Потопиха",
      "Правдюки",
      "Пустовійтівка",
      "Пушкарщина",
      "Пшінчине",
      "Ракова Січ",
      "Реви",
      "Ріпки",
      "Рогинці",
      "Ромни",
      "Рубанка",
      "Рудоман",
      "Русанівка",
      "Садове",
      "Саєве",
      "Саї",
      "Сакуниха",
      "Салогубівка",
      "Саханське",
      "Світівщина",
      "Семенівка",
      "Сененкове",
      "Синівка",
      "Скрипалі",
      "Слобідка",
      "Сміле",
      "Сороколітове",
      "Спартак",
      "Степурине",
      "Столярове",
      "Стягайлівка",
      "Сулими",
      "Сурмачівка",
      "Суха Грунь",
      "Тарасенки",
      "Терешки",
      "Терни",
      "Тимощенкове",
      "Тимченки",
      "Товсте",
      "Томашівка",
      "Тютюнникове",
      "Фартушине",
      "Федотове",
      "Філонове",
      "Харченки",
      "Хмелів",
      "Холодне",
      "Холодний Яр",
      "Холодник",
      "Хоминці",
      "Хорол",
      "Хоружівка",
      "Хустянка",
      "Цибуленки",
      "Чеберяки",
      "Чемоданівка",
      "Червона Долина",
      "Червоне",
      "Червоногірка",
      "Чижикове",
      "Чирвине",
      "Чисте",
      "Шаповалове",
      "Шилівське",
      "Шкуматове",
      "Шматове",
      "Шумське",
      "Юхти",
      "Яганівка",
      "Якименки",
      "Якимовичі",
      "Яковенкове",
      "Яловий Окіп",
      "Ярмолинці",
      "Ярошівка",
      "Яснопільщина",
      "Амбари",
      "Аркавське",
      "Атинське",
      "Бабаківка",
      "Баїха",
      "Бакша",
      "Барабашівка",
      "Барвінкове",
      "Барилівка",
      "Барило",
      "Басівщина",
      "Бездрик",
      "Безсалівка",
      "Безсокирне",
      "Березів Яр",
      "Битиця",
      "Бишкінь",
      "Білани",
      "Біликівка",
      "Біловишневе",
      "Біловоди",
      "Білопілля",
      "Боброве",
      "Бойки",
      "Болотишине",
      "Бондарівщина",
      "Боровенька",
      "Бранцівка",
      "Бровкове",
      "Бубликове",
      "Будилка",
      "Будки",
      "Букати",
      "Бурівка",
      "Бурчак",
      "Бутовщина",
      "Буцикове",
      "Вакалівщина",
      "Валіївка",
      "Валки",
      "Варачине",
      "Василівщина",
      "Васюківщина",
      "Велика Рибиця",
      "Велика Чернеччина",
      "Великий Бобрик",
      "Великий Вистороп",
      "Великий Прикіл",
      "Великий Яр",
      "Великі Вільми",
      "Великі Луки",
      "Верхнє Піщане",
      "Верхня Пожня",
      "Верхня Сироватка",
      "Верхосулка",
      "Вершина",
      "Веселівка",
      "Виднівка",
      "Визирівка",
      "Вири",
      "Вільшанка",
      "Влізьки",
      "Водолаги",
      "Волфине",
      "Ворожба",
      "Воронине",
      "Воропай",
      "Галушки",
      "Ганнівка-Вирівська",
      "Ганнівка-Тернівська",
      "Ганнівське",
      "Гапонівка",
      "Гарбарі",
      "Гарбузівка",
      "Гезівка",
      "Гиріне",
      "Гірне",
      "Глибне",
      "Глиняне",
      "Голишівське",
      "Головачі",
      "Головашівка",
      "Голубівка",
      "Горобівка",
      "Гостинне",
      "Гостробури",
      "Грабовське",
      "Грабці",
      "Грамине",
      "Графське",
      "Гринцеве",
      "Гриценкове",
      "Грицини",
      "Грунівка",
      "Грушеве",
      "Гудимівка",
      "Гуринівка",
      "Гутницьке",
      "Даценківка",
      "Деркачі",
      "Дігтярі",
      "Дігтярне",
      "Доценківка",
      "Дремлюги",
      "Дружне",
      "Дудченки",
      "Думівка",
      "Єлисеєнкове",
      "Житейське",
      "Жолобок",
      "Журавка",
      "Забуги",
      "Загірське",
      "Закумське",
      "Залізняк",
      "Запсілля",
      "Захарівське",
      "Зацарне",
      "Зелена Роща",
      "Земляне",
      "Зоряне",
      "Івахнівка",
      "Іволжанське",
      "Іскрисківщина",
      "Йосипове",
      "Кальченки",
      "Калюжне",
      "Кам’яне",
      "Кандибине",
      "Караван",
      "Кекине",
      "Кердилівщина",
      "Кирияківщина",
      "Кисла Дубина",
      "Кияниця",
      "Кіндратівка",
      "Ключинівка",
      "Коваленки",
      "Коломийці",
      "Комарицьке",
      "Корчаківка",
      "Корчани",
      "Коршачина",
      "Косівщина",
      "Костів",
      "Костянтинівка",
      "Котенки",
      "Кравченкове",
      "Краснопілля",
      "Крижик",
      "Кровне",
      "Куданівка",
      "Кулики",
      "Куличка",
      "Курасове",
      "Курган",
      "Куянівка",
      "Лебедин",
      "Линтварівка",
      "Липняк",
      "Лифине",
      "Лідине",
      "Лікарське",
      "Лободівщина",
      "Лозове",
      "Лозово-Грушеве",
      "Лохня",
      "Лугове",
      "Луциківка",
      "Любачеве",
      "Ляшки",
      "Майдаки",
      "Макіївка",
      "Максимівщина",
      "Мала Корчаківка",
      "Мала Рибиця",
      "Малий Бобрик",
      "Малий Вистороп",
      "Малі Вільми",
      "Маньки",
      "Мар’ївка",
      "Мар’їне",
      "Марківка",
      "Мартинці",
      "Марусенкове",
      "Марченки",
      "Машарі",
      "Межиріч",
      "Мезенівка",
      "Мелячиха",
      "Микільське",
      "Миколаївка-Тернівська",
      "Миловидівка",
      "Мирлоги",
      "Миронівщина",
      "Миропілля",
      "Миропільське",
      "Михайлівське",
      "Могриця",
      "Мороча",
      "Москаленки",
      "Москалівщина",
      "Московський Бобрик",
      "Мукіївка",
      "Нагірнівка",
      "Над’ярне",
      "Надточієве",
      "Наумівка",
      "Нескучне",
      "Нижня Сироватка",
      "Низи",
      "Никонці",
      "Нова Січ",
      "Новеньке",
      "Нові Вирки",
      "Новоандріївка",
      "Новодмитрівка",
      "Новоіванівка",
      "Новомиколаївка",
      "Новомихайлівка",
      "Новоолександрівка",
      "Новосільське",
      "Новосуханівка",
      "Облоги",
      "Ободи",
      "Овдянське",
      "Олександрія",
      "Олексенки",
      "Олексенкове",
      "Омельченки",
      "Ополонське",
      "Осоївка",
      "Павленкове",
      "Павлівка",
      "Павлівське",
      "Павлючки",
      "Падалки",
      "Панченки",
      "Парфили",
      "Патріотівка",
      "Пашкине",
      "Пащенкове",
      "Переліски",
      "Перше Травня",
      "Петрушівка",
      "Печище",
      "Писарівка",
      "Північне",
      "Підліснівка",
      "Підопригори",
      "Підсулля",
      "Пісківка",
      "Піщане",
      "Плетньове",
      "Покровка",
      "Помірки",
      "Порозок",
      "Постольне",
      "Пристайлове",
      "Протопопівщина",
      "Проходи",
      "Птиче",
      "Пушкарівка",
      "Радчуки",
      "Радьківка",
      "Ревки",
      "Рибці",
      "Рижівка",
      "Річки",
      "Рогізне",
      "Руда",
      "Руденкове",
      "Рудка",
      "Руднівка",
      "Рябушки",
      "Рясне",
      "Савенки",
      "Сад",
      "Самара",
      "Самотоївка",
      "Северинівка",
      "Сергіївка",
      "Симонівка",
      "Синяк",
      "Ситники",
      "Сінне",
      "Сіренки",
      "Сіробабине",
      "Склярівка",
      "Славгород",
      "Смоляниківка",
      "Соколине",
      "Солідарне",
      "Соляники",
      "Сохани",
      "Старе Село",
      "Старі Вирки",
      "Старонове",
      "Стеблянки",
      "Степаненкове",
      "Степне",
      "Степове",
      "Степок",
      "Стецьківка",
      "Стінка",
      "Стрільцеве",
      "Стукалівка",
      "Ступки",
      "Сула",
      "Сульське",
      "Суми",
      "Супруни",
      "Супрунівка",
      "Сушилине",
      "Таратутине",
      "Терешківка",
      "Терещенки",
      "Тімірязєвка",
      "Товста",
      "Токарі",
      "Топчії",
      "Тригуби",
      "Трохименкове",
      "Тур’я",
      "Угроїди",
      "Федірки",
      "Філонівщина",
      "Харківщина",
      "Хвойне",
      "Хилькове",
      "Хмелівка",
      "Хомине",
      "Хотінь",
      "Храпівщина",
      "Цимбалівка",
      "Черванівка",
      "Червлене",
      "Червона Діброва",
      "Червоний Кут",
      "Череватівка",
      "Черемухівка",
      "Чернецьке",
      "Чернишки",
      "Чижове",
      "Шапошникове",
      "Шевченківка",
      "Шкуратівка",
      "Шпилівка",
      "Шпиль",
      "Штанівка",
      "Штепівка",
      "Шумили",
      "Щетини",
      "Юнаківка",
      "Юсупівка",
      "Яблунівка",
      "Янченки",
      "Яроші",
      "Ясени",
      "Ясенок",
      "Яснопілля",
      "Яструбине",
      "Базелівщина",
      "Баничі",
      "Баранівка",
      "Бачівськ",
      "Бензики",
      "Береза",
      "Біла Береза",
      "Білиця",
      "Білокопитове",
      "Бобилівка",
      "Богданівка",
      "Богданка",
      "Боровичі",
      "Бугор",
      "Будища",
      "Будівельне",
      "Василець",
      "Василівське",
      "Велика Берізка",
      "Винторівка",
      "Вишеньки",
      "Вікторове",
      "Вільна Слобода",
      "Вовківка",
      "Вовна",
      "Воздвиженське",
      "Вознесенське",
      "Вороніж",
      "Гаврилова Слобода",
      "Гирине",
      "Глазове",
      "Глухів",
      "Говорунове",
      "Годунівка",
      "Горіле",
      "Грем’ячка",
      "Гудівщина",
      "Гудове",
      "Гукове",
      "Гута",
      "Гутко-Ожинка",
      "Дем’янівка",
      "Демченкове",
      "Деражня",
      "Дібрівка",
      "Дорошенкове",
      "Дунаєць",
      "Есмань",
      "Ємадикине",
      "Жалківщина",
      "Жихове",
      "Заболотне",
      "Заруцьке",
      "Зелена Діброва",
      "Зноб-Новгородське",
      "Зноб-Трубчевська",
      "Зорине",
      "Іващенкове",
      "Івот",
      "Івотка",
      "Імшана",
      "Іонине",
      "Каліївка",
      "Карпеченкове",
      "Київське",
      "Клишки",
      "Клочківка",
      "Княжичі",
      "Ковтунове",
      "Кореньок",
      "Косинське",
      "Красичка",
      "Красноярське",
      "Кренидівка",
      "Кривенкове",
      "Кривоносівка",
      "Крупець",
      "Кудоярове",
      "Курдюмівка",
      "Кустине",
      "Кучерівка",
      "Кушкине",
      "Лісова Поляна",
      "Ломленка",
      "Луг",
      "Лужки",
      "Лукашенкове",
      "Лушники",
      "Любахове",
      "Люте",
      "Макове",
      "Мала Слобідка",
      "Марчихина Буда",
      "Масензівка",
      "Масиків",
      "Мацкове",
      "Мефедівка",
      "Микитське",
      "Миронівка",
      "Муравейня",
      "Нарбутівка",
      "Некрасове",
      "Неплюєве",
      "Нова Гута",
      "Нова Спарта",
      "Нововасилівка",
      "Нововолодимирівка",
      "Об’єднане",
      "Обложки",
      "Ображіївка",
      "Одрадне",
      "Олександрівське",
      "Олине",
      "Ольгине",
      "Орлів Яр",
      "Орлівка",
      "Остроушки",
      "Очкине",
      "Палащенкове",
      "Паліївка",
      "Папірня",
      "Первомайське",
      "Пигарівка",
      "Пирогівка",
      "Погребки",
      "Поліське",
      "Полошки",
      "Порохонь",
      "Потапівка",
      "Привілля",
      "Прудище",
      "Пустогород",
      "Ржане",
      "Ріг",
      "Родіонівка",
      "Рождественське",
      "Рожковичі",
      "Романькове",
      "Ромашкове",
      "Ростов",
      "Рудак",
      "Руденка",
      "Рудня",
      "Сваркове",
      "Свеса",
      "Свірж",
      "Середина-Буда",
      "Сидорівка",
      "Ситне",
      "Скобичівське",
      "Сліпород",
      "Слоут",
      "Смикарівка",
      "Смолине",
      "Собич",
      "Собичеве",
      "Солотвине",
      "Сопич",
      "Сорокине",
      "Сороковий Клин",
      "Стара Гута",
      "Старикове",
      "Студенок",
      "Сутиски",
      "Суходіл",
      "Таборище",
      "Тиманівка",
      "Товстодубове",
      "Тополя",
      "Троїцьке",
      "Туранівка",
      "Уздиця",
      "Українське",
      "Уланове",
      "Улиця",
      "Уралове",
      "Усок",
      "Феофілівка",
      "Фотовиж",
      "Харківка",
      "Хильчичі",
      "Хлібороб",
      "Ходине",
      "Ходуня",
      "Холодівщина",
      "Хотминівка",
      "Чапліївка",
      "Червона Зоря",
      "Чернацьке",
      "Черневе",
      "Четвертакове",
      "Чигин",
      "Чорні Лози",
      "Чуйківка",
      "Шакутівщина",
      "Шалигине",
      "Шалимівка",
      "Шатрище",
      "Шкирманівка",
      "Шостка",
      "Щебри",
      "Ямпіль",
      "Ясна Поляна",
      "Яструбщина",
    ],
  },
  {
    region: "Тернопільська",
    cities: [
      "Андруга",
      "Андрушівка",
      "Антонівці",
      "Бакоти",
      "Башківці",
      "Башуки",
      "Бережанка",
      "Биківці",
      "Білозірка",
      "Білокриниця",
      "Бірки",
      "Богданівка",
      "Бодаки",
      "Боложівка",
      "Бонівка",
      "Борсуки",
      "Борщівка",
      "Бриків",
      "Буглів",
      "Будки",
      "Бутин",
      "Валігури",
      "Ванжулів",
      "Васьківці",
      "Велика Білка",
      "Велика Горянка",
      "Велика Іловиця",
      "Великий Кунинець",
      "Великі Бережці",
      "Великі Вікнини",
      "Великі Дедеркали",
      "Великі Загайці",
      "Великі Кусківці",
      "Великі Млинівці",
      "Вербиця",
      "Вербовець",
      "Верещаки",
      "Весела",
      "Веселівка",
      "Вишгородок",
      "Вишнівець",
      "Вілія",
      "Влащинці",
      "Вовківці",
      "Волиця",
      "Гаї",
      "Гнидава",
      "Горинка",
      "Града",
      "Грибова",
      "Гриньки",
      "Гриньківці",
      "Двірець",
      "Дзвиняча",
      "Діброва",
      "Дунаїв",
      "Духів",
      "Жолоби",
      "Жолобки",
      "Жуківці",
      "Забара",
      "Загірці",
      "Загороддя",
      "Залісці",
      "Залужжя",
      "Затишшя",
      "Зашляхом",
      "Зеблази",
      "Іванківці",
      "Івання",
      "Іква",
      "Катеринівка",
      "Кинахівці",
      "Кімната",
      "Козачки",
      "Колосова",
      "Комарин",
      "Комарівка",
      "Коновиця",
      "Кордишів",
      "Коржківці",
      "Коростова",
      "Котюжини",
      "Коханівка",
      "Краснолука",
      "Кременець",
      "Кривчики",
      "Крижі",
      "Круголець",
      "Крутнів",
      "Кудлаївка",
      "Куликів",
      "Кути",
      "Кутиска",
      "Кутянка",
      "Кушлин",
      "Ланівці",
      "Лідихів",
      "Літовище",
      "Лішня",
      "Лози",
      "Лопушне",
      "Лосятин",
      "Людвище",
      "Люлинці",
      "Мала Білка",
      "Мала Горянка",
      "Мала Іловиця",
      "Мала Карначівка",
      "Мала Снігурівка",
      "Малий Кунинець",
      "Малі Бережці",
      "Малі Вікнини",
      "Малі Дедеркали",
      "Малі Загайці",
      "Малі Кусківці",
      "Малі Садки",
      "Маневе",
      "Мартишківці",
      "Матвіївці",
      "Мирове",
      "Михайлівка",
      "Мишківці",
      "Мізюринці",
      "Млинівці",
      "Молотків",
      "Москалівка",
      "Нападівка",
      "Новий Кокорів",
      "Новий Олексинець",
      "Новосілка",
      "Новостав",
      "Обич",
      "Огризківці",
      "Одерадівка",
      "Онишківці",
      "Оришківці",
      "Осники",
      "Очеретне",
      "Пахиня",
      "Передмірка",
      "Переморівка",
      "Печірна",
      "Підгайці",
      "Підлісне",
      "Підлісці",
      "Піщатинці",
      "Плиска",
      "Плоске",
      "Поляни",
      "Попівці",
      "Потуторів",
      "Почаїв",
      "Радошівка",
      "Раковець",
      "Раславка",
      "Рибча",
      "Ридомиль",
      "Розтоки",
      "Рохманів",
      "Рудка",
      "Руська Гута",
      "Савчиці",
      "Садки",
      "Сапанів",
      "Синівці",
      "Снігурівка",
      "Соколівка",
      "Соснівка",
      "Сошище",
      "Старий Вишнівець",
      "Старий Кокорів",
      "Старий Олексинець",
      "Старий Почаїв",
      "Старий Тараж",
      "Стіжок",
      "Сураж",
      "Татаринці",
      "Темногайці",
      "Тетильківці",
      "Тилявка",
      "Тури",
      "Угорськ",
      "Устечко",
      "Федьківці",
      "Ходаки",
      "Хотівка",
      "Хотовиця",
      "Цеценівка",
      "Чайчинці",
      "Чугалі",
      "Шкроботівка",
      "Шпиколоси",
      "Шумбар",
      "Шумськ",
      "Шушківці",
      "Юськівці",
      "Якимівці",
      "Августівка",
      "Ангелівка",
      "Баворів",
      "Багатківці",
      "Базаринці",
      "Базниківка",
      "Байківці",
      "Баранівка",
      "Бартошівка",
      "Бенева",
      "Бережани",
      "Беримівці",
      "Бернадівка",
      "Бзовиця",
      "Бишки",
      "Біла",
      "Білківці",
      "Білоголови",
      "Білоскірка",
      "Біще",
      "Бліх",
      "Божиків",
      "Боків",
      "Болязуби",
      "Боричівка",
      "Бронгалівка",
      "Будилів",
      "Будова",
      "Бурканів",
      "Буцнів",
      "Вага",
      "Варваринці",
      "Велика Березовиця",
      "Велика Лука",
      "Велика Плавуча",
      "Великий Глибочок",
      "Великий Ходачків",
      "Великі Бірки",
      "Великі Гаї",
      "Вербів",
      "Вербівці",
      "Верняки",
      "Вертелка",
      "Веснівка",
      "Вибудів",
      "Вимислівка",
      "Висипівці",
      "Витківці",
      "Вишеньки",
      "Вишнівчик",
      "Вищі Луб’янки",
      "Вівся",
      "Вікторівка",
      "Вільховець",
      "Вільшанка",
      "Вірлів",
      "Вовчківці",
      "Волосівка",
      "Волощина",
      "Воля",
      "Воробіївка",
      "Гаї-Гречинські",
      "Гаї-За-Рудою",
      "Гаї-Розтоцькі",
      "Гаї-Шевченківські",
      "Гайворонка",
      "Гайок",
      "Галич",
      "Галущинці",
      "Гарбузів",
      "Гвардійське",
      "Геленки",
      "Гиновичі",
      "Глещава",
      "Глинна",
      "Глинчуки",
      "Глядки",
      "Гнилиці",
      "Гнилички",
      "Гнильче",
      "Гніздичне",
      "Годів",
      "Голгоча",
      "Голендра",
      "Голотки",
      "Голошинці",
      "Гончарки",
      "Горби",
      "Гори-Стрийовецькі",
      "Городище",
      "Городниця",
      "Грабківці",
      "Грабовець",
      "Грицівці",
      "Гукалівці",
      "Гумниська",
      "Гутисько",
      "Гущанки",
      "Данилівці",
      "Дарахів",
      "Двірці",
      "Дворище",
      "Дворіччя",
      "Демня",
      "Денисів",
      "Деренівка",
      "Дибще",
      "Дичків",
      "Дітківці",
      "Дмухівці",
      "Доброводи",
      "Добромірка",
      "Довге",
      "Довжанка",
      "Долина",
      "Домаморич",
      "Дорофіївка",
      "Драганівка",
      "Драгоманівка",
      "Дружба",
      "Дубівці",
      "Дуляби",
      "Жабиня",
      "Жеребки",
      "Жовнівка",
      "Жуків",
      "Заберізки",
      "Забойки",
      "Завалів",
      "Загайці",
      "Загір’я",
      "Заздрість",
      "Залав’є",
      "Залізці",
      "Залісся",
      "Зарваниця",
      "Зарубинці",
      "Заруддя",
      "Зарудечко",
      "Застав’є",
      "Заставче",
      "Застінка",
      "Застіноче",
      "Затурин",
      "Збараж",
      "Зборів",
      "Зеленче",
      "Золота Слобода",
      "Золотники",
      "Золочівка",
      "Зубів",
      "Іванівка",
      "Іванчани",
      "Івачів",
      "Івачів Горішній",
      "Івачів Долішній",
      "Івашківці",
      "Ігровиця",
      "Ілавче",
      "Ішків",
      "Йосипівка",
      "Кабарівці",
      "Калинівка",
      "Кальне",
      "Кам’янка",
      "Кам’янки",
      "Капустинський Ліс",
      "Капустинці",
      "Карначівка",
      "Качанівка",
      "Квіткове",
      "Киданці",
      "Кип’ячка",
      "Клебанівка",
      "Климківці",
      "Кобзарівка",
      "Кобилля",
      "Кобиловолоки",
      "Козівка",
      "Козлів",
      "Козова",
      "Козярі",
      "Кокутківці",
      "Колодіївка",
      "Колодне",
      "Конопківка",
      "Конюхи",
      "Корчунок",
      "Коршилів",
      "Коршилівка",
      "Костянтинівка",
      "Котів",
      "Котузів",
      "Кошляки",
      "Красівка",
      "Красна",
      "Краснопуща",
      "Красносільці",
      "Кретівці",
      "Криве",
      "Кривки",
      "Кровинка",
      "Кудинівці",
      "Кудобинці",
      "Купчинці",
      "Курівці",
      "Курники",
      "Куропатники",
      "Куряни",
      "Лавриківці",
      "Ладичин",
      "Лапшин",
      "Ласківці",
      "Лиса",
      "Лисичинці",
      "Литвинів",
      "Ліски",
      "Лісники",
      "Літятин",
      "Лозівка",
      "Лозова",
      "Лопушани",
      "Лошнів",
      "Лучка",
      "Магдалівка",
      "Маковисько",
      "Максимівка",
      "Мала Березовиця",
      "Мала Плавуча",
      "Малашівці",
      "Малий Глибочок",
      "Малий Ходачків",
      "Малів",
      "Маловоди",
      "Манаїв",
      "Мар’янівка",
      "Медин",
      "Медова",
      "Метенів",
      "Мечищів",
      "Микулинці",
      "Мильне",
      "Мирне",
      "Миролюбівка",
      "Мислова",
      "Митниця",
      "Мишковичі",
      "Млиниська",
      "Млинці",
      "Мовчанівка",
      "Мозолівка",
      "Молохів",
      "Монилівка",
      "Мужилів",
      "Мусорівці",
      "Мшана",
      "Мшанець",
      "Надорожнів",
      "Надрічне",
      "Налужжя",
      "Нараїв",
      "Настасів",
      "Нестерівці",
      "Нетерпинці",
      "Нижчі Луб’янки",
      "Нище",
      "Нова Брикуля",
      "Нова Гребля",
      "Нова Могильниця",
      "Нове Село",
      "Новий Роговець",
      "Новики",
      "Носів",
      "Носівці",
      "Ободівка",
      "Озерна",
      "Озерянка",
      "Олесине",
      "Олишківці",
      "Оліїв",
      "Опрілівці",
      "Оріховець",
      "Остальці",
      "Остап’є",
      "Осташівці",
      "Острів",
      "Острівець",
      "Охримівці",
      "Павлів",
      "Пальчинці",
      "Панасівка",
      "Пановичі",
      "Панталиха",
      "Пеньківці",
      "Перепельники",
      "Петриків",
      "Писарівка",
      "Підберізці",
      "Підвисоке",
      "Підволочиськ",
      "Підгайчики",
      "Підгора",
      "Підгородне",
      "Підруда",
      "Піщане",
      "Плебанівка",
      "Плесківці",
      "Плісняни",
      "Пліхів",
      "Плотича",
      "Погрібці",
      "Поділля",
      "Покропивна",
      "Полупанівка",
      "Поплави",
      "Поручин",
      "Посухів",
      "Потік",
      "Поточани",
      "Потутори",
      "Почапинці",
      "Присівці",
      "Просівці",
      "Прошова",
      "Рай",
      "Ратищі",
      "Рекшин",
      "Ренів",
      "Решнівка",
      "Рибники",
      "Різдвяни",
      "Рогачин",
      "Рожиськ",
      "Розгадів",
      "Розношинці",
      "Романівка",
      "Романове Село",
      "Росохуватець",
      "Рудники",
      "Сапова",
      "Саранчуки",
      "Семенів",
      "Семиківці",
      "Сеньків",
      "Серединки",
      "Серединці",
      "Середнє",
      "Серетець",
      "Синява",
      "Синягівка",
      "Сировари",
      "Сільце",
      "Скалат",
      "Скоморохи",
      "Скорики",
      "Славна",
      "Слобідка",
      "Слов’ятин",
      "Смиківці",
      "Смолянка",
      "Соборне",
      "Сокільники",
      "Соколів",
      "Сонячне",
      "Сороцьке",
      "Соснів",
      "Стара Брикуля",
      "Стара Могильниця",
      "Старе Місто",
      "Старий Збараж",
      "Старий Литвинів",
      "Старий Скалат",
      "Староміщина",
      "Стегниківці",
      "Степове",
      "Стриганці",
      "Стриївка",
      "Струсів",
      "Ступки",
      "Супранівка",
      "Сухівці",
      "Сущин",
      "Тарасівка",
      "Тарноруда",
      "Таурів",
      "Теклівка",
      "Теофілівка",
      "Теофіпілка",
      "Теребовля",
      "Тернопіль",
      "Терпилівка",
      "Товстолуг",
      "Токи",
      "Травневе",
      "Травотолоки",
      "Тростянець",
      "Турівка",
      "Тустоголови",
      "Тютьків",
      "Угринів",
      "Уритва",
      "Урмань",
      "Фащівка",
      "Футори",
      "Хатки",
      "Хмелиська",
      "Хмелівка",
      "Хоми",
      "Хомівка",
      "Хоптянка",
      "Хоробрів",
      "Хоростець",
      "Храбузна",
      "Цебрів",
      "Ценів",
      "Цецівка",
      "Цицори",
      "Чагарі-Збаразькі",
      "Червень",
      "Червоне",
      "Чернелів-Руський",
      "Чернилівка",
      "Чернихів",
      "Чернихівці",
      "Чеснівський Раковець",
      "Чистилів",
      "Чистопади",
      "Чорний Ліс",
      "Чумалі",
      "Шайбівка",
      "Шевченкове",
      "Шельпаки",
      "Шибалин",
      "Шили",
      "Шимківці",
      "Шляхтинці",
      "Шумляни",
      "Щаснівка",
      "Щепанів",
      "Юстинівка",
      "Яблунівка",
      "Ярославичі",
      "Ярчівці",
      "Ясне",
      "Яструбове",
      "Яцківці",
      "Антонів",
      "Бабинці",
      "Базар",
      "Бариш",
      "Бедриківці",
      "Берем’яни",
      "Бересток",
      "Бертники",
      "Бичківці",
      "Білий Потік",
      "Білинівка",
      "Білівці",
      "Білобожниця",
      "Більче-Золоте",
      "Білявинці",
      "Блищанка",
      "Бобрівники",
      "Бобулинці",
      "Боднарівка",
      "Боришківці",
      "Борщів",
      "Босири",
      "Броварі",
      "Буданів",
      "Бурдяківці",
      "Буряківка",
      "Буцики",
      "Бучач",
      "Васильків",
      "Васильківці",
      "Велеснів",
      "Великий Говилів",
      "Великі Чорнокінці",
      "Верб’ятин",
      "Вербівка",
      "Вербка",
      "Верхівці",
      "Верхняківці",
      "Вигода",
      "Виноградне",
      "Винятинці",
      "Висічка",
      "Високе",
      "Вікно",
      "Вільхівчик",
      "Вістря",
      "Возилів",
      "Ворвулинці",
      "Гадинківці",
      "Гермаківка",
      "Гиньківці",
      "Глибочок",
      "Глібів",
      "Глушка",
      "Голігради",
      "Головчинці",
      "Гончарівка",
      "Горигляди",
      "Горішня Вигнанка",
      "Горішня Слобідка",
      "Городок",
      "Горожанка",
      "Горошова",
      "Грабівці",
      "Гранітне",
      "Григорів",
      "Гримайлів",
      "Губин",
      "Гусятин",
      "Гуштин",
      "Гуштинка",
      "Давидківці",
      "Джурин",
      "Джуринська Слобідка",
      "Дзвенигород",
      "Дзвиняч",
      "Дзвинячка",
      "Дністрове",
      "Добрівляни",
      "Доброполе",
      "Дорогичівка",
      "Дубенка",
      "Дубівка",
      "Дуліби",
      "Дунів",
      "Дуплиська",
      "Ємелівка",
      "Жабинці",
      "Жизномир",
      "Жилинці",
      "Жнибороди",
      "Заболотівка",
      "Завадівка",
      "Завалля",
      "Заводське",
      "Задарів",
      "Заліщики",
      "Залуччя",
      "Заривинці",
      "Заставці",
      "Затишне",
      "Збриж",
      "Збручанське",
      "Звенигород",
      "Звиняч",
      "Зелена",
      "Зелене",
      "Зелений Гай",
      "Зозулинці",
      "Золотий Потік",
      "Зубрець",
      "Іване-Золоте",
      "Іване-Пусте",
      "Іванків",
      "Калагарівка",
      "Калинівщина",
      "Карашинці",
      "Касперівці",
      "Киданів",
      "Клювинці",
      "Ковалівка",
      "Козаччина",
      "Козина",
      "Кокошинці",
      "Колиндяни",
      "Колодрібка",
      "Констанція",
      "Копичинці",
      "Коржова",
      "Королівка",
      "Коропець",
      "Косів",
      "Космирин",
      "Костільники",
      "Котівка",
      "Коцюбинці",
      "Коцюбинчики",
      "Кошилівці",
      "Красіїв",
      "Красне",
      "Кривеньке",
      "Криволука",
      "Кривче",
      "Криниця",
      "Крогулець",
      "Крутилів",
      "Кудринці",
      "Кулаківці",
      "Курдибанівка",
      "Кут",
      "Лазарівка",
      "Латківці",
      "Лежанівка",
      "Лисичники",
      "Лисівці",
      "Литячі",
      "Личківці",
      "Ліщанці",
      "Лосяч",
      "Лука",
      "Лядське",
      "Мазурівка",
      "Майдан",
      "Мала Лука",
      "Малий Говилів",
      "Малі Бірки",
      "Малі Чорнокінці",
      "Маркова",
      "Мартинівка",
      "Матеушівка",
      "Медведівці",
      "Межигір’я",
      "Мельниця-Подільська",
      "Миколаївка",
      "Милівці",
      "Михалків",
      "Мишків",
      "Млинки",
      "Монастириська",
      "Монастириха",
      "Монастирок",
      "Мухавка",
      "Мушкарів",
      "Мушкатівка",
      "Набережне",
      "Нагірянка",
      "Нагоряни",
      "Нивра",
      "Нижбірок",
      "Низьколизи",
      "Нирків",
      "Нова Гута",
      "Нові Петликівці",
      "Новоставці",
      "Озеряни",
      "Окопи",
      "Олексинці",
      "Оленівка",
      "Олеша",
      "Осівці",
      "Паївка",
      "Палашівка",
      "Панівці",
      "Папірня",
      "Пастуше",
      "Переволока",
      "Передмістя",
      "Перемилів",
      "Переходи",
      "Печорна",
      "Пилатківці",
      "Пилипче",
      "Пилява",
      "Пишківці",
      "Пищатинці",
      "Підзамочок",
      "Підлісся",
      "Підпилип’я",
      "Пізнанка",
      "Пожежа",
      "Полівці",
      "Помірці",
      "Порохова",
      "Постолівка",
      "Пробіжна",
      "Пушкарі",
      "Раштівці",
      "Ридодуби",
      "Рідколісся",
      "Ріпинці",
      "Рожанівка",
      "Ромашівка",
      "Росохач",
      "Рублин",
      "Рудки",
      "Рукомиш",
      "Русилів",
      "Савелівка",
      "Саджівка",
      "Садове",
      "Самолусківці",
      "Сапогів",
      "Свершківці",
      "Свидова",
      "Світле",
      "Семаківці",
      "Сидорів",
      "Синьків",
      "Скала-Подільська",
      "Сков’ятин",
      "Скомороше",
      "Скородинці",
      "Слобідка-Мушкатівська",
      "Сновидів",
      "Сокиринці",
      "Сокілець",
      "Солоне",
      "Сорока",
      "Сороки",
      "Сосулівка",
      "Ставки",
      "Стара Ягільниця",
      "Старий Нижбірок",
      "Старі Петликівці",
      "Стигла",
      "Стінка",
      "Стрілківці",
      "Суходіл",
      "Сухостав",
      "Тарнавка",
      "Товсте",
      "Товстеньке",
      "Торське",
      "Трибухівці",
      "Трійця",
      "Тростянці",
      "Трубчин",
      "Тудорів",
      "Тулин",
      "Турильче",
      "Увисла",
      "Угринь",
      "Угриньківці",
      "Улашківці",
      "Урожайне",
      "Устя",
      "Устя-Зелене",
      "Хартонівці",
      "Хлопівка",
      "Хмелева",
      "Хоростків",
      "Худиківці",
      "Худіївці",
      "Цвітова",
      "Целіїв",
      "Цигани",
      "Чабарівка",
      "Чагарі",
      "Черкавщина",
      "Чехів",
      "Чорнокінецька Воля",
      "Чортків",
      "Швайківці",
      "Швейків",
      "Шершенівка",
      "Шидлівці",
      "Шипівці",
      "Шишківці",
      "Шманьківці",
      "Шманьківчики",
      "Шульганівка",
      "Шупарка",
      "Шутроминці",
      "Щитівці",
      "Юр’ямпіль",
      "Яблунів",
      "Ягільниця",
      "Язловець",
      "Якубівка",
      "Яргорів",
    ],
  },
  {
    region: "Харківська",
    cities: [
      "Андріївка",
      "Андрусівка",
      "Бабаки",
      "Бабенки",
      "Байрак",
      "Балабанівка",
      "Баранівка",
      "Баранове",
      "Басове",
      "Березівка",
      "Бідило",
      "Білоусове",
      "Благодатне",
      "Богодухів",
      "Бондарівка",
      "Борохи",
      "Братениця",
      "Бровкове",
      "Бугаї Другі",
      "Бугаївка",
      "Бузова",
      "Бузове",
      "Бурівка",
      "Бурякове",
      "Буцьківка",
      "В’язова",
      "Валки",
      "Вдовичине",
      "Велика Губщина",
      "Велика Кадигробівка",
      "Велика Рогозянка",
      "Вертіївка",
      "Високе",
      "Високопілля",
      "Вишневе",
      "Відродженівське",
      "Війтенки",
      "Вікнине",
      "Вікторівка",
      "Вільхівське",
      "Вільшанське",
      "Вінницькі Івани",
      "Водопій",
      "Водяна Балка",
      "Водяне",
      "Володимирівка",
      "Воскобійники",
      "Воскресенівка",
      "Гавриші",
      "Газове",
      "Ганнівка",
      "Гарбузи",
      "Гаркавець",
      "Гвоздьове",
      "Гладківка",
      "Головашівка",
      "Гонтів Яр",
      "Горбанівка",
      "Городнє",
      "Горького",
      "Гребінники",
      "Гресі",
      "Григорівка",
      "Гринів Яр",
      "Гринців Ріг",
      "Гришкове",
      "Губарівка",
      "Гузівка",
      "Гур’їв",
      "Гур’їв Козачок",
      "Гуринівка",
      "Гуртовівка",
      "Гути",
      "Данильчин Кут",
      "Дев’ятибратове",
      "Дегтярі",
      "Дмитрівка",
      "Добропілля",
      "Довжик",
      "Дорофіївка",
      "Дублянка",
      "Дуванка",
      "Журавлі",
      "Заброди",
      "Завадське",
      "Завгороднє",
      "Зайцівка",
      "Заміське",
      "Зарічне",
      "Зарябинка",
      "Зіньківське",
      "Золочів",
      "Золочівське",
      "Зрубанка",
      "Зубівка",
      "Іванівка",
      "Івано-Шийчине",
      "Івашки",
      "Кадниця",
      "Каленикове",
      "Калинове",
      "Кам’яно-Хутірське",
      "Кантакузівка",
      "Каплунівка",
      "Капранське",
      "Карайкозівка",
      "Карасівка",
      "Катричівка",
      "Качалівка",
      "Кисівка",
      "Китченківка",
      "Кияни",
      "Кленове",
      "Княжа Долина",
      "Кобзарівка",
      "Ков’яги",
      "Ковалі",
      "Ковалівка",
      "Ковалівське",
      "Ковальчуківка",
      "Коверівка",
      "Козаченківка",
      "Козіївка",
      "Колодківка",
      "Коломак",
      "Коломацький Шлях",
      "Колонтаїв",
      "Комарівка",
      "Корбині Івани",
      "Корнієнкове",
      "Коротке",
      "Корсунівка",
      "Корсунове",
      "Косенкове",
      "Костів",
      "Костянтинівка",
      "Котелевка",
      "Крамарівка",
      "Краснокутськ",
      "Крисине",
      "Круглик",
      "Крупчине",
      "Крута Балка",
      "Кручик",
      "Кузьмівка",
      "Куп’єваха",
      "Кусторівка",
      "Ландишове",
      "Латишівка",
      "Леміщине",
      "Леськівка",
      "Лисконоги",
      "Литвинівка",
      "Литвинове",
      "Лісне",
      "Логвинівка",
      "Лозова",
      "Лучки",
      "Любівка",
      "Лютівка",
      "Майдан",
      "Макариха",
      "Макарове",
      "Максимівка",
      "Мала Іванівка",
      "Мала Кадигробівка",
      "Мала Рогозянка",
      "Малижине",
      "Малі Феськи",
      "Манили",
      "Мар’їне",
      "Мартинівка",
      "Матвіївка",
      "Маяк",
      "Мельникове",
      "Мерло",
      "Миколаївка",
      "Минківка",
      "Мирне",
      "Миролюбівка",
      "Миронівка",
      "Михайлівка",
      "Михайлівське",
      "Мізяки",
      "Мірошники",
      "Мірошниківка",
      "Мічурінське",
      "Мозолівка",
      "Мойка",
      "Морозова Долина",
      "Москаленки",
      "Муравське",
      "Мурафа",
      "Мусійки",
      "Нагальне",
      "Настеньківка",
      "Нестеренки",
      "Новий Мерчик",
      "Новоіванівське",
      "Новоселівка",
      "Новософіївка",
      "Новоукраїнка",
      "Огульці",
      "Одноробівка",
      "Одрада",
      "Окіп",
      "Олександрівка",
      "Олексіївка",
      "Оленівське",
      "Олійники",
      "Орішанка",
      "Основинці",
      "Очеретове",
      "Павлівка",
      "Павлове",
      "Павлюківка",
      "Паляничники",
      "Панасівка",
      "Пархомівка",
      "Пасічне",
      "Пащенівка",
      "Первухинка",
      "Перекіп",
      "Перемога",
      "Перепелицівка",
      "Перовське",
      "Першотравневе",
      "Петренкове",
      "Петрівка",
      "Петрівське",
      "Петропавлівка",
      "Пильнянка",
      "Писарівка",
      "Підлісне",
      "Піски",
      "Пісочин",
      "Покровка",
      "Полкова Микитівка",
      "Постольне",
      "Привокзальне",
      "Прогрес",
      "Прокопенкове",
      "Прядківка",
      "Рандава",
      "Рідкодуб",
      "Рідний Край",
      "Різдвяне",
      "Різуненкове",
      "Ріпки",
      "Рогівка",
      "Розсохівка",
      "Розсохувате",
      "Рудий Байрак",
      "Рябоконеве",
      "Рясне",
      "Сазоно-Баланівка",
      "Свинарі",
      "Світличне",
      "Семенів Яр",
      "Семківка",
      "Сергіївка",
      "Серпневе",
      "Сидоренкове",
      "Ситники",
      "Сінне",
      "Скельки",
      "Сковородинівка",
      "Скорики",
      "Скосогорівка",
      "Слобідка",
      "Сніги",
      "Сніжків",
      "Сонцедарівка",
      "Сорокове",
      "Соснівка",
      "Сотницький Козачок",
      "Старий Мерчик",
      "Старі Валки",
      "Степанівка",
      "Степне",
      "Степове",
      "Стогнії",
      "Сургаївка",
      "Суха Балка",
      "Сухини",
      "Таверівка",
      "Тетющине",
      "Тимофіївка",
      "Трохимівка",
      "Трудолюбівка",
      "Тугаївка",
      "Тупицівка",
      "Турове",
      "Уди",
      "Улянівка",
      "Феськи",
      "Філатове",
      "Халимонівка",
      "Хворостове",
      "Ходунаївка",
      "Хрущова Микитівка",
      "Хутірське",
      "Цапівка",
      "Цепочкине",
      "Цилюрики",
      "Чепелі",
      "Червона Зоря",
      "Черемушна",
      "Чернещина",
      "Чорноглазівка",
      "Шарівка",
      "Шевченкове",
      "Шелестове",
      "Шелудькове",
      "Шигимагине",
      "Шийки",
      "Шийчине",
      "Широкий Яр",
      "Шкарлати",
      "Шлях",
      "Шляхове",
      "Шуби",
      "Щербаки",
      "Щербинівка",
      "Яблунівка",
      "Яблучне",
      "Явтухівка",
      "Ясеневе",
      "Яхременки",
      "Архангелівка",
      "Асіївка",
      "Африканівка",
      "Бабенкове",
      "Балаклія",
      "Барабашівка",
      "Барвінкове",
      "Бахтин",
      "Берестянки",
      "Богданове",
      "Богодарове",
      "Боголюбівка",
      "Богуславка",
      "Бойні",
      "Борівська Андріївка",
      "Борова",
      "Бородоярське",
      "Борщівка",
      "Бражківка",
      "Бригадирівка",
      "Букине",
      "Василівка Друга",
      "Василівка Перша",
      "Велика Андріївка",
      "Велика Комишуваха",
      "Вербівка",
      "Веселе",
      "Вишнева",
      "Вище Солоне",
      "Вільне",
      "Вільхуватка",
      "Вірнопілля",
      "Вітрівка",
      "Вовчий Яр",
      "Волвенкове",
      "Волобуївка",
      "Волохів Яр",
      "Гаврилівка",
      "Глазунівка",
      "Глинське",
      "Глущенкове",
      "Голубівка",
      "Гороховатка",
      "Грушуваха",
      "Гусарівка",
      "Дальня Шебелинка",
      "Данилівка",
      "Дібрівне",
      "Діброва",
      "Довгалівка",
      "Довгеньке",
      "Донець",
      "Донецьке",
      "Дружелюбівка",
      "Забавне",
      "Заводи",
      "Загризове",
      "Залиман",
      "Зелений Гай",
      "Іванчуківка",
      "Ізюм",
      "Ізюмське",
      "Іскра",
      "Калинівка",
      "Кам’янка",
      "Капитолівка",
      "Карнаухівка",
      "Козютівка",
      "Копанка",
      "Копанки",
      "Котівка",
      "Крейдянка",
      "Криничне",
      "Крючки",
      "Куньє",
      "Курулька",
      "Левківка",
      "Липчанівка",
      "Лисогірка",
      "Лозовенька",
      "Мала Комишуваха",
      "Малинівка",
      "Маліївка",
      "Малолітки",
      "Мар’ївка",
      "Мечебилове",
      "Мирна Долина",
      "Мілова",
      "Морозівка",
      "Надеждівка",
      "Нижня Журавка",
      "Нижче Солоне",
      "Нікополь",
      "Нова Гусарівка",
      "Нова Дмитрівка",
      "Нова Кругляківка",
      "Нова Миколаївка",
      "Нова Серпухівка",
      "Новий Мир",
      "Новобогданове",
      "Новомиколаївка",
      "Новопавлівка",
      "Новоплатонівка",
      "Новосергіївка",
      "Новотроїцьке",
      "Норцівка",
      "Нурове",
      "Ольгівка",
      "Оскіл",
      "П’ятигірське",
      "Пазіївка",
      "Парнувате",
      "Пасіка",
      "Пашкове",
      "Первомайське",
      "Петропілля",
      "Пимонівка",
      "Підвисоке",
      "Підлиман",
      "Піски-Радьківські",
      "Погонівка",
      "Подолівка",
      "Покровське",
      "Поляна",
      "Попасне",
      "Попівка",
      "Пригоже",
      "Придонецьке",
      "Пришиб",
      "Протопопівка",
      "Рідне",
      "Рудневе",
      "Савинці",
      "Семенівка",
      "Семиланне",
      "Серафимівка",
      "Синичено",
      "Слабунівка",
      "Слобожанське",
      "Сніжківка",
      "Соколівка",
      "Співаківка",
      "Ставкова Балка",
      "Стара Семенівка",
      "Степок",
      "Студенок",
      "Сулигівка",
      "Суха Кам’янка",
      "Сухий Яр",
      "Таранушине",
      "Теплянка",
      "Тихоцьке",
      "Топольське",
      "Українка",
      "Успенське",
      "Федорівка",
      "Чепіль",
      "Червона Балка",
      "Червона Гірка",
      "Червона Поляна",
      "Червоне",
      "Червоний Лиман",
      "Червоний Став",
      "Червоний Шлях",
      "Чистоводівка",
      "Чорнобаївка",
      "Шебелинка",
      "Шевелівка",
      "Шийківка",
      "Шпаківка",
      "Щасливе",
      "Щурівка",
      "Явірське",
      "Язикове",
      "Яковенкове",
      "Яремівка",
      "Ясинувате",
      "Абазівка",
      "Антонівка",
      "Аполлонівка",
      "Багата Чернещина",
      "Балки",
      "Бердянка",
      "Берестове",
      "Берестовенька",
      "Бесарабівка",
      "Білоусівка",
      "Варварівка",
      "Великі Бучки",
      "Винники",
      "Вільховий Ріг",
      "Власівка",
      "Вовківка",
      "Вознесенське",
      "Гадяч",
      "Гаркушине",
      "Германівка",
      "Гірчаківка",
      "Гранове",
      "Гришівка",
      "Гутирівка",
      "Дальнє",
      "Дар-Надежда",
      "Дегтярка",
      "Добренька",
      "Дослідне",
      "Дружба",
      "Дубові Гряди",
      "Дудівка",
      "Дячківка",
      "Забарине",
      "Завадівка",
      "Загаркушине",
      "Займанка",
      "Залінійне",
      "Зачепилівка",
      "Зелена Діброва",
      "Зелене",
      "Зелений Клин",
      "Землянки",
      "Зіньківщина",
      "Золотухівка",
      "Зоряне",
      "Іванівське",
      "Івано-Слиньківка",
      "Калюжине",
      "Карабущине",
      "Караван",
      "Касянівка",
      "Катеринівка",
      "Кегичівка",
      "Кирилівка",
      "Клинове",
      "Кобзівка",
      "Кобзівка Друга",
      "Кобцівка",
      "Козацьке",
      "Козачі Майдани",
      "Козирів",
      "Кофанівка",
      "Коханівка",
      "Кочетівка",
      "Красна Гірка",
      "Красне",
      "Красноград",
      "Красноярка",
      "Краснянське",
      "Крутоярівка",
      "Кузьминівка",
      "Куми",
      "Леб’яже",
      "Лебедівка",
      "Лесівка",
      "Лигівка",
      "Лиманівка",
      "Лип’янка",
      "Литовки",
      "Лукашівка",
      "Ляшівка",
      "Мажарка",
      "Малий Орчик",
      "Малі Бучки",
      "Маховик",
      "Медведівка",
      "Миколо-Комишувата",
      "Мокрянка",
      "Москальцівка",
      "Муравлинка",
      "Нагірне",
      "Надеждине",
      "Наталине",
      "Нова Балка",
      "Нова Парафіївка",
      "Нова Чернещина",
      "Нове Мажарове",
      "Нове Пекельне",
      "Новобогданівка",
      "Нововолодимирівка",
      "Новодмитрівка",
      "Новоіванівка",
      "Новомихайлівка",
      "Новоолександрівка",
      "Огіївка",
      "Одрадівка",
      "Олександрівське",
      "Оленівка",
      "Олянівка",
      "Орільське",
      "Орчик",
      "Охоче",
      "Палатки",
      "Парасковія",
      "Педашка Перша",
      "Печіївка",
      "Письмаківка",
      "Піскувате",
      "Піщанка",
      "Раківка",
      "Роздолля",
      "Розсохувата",
      "Романівка",
      "Рояківка",
      "Рунівщина",
      "Садове",
      "Сахновщина",
      "Світле",
      "Серго",
      "Скалонівка",
      "Скиртяне",
      "Сомівка",
      "Софіївка",
      "Станичне",
      "Старе Мажарове",
      "Старе Пекельне",
      "Старовірівка",
      "Староволодимирівка",
      "Сугарівське",
      "Судиха",
      "Тавежня",
      "Тарасівка",
      "Тернуватка",
      "Тишенківка",
      "Травневе",
      "Устимівка",
      "Халтуріна",
      "Хрестище",
      "Червона Долина",
      "Червоний Степ",
      "Чорнолозка",
      "Шкаврове",
      "Яковлівка",
      "Ясна Поляна",
      "Амбарне",
      "Анискине",
      "Аркадівка",
      "Аркушине",
      "Артільне",
      "Балка",
      "Безмятежне",
      "Березники",
      "Біле",
      "Благодатівка",
      "Богданівське",
      "Богодарівка",
      "Болдирівка",
      "Бологівка",
      "Борівське",
      "Буряківка",
      "Василенкове",
      "Василівка",
      "Васильцівка",
      "Велика Шапківка",
      "Великий Бурлук",
      "Великий Виселок",
      "Великі Хутори",
      "Верхньозорянське",
      "Вишнівка",
      "Вільшана",
      "Волоська Балаклія",
      "Воронцівка",
      "Гетьманівка",
      "Глушківка",
      "Гнилиця",
      "Гнилиця Перша",
      "Гогине",
      "Гороб’ївка",
      "Горожанівка",
      "Горяне",
      "Гракове",
      "Грачівка",
      "Гроза",
      "Грушівка",
      "Гряниківка",
      "Гусинка",
      "Дворічанське",
      "Дворічна",
      "Дворічне",
      "Добролюбівка",
      "Довжанка",
      "Дорошенкове",
      "Дорошівка",
      "Єгорівка",
      "Жуків Яр",
      "Журавка",
      "Заміст",
      "Западне",
      "Зарубинка",
      "Затишне",
      "Зорянське",
      "Івашкине",
      "Канівцеве",
      "Кислівка",
      "Ківшарівка",
      "Кіндрашівка",
      "Колісниківка",
      "Колодязне",
      "Комісарове",
      "Котлярівка",
      "Кравцівка",
      "Красне Перше",
      "Красноярське",
      "Крохмальне",
      "Кругляківка",
      "Куп’янськ",
      "Куп’янськ-Вузловий",
      "Купине",
      "Курганне",
      "Курилівка",
      "Курочкине",
      "Кутьківка",
      "Кучерівка",
      "Лелюківка",
      "Лиман Другий",
      "Лиман Перший",
      "Лісна Стінка",
      "Лобанівка",
      "Лозова Друга",
      "Лозова Перша",
      "Лозове",
      "Мала Шапківка",
      "Малахове",
      "Малий Бурлук",
      "Малі Кринки",
      "Мальцівка",
      "Манцівка",
      "Масютівка",
      "Мечнікове",
      "Микільське",
      "Миколаївка Друга",
      "Миколаївка Перша",
      "Миропілля",
      "Митрофанівка",
      "Мілове",
      "Моначинівка",
      "Московка",
      "Мостове",
      "Нежданівка",
      "Нестерівка",
      "Нефедівка",
      "Нечволодівка",
      "Нижній Бурлук",
      "Нова Олександрівка",
      "Нова Тарасівка",
      "Новий Лиман",
      "Нововасилівка",
      "Новоєгорівка",
      "Новомлинськ",
      "Новоосинове",
      "Новостепанівка",
      "Новоужвинівка",
      "Обухівка",
      "Огурцівка",
      "Одрадне",
      "Озерне",
      "Орлянка",
      "Осадьківка",
      "Осиново",
      "Паламарівка",
      "Петро-Іванівка",
      "Підсереднє",
      "Піщане",
      "Плескачівка",
      "Плоске",
      "Подоли",
      "Пойдунівка",
      "Полковниче",
      "Полтава",
      "Потихонове",
      "Приколотне",
      "Прилютове",
      "Пристін",
      "Прокопівка",
      "Просянка",
      "Путникове",
      "Радьківка",
      "Раївка",
      "Рогозянка",
      "Роздольне",
      "Рублене",
      "Садовод",
      "Сазонівка",
      "Самарське",
      "Самборівка",
      "Селище",
      "Сенькове",
      "Сеньок",
      "Середній Бурлук",
      "Синиха",
      "Синьківка",
      "Сірий Яр",
      "Слизневе",
      "Смолівка",
      "Смородьківка",
      "Соболівка",
      "Соніно",
      "Сподобівка",
      "Ставище",
      "Станіславка",
      "Старий Чизвик",
      "Степова Новоселівка",
      "Стецьківка",
      "Стінка",
      "Строївка",
      "Сумське",
      "Табаївка",
      "Тавільжанка",
      "Тамарганівка",
      "Терни",
      "Тетянівка",
      "Тищенківка",
      "Токарівка",
      "Тополі",
      "Троїцьке",
      "Устинівка",
      "Фиголівка",
      "Хатнє",
      "Хомине",
      "Худоярове",
      "Цибівка",
      "Червона Хвиля",
      "Чорне",
      "Чугунівка",
      "Шев’яківка",
      "Шипувате",
      "Широке",
      "Шишківка",
      "Юр’ївка",
      "Ягідне",
      "Яєчне",
      "Алісівка",
      "Бакшарівка",
      "Батюшки",
      "Башилівка",
      "Безпальцеве",
      "Берека",
      "Берестки",
      "Біляївка",
      "Близнюки",
      "Богданівка",
      "Богомолівка",
      "Браїлівка",
      "Братолюбівка",
      "Бритай",
      "Бубнове Перше",
      "Булацелівка",
      "Бунакове",
      "Бурбулатове",
      "Валер’яновка",
      "Васюкове",
      "Верхній Бишкин",
      "Верхньоводяне",
      "Верхня Орілька",
      "Верхня Самара",
      "Верхове",
      "Вільне Друге",
      "Вільне Перше",
      "Водолага",
      "Водолазьке",
      "Ганно-Рудаєве",
      "Герсеванівка",
      "Герсеванівське",
      "Горохівка",
      "Григоросове",
      "Грушине",
      "Далеке",
      "Дивізійне",
      "Добровілля",
      "Довгове",
      "Домаха",
      "Дубове",
      "Дунине",
      "Енергетиків",
      "Єлизаветівка",
      "Єфремівка",
      "Задорожнє",
      "Закутнівка",
      "Запарівка",
      "Запорізьке",
      "Захарівське",
      "Зеленівка",
      "Зубове",
      "Караченців",
      "Картамиш",
      "Кашпурівка",
      "Квітневе",
      "Киселі",
      "Кінне",
      "Кіптівка",
      "Копані",
      "Красиве",
      "Краснопавлівка",
      "Краснопавлівське",
      "Криштопівка",
      "Крутоярка",
      "Крюкове",
      "Лагідне",
      "Ладне",
      "Лиман",
      "Литовщина",
      "Лісівське",
      "Лозівське",
      "Лугове",
      "Мальцівське",
      "Маслівка",
      "Милівка",
      "Морокине",
      "Настасівка",
      "Нестеліївка",
      "Нижня Краснопавлівка",
      "Нова Іванівка",
      "Нова Мечебилівка",
      "Нова Семенівка",
      "Нове",
      "Новоберецьке",
      "Новомар’ївка",
      "Новонадеждине",
      "Новопокровка",
      "Новоуплатне",
      "Оддихне",
      "Одинецьке",
      "Одрадове",
      "Орілька",
      "Остерське",
      "Острівщина",
      "Павлівка Друга",
      "Панютине",
      "Паризьке",
      "Первомайський",
      "Перерізнівка",
      "Плахтіївка",
      "Плисове",
      "Побєда",
      "Поди",
      "Полтавське",
      "Преображенівка",
      "Привілля",
      "Радомислівка",
      "Ржавчик",
      "Рижове",
      "Різдвянка",
      "Роздолівка",
      "Роздори",
      "Рокитне",
      "Рубіжне",
      "Рудаєве",
      "Самійлівка",
      "Світловщина",
      "Святушине",
      "Сергієва Балка",
      "Слобідське",
      "Смирнівка",
      "Софіївка Перша",
      "Страсне",
      "Суданка",
      "Сумці",
      "Тимченки",
      "Тихопілля",
      "Трійчате",
      "Українське",
      "Уплатне",
      "Хижняківка",
      "Хлібне",
      "Холодне",
      "Царедарівка",
      "Червоний Кут",
      "Чернігівське",
      "Шатівка",
      "Шевченкове Друге",
      "Шевченкове Перше",
      "Шугаївка",
      "Шульське",
      "Якимівка",
      "Яковівка",
      "Бабаї",
      "Барчани",
      "Бахметівка",
      "Безлюдівка",
      "Безруки",
      "Безруків",
      "Березівське",
      "Бистре",
      "Бідряги",
      "Білаші",
      "Бірки",
      "Бісквітне",
      "Бобрівка",
      "Болибоки",
      "Борисівка",
      "Борове",
      "Борщова",
      "Бражники",
      "Брідок",
      "Буди",
      "Бутенкове",
      "В’язове",
      "Василівське",
      "Васищеве",
      "Ватутіне",
      "Великі Проходи",
      "Верхня Озеряна",
      "Верхня Роганка",
      "Ветеринарне",
      "Висока Яруга",
      "Високий",
      "Вільхівка",
      "Вільшани",
      "Глибоке",
      "Головнівка",
      "Гоптівка",
      "Горіхове",
      "Гранів",
      "Гуківка",
      "Гуляй Поле",
      "Гурине",
      "Дворічний Кут",
      "Дементіївка",
      "Дергачі",
      "Дерегівка",
      "Докучаєвське",
      "Дубівка",
      "Елітне",
      "Ємці",
      "Заїки",
      "Зайченки",
      "Замірці",
      "Затишшя",
      "Зернове",
      "Знам’янка",
      "Кирсанове",
      "Ключеводське",
      "Княжне",
      "Коваленки",
      "Козача Лопань",
      "Коропи",
      "Коротич",
      "Котляри",
      "Кочубеївка",
      "Кринички",
      "Круглянка",
      "Кудіївка",
      "Кулиничі",
      "Курортне",
      "Куряжанка",
      "Кут",
      "Кутузівка",
      "Лелюки",
      "Лещенки",
      "Лизогубівка",
      "Липкуватівка",
      "Липці",
      "Лихове",
      "Логачівка",
      "Лужок",
      "Лук’янці",
      "Люботин",
      "Мала Данилівка",
      "Мала Рогань",
      "Мале Веселе",
      "Малі Проходи",
      "Мануйлове",
      "Манченки",
      "Маслії",
      "Мерефа",
      "Мищенки",
      "Мовчани",
      "Мокра Рокитна",
      "Момотове",
      "Мороховець",
      "Моськівка",
      "Надточії",
      "Нескучне",
      "Нижня Озеряна",
      "Низівка",
      "Нова Березівка",
      "Нова Водолага",
      "Нова Козача",
      "Нова Мерефа",
      "Новий Коротич",
      "Новопросянське",
      "Одринка",
      "Олешки",
      "Олійникове",
      "Ордівка",
      "Пересічне",
      "Пильна",
      "Питомник",
      "Південне",
      "Пластунівка",
      "Подвірки",
      "Подольох",
      "Покотилівка",
      "Польова",
      "Пономаренки",
      "Прелесне",
      "Просяне",
      "Прудянка",
      "Рай-Оленівка",
      "Ржавець",
      "Рогань",
      "Руська Лозова",
      "Руські Тишки",
      "Санжари",
      "Селекційне",
      "Сіряки",
      "Слатине",
      "Смородське",
      "Солоний Яр",
      "Солоницівка",
      "Сороківка",
      "Сосонівка",
      "Спартаси",
      "Стара Водолага",
      "Стара Московка",
      "Степанки",
      "Стрілеча",
      "Стулепівка",
      "Темнівка",
      "Тернова",
      "Токарівка Друга",
      "Ударне",
      "Утківка",
      "Харків",
      "Хмарівка",
      "Хорошеве",
      "Хроли",
      "Циркуни",
      "Цупівка",
      "Чайківка",
      "Черкаська Лозова",
      "Черкаські Тишки",
      "Черняки",
      "Шаповалівка",
      "Шевченка",
      "Шовкопляси",
      "Шопине",
      "Шубіне",
      "Щебетуни",
      "Ярошівка",
      "Аксютівка",
      "Артемівка",
      "Артюхівка",
      "Базаліївка",
      "Бакшеївка",
      "Безпалівка",
      "Бережне",
      "Білий Колодязь",
      "Бірочок Другий",
      "Бочкове",
      "Бугруватка",
      "Бударки",
      "Бутівка",
      "Василів Хутір",
      "Введенка",
      "Велика Бабка",
      "Велика Гомільша",
      "Верхній Салтів",
      "Верхня Писарівка",
      "Вилівка",
      "Вирішальний",
      "Височинівка",
      "Вільча",
      "Вірівка",
      "Вовчанськ",
      "Вовчанські Хутори",
      "Водяхівка",
      "Волохівка",
      "Волохівське",
      "Гайдари",
      "Ганнопілля",
      "Гарашківка",
      "Гарбузівка",
      "Гатище",
      "Геївка",
      "Геніївка",
      "Глибока Долина",
      "Гонтарівка",
      "Графське",
      "Гришківка",
      "Гужвинське",
      "Гусина Поляна",
      "Гуслівка",
      "Дачне",
      "Дегтярне",
      "Джгун",
      "Дідівка",
      "Дудківка",
      "Есхар",
      "Жаданівка",
      "Задонецьке",
      "Залізничне",
      "Залізничні Бірки",
      "Замулівка",
      "Занки",
      "Западня",
      "Зарожне",
      "Зауддя",
      "Захарівка",
      "Звідки",
      "Зелений Колодязь",
      "Земляний Яр",
      "Зибине",
      "Зідьки",
      "Зміїв",
      "Ізбицьке",
      "Іськів Яр",
      "Кам’яна Яруга",
      "Камплиця",
      "Караїчне",
      "Кирюхи",
      "Кисле",
      "Кицівка",
      "Клименівка",
      "Клугино-Башкирівка",
      "Козачка",
      "Колісники",
      "Коробочкине",
      "Коропове",
      "Костянтівка",
      "Кочеток",
      "Кравцове",
      "Красна Поляна",
      "Красний Яр",
      "Кругле",
      "Кукулівка",
      "Лазуківка",
      "Лосівка",
      "Лошакове",
      "Лукашове",
      "Мала Вовча",
      "Мартове",
      "Металівка",
      "Миргороди",
      "Молодова",
      "Москалівка",
      "Мосьпанове",
      "Мохнач",
      "Нестерне",
      "Нижній Бишкин",
      "Нова Гнилиця",
      "Новий Бурлук",
      "Огірцеве",
      "Омельченки",
      "Осиковий Гай",
      "Островерхівка",
      "Охрімівка",
      "П’ятницьке",
      "Пасіки",
      "Паськівка",
      "Перківка",
      "Петрищеве",
      "Печеніги",
      "Пільна",
      "Плетенівка",
      "Погоріле",
      "Покаляне",
      "Приліпка",
      "Приморське",
      "Пушкарне",
      "Радькове",
      "Реп’яхівка",
      "Рибалкине",
      "Різникове",
      "Ртищівка",
      "Світанок",
      "Сердобине",
      "Середівка",
      "Сидори",
      "Симинівка",
      "Синельникове",
      "Скрипаї",
      "Соколове",
      "Сосновий Бір",
      "Стара Гнилиця",
      "Стара Покровка",
      "Старий Салтів",
      "Стариця",
      "Суха Гомільша",
      "Таганка",
      "Таранівка",
      "Тетлега",
      "Тихе",
      "Томахівка",
      "Тросне",
      "Хижнякове",
      "Хотімля",
      "Хрипуни",
      "Цегельне",
      "Чемужівка",
      "Черемушне",
      "Черкаський Бишкин",
      "Черняків",
      "Чкаловське",
      "Чугуїв",
      "Шабельне",
      "Шелудьківка",
      "Шестакове",
      "Шестерівка",
      "Юрченкове",
    ],
  },
  {
    region: "Херсонська",
    cities: [
      "Андріївка",
      "Архангельське",
      "Безводне",
      "Берислав",
      "Біла Криниця",
      "Білогірка",
      "Білоусове",
      "Біляївка",
      "Благодатівка",
      "Благодатне",
      "Блакитне",
      "Бобровий Кут",
      "Борозенське",
      "Брускинське",
      "Бургунка",
      "Буцівське",
      "Велика Олександрівка",
      "Вереміївка",
      "Веселе",
      "Високе",
      "Високопілля",
      "Вишневе",
      "Вірівка",
      "Гаврилівка",
      "Давидів Брід",
      "Дмитренка",
      "Добрянка",
      "Довгове",
      "Дудчани",
      "Заградівка",
      "Заможне",
      "Заповіт",
      "Запоріжжя",
      "Зарічне",
      "Зелений Гай",
      "Зміївка",
      "Золота Балка",
      "Іванівка",
      "Іщенка",
      "Калинівське",
      "Кам’яне",
      "Кам’янка",
      "Кар’єрне",
      "Качкарівка",
      "Князівка",
      "Костирка",
      "Костомарове",
      "Костромка",
      "Кочубеївка",
      "Кошара",
      "Краснівка",
      "Краснолюбецьк",
      "Красносільське",
      "Криничанка",
      "Крупиця",
      "Кучерське",
      "Ленінське",
      "Лозове",
      "Львівські Отруби",
      "Львове",
      "Любимівка",
      "Майське",
      "Максима Горького",
      "Мала Олександрівка",
      "Мала Сейдеминуха",
      "Мала Шестірня",
      "Мар’їне",
      "Матросівка",
      "Микільське",
      "Миколаївка",
      "Милове",
      "Миролюбівка",
      "Михайлівка",
      "Монастирське",
      "Наталине",
      "Нова Калуга",
      "Нова Калуга Друга",
      "Нова Кам’янка",
      "Нова Кубань",
      "Нова Шестірня",
      "Новоберислав",
      "Новобратське",
      "Нововасилівка",
      "Нововознесенське",
      "Нововоронцовка",
      "Нововоскресенське",
      "Новогреднєве",
      "Новогригорівка",
      "Новогригорівське",
      "Новодмитрівка",
      "Новокаїри",
      "Новомиколаївка",
      "Новоолександрівка",
      "Новопавлівка",
      "Новопетрівка",
      "Новополтавка",
      "Новорайськ",
      "Новосілка",
      "Одрадокам’янка",
      "Ольгине",
      "Ольгівка",
      "Орлове",
      "Осокорівка",
      "П’ятихатки",
      "Первомайське",
      "Першотравневе",
      "Петрівка",
      "Петропавлівка",
      "Потьомкине",
      "Пригір’я",
      "Раківка",
      "Республіканець",
      "Рівнопілля",
      "Розівка",
      "Саблуківка",
      "Садок",
      "Світлівка",
      "Свободне",
      "Стариця",
      "Старосілля",
      "Степне",
      "Степове",
      "Суханове",
      "Сухий Ставок",
      "Таврійське",
      "Тараса Шевченка",
      "Твердомедове",
      "Токареве",
      "Томарине",
      "Тополине",
      "Трифонівка",
      "Трудолюбівка",
      "Тягинка",
      "Українка",
      "Урожайне",
      "Федорівка",
      "Хрещенівка",
      "Чарівне",
      "Червона Людмилівка",
      "Червоне",
      "Червоний Маяк",
      "Червоний Яр",
      "Черешневе",
      "Чкалове",
      "Шевченківка",
      "Шляхове",
      "Шостакове",
      "Щасливе",
      "Агаймани",
      "Азовське",
      "Анатолівка",
      "Атамань",
      "Балашове",
      "Благовіщенка",
      "Богданівка",
      "Бойове",
      "Братське",
      "Василівка",
      "Велетнівка",
      "Верби",
      "Верхні Сірогози",
      "Верхні Торгаї",
      "Веселівка",
      "Веснянка",
      "Виноградний Клин",
      "Вікторівка",
      "Вільне",
      "Водославка",
      "Вознесенка",
      "Володимирівка",
      "Воскресенка",
      "Воскресенське",
      "Гайове",
      "Генічеськ",
      "Генічеська Гірка",
      "Гордієнківці",
      "Горностаївка",
      "Громівка",
      "Дальнє",
      "Двійне",
      "Дем’янівка",
      "Дивне",
      "Дмитрівка",
      "Догмарівка",
      "Донцове",
      "Драгоманове",
      "Дружбівка",
      "Дружелюбівка",
      "Залізничне",
      "Заозерне",
      "Заповітне",
      "Запорожець",
      "Захарівка",
      "Зелене",
      "Зернове",
      "Змагання",
      "Калинівка",
      "Катеринівка",
      "Квіткове",
      "Ковильне",
      "Комунарське",
      "Косаківка",
      "Кривий Ріг",
      "Лиходідівка",
      "Люблинка",
      "Макшіївка",
      "Малинівка",
      "Мартівка",
      "Маячка",
      "Метрополь",
      "Москаленка",
      "Муравейник",
      "Нижні Сірогози",
      "Нижні Торгаї",
      "Нова Праця",
      "Новий Азов",
      "Новий Мир",
      "Новий Труд",
      "Новодмитрівка Друга",
      "Новодмитрівка Перша",
      "Новоєфремівка",
      "Новознаменка",
      "Новоіванівка",
      "Новомихайлівка",
      "Новоолексіївка",
      "Новопокровка",
      "Новорепівка",
      "Новорогачинське",
      "Новосеменівка",
      "Новотроїцьке",
      "Новоукраїнка",
      "Ногайське",
      "Овер’янівка",
      "Одрадівка",
      "Озеряни",
      "Олександрівка",
      "Олексіївка",
      "Павлівка",
      "Партизани",
      "Перекоп",
      "Перемога",
      "Першопокровка",
      "Плавське",
      "Подове",
      "Попелак",
      "Попівка",
      "Привільне",
      "Придорожнє",
      "Приморське",
      "Приозерне",
      "Пробудження",
      "Пчілка",
      "Рикове",
      "Рівне",
      "Роздолля",
      "Садове",
      "Салькове",
      "Свиридонівка",
      "Семихатка",
      "Сергіївка",
      "Сиваське",
      "Сиваш",
      "Сивашівка",
      "Сірогози",
      "Сокологірне",
      "Софіївка",
      "Стокопані",
      "Стрілкове",
      "Тимофіївка",
      "Трохимівка",
      "Українське",
      "Херсонське",
      "Чернігівка",
      "Чеховка",
      "Чонгар",
      "Чумацький Шлях",
      "Широка Балка",
      "Шотівка",
      "Щасливцеве",
      "Ярошик",
      "Ясна Поляна",
      "Антонівка",
      "Архангельська Слобода",
      "Асканія-Нова",
      "Бабине",
      "Балтазарівка",
      "Бережанка",
      "Білоцерківка",
      "Братолюбівка",
      "Велика Благовіщенка",
      "Велика Лепетиха",
      "Верхній Рогачик",
      "Веселе Друге",
      "Вільна Україна",
      "Волинське",
      "Георгіївка",
      "Григорівка",
      "Демидівка",
      "Діброва",
      "Дніпряни",
      "Долинське",
      "Дубівка",
      "Дудчине",
      "Завітне",
      "Заводівка",
      "Запорізьке",
      "Зелена Рубанівка",
      "Зелений Під",
      "Зелений Под",
      "Зірка",
      "Зоря",
      "Іллінка",
      "Каїри",
      "Каховка",
      "Князе-Григорівка",
      "Кожум’яки",
      "Козацьке",
      "Козачі Лагері",
      "Комиш",
      "Комишанка",
      "Коробки",
      "Корсунка",
      "Костогризове",
      "Костянтинівка",
      "Красне",
      "Кудряве",
      "Кучерявоволодимирівка",
      "Лисиче",
      "Лопатки",
      "Лук’янівка",
      "Любимо-Мар’ївка",
      "Любимо-Павлівка",
      "Магдалинівка",
      "Мала Лепетиха",
      "Малокаховка",
      "Мар’янівка",
      "Маринське",
      "Маркеєв",
      "Маслівка",
      "Маячинка",
      "Молочне",
      "Морозівка",
      "Надеждівка",
      "Наталівка",
      "Нижній Рогачик",
      "Нова Благовіщенка",
      "Нова Каховка",
      "Нове",
      "Новий Гай",
      "Новий Етап",
      "Нові Лагері",
      "Нові Олешки",
      "Нововолодимирівка",
      "Новоєлизаветівка",
      "Новознам’янка",
      "Новокам’янка",
      "Новонаталівка",
      "Обривка",
      "Остапенка",
      "Первомаївка",
      "Першокостянтинівка",
      "Питомник",
      "Піщане",
      "Плодове",
      "Подівка",
      "Преображенка",
      "Просторе",
      "Райське",
      "Рачівка",
      "Рогачинка",
      "Роздольне",
      "Рубанівка",
      "Самійлівка",
      "Світле",
      "Семенівка",
      "Середнє",
      "Скадовка",
      "Скворцівка",
      "Славне",
      "Слиненка",
      "Сокирки",
      "Солідарне",
      "Старолук’янівка",
      "Строганівка",
      "Тавричанка",
      "Таврійськ",
      "Тополівка",
      "Трудовик",
      "Ушкалка",
      "Хлібодарівка",
      "Хрестівка",
      "Цукури",
      "Чаплинка",
      "Червона Поляна",
      "Червоне Поділля",
      "Червоноблагодатне",
      "Чистопілля",
      "Чорна Долина",
      "Чорноморівка",
      "Чорнянка",
      "Шевченка",
      "Бабенківка Друга",
      "Бабенківка Перша",
      "Берегове",
      "Бехтери",
      "Біленьке",
      "Білогрудове",
      "Буркути",
      "Велика Андронівка",
      "Велика Кардашинка",
      "Великий Клин",
      "Вербове",
      "Виноградне",
      "Вільна Дружина",
      "Гаврилівка Друга",
      "Геройське",
      "Гладківка",
      "Гола Пристань",
      "Гостроподолянське",
      "Грушівка",
      "Дарівка",
      "Добропілля",
      "Долматівка",
      "Забарине",
      "Залізний Порт",
      "Збур’ївка",
      "Зеленотропинське",
      "Каїрка",
      "Каланчак",
      "Карабулат",
      "Кардашинка",
      "Київка",
      "Кохани",
      "Круглоозерка",
      "Лазурне",
      "Лиманівка",
      "Лиманське",
      "Макарівка",
      "Мала Андронівка",
      "Мала Кардашинка",
      "Малі Копані",
      "Малоолександрівка",
      "Мирне",
      "Нова Збур’ївка",
      "Новокиївка",
      "Новоросійське",
      "Новоселівка",
      "Новософіївка",
      "Новофедорівка",
      "Новочорномор’я",
      "Облої",
      "Озерне",
      "Очаківське",
      "Пам’ятне",
      "Пам’ятник",
      "Польове",
      "Привілля",
      "Промінь",
      "Радісне",
      "Рибальче",
      "Рідна Україна",
      "Світанок",
      "Скадовськ",
      "Сліпушинське",
      "Ставки",
      "Стара Збур’ївка",
      "Суворовка",
      "Таврія",
      "Тарасівка",
      "Тендрівське",
      "Труд",
      "Улянівка",
      "Хатки",
      "Хорли",
      "Чорноморське",
      "Чорноморські Криниці",
      "Чулаківка",
      "Шевченко",
      "Широке",
      "Абрикосівка",
      "Барвінок",
      "Білозерка",
      "Благовіщенське",
      "Брилівка",
      "Велетенське",
      "Великі Копані",
      "Виноградове",
      "Висунці",
      "Вітрове",
      "Гончарне",
      "Грозове",
      "Дар’ївка",
      "Дніпровське",
      "Добросілля",
      "Дослідне",
      "Дружне",
      "Загорянівка",
      "Зеленівка",
      "Зимівник",
      "Знам’янка",
      "Зорівка",
      "Інгулець",
      "Інгулівка",
      "Інженерне",
      "Киселівка",
      "Кізомис",
      "Клапая",
      "Клини",
      "Комишани",
      "Копані",
      "Кринки",
      "Крутий Яр",
      "Лиманець",
      "Марченка",
      "Мірошниківка",
      "Молодецьке",
      "Молодіжне",
      "Музиківка",
      "Наддніпрянське",
      "Нова Зоря",
      "Нова Маячка",
      "Новотягинка",
      "Олешки",
      "Паришеве",
      "Підлісне",
      "Підстепне",
      "Піщанівка",
      "Поди",
      "Подо-Калинівка",
      "Пойма",
      "Понятівка",
      "Посад-Покровське",
      "Правдине",
      "Привітне",
      "Придніпровське",
      "Раденськ",
      "Рідне",
      "Розлив",
      "Ромашкове",
      "Саги",
      "Солдатське",
      "Солонці",
      "Сонячне",
      "Станіслав",
      "Стара Маячка",
      "Степанівка",
      "Східне",
      "Токарівка",
      "Томина Балка",
      "Ульяновка",
      "Херсон",
      "Чайкине",
      "Челбурда",
      "Черешеньки",
      "Чорнобаївка",
      "Ювілейне",
      "Янтарне",
    ],
  },
  {
    region: "Хмельницька",
    cities: [
      "Абрикосівка",
      "Адамівка",
      "Андріївка",
      "Антонівка",
      "Бабшин",
      "Баговиця",
      "Балабанівка",
      "Балин",
      "Балинівка",
      "Бережанка",
      "Березівка",
      "Біла",
      "Блищанівка",
      "Боднарівка",
      "Боришківці",
      "Борсуки",
      "Брага",
      "Браїлівка",
      "Броварі",
      "Бурти",
      "Бучая",
      "Варварівка",
      "Вахнівці",
      "Велика Зелена",
      "Велика Кужелева",
      "Велика Побійна",
      "Велика Слобідка",
      "Великий Жванчик",
      "Великозалісся",
      "Вербка",
      "Вигода",
      "Виселок",
      "Вихватнівці",
      "Вихрівка",
      "Вишневе",
      "Вишнівчик",
      "Вівся",
      "Вікторівка",
      "Вільне",
      "Вільхівці",
      "Вільховець",
      "Вітківці",
      "Воробіївка",
      "Врублівці",
      "Гаврилівці",
      "Гайове",
      "Гамарня",
      "Ганнівка",
      "Гелетина",
      "Гірчична",
      "Глибівка",
      "Глибочок",
      "Глібів",
      "Голенищеве",
      "Голозубинці",
      "Голосків",
      "Гораївка",
      "Городиська",
      "Гринчук",
      "Грушка",
      "Губарів",
      "Гуків",
      "Гуменці",
      "Гусятин",
      "Гута-Блищанівська",
      "Гута-Глібівська",
      "Гута-Морозівська",
      "Гута-Чугорська",
      "Гута-Яцьковецька",
      "Дем’янківці",
      "Демківці",
      "Демшин",
      "Дерев’яне",
      "Держанівка",
      "Джуржівка",
      "Діброва",
      "Добровілля",
      "Довжок",
      "Долинівка",
      "Драганівка",
      "Дубинка",
      "Дубівка",
      "Думанів",
      "Дунаївці",
      "Жабинці",
      "Жванець",
      "Жердя",
      "Заборознівці",
      "Завадівка",
      "Завалля",
      "Заголосна",
      "Загоряни",
      "Загродське",
      "Закупне",
      "Залісся",
      "Залісся Друге",
      "Залісся Перше",
      "Залісці",
      "Залуччя",
      "Заміхів",
      "Зарічанка",
      "Заставля",
      "Збриж",
      "Збруч",
      "Зелені Курилівці",
      "Зеленче",
      "Зіньківці",
      "Зубрівка",
      "Іванівка",
      "Іванківці",
      "Івахнівці",
      "Івашківці",
      "Ісаківці",
      "Йосипівка",
      "Каветчина",
      "Кадиївці",
      "Калачківці",
      "Калиня",
      "Кам’янець-Подільський",
      "Кам’янка",
      "Капустяни",
      "Карачківці",
      "Каскада",
      "Катеринівка",
      "Каштанівка",
      "Киселівка",
      "Китайгород",
      "Кізя",
      "Кізя-Кудринецька",
      "Княгинин",
      "Княжпіль",
      "Колибаївка",
      "Колодіївка",
      "Колубаївці",
      "Кормильча",
      "Корчівка",
      "Косиківці",
      "Кочубіїв",
      "Красноставці",
      "Кривчик",
      "Криків",
      "Криничани",
      "Кружківці",
      "Крушанівка",
      "Ксаверівка",
      "Кугаївці",
      "Кудринці",
      "Кузьминчик",
      "Кульчіївці",
      "Куражин",
      "Кутківці",
      "Куча",
      "Ластівці",
      "Ленівка",
      "Летава",
      "Липи",
      "Лисець",
      "Лисківці",
      "Лисогірка",
      "Ліпини",
      "Лісківці",
      "Лошківці",
      "Лучки",
      "Любомирівка",
      "Маків",
      "Мала Бережанка",
      "Мала Зелена",
      "Мала Кужелівка",
      "Мала Побіянка",
      "Мала Слобідка",
      "Мала Стружка",
      "Мала Щурка",
      "Малий Жванчик",
      "Малий Карабчіїв",
      "Малинівці",
      "Маліївці",
      "Малозалісся",
      "Мар’янівка",
      "Маціорськ",
      "Межигір",
      "Миньківці",
      "Миржіївка",
      "Михайлівка",
      "Михівка",
      "Мілівці",
      "Міцівці",
      "Млаки",
      "Морозів",
      "Мукша Китайгородська",
      "Мушкутинці",
      "Нагоряни",
      "Нестерівці",
      "Нефедівці",
      "Ніверка",
      "Нігин",
      "Нова Гута",
      "Нова Ушиця",
      "Нове Життя",
      "Новий Глібів",
      "Нововолодимирівка",
      "Оленівка",
      "Оринин",
      "Острівчани",
      "Отроків",
      "П’ятничани",
      "Панасівка",
      "Панівці",
      "Параївка",
      "Патринці",
      "Песець",
      "Пижівка",
      "Пилипи-Хребтіївські",
      "Пилипківці",
      "Підлісний Мукарів",
      "Підпилип’я",
      "Пільний Мукарів",
      "Подільське",
      "Подоляни",
      "Почапинці",
      "Привітне",
      "Привороття",
      "Привороття Друге",
      "Притулівка",
      "Пудлівці",
      "Пукляки",
      "Рахнівка",
      "Рачинці",
      "Рихта",
      "Ріпинці",
      "Рогізна",
      "Романівка",
      "Руда",
      "Руда-Гірчичнянська",
      "Рудка",
      "Рудківці",
      "Ружа",
      "Рункошів",
      "Садове",
      "Сахкамінь",
      "Свіршківці",
      "Сивороги",
      "Синяківці",
      "Січинці",
      "Слобідка",
      "Слобідка-Балинська",
      "Слобідка-Гірчичнянська",
      "Слобідка-Гуменецька",
      "Слобідка-Залісецька",
      "Слобідка-Кульчієвецька",
      "Слобідка-Малиновецька",
      "Слобідка-Малієвецька",
      "Слобідка-Рахнівська",
      "Слобідка-Рихтівська",
      "Слобідка-Скипчанська",
      "Слобідка-Смотрицька",
      "Слобода",
      "Смотрич",
      "Сокиринці",
      "Сокіл",
      "Сокілець",
      "Соколівка",
      "Соснівка",
      "Сприсівка",
      "Ставище",
      "Ставчани",
      "Станіславівка",
      "Стара Гута",
      "Стара Ушиця",
      "Степанівка",
      "Степок",
      "Струга",
      "Субіч",
      "Супрунківці",
      "Суржа",
      "Суржинці",
      "Тарасівка",
      "Теклівка",
      "Теремківці",
      "Тернавка",
      "Тернова",
      "Тимків",
      "Тинна",
      "Томашівка",
      "Трибухівка",
      "Удріївці",
      "Улянівка",
      "Устя",
      "Філянівка",
      "Фурманівка",
      "Харитонівка",
      "Хворосна",
      "Ходорівці",
      "Хребтіїв",
      "Хропотова",
      "Цвіклівці Другі",
      "Цвіклівці Перші",
      "Цибулівка",
      "Цикова",
      "Цівківці",
      "Чабанівка",
      "Чагарівка",
      "Чаньків",
      "Чемерівці",
      "Червона Діброва",
      "Червона Чагарівка",
      "Черче",
      "Чечельник",
      "Чимбарівка",
      "Чорна",
      "Чорнокозинці",
      "Шатава",
      "Шебутинці",
      "Шелестяни",
      "Шидлівці",
      "Шустівці",
      "Шутнівці",
      "Щербівці",
      "Юрківці",
      "Ямпільчик",
      "Ярова Слобідка",
      "Яруга",
      "Яцьківці",
      "Авратин",
      "Адампіль",
      "Андрійківці",
      "Андронівка",
      "Антонівці",
      "Антоніни",
      "Анютине",
      "Аркадіївці",
      "Бабине",
      "Баглаї",
      "Баглайки",
      "Базалія",
      "Балакири",
      "Баламутівка",
      "Балки",
      "Бальківці",
      "Баранівка",
      "Басівка",
      "Бахматівці",
      "Бедриківці",
      "Берегелі",
      "Бережинці",
      "Березина",
      "Березне",
      "Березове",
      "Бистриця",
      "Білецьке",
      "Бовкуни",
      "Богданівка",
      "Богданівці",
      "Божиківці",
      "Бокиївка",
      "Бомкове",
      "Борбухи",
      "Борщівка",
      "Бохни",
      "Бронівка",
      "Бубнівка",
      "Буглаї",
      "Буйволівці",
      "Бутівці",
      "Буцневе",
      "Буцні",
      "Варенка",
      "Варівці",
      "Василівка",
      "Васильківці",
      "Васьківчики",
      "Везденьки",
      "Велика Калинівка",
      "Велика Клітна",
      "Велика Левада",
      "Велика Медведівка",
      "Велика Салиха",
      "Велика Яромирка",
      "Великий Карабчіїв",
      "Великий Лазучин",
      "Великий Олександрів",
      "Великий Чернятин",
      "Великі Жеребки",
      "Великі Зозулинці",
      "Великі Мацевичі",
      "Великі Орлинці",
      "Великі Юначки",
      "Вербівка",
      "Вербівочка",
      "Вербка-Мурована",
      "Вербородинці",
      "Вереміївка",
      "Верхівці",
      "Верхняки",
      "Веселе",
      "Веселець",
      "Веселівка",
      "Веснянка",
      "Вигнанка",
      "Видава",
      "Видошня",
      "Виноградівка",
      "Вихилівка",
      "Вишнопіль",
      "Вищі Вовківці",
      "Вівсяники",
      "Війтівці",
      "Вільшани",
      "Віньківці",
      "Вовківці",
      "Вовковинці",
      "Вовча Гора",
      "Водички",
      "Волиця",
      "Волиця Друга",
      "Волиця-Керекешина",
      "Волиця-Польова",
      "Волосівці",
      "Волоське",
      "Волочиськ",
      "Волудринці",
      "Воронівці",
      "Воронківці",
      "Воскодавинці",
      "Вочківці",
      "Гаврилівка",
      "Гаївка",
      "Гайдайки",
      "Галузинці",
      "Гальчинці",
      "Гарнишівка",
      "Гатна",
      "Гвардійське",
      "Гелетинці",
      "Глібки",
      "Глушківці",
      "Глушковецьке",
      "Глядки",
      "Гнатівці",
      "Гнатки",
      "Говори",
      "Гоголі",
      "Головчинці",
      "Голохвасти",
      "Гонорівка",
      "Гончариха",
      "Горбасів",
      "Городище",
      "Городок",
      "Гречана",
      "Гречинці",
      "Грибенинка",
      "Григорівка",
      "Грим’ячка",
      "Гринева",
      "Гриценки",
      "Грицики",
      "Грицьків",
      "Гришки",
      "Громівка",
      "Грузевиця",
      "Грушківці",
      "Губин",
      "Губча",
      "Гута",
      "Давидківці",
      "Данюки",
      "Дахнівка",
      "Дашківці",
      "Дворик",
      "Деражня",
      "Деркачі",
      "Дзеленці",
      "Дмитрівка",
      "Доброгорща",
      "Долинівці",
      "Драчі",
      "Дружба",
      "Дружне",
      "Дубина",
      "Дубище",
      "Дубова",
      "Єлизаветпіль",
      "Ємці",
      "Жабче",
      "Жаглівка",
      "Женишківці",
      "Жилинці",
      "Жищинці",
      "Журавлинці",
      "Жучківці",
      "Завадинці",
      "Завалійки",
      "Загінці",
      "Загірне",
      "Зайчики",
      "Закриниччя",
      "Западинці",
      "Заруддя",
      "Заслучне",
      "Заставки",
      "Заставці",
      "Захарівці",
      "Збручівка",
      "Зверхівці",
      "Згар",
      "Згарок",
      "Зелена",
      "Зеленці",
      "Зіньків",
      "Зоряне",
      "Зяньківці",
      "Іванинці",
      "Іванівці",
      "Івки",
      "Ілляшівка",
      "Ільківці",
      "Ілятка",
      "Іршики",
      "Кадиївка",
      "Кайтанівка",
      "Каламаринка",
      "Калинівка",
      "Калитинці",
      "Кальна",
      "Калюсик",
      "Канівка",
      "Кантівка",
      "Капустин",
      "Карабіївка",
      "Караїмівка",
      "Караїна",
      "Карачіївці",
      "Карижин",
      "Карпівці",
      "Качуринці",
      "Киселі",
      "Климашівка",
      "Климківці",
      "Клинини",
      "Клинове",
      "Клопотівці",
      "Ключівка",
      "Ковалівка",
      "Козачки",
      "Колибань",
      "Колісець",
      "Колки",
      "Копачівка",
      "Копачівка Друга",
      "Копистин",
      "Копитинці",
      "Коржівка",
      "Коржівці",
      "Користова",
      "Коритна",
      "Коричинці",
      "Корначівка",
      "Коров’є",
      "Королівка",
      "Косогірка",
      "Костянець",
      "Котюржинці",
      "Кошелівка",
      "Красилів",
      "Красівка",
      "Красносілка",
      "Крачки",
      "Кременчуки",
      "Кремінна",
      "Кремінянські Хутори",
      "Кривачинці",
      "Кривовілька",
      "Криворудка",
      "Криниця",
      "Кринцілів",
      "Криштопівка",
      "Круглики",
      "Круті Броди",
      "Круча",
      "Кудинка",
      "Кузьмин",
      "Кузьминці",
      "Кульчини",
      "Кульчинки",
      "Куманів",
      "Кунча",
      "Купин",
      "Купіль",
      "Курилівка",
      "Курівка",
      "Куровечка",
      "Кучівка",
      "Кучманівка",
      "Кушнирівка",
      "Кушнирівська Слобідка",
      "Кущівка",
      "Лагодинці",
      "Ладиги",
      "Лажева",
      "Ланок",
      "Лапківці",
      "Лебедівка",
      "Левківка",
      "Левківці",
      "Ледянка",
      "Летичів",
      "Лехнівка",
      "Липівка",
      "Липки",
      "Лисанівці",
      "Лисинці",
      "Лисівка",
      "Личівка",
      "Лідихівка",
      "Ліпибоки",
      "Лісо-Березівка",
      "Лісова Волиця",
      "Лісове",
      "Лісові Гринівці",
      "Лісоводи",
      "Лісогірка",
      "Літки",
      "Лозни",
      "Лозова",
      "Лозове",
      "Ломачинці",
      "Лонки",
      "Лугове",
      "Лука",
      "Лютарівка",
      "Ляпинці",
      "Магнишівка",
      "Мазники",
      "Майдан",
      "Майдан-Вербецький",
      "Майдан-Голенищівський",
      "Майдан-Карачієвецький",
      "Майдан-Морозівський",
      "Майдан-Олександрівський",
      "Майдан-Петрівський",
      "Майдан-Сахнівський",
      "Майдан-Чернелевецький",
      "Макарове",
      "Мала Клітна",
      "Мала Колибань",
      "Мала Левада",
      "Мала Салиха",
      "Мала Яромирка",
      "Малаківщина",
      "Малашівці",
      "Маленки",
      "Малий Вишнопіль",
      "Малий Лазучин",
      "Малий Чернятин",
      "Малиничі",
      "Малишівка",
      "Малі Жеребки",
      "Малі Зозулинці",
      "Малі Мацевичі",
      "Малі Орлинці",
      "Малі Пузирки",
      "Малі Юначки",
      "Мальки",
      "Маначин",
      "Маниківці",
      "Манилівка",
      "Манівці",
      "Марківка",
      "Марківці",
      "Мартинівка",
      "Мартинківці",
      "Масівці",
      "Матвійківці",
      "Махаринці",
      "Мацьківці",
      "Медведівка",
      "Меджибіж",
      "Медисівка",
      "Медці",
      "Микитинці",
      "Миколаїв",
      "Миколаївка",
      "Мирівка",
      "Миролюбне",
      "Мислова",
      "Мисюрівка",
      "Митинці",
      "Митківці",
      "Михайлівці",
      "Михалківці",
      "Михиринці",
      "Михнівка",
      "Михунки",
      "Мовчани",
      "Моломолинці",
      "Монастирок",
      "Мончинці",
      "Моньки",
      "Морозівка",
      "Москалівка",
      "Мотрунки",
      "Мочулинці",
      "Мудриголови",
      "Мшанець",
      "Нападівка",
      "Наркевичі",
      "Нафтулівка",
      "Немиринці",
      "Немирівка",
      "Немичинці",
      "Нетечинці",
      "Нижнє",
      "Нижчі Вовківці",
      "Нова Гребля",
      "Нова Криворудка",
      "Нова Пісочна",
      "Нова Синявка",
      "Нове Поріччя",
      "Нове Село",
      "Новий Майдан",
      "Новий Світ",
      "Нові Терешки",
      "Новодубище",
      "Новоіванівка",
      "Новокостянтинів",
      "Новоленськ",
      "Новомиколаївка",
      "Новоселиця",
      "Новосілка",
      "Новоставці",
      "Новостроївка",
      "Огіївці",
      "Ожарівка",
      "Ожигівці",
      "Олександрівка",
      "Олексіївка",
      "Олешин",
      "Олешківці",
      "Олійники",
      "Ординці",
      "Оріхівка",
      "Осикове",
      "Осламів",
      "Остапківці",
      "Осташки",
      "Охрімівці",
      "Павликівці",
      "Пальчинці",
      "Паньківці",
      "Папірня",
      "Паплинці",
      "Партинці",
      "Пархомівці",
      "Пасічна",
      "Пахутинці",
      "Пашківці",
      "Пашутинці",
      "Педоси",
      "Пеньки",
      "Перегінка",
      "Перекора",
      "Першотравневе",
      "Петрашівка",
      "Петриківці",
      "Петрівка",
      "Петрівщина",
      "Печеське",
      "Печеськи",
      "Пилипи",
      "Пилипи-Олександрівські",
      "Пилява",
      "Пилявка",
      "Пирогівка",
      "Пирогівці",
      "Писарівка",
      "Пихтії",
      "Пишки",
      "Підгірне",
      "Підліски",
      "Підлісне",
      "Підлісний Веселець",
      "Підлісний Олексинець",
      "Підлісся",
      "Пільний Олексинець",
      "Подолянське",
      "Покровка",
      "Покутинці",
      "Половинники",
      "Польові Гринівці",
      "Поляни",
      "Поляхова",
      "Попівці",
      "Порохня",
      "Постолівка",
      "Правдівка",
      "Прибузьке",
      "Прилужне",
      "Проскурівка",
      "Прохорівка",
      "Радівці",
      "Радісне",
      "Радківці",
      "Радковиця",
      "Райки",
      "Райківці",
      "Раціборівка",
      "Раштівка",
      "Ревуха",
      "Редвинці",
      "Решнівка",
      "Рижулинці",
      "Рідка",
      "Рідкодуб",
      "Рідкодуби",
      "Ріпна",
      "Рожни",
      "Розсохи",
      "Розсохувата",
      "Розсоша",
      "Романів",
      "Російська Буда",
      "Росолівці",
      "Рублянка",
      "Рудня",
      "Ружичанка",
      "Русанівці",
      "Рябіївка",
      "Савинці",
      "Самчики",
      "Самчинці",
      "Сарнів",
      "Сатанів",
      "Сатанівка",
      "Сахни",
      "Сахнівці",
      "Світле",
      "Свічна",
      "Святець",
      "Северини",
      "Севрюки",
      "Семиреньки",
      "Сербинів",
      "Сербинівка",
      "Сергіївка",
      "Сирватинці",
      "Сіноводи",
      "Скаржинці",
      "Скіпче",
      "Сковородки",
      "Славута",
      "Слобідка-Глушковецька",
      "Слобідка-Кадиївська",
      "Слобідка-Красилівська",
      "Слобідка-Кузьминська",
      "Слобідка-Олексинецька",
      "Слобідка-Охрімовецька",
      "Слобідка-Чернелівська",
      "Слобідка-Шелехівська",
      "Случ",
      "Снітівка",
      "Соболівка",
      "Солобківці",
      "Соломна",
      "Сорокодуби",
      "Спасівка",
      "Ставниця",
      "Ставчинці",
      "Стара Пісочна",
      "Стара Синява",
      "Старе Поріччя",
      "Старий Майдан",
      "Старий Остропіль",
      "Старокостянтинів",
      "Степівка",
      "Стецьки",
      "Стреків",
      "Стріхівці",
      "Строки",
      "Стуфчинці",
      "Суслівці",
      "Сутківці",
      "Сушки",
      "Сьомаки",
      "Тарноруда",
      "Теліжинці",
      "Теофіполь",
      "Теперівка",
      "Терешівці",
      "Терешки",
      "Терлівка",
      "Тиранівка",
      "Травневе",
      "Требухівці",
      "Трительники",
      "Тріски",
      "Тростянець",
      "Троянівка",
      "Трудове",
      "Трусилівка",
      "Турівка",
      "Турчинці",
      "Уласово-Русанівка",
      "Фащіївка",
      "Федірки",
      "Федорівка",
      "Харківці",
      "Хижники",
      "Хмелівка",
      "Хмельницький",
      "Ходаківці",
      "Холодець",
      "Хоминці",
      "Хоптинці",
      "Хотьківці",
      "Хутір Дашківський",
      "Хутори",
      "Цимбалівка",
      "Чабани",
      "Чапля",
      "Чепелівка",
      "Червона Дубина",
      "Червона Семенівка",
      "Червоне",
      "Червоний Кут",
      "Червоний Острів",
      "Червоний Случ",
      "Черепівка",
      "Черепова",
      "Черешенька",
      "Черкасівка",
      "Чернелівка",
      "Чернелівці",
      "Чернява",
      "Чехи",
      "Човгузів",
      "Чорниводи",
      "Чорний Острів",
      "Чухелі",
      "Шарівка",
      "Шарки",
      "Шаровечка",
      "Шевченка",
      "Шелехове",
      "Шибена",
      "Шиїнці",
      "Шишківці",
      "Шмирки",
      "Шпичинці",
      "Шрубків",
      "Шумівці",
      "Щаснівка",
      "Щербані",
      "Щиборівка",
      "Юзіно",
      "Юринці",
      "Юрченки",
      "Юхимівці",
      "Юхт",
      "Яблунівка",
      "Яворівці",
      "Явтухи",
      "Якимівці",
      "Ялинівка",
      "Яремичі",
      "Ярмолинці",
      "Ярославка",
      "Ясенівка",
      "Яснозір’я",
      "Яськівці",
      "Яхнівці",
      "Адамів",
      "Баймаки",
      "Бачманівка",
      "Бейзими",
      "Берездів",
      "Бесідки",
      "Білеве",
      "Білижинці",
      "Білогір’я",
      "Білогородка",
      "Білокриниччя",
      "Білопіль",
      "Білотин",
      "Більчин",
      "Більчинка",
      "Блидні",
      "Борисів",
      "Бражинці",
      "Брикуля",
      "Бронники",
      "Буртин",
      "В’язовець",
      "Варивідки",
      "Васьківці",
      "Вачів",
      "Велика Березна",
      "Велика Боровиця",
      "Велика Радогощ",
      "Велика Рішнівка",
      "Велика Шкарівка",
      "Великий Правутин",
      "Великий Скнит",
      "Великі Каленичі",
      "Великі Калетинці",
      "Великі Пузирки",
      "Вербівці",
      "Веселинівка",
      "Весняне",
      "Вікентове",
      "Вікнини",
      "Вільшаниця",
      "Влашанівка",
      "Вовківчики",
      "Ганнопіль",
      "Ганнусине",
      "Глинники",
      "Голики",
      "Головлі",
      "Голубча",
      "Гориця",
      "Городнявка",
      "Гриців",
      "Губельці",
      "Гулівці",
      "Гурщина",
      "Данилівка",
      "Даньківці",
      "Двірець",
      "Денисівка",
      "Держаки",
      "Дертка",
      "Дзвінки",
      "Дібровка",
      "Дідківці",
      "Дідова Гора",
      "Добрин",
      "Довгалівка",
      "Довжки",
      "Долоччя",
      "Досін",
      "Дубіївка",
      "Дубовий Гай",
      "Дяків",
      "Дятилівка",
      "Жемелинці",
      "Жижниківці",
      "Жолудки",
      "Жуків",
      "Забрід",
      "Загірці",
      "Загребля",
      "Закіт",
      "Закриничне",
      "Закружці",
      "Залужжя",
      "Заморочення",
      "Заріччя",
      "Зіньки",
      "Зубарі",
      "Зубівщина",
      "Ізяслав",
      "Йосипівці",
      "Калетинці",
      "Карасиха",
      "Карпилівка",
      "Кащенці",
      "Квітневе",
      "Киликиїв",
      "Кіндратки",
      "Кіпчинці",
      "Клепачі",
      "Климентовичі",
      "Клубівка",
      "Козин",
      "Колом’є",
      "Колосівка",
      "Комарівка",
      "Комини",
      "Конотоп",
      "Коритне",
      "Корниця",
      "Корпилівка",
      "Корчик",
      "Коса Рішнівка",
      "Коськів",
      "Котелянка",
      "Коханівка",
      "Красностав",
      "Крачанівка",
      "Криволука",
      "Кропивна",
      "Круглик",
      "Крупець",
      "Кунів",
      "Купине",
      "Кур’янки",
      "Курганівка",
      "Кустівці",
      "Кутки",
      "Лавринівці",
      "Ленківці",
      "Лепесівка",
      "Лисиче",
      "Ліски",
      "Лісна",
      "Лісне",
      "Ліщани",
      "Лодзянівка",
      "Лозичне",
      "Лопушне",
      "Лотівка",
      "Любомирка",
      "Лютарка",
      "М’якоти",
      "Майдан-Лабунь",
      "Мала Боровиця",
      "Мала Медведівка",
      "Мала Радогощ",
      "Мала Шкарівка",
      "Малий Правутин",
      "Малий Скнит",
      "Малі Каленичі",
      "Малі Калетинці",
      "Мальованка",
      "Манятин",
      "Марачівка",
      "Миклаші",
      "Микулин",
      "Мирне",
      "Мирутин",
      "Мислятин",
      "Михайлючка",
      "Михля",
      "Михнів",
      "Міжгір’я",
      "Модестівка",
      "Мокіївці",
      "Мокрець",
      "Мокроволя",
      "Москвитянівка",
      "Мухарів",
      "Надишень",
      "Нараївка",
      "Нетішин",
      "Нечаївка",
      "Нижні Головлі",
      "Нова Гутиська",
      "Новаки",
      "Новий Кривин",
      "Новичі",
      "Новолабунь",
      "Новосеменів",
      "Новостав",
      "Ногачівка",
      "Норилів",
      "Окіп",
      "Онацьківці",
      "Онишківці",
      "Орлинці",
      "Пашуки",
      "Перемишель",
      "Переросле",
      "Печиводи",
      "Пильки",
      "Пиляї",
      "Півнева Гора",
      "Піддубці",
      "Підлісці",
      "Плесна",
      "Пліщин",
      "Плоска",
      "Плужне",
      "Погорільці",
      "Покощівка",
      "Поліське",
      "Полонне",
      "Поляна",
      "Полянь",
      "Понінка",
      "Понора",
      "Потереба",
      "Прикордонна Улашанівка",
      "Припутенка",
      "Припутні",
      "Прислуч",
      "Пузирки",
      "Путринці",
      "Радошівка",
      "Рилівка",
      "Рівки",
      "Ріпки",
      "Роговичі",
      "Рожична",
      "Рокитне",
      "Романіни",
      "Рудня-Новенька",
      "Саверці",
      "Савичі",
      "Сасанівка",
      "Свириди",
      "Селичів",
      "Семенів",
      "Сенігів",
      "Серединці",
      "Сивір",
      "Сивки",
      "Синютки",
      "Сморшки",
      "Соснівочка",
      "Сохужинці",
      "Сошки",
      "Сошне",
      "Ставичани",
      "Ставищани",
      "Ставок",
      "Стара Гутиська",
      "Старий Кривин",
      "Старі Бейзими",
      "Степ",
      "Сторониче",
      "Стригани",
      "Судилків",
      "Судимонт",
      "Сульжин",
      "Сушівці",
      "Сягрів",
      "Ташки",
      "Титьків",
      "Тихомель",
      "Тишевичі",
      "Топірчики",
      "Топори",
      "Траулин",
      "Троєщина",
      "Тростянка",
      "Українка",
      "Улашанівка",
      "Устянівка",
      "Фадіївка",
      "Хвощівка",
      "Хоняків",
      "Хоровець",
      "Хоровиця",
      "Хоросток",
      "Хорошів",
      "Хотень Другий",
      "Хотень Перший",
      "Храбузна",
      "Христівка",
      "Хролин",
      "Хутір",
      "Цвіт",
      "Цвітоха",
      "Цмівка",
      "Чепці",
      "Червоний Цвіт",
      "Черніївка",
      "Чижівка",
      "Чотирбоки",
      "Шагова",
      "Шатерники",
      "Шевченко",
      "Шекеринці",
      "Шельвів",
      "Шепетівка",
      "Шимківці",
      "Шуньки",
      "Щурівці",
      "Щурівчики",
      "Ювківці",
      "Юрівка",
      "Юровщина",
      "Ямпіль",
    ],
  },
  {
    region: "Черкаська",
    cities: [
      "Антонівка",
      "Багачівка",
      "Барвінок",
      "Білашки",
      "Боровикове",
      "Босівка",
      "Боярка",
      "Бродецьке",
      "Будище",
      "Бужанка",
      "Бурти",
      "В’язівок",
      "Васильків",
      "Ватутіне",
      "Вербівка",
      "Вербовець",
      "Верещаки",
      "Веселий Кут",
      "Виноград",
      "Витязеве",
      "Вишнопіль",
      "Вікнине",
      "Вільхівчик",
      "Вільховець",
      "Вільшана",
      "Водяне",
      "Водяники",
      "Воронівка",
      "Вотилівка",
      "Ганжалівка",
      "Георгіївка",
      "Глибочок",
      "Глиняна Балка",
      "Глушки",
      "Гнилець",
      "Гончариха",
      "Гордашівка",
      "Грушківка",
      "Гудзівка",
      "Гуляйка",
      "Гуляйполе",
      "Гусакове",
      "Гута-Селицька",
      "Гута-Стеблівська",
      "Дацьки",
      "Дашуківка",
      "Демкове",
      "Дібрівка",
      "Дмитрове",
      "Добрянка",
      "Довгеньке",
      "Дубина",
      "Єлизаветка",
      "Єрки",
      "Жаб’янка",
      "Журавка",
      "Журжинці",
      "Завадівка",
      "Залізнячка",
      "Заліське",
      "Заріччя",
      "Звенигородка",
      "Здобуток",
      "Зелена Діброва",
      "Зеленьків",
      "Ільченкове",
      "Іскрене",
      "Кавунівка",
      "Кайтанівка",
      "Кам’яний Брід",
      "Кам’януватка",
      "Капустине",
      "Карашина",
      "Катеринопіль",
      "Квітки",
      "Киселівка",
      "Кличкове",
      "Княжа",
      "Кобиляки",
      "Кобилянка",
      "Кобринова Гребля",
      "Кобринове",
      "Козацьке",
      "Козачани",
      "Колодисте",
      "Комарівка",
      "Кононове-Івасів",
      "Коротине",
      "Корсунка",
      "Кошмак",
      "Криві Коліна",
      "Кримки",
      "Кудинівка",
      "Кучківка",
      "Лащова",
      "Лебедин",
      "Левада",
      "Легедзине",
      "Лип’янка",
      "Лисича Балка",
      "Листвина",
      "Лисянка",
      "Лісове",
      "Лозуватка",
      "Лоташеве",
      "Луківка",
      "Любисток",
      "Майданецьке",
      "Майданівка",
      "Мар’янівка",
      "Маслове",
      "Матусів",
      "Межигірка",
      "Мизинівка",
      "Миколаївка",
      "Миропілля",
      "Михайлівка",
      "Мокра Калигірка",
      "Моргунове",
      "Моринці",
      "Мошурів",
      "Мурзинці",
      "Надвисся",
      "Надточаївка",
      "Неморож",
      "Нечаєве",
      "Нова Буда",
      "Нова Павлівка",
      "Нова Ярославка",
      "Новомайданецьке",
      "Новоселиця",
      "Новоукраїнка",
      "Озірна",
      "Олександрівка",
      "Онопріївка",
      "Орли",
      "Павлівка",
      "Павлівка Друга",
      "Павлівка Перша",
      "Пальчик",
      "Папужинці",
      "Пединівка",
      "Переможинці",
      "Петраківка",
      "Петрики",
      "Петрівка-Попівка",
      "Петрівська Гута",
      "Петрушки",
      "Писарівка",
      "Піщана",
      "Погибляк",
      "Попівка",
      "Поташ",
      "Потоки",
      "Почапинці",
      "Прутильці",
      "Радчиха",
      "Рижанівка",
      "Ризине",
      "Ріпки",
      "Розкошівка",
      "Розсохуватка",
      "Романівка",
      "Ромейково",
      "Рубаний Міст",
      "Сагайдачне",
      "Сегединці",
      "Селище",
      "Семенівка",
      "Сердегівка",
      "Сигнаївка",
      "Сидорівка",
      "Скаливатка",
      "Склименці",
      "Скотареве",
      "Скрипчинці",
      "Смільчинці",
      "Соболівка",
      "Соколівочка",
      "Стадниця",
      "Станіславчик",
      "Стара Буда",
      "Стеблів",
      "Стебне",
      "Степне",
      "Стецівка",
      "Стійкове",
      "Ступичне",
      "Суха Калигірка",
      "Сухини",
      "Тальне",
      "Тальянки",
      "Тарасівка",
      "Тараща",
      "Терешки",
      "Тихі Верби",
      "Тихонівка",
      "Товмач",
      "Товста",
      "Товсті Роги",
      "Топильна",
      "Трихуторівка",
      "Федюківка",
      "Хижинці",
      "Хильки",
      "Хлерівка",
      "Хлипнівка",
      "Ховківка",
      "Хрестівка",
      "Чаплинка",
      "Чемериське",
      "Червоне",
      "Червоний Брід",
      "Черепин",
      "Чеснівка",
      "Чеснопіль",
      "Чижівка",
      "Чичиркозівка",
      "Шалаське",
      "Шампанія",
      "Шаулиха",
      "Шевченкове",
      "Шендерівка",
      "Шестеринці",
      "Шостакове",
      "Шпола",
      "Шубині Стави",
      "Шушківка",
      "Юрківка",
      "Юркове",
      "Яблунівка",
      "Ямпіль",
      "Ярославка",
      "Ярошівка",
      "Антипівка",
      "Ашанівка",
      "Бакаєве",
      "Бакаївка",
      "Баталий",
      "Безбородьки",
      "Безпальче",
      "Бирлівка",
      "Білоусівка",
      "Благодатне",
      "Богдани",
      "Богданівка",
      "Богодухівка",
      "Богуславець",
      "Бойківщина",
      "Бондарівка",
      "Броварки",
      "Бубнівська Слобідка",
      "Васютинці",
      "Велика Бурімка",
      "Великий Хутір",
      "Великі Канівці",
      "Вереміївка",
      "Вершина-Згарська",
      "Веселий Поділ",
      "Веселий Хутір",
      "Вишневе",
      "Вишнівка",
      "Вільхи",
      "Вознесенське",
      "Воронинці",
      "Гай",
      "Гельмязів",
      "Гладківщина",
      "Гречанівка",
      "Григорівка",
      "Гришківка",
      "Демки",
      "Деньги",
      "Дмитрівка",
      "Домантове",
      "Драбів",
      "Драбівці",
      "Драбове-Барятинське",
      "Жовнине",
      "Жорнокльови",
      "Журавлине",
      "Загородище",
      "Згар",
      "Золотоноша",
      "Золотоношка",
      "Зорівка",
      "Іванівка",
      "Іркліїв",
      "Каленики",
      "Канівщина",
      "Кантакузівка",
      "Квітневе",
      "Кедина Гора",
      "Кліщинці",
      "Ковалівка",
      "Коврай",
      "Коврай Другий",
      "Коврайські Хутори",
      "Ковтуни",
      "Ковтунівка",
      "Козаче",
      "Козорізи",
      "Коломиці",
      "Кононівка",
      "Коробівка",
      "Красенівка",
      "Кривоносівка",
      "Криштопівка",
      "Кропивна",
      "Крупське",
      "Крутьки",
      "Левченкове",
      "Лихоліти",
      "Лукашівка",
      "Львівка",
      "Лящівка",
      "Мала Бурімка",
      "Малинівщина",
      "Малі Канівці",
      "Маліївка",
      "Маркізівка",
      "Матвіївка",
      "Мелесівка",
      "Мельники",
      "Мехедівка",
      "Мирне",
      "Митлашівка",
      "Мицалівка",
      "Мойсівка",
      "Москаленки",
      "Мохнач",
      "Нехайки",
      "Нова Гребля",
      "Нова Дмитрівка",
      "Нове Життя",
      "Новомиколаївка",
      "Олімпіадівка",
      "Остапівка",
      "Павлівщина",
      "Пальміра",
      "Перервинці",
      "Перше Травня",
      "Першотравневе",
      "Підставки",
      "Піщане",
      "Плешкані",
      "Погреби",
      "Подільське",
      "Привітне",
      "Придніпровське",
      "Ревбинці",
      "Рецюківщина",
      "Рождественське",
      "Роздол",
      "Савківка",
      "Свічківка",
      "Сеньківці",
      "Синьооківка",
      "Скориківка",
      "Скородистик",
      "Снігурівка",
      "Софіївка",
      "Старий Коврай",
      "Старий Мохнач",
      "Степанівка",
      "Степове",
      "Тимченки",
      "Тополі",
      "Франківка",
      "Хвильово-Сорочин",
      "Холодне",
      "Хомівщина",
      "Хрестителеве",
      "Хрущівка",
      "Червона Дача",
      "Червоногірка",
      "Червонохижинці",
      "Чернещина",
      "Чехівка",
      "Чорнобай",
      "Шабельники",
      "Шкодунівка",
      "Шрамківка",
      "Щербинівка",
      "Яворівка",
      "Ярки",
      "Аврамівка",
      "Адамівка",
      "Антоніна",
      "Аполянка",
      "Бабанка",
      "Багва",
      "Бачкурине",
      "Баштечки",
      "Безпечна",
      "Березівка",
      "Берестівець",
      "Ботвинівка",
      "Бубельня",
      "Бузівка",
      "Буки",
      "Велика Севастянівка",
      "Вербувата",
      "Верхнячка",
      "Веселівка",
      "Вікторівка",
      "Вільна",
      "Вільшана-Слобідка",
      "Вільшанка",
      "Владиславчик",
      "Володимирівка",
      "Вороне",
      "Гереженівка",
      "Городецьке",
      "Городниця",
      "Гребля",
      "Гродзеве",
      "Громи",
      "Дзензелівка",
      "Дмитрушки",
      "Добра",
      "Доброводи",
      "Долинка",
      "Дубова",
      "Жашків",
      "Житники",
      "Жолудькове",
      "Забіляни",
      "Зарубинці",
      "Затишок",
      "Заячківка",
      "Зелений Гай",
      "Зелений Ріг",
      "Зоряне",
      "Зюбриха",
      "Івангород",
      "Іваньки",
      "Івахни",
      "Кислин",
      "Кищенці",
      "Княжа Криниця",
      "Княжики",
      "Конела",
      "Конельська Попівка",
      "Конельські Хутори",
      "Копіювата",
      "Коржова",
      "Коржова Слобода",
      "Коржовий Кут",
      "Коритня",
      "Королівка",
      "Косенівка",
      "Костянтинівка",
      "Кочержинці",
      "Кочубіївка",
      "Краснопілка",
      "Крачківка",
      "Кривець",
      "Кривчунка",
      "Кудинів Ліс",
      "Кузьмина Гребля",
      "Кути",
      "Ладижинка",
      "Леміщиха",
      "Леськове",
      "Летичівка",
      "Литвинівка",
      "Ліщинівка",
      "Максимівка",
      "Мала Іванівка",
      "Мала Маньківка",
      "Мала Севастянівка",
      "Малий Затишок",
      "Маньківка",
      "Марійка",
      "Матвіїха",
      "Медувата",
      "Молодецьке",
      "Монастирище",
      "Нагірна",
      "Нестерівка",
      "Нове Місто",
      "Новосілка",
      "Одай",
      "Оксанина",
      "Орадівка",
      "Осітна",
      "Острівець",
      "Острожани",
      "Охматів",
      "Паланка",
      "Паланочка",
      "Панський Міст",
      "Пеніжкове",
      "Піківець",
      "Побійна",
      "Подібна",
      "Покровка",
      "Половинчик",
      "Полянецьке",
      "Поминик",
      "Попудня",
      "Посухівка",
      "Пугачівка",
      "Рижавка",
      "Роги",
      "Рогова",
      "Родниківка",
      "Розсішки",
      "Ропотуха",
      "Рудка",
      "Русалівка",
      "Сабадаш",
      "Сарни",
      "Сатанівка",
      "Свинарка",
      "Синиця",
      "Сичі",
      "Сичівка",
      "Скибин",
      "Собківка",
      "Соколівка",
      "Сорокотяга",
      "Старі Бабани",
      "Степівка",
      "Степківка",
      "Сушківка",
      "Талалаївка",
      "Танське",
      "Тарнава",
      "Текуча",
      "Теолин",
      "Терлиця",
      "Тетерівка",
      "Тимошівка",
      "Тинівка",
      "Тихий Хутір",
      "Томашівка",
      "Углуватка",
      "Улянівка",
      "Умань",
      "Філіція",
      "Фурманка",
      "Халаїдове",
      "Харківка",
      "Хасанівка",
      "Хейлове",
      "Хижня",
      "Христинівка",
      "Цибулів",
      "Чайківка",
      "Червоний Кут",
      "Черповоди",
      "Чорна Кам’янка",
      "Шабастівка",
      "Шарин",
      "Шарнопіль",
      "Шельпахівка",
      "Шукайвода",
      "Шуляки",
      "Юрпіль",
      "Ягубець",
      "Яри",
      "Яроватка",
      "Ятранівка",
      "Бабичі",
      "Байбузи",
      "Балаклея",
      "Баландине",
      "Баси",
      "Березняки",
      "Бересняги",
      "Беркозівка",
      "Берлютине",
      "Білозір’я",
      "Бобриця",
      "Богданівське",
      "Богунове",
      "Боровиця",
      "Бровахи",
      "Буда",
      "Буда-Бровахівська",
      "Буда-Горобіївська",
      "Буда-Макіївка",
      "Будки",
      "Будо-Орловецька",
      "Бузуків",
      "Бурякове",
      "Бучак",
      "Валява",
      "Вдовичине",
      "Велика Яблунівка",
      "Вергуни",
      "Вершаці",
      "Виграїв",
      "Вітове",
      "Вовківка",
      "Галаганівка",
      "Гамарня",
      "Гарбузин",
      "Геронимівка",
      "Глинча",
      "Гненне",
      "Голов’ятине",
      "Головківка",
      "Голяки",
      "Горобіївка",
      "Городище",
      "Грекове",
      "Грищинці",
      "Гуляйгородок",
      "Гута Межиріцька",
      "Гута Станіславчицька",
      "Дарівка",
      "Деменці",
      "Деренковець",
      "Дирдин",
      "Драбівка",
      "Дубіївка",
      "Думанці",
      "Єлизаветівка",
      "Жаботин",
      "Закревки",
      "Залевки",
      "Зам’ятниця",
      "Іваньків",
      "Івківці",
      "Ірдинівка",
      "Ірдинь",
      "Калинівка",
      "Кам’янка",
      "Канів",
      "Катеринівка",
      "Келеберда",
      "Кичинці",
      "Ключники",
      "Ковалиха",
      "Ковалі",
      "Козарівка",
      "Кононча",
      "Копійчана",
      "Копіювате",
      "Корнилівка",
      "Корсунь-Шевченківський",
      "Косарі",
      "Коханівка",
      "Красносілля",
      "Ксаверове",
      "Кудашеве",
      "Куликівка",
      "Кумейки",
      "Курилівка",
      "Куцівка",
      "Лазірці",
      "Лебедівка",
      "Ленське",
      "Леськи",
      "Литвинець",
      "Лізки",
      "Ліпляве",
      "Лозівок",
      "Лубенці",
      "Лузанівка",
      "Лука",
      "Луковиця",
      "Макіївка",
      "Мала Смілянка",
      "Мале Старосілля",
      "Малий Бузуків",
      "Малий Ржавець",
      "Мартинівка",
      "Медведівка",
      "Межиріч",
      "Мельниківка",
      "Мірошниківка",
      "Мліїв",
      "Мошни",
      "Набоків",
      "Набутів",
      "Нетеребка",
      "Нехворощ",
      "Нечаївка",
      "Новоселівка",
      "Носачів",
      "Озерище",
      "Олянине",
      "Орловець",
      "Оршанець",
      "Пастирське",
      "Паськів",
      "Пекарі",
      "Первомайське",
      "Петропавлівка",
      "Пилява",
      "Пищальники",
      "Пішки",
      "Плескачівка",
      "Плоске",
      "Пляківка",
      "Погорільці",
      "Полствин",
      "Полуднівка",
      "Потапці",
      "Поташня",
      "Прохорівка",
      "Пшеничники",
      "Радиванівка",
      "Райгород",
      "Райок",
      "Рацеве",
      "Ребедайлівка",
      "Ревівка",
      "Розсошинці",
      "Ротмістрівка",
      "Рублівка",
      "Руська Поляна",
      "Сагунівка",
      "Самгородок",
      "Саморідня",
      "Санжариха",
      "Сахнівка",
      "Сахнівське",
      "Свидівок",
      "Сердюківка",
      "Синявка",
      "Ситники",
      "Скаржинка",
      "Скелівка",
      "Сміла",
      "Сокирна",
      "Сокирне",
      "Сотники",
      "Старосілля",
      "Степанецьке",
      "Степанки",
      "Степанці",
      "Степок",
      "Студенець",
      "Суботів",
      "Сунки",
      "Сушки",
      "Таганча",
      "Тарасо-Григорівка",
      "Ташлик",
      "Теклине",
      "Телепине",
      "Тернівка",
      "Тіньки",
      "Топилівка",
      "Трахтемирів",
      "Тростянець",
      "Трощин",
      "Трушівці",
      "Тубільці",
      "Флярківка",
      "Хацьки",
      "Хлистунівка",
      "Хмільна",
      "Холоднянське",
      "Хрещатик",
      "Худоліївка",
      "Худяки",
      "Хутір-Хмільна",
      "Хутори",
      "Цвіткове",
      "Червона Слобода",
      "Черкаси",
      "Чернече",
      "Черниші",
      "Чигирин",
      "Чмирівка",
      "Чорнявка",
      "Чубівка",
      "Шевченка",
      "Шелепухи",
      "Юрчиха",
      "Яблунів",
      "Ярове",
      "Яснозір’я",
    ],
  },
  {
    region: "Чернівецька",
    cities: [
      "Андреківське",
      "Бабине",
      "Багна",
      "Банилів",
      "Берегомет",
      "Бережниця",
      "Бережонка",
      "Бисків",
      "Бісків",
      "Брусенки",
      "Брусниця",
      "Вали",
      "Вахнівці",
      "Вашківці",
      "Велике",
      "Великий Липовець",
      "Верхні Станівці",
      "Верхній Яловець",
      "Виженка",
      "Вижниця",
      "Виноград",
      "Випчина",
      "Вовчинець",
      "Волока",
      "Галицівка",
      "Голошина",
      "Греблина",
      "Гробище",
      "Дихтинець",
      "Діброва",
      "Довгопілля",
      "Долішній Шепіт",
      "Замогила",
      "Замостя",
      "Заріччя",
      "Зеленів",
      "Іспас",
      "Кальнівці",
      "Карапчів",
      "Кибаки",
      "Киселиці",
      "Конятин",
      "Коритне",
      "Лекечі",
      "Липовани",
      "Лопушна",
      "Лукавці",
      "Лустун",
      "Майдан",
      "Малий Дихтинець",
      "Мариничі",
      "Мигове",
      "Міжброди",
      "Мілієве",
      "Нижні Станівці",
      "Нижній Яловець",
      "Околена",
      "Остра",
      "Паркулина",
      "Петраші",
      "Підзахаричі",
      "Плай",
      "Плита",
      "Плоска",
      "Площі",
      "Поляківське",
      "Путила",
      "Рижа",
      "Рипень",
      "Розтоки",
      "Руська",
      "Самакова",
      "Сарата",
      "Селятин",
      "Сергії",
      "Середній Майдан",
      "Слобода-Банилів",
      "Соколій",
      "Стебні",
      "Тесницька",
      "Товарниця",
      "Тораки",
      "Усть-Путила",
      "Фальків",
      "Фошки",
      "Хорови",
      "Черешенька",
      "Чорногузи",
      "Чортория",
      "Шепіт",
      "Шпетки",
      "Яблуниця",
      "Ями",
      "Анадоли",
      "Атаки",
      "Бабин",
      "Балківці",
      "Бернове",
      "Білівці",
      "Білоусівка",
      "Блищадь",
      "Браїлівка",
      "Братанівка",
      "Бузовиця",
      "Бурдюг",
      "Вартиківці",
      "Василівка",
      "Вітрянка",
      "Владична",
      "Волошкове",
      "Ворничани",
      "Вороновиця",
      "Галиця",
      "Гвіздівці",
      "Гордівці",
      "Гринячка",
      "Грубна",
      "Грушівці",
      "Данківці",
      "Дністрівка",
      "Долиняни",
      "Драниця",
      "Зарожани",
      "Зелена",
      "Зелена Липа",
      "Іванівці",
      "Каплівка",
      "Кельменці",
      "Керстенці",
      "Клішківці",
      "Коболчин",
      "Козиряни",
      "Комарів",
      "Коновка",
      "Кормань",
      "Корнешти",
      "Кошуляни",
      "Кроква",
      "Круглик",
      "Крутеньки",
      "Кулішівка",
      "Ленківці",
      "Лівинці",
      "Ломачинці",
      "Лопатів",
      "Лукачівка",
      "Майорка",
      "Макарівка",
      "Малинці",
      "Мамалига",
      "Михайлівка",
      "Михалкове",
      "Млинки",
      "Мошанець",
      "Нагоряни",
      "Негринці",
      "Недобоївці",
      "Нелипівці",
      "Непоротове",
      "Несвоя",
      "Нова Слобода",
      "Новодністровськ",
      "Новоолексіївка",
      "Новоселиця",
      "Ожеве",
      "Олексіївка",
      "Орестівка",
      "Оселівка",
      "Пашківці",
      "Перебиківці",
      "Перківці",
      "Подвір’ївка",
      "Подвірне",
      "Покровка",
      "Поляна",
      "Пригородок",
      "Путрине",
      "Рашків",
      "Розкопинці",
      "Романківці",
      "Росошани",
      "Рукшин",
      "Рухотин",
      "Санківці",
      "Селище",
      "Сербичани",
      "Слобідка",
      "Сокиряни",
      "Ставчани",
      "Стальнівці",
      "Струмок",
      "Хотин",
      "Чепоноси",
      "Шебутинці",
      "Шилівці",
      "Ширівці",
      "Шишківці",
      "Ярівка",
      "Аршиця",
      "Багринівка",
      "Байраки",
      "Баламутівка",
      "Банилів-Підгірний",
      "Банчени",
      "Берестя",
      "Біла",
      "Біла Криниця",
      "Бобівці",
      "Борівці",
      "Бочківці",
      "Бояни",
      "Боянівка",
      "Боянчук",
      "Брідок",
      "Буда",
      "Буденець",
      "Буківка",
      "Бурдей",
      "Валя Кузьмина",
      "Валява",
      "Ванчиківці",
      "Ванчинець",
      "Василів",
      "Васловівці",
      "Велика Буда",
      "Великий Кучурів",
      "Великосілля",
      "Вербівці",
      "Веренчанка",
      "Верхні Петрівці",
      "Верхні Синівці",
      "Вимушів",
      "Витилівка",
      "Вікно",
      "Гаврилівці",
      "Гай",
      "Герца",
      "Глибока",
      "Глибочок",
      "Глиниця",
      "Годилів",
      "Годинівка",
      "Горбівці",
      "Горбова",
      "Горішні Шерівці",
      "Горошівці",
      "Грозинці",
      "Грушівка",
      "Давидівка",
      "Давидівці",
      "Димка",
      "Динівці",
      "Дібрівка",
      "Добринівці",
      "Довжок",
      "Дорошівці",
      "Драчинці",
      "Дубівці",
      "Дубове",
      "Думени",
      "Дяківці",
      "Жилівка",
      "Заболоття",
      "Заволока",
      "Задубрівка",
      "Заставна",
      "Звенячин",
      "Зелений Гай",
      "Зруб-Комарівський",
      "Іванківці",
      "Їжівці",
      "Йорданешти",
      "Йосипівка",
      "Кадубівці",
      "Кам’яна",
      "Кам’янка",
      "Киселів",
      "Кіцмань",
      "Кліводин",
      "Клокічка",
      "Колінківці",
      "Комарівці",
      "Коровія",
      "Коростувата",
      "Корчівці",
      "Косованка",
      "Костинці",
      "Костичани",
      "Кострижівка",
      "Котелеве",
      "Красноїльськ",
      "Круп’янське",
      "Куликівка",
      "Кулівці",
      "Купка",
      "Кут",
      "Лашківка",
      "Лужани",
      "Луковиця",
      "Лунка",
      "Магала",
      "Мала Буда",
      "Малий Кучурів",
      "Малинівка",
      "Малятинці",
      "Мамаївці",
      "Маморниця",
      "Маморниця Вама",
      "Маршинці",
      "Митків",
      "Михальча",
      "Могилівка",
      "Молниця",
      "Молодія",
      "Мосорівка",
      "Неполоківці",
      "Нижні Петрівці",
      "Нижні Синівці",
      "Нова Жадова",
      "Нова Красношора",
      "Новий Вовчинець",
      "Новий Киселів",
      "Нові Бросківці",
      "Нові Драчинці",
      "Новоіванківці",
      "Онут",
      "Опришени",
      "Оршівці",
      "Остриця",
      "Ошихліби",
      "П’ядиківці",
      "Панка",
      "Петрашівка",
      "Петричанка",
      "Підвальне",
      "Погорілівка",
      "Привороки",
      "Прилипче",
      "Припруття",
      "Просикуряни",
      "Просіка",
      "Прут",
      "Реваківці",
      "Ревківці",
      "Ревне",
      "Репужинці",
      "Ржавинці",
      "Рингач",
      "Рідківці",
      "Рокитне",
      "Ропча",
      "Рудка",
      "Самушин",
      "Слобода",
      "Слобода-Комарівці",
      "Снячів",
      "Спаська",
      "Станівці",
      "Стара Жадова",
      "Стара Красношора",
      "Старий Вовчинець",
      "Старі Бросківці",
      "Степанівка",
      "Стерче",
      "Сторожинець",
      "Стрілецький Кут",
      "Строїнці",
      "Суховерхів",
      "Сучевени",
      "Тарасівці",
      "Тарашани",
      "Тереблече",
      "Тернавка",
      "Тисовець",
      "Товтри",
      "Топорівці",
      "Турятка",
      "Форосна",
      "Хлівище",
      "Хрещатик",
      "Хряцька",
      "Цурень",
      "Чагор",
      "Червона Діброва",
      "Черепківці",
      "Череш",
      "Черленівка",
      "Чернівці",
      "Чорний Потік",
      "Чорнівка",
      "Чудей",
      "Чуньків",
      "Шипинці",
      "Шубранець",
      "Щербинці",
      "Южинець",
      "Юрківці",
      "Яблунівка",
      "Ясени",
    ],
  },
  {
    region: "Чернігівська",
    cities: [
      "Андроники",
      "Баляси",
      "Безуглівка",
      "Березова Роща",
      "Бешківка",
      "Білошицька Слобода",
      "Бірківка",
      "Блистова",
      "Бобрик",
      "Богдалівка",
      "Бондарівка",
      "Борок",
      "Бреч",
      "Буда",
      "Будище",
      "Бурківка",
      "Бутівка",
      "Велике Устя",
      "Великий Щимель",
      "Величківка",
      "Верхолісся",
      "Веселе",
      "Високе",
      "Вільне",
      "Вільшане",
      "Волинка",
      "Воловики",
      "Волосківці",
      "Гай",
      "Ганнівка",
      "Гапішківка",
      "Гвоздиківка",
      "Гірськ",
      "Глибокий Ріг",
      "Голубівщина",
      "Городище",
      "Гуринівка",
      "Гута-Студенецька",
      "Гутище",
      "Данилівка",
      "Дачне",
      "Дерепівка",
      "Дібрівка",
      "Дмитрівка",
      "Довга Гребля",
      "Довжик",
      "Долинське",
      "Домашлин",
      "Дягова",
      "Єліне",
      "Єнькова Рудня",
      "Жовідь",
      "Жукля",
      "Журавлева Буда",
      "Журавок",
      "Забарівка",
      "Загорівка",
      "Загребелля",
      "Загребельна Слобода",
      "Займище",
      "Заляддя",
      "Заріччя",
      "Змітнів",
      "Іванівка",
      "Ількуча",
      "Камка",
      "Кирилівка",
      "Киріївка",
      "Киселівка",
      "Клюси",
      "Кнути",
      "Козилівка",
      "Козляничі",
      "Комарівка",
      "Конятин",
      "Корюківка",
      "Костирів",
      "Костючки",
      "Костянтинівка",
      "Крестопівщина",
      "Кугуки",
      "Кудрівка",
      "Куковицьке",
      "Куковичі",
      "Купчичі",
      "Куропіївка",
      "Кучинівка",
      "Кучугури",
      "Лави",
      "Лазарівка",
      "Лебіддя",
      "Липівка",
      "Ліски",
      "Лісове",
      "Лозова",
      "Лосєва Слобода",
      "Лубенець",
      "Лука",
      "Луки",
      "Луковець",
      "Лупасове",
      "Лютівка",
      "Ляшківці",
      "Майбутнє",
      "Майське",
      "Макошине",
      "Максаки",
      "Мала Бондарівка",
      "Мале Устя",
      "Масалаївка",
      "Матвіївка",
      "Маховики",
      "Мена",
      "Михайлівка",
      "Мишине",
      "Містки",
      "Млинок",
      "Наумівка",
      "Низківка",
      "Нова Буда",
      "Нова Гуринівка",
      "Нові Боровичі",
      "Нові Броди",
      "Нові Млини",
      "Новоселівка",
      "Овчарівка",
      "Озереди",
      "Олександрівка",
      "Олешня",
      "Олійники",
      "Остапівка",
      "Осьмаки",
      "Охрамієвичі",
      "Парастовське",
      "Пекарів",
      "Переділ",
      "Перелюб",
      "Петрівка",
      "Петрова Слобода",
      "Піски",
      "Піщанка",
      "Пльохів",
      "Покровське",
      "Полісся",
      "Польове",
      "Попільня",
      "Прибинь",
      "Привільне",
      "Прогони",
      "Прогрес",
      "Радвине",
      "Радомка",
      "Рейментарівка",
      "Рибинськ",
      "Рогізки",
      "Романівська Буда",
      "Руда",
      "Рудня",
      "Савинки",
      "Садове",
      "Сальне",
      "Самотуги",
      "Самсонівка",
      "Сахутівка",
      "Свірок",
      "Селище",
      "Семенівка",
      "Синютин",
      "Синявка",
      "Слава",
      "Слобідка",
      "Смяч",
      "Сновськ",
      "Сновське",
      "Сосниця",
      "Соснівка",
      "Софіївка",
      "Спаське",
      "Спичувате",
      "Стара Рудня",
      "Старі Боровичі",
      "Старобутівка",
      "Степанівка",
      "Стольне",
      "Стопилка",
      "Суничне",
      "Сядрине",
      "Тельне",
      "Тихонівське",
      "Тихоновичі",
      "Трудовик",
      "Тур’я",
      "Турівка",
      "Тютюнниця",
      "Уріччя",
      "Ушня",
      "Феськівка",
      "Філонівка",
      "Хлоп’яники",
      "Ховдіївка",
      "Холми",
      "Хотіївка",
      "Хотуничі",
      "Хрінівка",
      "Ченчики",
      "Чепелів",
      "Чорногорці",
      "Чорнотичі",
      "Шишка",
      "Шишківка",
      "Шкробове",
      "Щокоть",
      "Якличі",
      "Адамівка",
      "Андріївка",
      "Бакланове",
      "Батурин",
      "Бахмач",
      "Безпечне",
      "Березанка",
      "Березівка",
      "Берестовець",
      "Бідин",
      "Біловежі Другі",
      "Біловежі Перші",
      "Білоцерківці",
      "Бірки",
      "Бобровиця",
      "Богданівка",
      "Бондарі",
      "Борзна",
      "Браниця",
      "Бригинці",
      "Буглаки",
      "Валентіїв",
      "Варварівка",
      "Ведмедівка",
      "Велика Дорога",
      "Велика Доч",
      "Велика Загорівка",
      "Велика Кошелівка",
      "Веприк",
      "Вербівка",
      "Вербове",
      "Вертіївка",
      "Веселівка",
      "Вили",
      "Вишневе",
      "Вишнівське",
      "Відрадне",
      "Вікторівка",
      "Воловиця",
      "Володькова Дівиця",
      "Вольниця",
      "Ворона",
      "Вороньки",
      "Восьме Березня",
      "Гаврилівка",
      "Гайворон",
      "Галайбине",
      "Галиця",
      "Гармащина",
      "Гарт",
      "Глибоке",
      "Голінка",
      "Головеньки",
      "Голубів",
      "Горбачі",
      "Григорівка",
      "Гришівка",
      "Грушівка",
      "Данина",
      "Дебреве",
      "Держанівка",
      "Діброва",
      "Добропілля",
      "Довге",
      "Дослідне",
      "Дуболугівка",
      "Жданів",
      "Забілівщина",
      "Забілівщина Друга",
      "Заболоття",
      "Залісся",
      "Заньки",
      "Запоріжжя",
      "Запорізьке",
      "Зарукавне",
      "Затишшя",
      "Зелене",
      "Зеленівка",
      "Зруб",
      "Іллінці",
      "Іржавець",
      "Каблуки",
      "Калинівка",
      "Кальчинівка",
      "Карабинівка",
      "Кардаші",
      "Карпенкове",
      "Катеринівка",
      "Каціри",
      "Кербутівка",
      "Кинашівка",
      "Кленове",
      "Клипин",
      "Кобижча",
      "Кобилещина",
      "Ковальове",
      "Ковтунівка",
      "Козари",
      "Козацьке",
      "Колісники",
      "Коломійцівка",
      "Коношівка",
      "Коробчине",
      "Кошмалів",
      "Кравчиха",
      "Красилівка",
      "Красне",
      "Красносільське",
      "Красностав",
      "Криниця",
      "Кропивне",
      "Крути",
      "Кукшин",
      "Кулішове",
      "Кунашівка",
      "Купченків",
      "Курилівка",
      "Курінь",
      "Леонідівка",
      "Линівка",
      "Липів Ріг",
      "Лихачів",
      "Лідин",
      "Лісова Поляна",
      "Лісові Хутори",
      "Лопатин",
      "Лосинівка",
      "Лукашівка",
      "Лустівка",
      "Любомудрівка",
      "Майнівка",
      "Макарівка",
      "Макіївка",
      "Мала Доч",
      "Мала Загорівка",
      "Мала Кошелівка",
      "Маличина Гребля",
      "Марківці",
      "Матіївка",
      "Махнівка",
      "Миколаїв",
      "Миколаївка",
      "Мильники",
      "Мирне",
      "Митченки",
      "Мовчинів",
      "Молодіжне",
      "Мости",
      "Мочалище",
      "Мрин",
      "Наумівське",
      "Нечаїв",
      "Низи",
      "Ніжин",
      "Ніжинське",
      "Нова Басань",
      "Нове",
      "Нове Полісся",
      "Новий Биків",
      "Новоселиця",
      "Носелівка",
      "Носівка",
      "Обірки",
      "Обмачів",
      "Озеряни",
      "Олексіївка",
      "Оленівка",
      "Омбиш",
      "Ополонське",
      "Осинівка",
      "Осіч",
      "Осовець",
      "Осокорівка",
      "Остер",
      "Острів",
      "Острів Надії",
      "Паливода",
      "Пальчики",
      "Пам’ятне",
      "Паристівка",
      "Пашків",
      "Пашківка",
      "Перебудова",
      "Перемога",
      "Переходівка",
      "Переяслівка",
      "Перше Травня",
      "Печі",
      "Пирогівка",
      "Підгайне",
      "Платонівка",
      "Плиски",
      "Плоске",
      "Плуг",
      "Погребець",
      "Поляна",
      "Почечине",
      "Прачі",
      "Прохори",
      "Пустотине",
      "Рівчак-Степанівка",
      "Роздольне",
      "Рокитне",
      "Рубанка",
      "Рудьківка",
      "Сапонівка",
      "Свидовець",
      "Світанок",
      "Сиволож",
      "Сидорівка",
      "Синдаревське",
      "Смолове",
      "Смоляж",
      "Соколівка",
      "Ставок",
      "Станція Лосинівська",
      "Стара Басань",
      "Старий Биків",
      "Степ",
      "Степове",
      "Степові Хутори",
      "Стодоли",
      "Стрільники",
      "Сулак",
      "Сухиня",
      "Суховодка",
      "Талалаївка",
      "Тасуїв",
      "Татарівка",
      "Терешиха",
      "Терешківка",
      "Тертишники",
      "Тиниця",
      "Титівка",
      "Травкине",
      "Тростянка",
      "Українка",
      "Українське",
      "Урожайне",
      "Фастівці",
      "Халимонове",
      "Хвилівка",
      "Ховми",
      "Холявки",
      "Хомине",
      "Хомівка",
      "Хомівці",
      "Хороше Озеро",
      "Хотинівка",
      "Часниківка",
      "Червона Гірка",
      "Червоне Озеро",
      "Черемушки",
      "Черняхівка",
      "Чистий Колодязь",
      "Чистопілля",
      "Шаповалівка",
      "Шатура",
      "Шевченка",
      "Шевченкове",
      "Шняківка",
      "Шумейкине",
      "Шумейків",
      "Шумин",
      "Щаснівка",
      "Щуча Гребля",
      "Юність",
      "Юрківщина",
      "Яблуневе",
      "Яблунівка",
      "Ядути",
      "Ярославка",
      "Ясна Зірка",
      "Яхнівка",
      "Авдіївка",
      "Араповичі",
      "Архипівка",
      "Аршуки",
      "Атюша",
      "Баранівка",
      "Березова Гать",
      "Березовий Гай",
      "Билка",
      "Бирине",
      "Блешня",
      "Бобрик Другий",
      "Богданове",
      "Борисове",
      "Брониви",
      "Бугринівка",
      "Будо-Вороб’ївська",
      "Бужанка",
      "Бучки",
      "Великий Гай",
      "Великий Ліс",
      "Верба",
      "Вильчики",
      "Вишеньки",
      "Вільхівка",
      "Вільшанка",
      "Внутрішній Бір",
      "Володимирівка",
      "Вороб’ївка",
      "Восточне",
      "Газопровідне",
      "Галаганівка",
      "Галичівка",
      "Гаті",
      "Гірки",
      "Гнатівка",
      "Горбове",
      "Горохове",
      "Грем’яч",
      "Грем’ячка",
      "Груди",
      "Гута",
      "Деснянське",
      "Дігтярівка",
      "Дрібці",
      "Дробишів",
      "Єгорівка",
      "Єршов",
      "Жаданівка",
      "Жадове",
      "Жернівка",
      "Зайцеве",
      "Залізний Міст",
      "Зелена Поляна",
      "Зелена Роща",
      "Зелений Гай",
      "Зоря",
      "Іванине",
      "Іванпуть",
      "Іваньків",
      "Калинівське",
      "Кам’янська Слобода",
      "Камінь",
      "Карабани",
      "Карасі",
      "Карацюбине",
      "Карильське",
      "Карповичі",
      "Клевин",
      "Ковпинка",
      "Козилівщина",
      "Колос",
      "Комань",
      "Короп",
      "Костобобрів",
      "Красна Гірка",
      "Красний Хутір",
      "Красні Лози",
      "Краснопілля",
      "Кремський Бугор",
      "Кривуша",
      "Кринички",
      "Криски",
      "Криульки",
      "Кролевець-Слобідка",
      "Кудлаївка",
      "Кузьминське",
      "Кути Другі",
      "Кучі",
      "Ларинівка",
      "Лебедин",
      "Леньків",
      "Леонівка",
      "Лизунівка",
      "Лиса Гора",
      "Лісківщина",
      "Лісконоги",
      "Ломанка",
      "Лосевочка",
      "Лосівка",
      "Лоска",
      "Луб’яне",
      "Лубня",
      "Лукнів",
      "Максимихине",
      "Мамекине",
      "Марс",
      "Машеве",
      "Медведівка",
      "Мезин",
      "Михальчина-Слобода",
      "Мовчанів",
      "Мохове",
      "Мурав’ї",
      "Муравейник",
      "Мхи",
      "Набережне",
      "Накот",
      "Некрите",
      "Нехаївка",
      "Новгород-Сіверський",
      "Новеньке",
      "Нориця",
      "Об’єднане",
      "Оболоння",
      "Орликівка",
      "Орлівка",
      "Осове",
      "Печенюги",
      "Підгірне",
      "Погорільці",
      "Подоляки",
      "Покошичі",
      "Поліське",
      "Полюшкине",
      "Понорниця",
      "Попівка",
      "Придеснянське",
      "Прокопівка",
      "Пуста Гребля",
      "Путивськ",
      "Пушкарі",
      "Радичів",
      "Райгородок",
      "Ракути",
      "Ранок",
      "Риботин",
      "Рижки",
      "Рихли",
      "Рогівка",
      "Рождественське",
      "Розльоти",
      "Роща",
      "Рубанників",
      "Сапожків Хутір",
      "Сергіївське",
      "Сміле",
      "Солов’їв",
      "Сохачі",
      "Станове",
      "Стара Гутка",
      "Стахорщина",
      "Студинка",
      "Східне",
      "Тарасівка",
      "Тимоновичі",
      "Тополівка",
      "Троїцьке",
      "Турове",
      "Угли",
      "Узруй",
      "Ушівка",
      "Фаївка",
      "Ферубки",
      "Форостовичі",
      "Фроли",
      "Фурсове",
      "Хандобоківка",
      "Чайкине",
      "Черешеньки",
      "Чернацьке",
      "Чорний Ріг",
      "Чорнозем",
      "Чорнявка",
      "Чулатів",
      "Шабалинів",
      "Шаболтасівка",
      "Шведчина",
      "Шептаки",
      "Юхнове",
      "Янжулівка",
      "Ясна Поляна",
      "Ясне",
      "Августівка",
      "Антішки",
      "Антонівка",
      "Артеменків",
      "Бажанівка",
      "Бакаївка",
      "Барбурське",
      "Барвінкове",
      "Безбородьків",
      "Безводівка",
      "Бережівка",
      "Березовиця",
      "Берізка",
      "Білещина",
      "Білорічиця",
      "Білошапки",
      "Більмачівка",
      "Богдани",
      "Болотниця",
      "Боротьба",
      "Боршна",
      "Боханів",
      "Боярщина",
      "Брагинці",
      "Бубнівщина",
      "Буди",
      "Булавівщина",
      "Бурімка",
      "Валки",
      "Варва",
      "Васьківці",
      "Васюків",
      "Велика Дівиця",
      "Верескуни",
      "Вишнівка",
      "Власівка",
      "Воронівка",
      "Воскресенське",
      "Галка",
      "Гейці",
      "Глинське",
      "Глинщина",
      "Гмирянка",
      "Гнідинці",
      "Голубівка",
      "Горобіївка",
      "Городня",
      "Грабів",
      "Грабщина",
      "Григорівщина",
      "Грицівка",
      "Гриціївка",
      "Гужівка",
      "Гурбинці",
      "Густиня",
      "Даньківка",
      "Дащенки",
      "Дейманівка",
      "Дзюбівка",
      "Димирівка",
      "Дігтярі",
      "Дідівці",
      "Довбні",
      "Довгалівка",
      "Дорогинка",
      "Дружба",
      "Дубовий Гай",
      "Жолобок",
      "Журавка",
      "Загін",
      "Заїзд",
      "Замістя",
      "Запереводське",
      "Зарудка",
      "Заудаївське",
      "Заудайка",
      "Зінченкове",
      "Знам’янка",
      "Зоцівка",
      "Івангород",
      "Іваниця",
      "Іванківці",
      "Івківці",
      "Іценків",
      "Ічня",
      "Калиновиця",
      "Калюжинці",
      "Канівщина",
      "Капустенці",
      "Карпилівка",
      "Качанівка",
      "Киколи",
      "Коломійцеве",
      "Колядин",
      "Корінецьке",
      "Коршаки",
      "Красляни",
      "Красний Колядин",
      "Кроти",
      "Крупичполе",
      "Крутоярівка",
      "Куликівка",
      "Кулишівка",
      "Купина",
      "Кути",
      "Кухарка",
      "Лавіркове",
      "Ладан",
      "Ладівщина",
      "Лебединці",
      "Левівщина",
      "Левки",
      "Леляки",
      "Линовиця",
      "Липове",
      "Лиски",
      "Лисогори",
      "Лісові Сорочинці",
      "Лозове",
      "Лугове",
      "Лутайка",
      "Лучківка",
      "Мазки",
      "Макаренкове",
      "Максимівка",
      "Макушиха",
      "Мала Дівиця",
      "Малківка",
      "Манжосівка",
      "Мармизівка",
      "Мартинівка",
      "Маціївка",
      "Мигурів",
      "Мільки",
      "Мокляки",
      "Монастирище",
      "Мохнівка",
      "Мудре",
      "Нетягівщина",
      "Нетяжино",
      "Никонівка",
      "Нова",
      "Нова Гребля",
      "Нова Ольшана",
      "Нова Тарнавщина",
      "Новий Лад",
      "Новий Поділ",
      "Новопетрівське",
      "Обичів",
      "Обухове",
      "Однольків",
      "Олексинці",
      "Ольшана",
      "Оникіївка",
      "Онищенків",
      "Основа",
      "Охіньки",
      "Парафіївка",
      "Пелюхівка",
      "Переволочна",
      "Петрівське",
      "Петрушівка",
      "Петьків",
      "Пирогівці",
      "Піддубівка",
      "Плугатар",
      "Побочіївка",
      "Погреби",
      "Подище",
      "Поділ",
      "Поетин",
      "Покрівка",
      "Полова",
      "Пологи",
      "Полонки",
      "Понори",
      "Поповичка",
      "Прилуки",
      "Приозерне",
      "Припутні",
      "Проліски",
      "Пручаї",
      "Радьківка",
      "Рибці",
      "Рожнівка",
      "Рубанів",
      "Рудівка",
      "Рябухи",
      "Ряшки",
      "Саверське",
      "Савинці",
      "Сваричівка",
      "Світанкове",
      "Світличне",
      "Сезьки",
      "Селихів",
      "Сергіївка",
      "Сіриків",
      "Сіряківщина",
      "Скороходове",
      "Смош",
      "Сокиринці",
      "Співакове",
      "Срібне",
      "Стара Талалаївка",
      "Стасівщина",
      "Степанівське",
      "Ступаківка",
      "Сухоліски",
      "Сухополова",
      "Сухоставець",
      "Сухоярівка",
      "Тихе",
      "Тишківка",
      "Товкачівка",
      "Томашівка",
      "Тонка",
      "Тополя",
      "Точене",
      "Тростянець",
      "Турківка",
      "Удайці",
      "Хаєнки",
      "Хаїха",
      "Харитонівка",
      "Харкове",
      "Хортиця",
      "Хукалівка",
      "Червоне",
      "Чернецьке",
      "Шиловичі",
      "Щурівка",
      "Южне",
      "Юрківці",
      "Яблунівське",
      "Ярова Білещина",
      "Ященків",
      "Автуничі",
      "Альошинське",
      "Анисів",
      "Антоновичі",
      "Аткильня",
      "Бабарики",
      "Бакланова Муравійка",
      "Барабанівське",
      "Бахани",
      "Безиків",
      "Березна",
      "Беремицьке",
      "Берилівка",
      "Берлози",
      "Бихольцохівка",
      "Бігач",
      "Білейки",
      "Білики",
      "Бір",
      "Ближнє",
      "Блудше",
      "Бобруйки",
      "Боровики",
      "Боромики",
      "Борсуків",
      "Боярівка",
      "Брусилів",
      "Булахів",
      "Бурівка",
      "Буянки",
      "Ваганичі",
      "Василева Гута",
      "Ведильці",
      "Велика Вісь",
      "Великий Дирчин",
      "Великий Зліїв",
      "Великий Листвен",
      "Великі Осняки",
      "Вербичі",
      "Вересоч",
      "Вертеча",
      "Вершини",
      "Вершинова Муравійка",
      "Виблі",
      "Виповзів",
      "Вир",
      "Високинь",
      "Вихвостів",
      "Вовчок",
      "Вознесенське",
      "Вокзал-Городня",
      "Волевачі",
      "Вороб’їв",
      "Ворохівка",
      "Гайове",
      "Галків",
      "Гальчин",
      "Гарбузин",
      "Гасичівка",
      "Гірманка",
      "Гладке",
      "Гламазди",
      "Глиненка",
      "Глузди",
      "Глядин",
      "Гніздище",
      "Голубичі",
      "Гончарівське",
      "Гориця",
      "Горностаївка",
      "Горошківка",
      "Грабівка",
      "Гребля",
      "Грибова Рудня",
      "Губарі",
      "Губичі",
      "Гуньківка",
      "Гусавка",
      "Гута-Ткачова",
      "Гучин",
      "Гущин",
      "Даничі",
      "Данівка",
      "День-Добрий",
      "Деревини",
      "Десна",
      "Деснянка",
      "Дешки",
      "Димерка",
      "Диханівка",
      "Дібрівне",
      "Дніпровське",
      "Добрянка",
      "Довгуни",
      "Домниця",
      "Драчівщина",
      "Дрімайлівка",
      "Дроздівка",
      "Дроздовиця",
      "Друцьке",
      "Духанки",
      "Євминка",
      "Єньків",
      "Єрків",
      "Жавинка",
      "Жеведь",
      "Жеребецьке",
      "Жидиничі",
      "Жилин Млинок",
      "Жуківка",
      "Жуківщина",
      "Жукотки",
      "Завод",
      "Заводське",
      "Загатка",
      "Задеріївка",
      "Зайці",
      "Закревське",
      "Замглай",
      "Звеничів",
      "Здрягівка",
      "Золотинка",
      "Зубахи",
      "Івашківка",
      "Ільмівка",
      "Калитянське",
      "Кам’янка",
      "Карасинівка",
      "Карпівка",
      "Карпоки",
      "Картовецьке",
      "Кархівка",
      "Киїнка",
      "Кислі",
      "Кіпті",
      "Кладьківка",
      "Климентинівка",
      "Клонів",
      "Клочків",
      "Клубівка",
      "Кобилянка",
      "Ковпита",
      "Ковчин",
      "Козелець",
      "Козероги",
      "Количівка",
      "Конотоп",
      "Копачів",
      "Корніїв",
      "Коробки",
      "Корольча",
      "Короп’є",
      "Коростень",
      "Корчев’я",
      "Косачівка",
      "Котів",
      "Кошани",
      "Кошарище",
      "Кошівка",
      "Красківське",
      "Кратинь",
      "Крені",
      "Крехаїв",
      "Кривицьке",
      "Кругле",
      "Кувечичі",
      "Кузничі",
      "Кукарі",
      "Курганське",
      "Кусії",
      "Ладинка",
      "Лашуки",
      "Лебедівка",
      "Левичівка",
      "Левковичі",
      "Левоньки",
      "Лемеші",
      "Лемешівка",
      "Леньків Круг",
      "Лизунова Рудня",
      "Линея",
      "Лихолітки",
      "Лісківка",
      "Лісне",
      "Ловинь",
      "Локнисте",
      "Лопатні",
      "Лошакова Гута",
      "Лутава",
      "Льгів",
      "Льгівка",
      "Любеч",
      "Любечанинів",
      "Лютіж",
      "Мажугівка",
      "Макишин",
      "Максим",
      "Малий Дирчин",
      "Малий Зліїв",
      "Малий Листвен",
      "Малинівка",
      "Малійки",
      "Мальча",
      "Маньки",
      "Мекшунівка",
      "Минаївщина",
      "Миси",
      "Михайло-Коцюбинське",
      "Мньов",
      "Мокрі Велички",
      "Моложава",
      "Молочки",
      "Моргуличі",
      "Морівськ",
      "Москалі",
      "Мостище",
      "Мохнатин",
      "Мохначі",
      "Мощенка",
      "Мощне",
      "Мурівка",
      "Мутичів",
      "Набільське",
      "Надинівка",
      "Невкля",
      "Неданчичі",
      "Нічогівка",
      "Нова Папірня",
      "Нова Рудня",
      "Новий Білоус",
      "Новий Шлях",
      "Новики",
      "Нові Яриловичі",
      "Новосілки",
      "Новоукраїнське",
      "Одинці",
      "Озерне",
      "Олбин",
      "Олександрівка Друга",
      "Олексіївщина",
      "Олишівка",
      "Омелянів",
      "Опеньки",
      "Отрохи",
      "Павлівка",
      "Павло-Іванівське",
      "Пакуль",
      "Папірня",
      "Пархимів",
      "Патюти",
      "Пекурівка",
      "Пенязівка",
      "Перепис",
      "Перерост",
      "Пересаж",
      "Петрики",
      "Петрове",
      "Петрушин",
      "Петруші",
      "Пилипча",
      "Пильня",
      "Пилятин",
      "Пищики",
      "Півнівщина",
      "Підлісне",
      "Пізнє",
      "Пізнопали",
      "Пісоцьке",
      "Плехтіївка",
      "Повідов",
      "Подин",
      "Політрудня",
      "Полуботки",
      "Привітне",
      "Присторонь",
      "Прохорів",
      "Пустинки",
      "Пушкіне",
      "Радуль",
      "Рашкова Слобода",
      "Ревунів Круг",
      "Редьківка",
      "Рижики",
      "Риків",
      "Рівнопілля",
      "Ріпки",
      "Рогощі",
      "Розвинівка",
      "Розівка",
      "Розсудів",
      "Роїще",
      "Романьки",
      "Рубіж",
      "Рудка",
      "Рябці",
      "Савин",
      "Савинка",
      "Салтикова Дівиця",
      "Самійлівка",
      "Самсони",
      "Сахнівка",
      "Святі Гори",
      "Седнів",
      "Селянська Слобода",
      "Семаки",
      "Семенягівка",
      "Сеньківка",
      "Сенюки",
      "Серединка",
      "Сибереж",
      "Сивухи",
      "Сиделівка",
      "Сираї",
      "Сіножацьке",
      "Скиток",
      "Скитьки",
      "Скорінець",
      "Скрипчин",
      "Скугарі",
      "Слабин",
      "Слобода",
      "Смичин",
      "Смолигівка",
      "Смолин",
      "Смолянка",
      "Снов’янка",
      "Сокирин",
      "Солонівка",
      "Сорокошичі",
      "Ставиське",
      "Старий Білоус",
      "Старик",
      "Старі Яриловичі",
      "Старосілля",
      "Стаси",
      "Стовпівка",
      "Строївка",
      "Студенець",
      "Суличівка",
      "Суслівка",
      "Сутоки",
      "Табаївка",
      "Тамарівка",
      "Тараса Шевченка",
      "Тарасів",
      "Терехівка",
      "Товстоліс",
      "Тополі",
      "Топчіївка",
      "Травневе",
      "Трисвятська Слобода",
      "Трудове",
      "Тужар",
      "Тулія",
      "Туманська Гута",
      "Тупичів",
      "Убіжичі",
      "Уборки",
      "Углова Рудня",
      "Унучки",
      "Халявин",
      "Хатилова Гута",
      "Хибалівка",
      "Хмільниця",
      "Хоробичі",
      "Хотівля",
      "Храпате",
      "Хрещате",
      "Хрипівка",
      "Центральне",
      "Часнівці",
      "Чемер",
      "Черецьке",
      "Черниш",
      "Чернігів",
      "Чижівка",
      "Чисті Лужі",
      "Чудівка",
      "Чумак",
      "Шами",
      "Шапіхи",
      "Шестовиця",
      "Шибиринівка",
      "Шкуранка",
      "Шмаївка",
      "Шолойки",
      "Шпаків",
      "Шульгівка",
      "Шуляки",
      "Шумани",
      "Юр’ївка",
      "Ягідне",
      "Якубівка",
      "Ямище",
      "Ясенівка",
      "Яськове",
    ],
  },
];
