/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import propTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import BlockIcon from "@mui/icons-material/Block";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// Data to delete
// import currencysTableData from "layouts/tables/data/currencysTableData";

import {
  Button,
  Menu,
  MenuItem,
  Pagination,
  Paper,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ApiService from "../../../helpers/apiHelper";

import { useEffect, useState } from "react";
// eslint-disable-next-line
import SearchBar from "components/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import { setIsOpenDeleteModal, setIsOpenSearchBar } from "redux/mainReducer";

import { dateTimeFormattingByString } from "helpers/date-helpers";
import { setIsOpenServiceModal } from "redux/mainReducer";
import { setIsOpenBlockModal } from "redux/mainReducer";
import { Spinner } from "components/Spinner";
function createTableData(
  id,
  nameService,
  price,
  currency,
  creationTimeForService,
  numberFeedback,
  rating,

  templateData
) {
  return {
    id,
    nameService,
    price,
    currency,
    creationTimeForService,
    numberFeedback,
    rating,
    templateData,
  };
}
function Row({ row, editRow }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // const badgeContent = () => {
  //   if (row.status === "premium") {
  //     return "Преміум";
  //   } else if (row.status === "base") {
  //     return "Базовий";
  //   }
  // };
  // const badgeColor = () => {
  //   if (row.status === "premium") {
  //     return "warning";
  //   } else if (row.status === "base") {
  //     return "success";
  //   }
  // };
  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      width: "300px",
      maxWidth: "500px",
    },
  });
  return (
    <>
      <TableRow
        sx={{
          "& > *": { borderBottom: "unset" },
          backgroundColor:
            row?.templateData?.isBlocked && "rgba(245, 151, 151, 0.28)",
        }}
      >
        <TableCell align="left">
          <CustomWidthTooltip
            title={row.id}
            placement="right"
            sx={{ width: "10px" }}
          >
            <Typography
              sx={({ typography: { size, fontWeightBold } }) => ({
                fontSize: size.xxs,
                fontWeight: fontWeightBold,
                textTransform: "uppercase",
                ":hover": {
                  color: "lightblue",
                  cursor: "pointer",
                },
                width: "fit-content",
              })}
            >
              Показати
            </Typography>
          </CustomWidthTooltip>
        </TableCell>
        <TableCell
          align="center"
          sx={({ typography: { size, fontWeightBold } }) => ({
            fontSize: size.xxs,
            fontWeight: fontWeightBold,
            textTransform: "uppercase",
            // width: cell.width,
          })}
        >
          <Typography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xxs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {row.nameService}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xxs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {`${row.price} ${row.currency}`}
          </Typography>
        </TableCell>
        <TableCell
          align="center"
          sx={({ typography: { size, fontWeightBold } }) => ({
            fontSize: size.xxs,
            fontWeight: fontWeightBold,
            textTransform: "uppercase",
            // width: cell.width,
          })}
        >
          <Typography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xxs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {dateTimeFormattingByString(row.creationTimeForService)}
          </Typography>
        </TableCell>
        <TableCell
          align="center"
          sx={({ typography: { size, fontWeightBold } }) => ({
            fontSize: size.xxs,
            fontWeight: fontWeightBold,
            textTransform: "uppercase",
            // width: cell.width,
          })}
        >
          <Typography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xxs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {row.numberFeedback}
          </Typography>
        </TableCell>
        <TableCell
          align="center"
          sx={({ typography: { size, fontWeightBold } }) => ({
            fontSize: size.xxs,
            fontWeight: fontWeightBold,
            textTransform: "uppercase",
            // width: cell.width,
          })}
        >
          <Rating
            sx={{ ml: "15px" }}
            name="read-only"
            value={row.rating}
            precision={0.5}
            readOnly
          />
        </TableCell>

        <TableCell align="center">
          {/* <Typography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xxs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            <Tooltip title="Змінити" placement="bottom">
              <EditIcon
                sx={{ fontSize: "16px !important", cursor: "pointer" }}
                onClick={() => editRow(row, "edit")}
              />
            </Tooltip>
            <Tooltip title="Видалити" placement="bottom">
              <DeleteOutlineIcon
                sx={{ fontSize: "16px !important", cursor: "pointer" }}
                onClick={() => editRow(row, "delete")}
              />
            </Tooltip>
          </Typography> */}
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <MoreVertIcon />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                editRow(row, "block");
                handleClose();
              }}
            >
              <BlockIcon
                sx={{ fontSize: "16px !important", cursor: "pointer", mr: 1 }}
              />
              {row?.templateData?.isBlocked ? "Розблокувати" : "Заблокувати"}
            </MenuItem>

            <MenuItem
              onClick={() => {
                editRow(row, "edit");
                handleClose();
              }}
            >
              <EditIcon
                sx={{
                  fontSize: "16px !important",
                  cursor: "pointer",
                  mr: 1,
                }}
              />
              Змінити
            </MenuItem>

            <MenuItem
              onClick={() => {
                editRow(row, "delete");
                handleClose();
              }}
            >
              <DeleteOutlineIcon
                sx={{ fontSize: "16px !important", cursor: "pointer", mr: 1 }}
              />
              Видалити
            </MenuItem>
          </Menu>
        </TableCell>
      </TableRow>
    </>
  );
}

function TableServices() {
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [pagesCount, setPagesCount] = useState(0);
  const [offset, setOffset] = useState(0);
  // eslint-disable-next-line
  const isOpenSearchBar = useSelector((state) => state.global.isOpenSearchBar);

  const dispatch = useDispatch();
  // const apiBlock = new ApiService("service/switch-blocking");
  // const apiGetServices = new ApiService("service/search/admin");
  // const apiService = new ApiService("service");
  // const apiUserToken = new ApiService("auth/admin/get-user-token");
  const apiBlock = new ApiService("posts/service/switch-blocking");
  const apiGetServices = new ApiService("posts/service/search/admin");
  const apiService = new ApiService("posts/service");
  const apiUserToken = new ApiService("auth/admin/get-user-token");

  const tableCells = [
    { header: "id", align: "left", width: "5%" },
    { header: "Назва", align: "center" },
    { header: "Ціна", align: "center" },
    { header: "Дата створення", align: "center" },
    { header: "К-ть відгуків", align: "center" },
    { header: "Рейтинг", align: "center" },
    // { header: "Статус", align: "center" },
    { header: "Дії", align: "center", width: "5%" },
  ];
  const [rows, setRows] = useState([]);

  const handleGetServices = () => {
    setIsLoading(true);
    apiGetServices
      .getAll({ start: offset, count: 10 })
      .then((res) => {
        const rows = res.result.map((row) => {
          return createTableData(
            row.id,
            row.nameService,
            row.price,
            row.currency,
            row.creationTimeForService,
            row.numberFeedback,
            row.rating,
            row
          );
        });
        setRows(rows);
        setPagesCount(
          Number.isInteger(res.count / 10)
            ? res.count / 10
            : Math.floor(+(res.count / 10)) + 1
        );
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };
  const searchFunction = (searchValue) => {
    setIsLoading(true);
    if (searchValue && searchValue.length !== 0) {
      apiGetServices
        .getAll({
          start: 0,
          count: 10,
          searchString: searchValue ? searchValue : "",
        })
        .then((res) => {
          const rows = res.result.map((row) => {
            return createTableData(
              row.id,
              row.nameService,
              row.price,
              row.currency,
              row.creationTimeForService,
              row.numberFeedback,
              row.rating,
              row
            );
          });
          setRows(rows);
          setPagesCount(
            Number.isInteger(res.count / 10)
              ? res.count / 10
              : Math.floor(+(res.count / 10)) + 1
          );
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    } else {
      handleGetServices();
    }
  };

  const deleteFunc = (id, creator) => {
    apiUserToken.getAll({ userId: creator }).then(async (res) => {
      await localStorage.setItem("userToken", `Bearer ${res.token}`);
      apiService
        .deleteItemAdmin(id)
        .then((res) => {
          setSelectedRow(null);
          handleGetServices();
          dispatch(
            setIsOpenDeleteModal({
              isOpen: false,
              text: "",
              func: null,
              id: null,
              creatorId: null,
            })
          );
          localStorage.removeItem("userToken");
        })
        .catch((error) => console.error(error));
    });
  };
  const blockFunc = (id, row) => {
    apiBlock
      .updateDataQuery(
        row
          ? {
              serviceId: id,
              isBlocked: row?.templateData?.isBlocked ? false : true,
            }
          : {
              serviceId: id,
              isBlocked: selectedRow?.templateData?.isBlocked ? false : true,
            }
      )
      .then((res) => {
        handleGetServices();
        setSelectedRow(null);
        dispatch(
          setIsOpenBlockModal({
            isOpen: false,
            text: "",
            func: null,
            id: null,
          })
        );
      });
  };
  const handleEditRow = (row, reason) => {
    if (reason === "delete") {
      apiService.getItemById(row?.id).then((res) => {
        const rowPrepared = { ...row, templateData: res.result };
        setSelectedRow(rowPrepared);
        dispatch(
          setIsOpenDeleteModal({
            isOpen: true,
            text: "цю послугу?",
            func: deleteFunc,
            id: row.id,
            creatorId: res?.result?.userDTO?.id,
          })
        );
      });
    }
    if (reason === "edit") {
      apiService.getItemById(row?.id).then((res) => {
        const rowPrepared = { ...row, templateData: res.result };
        setSelectedRow(rowPrepared);
        dispatch(
          setIsOpenServiceModal({
            isOpen: true,
            data: rowPrepared?.templateData,
            func: handleGetServices,
          })
        );
      });
    }
    if (reason === "block") {
      setSelectedRow(row);

      if (row?.templateData?.isBlocked) {
        blockFunc(row?.id, row);
      } else {
        dispatch(
          setIsOpenBlockModal({
            isOpen: true,
            text: "цю послугу?",
            func: blockFunc,
            id: row.id,
          })
        );
      }
    }
  };
  useEffect(() => {
    handleGetServices();
    // dispatch(setIsOpenSearchBar(true));
    // eslint-disable-next-line
  }, [offset]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* {isOpenServiceModal && (
        <ServiceModal
          isOpenModal={isOpenServiceModal}
          data={selectedRow?.templateData}
          refreshData={handleGetServices}
        />
      )} */}

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="success"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Послуги
                </MDTypography>
                <SearchBar func={searchFunction} />
                <SearchIcon
                  sx={{
                    color: "white !important",
                    cursor: "pointer !important",
                  }}
                  onClick={() => dispatch(setIsOpenSearchBar(!isOpenSearchBar))}
                />
                {/* <Tooltip title="Додати валюту">
                  <AddIcon
                    onClick={() => console.log("CURRENCY ADD")}
                    sx={{
                      color: "white !important",
                      fontSize: "24px !important",
                      cursor: "pointer",
                      ":hover": {
                        color: "black !important",
                      },
                    }}
                  />
                </Tooltip> */}
              </MDBox>
              <MDBox pt={2}>
                {/* <DataTable
                  // canSearch
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                /> */}
                {!isLoading ? (
                  <>
                    {" "}
                    <TableContainer
                      component={Paper}
                      sx={{ borderRadius: "0px !important", mt: 0 }}
                    >
                      <Table>
                        <TableHead
                          sx={{
                            borderRadius: "0px !important",
                            display: "table-header-group",
                          }}
                        >
                          <TableRow>
                            {tableCells.map((cell, index) => (
                              <TableCell
                                key={cell.header + cell + index}
                                align={cell.align}
                                sx={({
                                  typography: { size, fontWeightBold },
                                }) => ({
                                  fontSize: size.xxs,
                                  fontWeight: fontWeightBold,
                                  textTransform: "uppercase",
                                  width: cell.width,
                                })}
                              >
                                {cell.header}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row) => (
                            <Row
                              key={row.id + row.tikker}
                              row={row}
                              editRow={handleEditRow}
                              refreshData={handleGetServices}
                            />
                          ))}
                        </TableBody>
                      </Table>
                      <Pagination
                        sx={{ display: "flex", justifyContent: "end", m: 1 }}
                        defaultPage={1}
                        siblingCount={0}
                        count={pagesCount}
                        shape="rounded"
                        page={offset / 10 + 1}
                        onChange={(event, value) => setOffset(value * 10 - 10)}
                      />
                    </TableContainer>
                  </>
                ) : (
                  <div style={{ marginTop: "10%", marginBottom: "10%" }}>
                    <Spinner type="small" />
                  </div>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
Row.propTypes = {
  row: propTypes.object,
};
export default TableServices;
