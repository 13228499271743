/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import propTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";
// Data to delete
// import currencysTableData from "layouts/tables/data/currencysTableData";

import {
  Button,
  Menu,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import MDBadge from "components/MDBadge";
import * as React from "react";
import ApiService from "../../../helpers/apiHelper";

import { useEffect, useState } from "react";
// eslint-disable-next-line
import SearchBar from "components/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import { setIsOpenDeleteModal, setIsOpenSearchBar } from "redux/mainReducer";

import UserEditModal from "components/UserEditModal/UserEditModal";
import { setIsOpenUserEditModal } from "redux/mainReducer";
import { setIsOpenBlockModal } from "redux/mainReducer";
import { setIsOpenPremiumSetterModal } from "redux/mainReducer";
import { setIsOpenStatusSetterModal } from "redux/mainReducer";
import { Spinner } from "components/Spinner";
import { useSnackbar } from "notistack";
function createTableData(
  id,
  nickname,
  name,
  surname,
  email,
  phone,
  servicesCount,
  postsCount,
  status,
  isBlocked,
  templateData
) {
  return {
    id,
    nickname,
    name,
    surname,
    email,
    phone,
    servicesCount,
    postsCount,
    status,
    isBlocked,
    templateData,
  };
}
function Row({ row, editRow, userData }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const badgeContent = () => {
    if (row.status === "PREMIUM_USER" || row.status === "FREEZE_PREMIUM") {
      if (
        row?.templateData?.subscriptionType &&
        row?.templateData?.subscriptionType === "FREE_TRIAL_PREMIUM"
      ) {
        return (
          <p>
            Преміум <br /> <span style={{ fontSize: "7px" }}>пробний</span>
          </p>
        );
      } else if (
        row?.templateData?.subscriptionType &&
        row?.templateData?.subscriptionType === "TEMP_PREMIUM"
      ) {
        return (
          <p>
            Преміум <br /> <span style={{ fontSize: "7px" }}>тимчасовий</span>
          </p>
        );
      } else if (
        row?.templateData?.subscriptionType &&
        row?.templateData?.subscriptionType === "MONTH_PREMIUM"
      ) {
        return (
          <p>
            Преміум <br /> <span style={{ fontSize: "7px" }}>місяць</span>
          </p>
        );
      } else if (
        row?.templateData?.subscriptionType &&
        row?.templateData?.subscriptionType === "THREE_MONTH_PREMIUM"
      ) {
        return (
          <p>
            Преміум <br /> <span style={{ fontSize: "7px" }}>3 місяці</span>
          </p>
        );
      } else if (
        row?.templateData?.subscriptionType &&
        row?.templateData?.subscriptionType === "THREE_MONTH_PREMIUM"
      ) {
        return (
          <p>
            Преміум <br /> <span style={{ fontSize: "7px" }}>рік</span>
          </p>
        );
      } else {
        return "Преміум";
      }
    } else if (row.status === "USER") {
      return "Базовий";
    } else if (row.status === "ADMIN") {
      return "Адмін";
    } else if (row.status === "SUPER_ADMIN") {
      return "Супер-Адмін";
    }
  };
  const badgeColor = () => {
    if (!row?.isBlocked) {
      if (row.status === "PREMIUM_USER" || row.status === "FREEZE_PREMIUM") {
        return "warning";
      } else if (row.status === "USER") {
        return "success";
      } else if (row.status === "ADMIN") {
        return "info";
      } else if (row.status === "SUPER_ADMIN") {
        return "dark";
      }
    } else {
      return "error";
    }
  };
  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      width: "300px",
      maxWidth: "500px",
    },
  });
  return (
    <>
      <TableRow
        sx={{
          "& > *": {
            borderBottom: "unset",
            backgroundColor:
              row?.templateData?.isBlocked && "rgba(245, 151, 151, 0.28)",
          },
        }}
      >
        <TableCell align="left">
          <CustomWidthTooltip
            title={row.id}
            placement="right"
            sx={{ width: "10px" }}
          >
            <Typography
              sx={({ typography: { size, fontWeightBold } }) => ({
                fontSize: size.xxs,
                fontWeight: fontWeightBold,
                textTransform: "uppercase",
                ":hover": {
                  color: "lightblue",
                  cursor: "pointer",
                },
                width: "fit-content",
              })}
            >
              Показати
            </Typography>
          </CustomWidthTooltip>
        </TableCell>
        <TableCell
          align="center"
          sx={({ typography: { size, fontWeightBold } }) => ({
            fontSize: size.xxs,
            fontWeight: fontWeightBold,
            textTransform: "uppercase",
            // width: cell.width,
          })}
        >
          <Typography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xxs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {row.nickname}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xxs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {`${row.surname}`}
          </Typography>
          <Typography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xxs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {`${row.name}`}
          </Typography>
        </TableCell>
        <TableCell
          align="center"
          sx={({ typography: { size, fontWeightBold } }) => ({
            fontSize: size.xxs,
            fontWeight: fontWeightBold,
            textTransform: "uppercase",
            // width: cell.width,
          })}
        >
          <Typography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xxs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {row.email}
          </Typography>
          <Typography
            sx={({ typography: { size, fontWeightLight } }) => ({
              fontSize: size.xxs,
              fontWeight: fontWeightLight,
              textTransform: "capitalize",
            })}
          >
            {row.phone}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xxs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {row.servicesCount}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xxs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            {row.postsCount}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xxs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            <MDBadge
              badgeContent={badgeContent()}
              color={badgeColor()}
              variant="gradient"
              size="sm"
              style={{
                marginLeft: 0,
                width: 80,
                justifyContent: "center",
              }}
            />
          </Typography>
        </TableCell>
        <TableCell align="center">
          {/* <Typography
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xxs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
            })}
          >
            <Tooltip title="Змінити" placement="bottom">
              <EditIcon
                sx={{ fontSize: "16px !important", cursor: "pointer" }}
                onClick={() => editRow(row, "edit")}
              />
            </Tooltip>
            <Tooltip title="Видалити" placement="bottom">
              <DeleteOutlineIcon
                sx={{ fontSize: "16px !important", cursor: "pointer" }}
                onClick={() => editRow(row, "delete")}
              />
            </Tooltip>
          </Typography> */}
          {(userData &&
            userData?.role === "ADMIN" &&
            row?.templateData?.role === "USER") ||
          (userData &&
            userData?.role === "ADMIN" &&
            row?.templateData?.role === "PREMIUM_USER") ||
          (userData &&
            userData?.role === "SUPER_ADMIN" &&
            row?.templateData?.role === "ADMIN") ||
          (userData &&
            userData?.role === "SUPER_ADMIN" &&
            row?.templateData?.role === "SUPER_ADMIN") ||
          (userData &&
            userData?.role === "SUPER_ADMIN" &&
            row?.templateData?.role === "USER") ? (
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <MoreVertIcon />
            </Button>
          ) : null}

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {(userData &&
              userData?.role === "ADMIN" &&
              row?.templateData?.role === "USER") ||
            (userData &&
              userData?.role === "ADMIN" &&
              row?.templateData?.role === "PREMIUM_USER" &&
              row?.templateData?.subscriptionType === "TEMP_PREMIUM") ? (
              <MenuItem
                onClick={() => {
                  editRow(row, "premium");
                  handleClose();
                }}
              >
                <WorkspacePremiumOutlinedIcon
                  sx={{
                    fontSize: "16px !important",
                    cursor: "pointer",
                    mr: 1,
                  }}
                />
                {row?.templateData?.role !== "PREMIUM_USER"
                  ? "Надати преміум"
                  : "Забрати преміум"}
              </MenuItem>
            ) : null}
            {userData &&
            userData?.role === "ADMIN" &&
            row?.templateData?.role === "PREMIUM_USER" ? (
              <MenuItem
                onClick={() => {
                  editRow(row, "premiumProlongation");
                  handleClose();
                }}
              >
                <WorkspacePremiumOutlinedIcon
                  sx={{
                    fontSize: "16px !important",
                    cursor: "pointer",
                    mr: 1,
                  }}
                />
                Продовжити преміум
              </MenuItem>
            ) : null}
            {(userData &&
              userData?.role === "ADMIN" &&
              row?.templateData?.role === "USER") ||
            (userData &&
              userData?.role === "ADMIN" &&
              row?.templateData?.role === "PREMIUM_USER") ||
            (userData &&
              userData?.role === "SUPER_ADMIN" &&
              row?.templateData?.role === "ADMIN") ||
            (userData &&
              userData?.role === "SUPER_ADMIN" &&
              row?.templateData?.role === "SUPER_ADMIN") ? (
              <MenuItem
                onClick={() => {
                  editRow(row, "block");
                  handleClose();
                }}
              >
                <BlockIcon
                  sx={{ fontSize: "16px !important", cursor: "pointer", mr: 1 }}
                />
                {row?.templateData?.isBlocked ? "Розблокувати" : "Заблокувати"}
              </MenuItem>
            ) : null}

            {userData && userData?.role === "ADMIN" ? (
              <MenuItem
                onClick={() => {
                  editRow(row, "edit");
                  handleClose();
                }}
              >
                <EditIcon
                  sx={{
                    fontSize: "16px !important",
                    cursor: "pointer",
                    mr: 1,
                  }}
                />
                Змінити
              </MenuItem>
            ) : null}
            {userData && userData?.role === "ADMIN" ? (
              <MenuItem
                onClick={() => {
                  editRow(row, "delete");
                  handleClose();
                }}
              >
                <DeleteOutlineIcon
                  sx={{ fontSize: "16px !important", cursor: "pointer", mr: 1 }}
                />
                Видалити
              </MenuItem>
            ) : null}
            {(userData &&
              userData?.role === "SUPER_ADMIN" &&
              row?.templateData?.role === "ADMIN") ||
            (userData &&
              userData?.role === "SUPER_ADMIN" &&
              row?.templateData?.role === "USER") ||
            (userData &&
              userData?.role === "SUPER_ADMIN" &&
              row?.templateData?.role === "SUPER_ADMIN") ? (
              <MenuItem
                onClick={() => {
                  editRow(row, "status");
                  handleClose();
                }}
              >
                <EditIcon
                  sx={{
                    fontSize: "16px !important",
                    cursor: "pointer",
                    mr: 1,
                  }}
                />
                Змінити статус
              </MenuItem>
            ) : null}
          </Menu>
        </TableCell>
      </TableRow>
    </>
  );
}

function TableUsers() {
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [pagesCount, setPagesCount] = useState(0);
  const [offset, setOffset] = useState(0);
  // eslint-disable-next-line
  const userData = useSelector((state) => state.global.userData);
  const isOpenSearchBar = useSelector((state) => state.global.isOpenSearchBar);
  const isOpenUserEditModal = useSelector(
    (state) => state.global.isOpenUserEditModal
  );
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const apiBlock = new ApiService("auth/admin/switch-blocked-status-user");
  const apiGetUsers = new ApiService("auth/admin/search");
  const apiGetUsersSuper = new ApiService("auth/admin-action/search");
  const apiDelete = new ApiService("auth/admin/delete-user");
  const apiStatusChange = new ApiService("auth/admin-action/change-status");
  const apiPremiumSetter = new ApiService(
    "auth/admin/create-premium-subscription"
  );
  const apiPremiumSetterRemove = new ApiService(
    "auth/admin/remove-premium-subscription"
  );

  const tableCells = [
    { header: "id", align: "left", width: "5%" },
    { header: "Нікнейм", align: "center" },
    { header: "ПІБ", align: "center" },
    { header: "Контакти", align: "center" },
    { header: "К-ть сервісів", align: "center" },
    { header: "К-ть постів", align: "center" },
    { header: "Статус", align: "center" },
    { header: "Дії", align: "center", width: "5%" },
  ];
  const [rows, setRows] = useState([]);

  const handleGetUsers = () => {
    setIsLoading(true);
    if (userData?.role === "ADMIN") {
      apiGetUsers
        .getAll({ startWith: offset, count: 10 })
        .then((res) => {
          const rows = res.result.map((row) => {
            return createTableData(
              row.id,
              row.nickname,
              row.name,
              row.surname,
              row.email,
              row.number,
              row.countServices,
              row.countPosts,
              row.role,
              row.isBlocked,
              row
            );
          });
          setRows(rows);
          setPagesCount(
            Number.isInteger(res.count / 10)
              ? res.count / 10
              : Math.floor(+(res.count / 10)) + 1
          );
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    }
    if (userData?.role === "SUPER_ADMIN") {
      apiGetUsersSuper
        .getAll({ startWith: offset, count: 10 })
        .then((res) => {
          const rows = res.result.map((row) => {
            return createTableData(
              row.id,
              row.nickname,
              row.name,
              row.surname,
              row.email,
              row.number,
              row.countServices,
              row.countPosts,
              row.role,
              row.isBlocked,
              row
            );
          });
          setRows(rows);
          setPagesCount(
            Number.isInteger(res.count / 10)
              ? res.count / 10
              : Math.floor(+(res.count / 10)) + 1
          );
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  };
  const searchFunction = (searchValue) => {
    setIsLoading(true);
    if (searchValue && searchValue.length !== 0) {
      if (userData?.role === "ADMIN") {
        apiGetUsers
          .getAll({
            startWith: 0,
            count: 10,
            searchString: searchValue ? searchValue : "",
          })
          .then((res) => {
            const rows = res.result.map((row) => {
              return createTableData(
                row.id,
                row.nickname,
                row.name,
                row.surname,
                row.email,
                row.number,
                row.countServices,
                row.countPosts,
                row.role,
                row.isBlocked,
                row
              );
            });
            setRows(rows);
            setPagesCount(
              Number.isInteger(res.count / 10)
                ? res.count / 10
                : Math.floor(+(res.count / 10)) + 1
            );
            setIsLoading(false);
          })
          .catch((error) => {
            console.error(error);
            setIsLoading(false);
          });
      }
      if (userData?.role === "SUPER_ADMIN") {
        apiGetUsersSuper
          .getAll({
            startWith: 0,
            count: 10,
            searchString: searchValue ? searchValue : "",
          })
          .then((res) => {
            const rows = res.result.map((row) => {
              return createTableData(
                row.id,
                row.nickname,
                row.name,
                row.surname,
                row.email,
                row.number,
                row.countServices,
                row.countPosts,
                row.role,
                row.isBlocked,
                row
              );
            });
            setRows(rows);
            setPagesCount(
              Number.isInteger(res.count / 10)
                ? res.count / 10
                : Math.floor(+(res.count / 10)) + 1
            );
            setIsLoading(false);
          })
          .catch((error) => {
            console.error(error);
            setIsLoading(false);
          });
      }
    } else {
      handleGetUsers();
    }
  };

  const statusSetter = (id, status = null) => {
    if (status) {
      apiStatusChange
        .updateDataQuery({ userId: id, roleType: status })
        .then((res) => {
          setSelectedRow(null);
          handleGetUsers();
          dispatch(
            setIsOpenStatusSetterModal({
              isOpen: false,
              func: null,
              id: null,
              status: "",
            })
          );
        })
        .catch((error) => {
          console.error(error);
          enqueueSnackbar(error, { variant: "error" });
        });
    } else {
    }
  };
  const premiumSetter = (id, date = null) => {
    if (date) {
      apiPremiumSetter
        .postDataQuery({ userId: id, paymentTo: date })
        .then((res) => {
          setSelectedRow(null);
          handleGetUsers();
          dispatch(
            setIsOpenPremiumSetterModal({
              isOpen: false,
              func: null,
              id: null,
              isPremium: false,
              endDate: null,
            })
          );
        })
        .catch((error) => console.error(error));
    } else {
      apiPremiumSetterRemove
        .postDataQuery({ userId: id })
        .then((res) => {
          setSelectedRow(null);
          handleGetUsers();
          dispatch(
            setIsOpenPremiumSetterModal({
              isOpen: false,
              func: null,
              id: null,
              isPremium: false,
              endDate: null,
            })
          );
        })
        .catch((error) => console.error(error));
    }
  };
  const deleteFunc = (id) => {
    apiDelete
      .deleteItemQuery({ userId: id })
      .then((res) => {
        setSelectedRow(null);
        handleGetUsers();
        dispatch(
          setIsOpenDeleteModal({
            isOpen: false,
            text: "",
            func: null,
            id: null,
          })
        );
      })
      .catch((error) => console.error(error));
  };
  const blockFunc = (id, row) => {
    apiBlock
      .updateDataQuery(
        row
          ? {
              userId: id,
              isBlockedUser: row?.templateData?.isBlocked ? false : true,
            }
          : {
              userId: id,
              isBlockedUser: selectedRow?.templateData?.isBlocked
                ? false
                : true,
            }
      )
      .then((res) => {
        handleGetUsers();
        setSelectedRow(null);
        dispatch(
          setIsOpenBlockModal({
            isOpen: false,
            text: "",
            func: null,
            id: null,
          })
        );
      });
  };
  const handleEditRow = (row, reason) => {
    if (reason === "status") {
      dispatch(
        setIsOpenStatusSetterModal({
          isOpen: true,
          func: statusSetter,
          id: row?.id,
          status: row?.status,
        })
      );
    }
    if (reason === "premium") {
      dispatch(
        setIsOpenPremiumSetterModal({
          isOpen: true,
          func: premiumSetter,
          id: row?.id,
          isPremium: row?.templateData?.role === "PREMIUM_USER" ? true : false,
          endDate: null,
        })
      );
    }
    if (reason === "premiumProlongation") {
      dispatch(
        setIsOpenPremiumSetterModal({
          isOpen: true,
          func: premiumSetter,
          id: row?.id,
          isPremium: false,
          endDate: null,
        })
      );
    }
    if (reason === "delete") {
      dispatch(
        setIsOpenDeleteModal({
          isOpen: true,
          text: "цього юзера?",
          func: deleteFunc,
          id: row.id,
        })
      );
    }
    if (reason === "edit") {
      setSelectedRow(row);
      dispatch(setIsOpenUserEditModal(true));
    }
    if (reason === "block") {
      setSelectedRow(row);
      if (row?.templateData?.isBlocked) {
        blockFunc(row?.id, row);
      } else {
        dispatch(
          setIsOpenBlockModal({
            isOpen: true,
            text: "цього користувача?",
            func: blockFunc,
            id: row.id,
          })
        );
      }
    }
  };
  useEffect(() => {
    handleGetUsers();
    // dispatch(setIsOpenSearchBar(true));
    // eslint-disable-next-line
  }, [offset]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <UserEditModal
        isOpenModal={isOpenUserEditModal}
        data={selectedRow?.templateData}
        refreshData={handleGetUsers}
      />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="success"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Користувачі
                </MDTypography>
                <SearchBar func={searchFunction} />
                <SearchIcon
                  sx={{
                    color: "white !important",
                    cursor: "pointer !important",
                  }}
                  onClick={() => dispatch(setIsOpenSearchBar(!isOpenSearchBar))}
                />
                {/* <Tooltip title="Додати валюту">
                  <AddIcon
                    onClick={() => console.log("CURRENCY ADD")}
                    sx={{
                      color: "white !important",
                      fontSize: "24px !important",
                      cursor: "pointer",
                      ":hover": {
                        color: "black !important",
                      },
                    }}
                  />
                </Tooltip> */}
              </MDBox>
              <MDBox pt={2}>
                {/* <DataTable
                  // canSearch
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                /> */}

                {!isLoading ? (
                  <>
                    {" "}
                    <TableContainer
                      component={Paper}
                      sx={{ borderRadius: "0px !important", mt: 0 }}
                    >
                      <Table>
                        <TableHead
                          sx={{
                            borderRadius: "0px !important",
                            display: "table-header-group",
                          }}
                        >
                          <TableRow>
                            {tableCells.map((cell, index) => (
                              <TableCell
                                key={cell.header + cell + index}
                                align={cell.align}
                                sx={({
                                  typography: { size, fontWeightBold },
                                }) => ({
                                  fontSize: size.xxs,
                                  fontWeight: fontWeightBold,
                                  textTransform: "uppercase",
                                  width: cell.width,
                                })}
                              >
                                {cell.header}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row) => (
                            <Row
                              key={row.id + row.tikker}
                              row={row}
                              editRow={handleEditRow}
                              refreshData={handleGetUsers}
                              userData={userData}
                            />
                          ))}
                        </TableBody>
                      </Table>
                      <Pagination
                        sx={{ display: "flex", justifyContent: "end", m: 1 }}
                        defaultPage={1}
                        siblingCount={0}
                        count={pagesCount}
                        shape="rounded"
                        page={offset / 10 + 1}
                        onChange={(event, value) => setOffset(value * 10 - 10)}
                      />
                    </TableContainer>
                  </>
                ) : (
                  <div style={{ marginTop: "10%", marginBottom: "10%" }}>
                    <Spinner type="small" />
                  </div>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
Row.propTypes = {
  row: propTypes.object,
};
export default TableUsers;
