/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import SignIn from "layouts/authentication/sign-in";
import Dashboard from "layouts/dashboard";

// @mui icons
import Icon from "@mui/material/Icon";

import TableUsers from "layouts/tables/Users";
import TableServices from "layouts/tables/Services";
import TablePosts from "layouts/tables/Posts";
import TableHistory from "layouts/tables/History";
import TableSubscriptions from "layouts/tables/Subscriptions";
import TableComplaints from "layouts/tables/Complaints";
import TableSupport from "layouts/tables/Support";

const routes = [
  {
    roleAccess: ["ADMIN", "SUPER_ADMIN"],
    type: "route",
    name: "sign",
    key: "sign",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/sign-in",
    component: <SignIn />,
  },
  {
    roleAccess: ["ADMIN", "SUPER_ADMIN"],
    type: "collapse",
    name: "Статистика",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    roleAccess: ["ADMIN", "SUPER_ADMIN"],
    type: "collapse",
    name: "Користувачі",
    key: "users",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/users",
    component: <TableUsers />,
  },
  {
    roleAccess: ["ADMIN"],
    type: "collapse",
    name: "Послуги",
    key: "services",
    icon: <Icon fontSize="small">shopping_cart</Icon>,
    route: "/services",
    component: <TableServices />,
  },
  {
    roleAccess: ["ADMIN"],
    type: "collapse",
    name: "Пости",
    key: "posts",
    icon: <Icon fontSize="small">chat</Icon>,
    route: "/posts",
    component: <TablePosts />,
  },
  {
    roleAccess: ["ADMIN"],
    type: "collapse",
    name: "Підписки",
    key: "subscriptions",
    icon: <Icon fontSize="small">workspace_premium</Icon>,
    route: "/subscriptions",
    component: <TableSubscriptions />,
  },
  {
    roleAccess: ["ADMIN"],
    type: "collapse",
    name: "Скарги",
    key: "complaints",
    icon: <Icon fontSize="small">warning</Icon>,
    route: "/complaints",
    component: <TableComplaints />,
  },
  {
    roleAccess: ["SUPER_ADMIN"],
    type: "collapse",
    name: "Історія дій",
    key: "history",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/history",
    component: <TableHistory />,
  },
  {
    roleAccess: ["ADMIN"],
    type: "collapse",
    name: "Підтримка",
    key: "support",
    icon: <Icon fontSize="small">contact_support</Icon>,
    route: "/support",
    component: <TableSupport />,
  },
  // {
  //   type: "collapse",
  //   name: "Валюти",
  //   key: "currencys",
  //   icon: <Icon fontSize="small">price_change</Icon>,
  //   route: "/currencys",
  //   component: <TablesCurrencys />,
  // },
  // {
  //   type: "collapse",
  //   name: "Курси обміну",
  //   key: "courses",
  //   icon: <Icon fontSize="small">currency_exchange</Icon>,
  //   route: "/courses",
  //   component: <TablesCourses />,
  // },
  // {
  //   type: "collapse",
  //   name: "Клієнти",
  //   key: "clients",
  //   icon: <Icon fontSize="small">people_alt</Icon>,
  //   route: "/clients",
  //   component: <TablesClients />,
  // },
  // {
  //   type: "collapse",
  //   name: "Статуси клієнтів",
  //   key: "clientsStatuses",
  //   icon: <Icon fontSize="small">military_tech</Icon>,
  //   route: "/clients-statuses",
  //   component: <TablesClientsStatuses />,
  // },
  // {
  //   type: "collapse",
  //   name: "Відділення",
  //   key: "departments",
  //   icon: <Icon fontSize="small">account_balance</Icon>,
  //   route: "/departments",
  //   component: <TablesDepartments />,
  // },
  // {
  //   type: "collapse",
  //   name: "Новини",
  //   key: "news",
  //   icon: <Icon fontSize="small">newspaper</Icon>,
  //   route: "/news",
  //   component: <TablesCourses />,
  // },
  // {
  //   type: "collapse",
  //   name: "Послуги",
  //   key: "services",
  //   icon: <Icon fontSize="small">home_repair_service</Icon>,
  //   route: "/services",
  //   component: <TablesCurrencys />,
  // },
  // {
  //   type: "collapse",
  //   name: "Партнери",
  //   key: "partners",
  //   icon: <Icon fontSize="small">handshake</Icon>,
  //   route: "/partners",
  //   component: <TablesCurrencys />,
  // },
  // {
  //   type: "collapse",
  //   name: "FAQ",
  //   key: "FAQ",
  //   icon: <Icon fontSize="small">question_mark</Icon>,
  //   route: "/FAQ",
  //   component: <TablesCourses />,
  // },
];

export default routes;
