/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// @mui icons

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { connect, useDispatch } from "react-redux";
import ApiService from "../../../helpers/apiHelper";
// eslint-disable-next-line

import {
  decryptData,
  encryptData,
  generateKeys,
} from "../../../helpers/cryptoHelper";
import Base64 from "crypto-js/enc-base64";
import Utf8 from "crypto-js/enc-utf8";
import hmacSHA512 from "crypto-js/hmac-sha512";
import { base } from "redux/thunk";
import { Spinner } from "components/Spinner";

function Basic({ getUserDataThunk, userData }) {
  // eslint-disable-next-line
  const [email, setEmail] = useState("");
  // eslint-disable-next-line
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [publicKey, setPublicKey] = useState("");
  const [privateKey, setPrivateKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // eslint-disable-next-line
  const apiAuth = new ApiService("auth/auth");
  const apiHash = new ApiService("auth/hash");
  const loader = (isLoad) => {
    setIsLoading(isLoad);
  };
  const handleSignIn = () => {
    loader(true);
    if (email.length !== 0 && password.length !== 0) {
      apiHash
        .sendRequest({ email: email, key: publicKey })
        .then(async (res) => {
          const decryptedKey = decryptData(res.result, privateKey);
          const encryptedPassword = await encryptData(password, decryptedKey);
          const tokenHeader = {
            alg: "HS512",
            typ: "JWT",
          };
          // const stringifiedHeader = btoa(JSON.stringify(tokenHeader));
          const stringifiedHeader = Utf8.parse(JSON.stringify(tokenHeader));
          let encodedHeader = base64url(stringifiedHeader);

          const data = {
            sub: `${email}:${encryptedPassword}`,
          };
          // const stringifiedData = btoa(JSON.stringify(data));
          const stringifiedData = Utf8.parse(JSON.stringify(data));
          let encodedData = base64url(stringifiedData);
          let token = encodedHeader + "." + encodedData;

          let secret =
            "UG7QRc1D/T4NnKjO2UWZ4dxv49/ZdtcwTu0XdR2wHsTt7iMqzez+UbGjOyPXwLyGrT6asLqwC7hmWEuQpV6sUg==";

          let signature = hmacSHA512(token, secret);
          signature = base64url(signature);
          let signedToken = token + "." + signature;
          const authHeaders = {
            Authorization: "Bearer " + signedToken,
          };

          apiAuth
            .authRequest(authHeaders)
            .then(async (res) => {
              await localStorage.setItem(
                "accessToken",
                `Bearer ${res.accessToken}`
              );
              await localStorage.setItem("refreshToken", `${res.refreshToken}`);
              getUserDataThunk(true, navigate, loader);
              // navigate("/dashboard");
              // setIsLoading(false);
            })
            .catch((error) => {
              loader(false);
              // if (typeof error !== "object") {
              //   enqueueSnackbar(error, { variant: "error" });
              // } else {
              //   if (Object.keys(error).length !== 0) {
              //     setErrors(error);
              //   } else
              //     enqueueSnackbar("Відсутній зв'язок із сервером", {
              //       variant: "error",
              //     });
              // }
            });
        })
        .catch((error) => {
          loader(false);
          // if (typeof error !== "object") {
          //   enqueueSnackbar(error, { variant: "error" });
          // } else {
          //   if (Object.keys(error).length !== 0) {
          //     setErrors(error);
          //   } else
          //     enqueueSnackbar("Відсутній зв'язок із сервером", {
          //       variant: "error",
          //     });
          // }
        });
    }
  };

  const keysGenerator = () => {
    const keys = generateKeys();
    if (keys) {
      setPublicKey(keys?.publicKey);
      setPrivateKey(keys?.privateKey);
    }
  };
  function base64url(source) {
    // Encode in classical base64
    let encodedSource = Base64.stringify(source);

    // Remove padding equal characters
    encodedSource = encodedSource.replace(/=+$/, "");

    // Replace characters according to base64url specifications
    encodedSource = encodedSource.replace(/\+/g, "-");
    encodedSource = encodedSource.replace(/\//g, "_");

    return encodedSource;
  }
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setTimeout(() => {
        keysGenerator();
      }, [200]);
    }
    return () => {
      mounted = false;
      clearTimeout(keysGenerator);
    }; // eslint-disable-next-line
  }, []);

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Увійти
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                onChange={(event) => {
                  setError("");
                  setEmail(event.target.value);
                }}
                type="email"
                label="Email"
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                onChange={(event) => {
                  setError("");
                  setPassword(event.target.value);
                }}
                type="password"
                label="Пароль"
                fullWidth
              />
            </MDBox>
            {error.length !== 0 ? (
              <MDBox display="flex" alignItems="center" ml={1}>
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="error"
                >
                  {error}
                </MDTypography>
              </MDBox>
            ) : null}

            <MDBox mt={4} mb={1}>
              {!isLoading ? (
                <MDButton
                  onClick={handleSignIn}
                  variant="gradient"
                  color="info"
                  fullWidth
                >
                  Вхід
                </MDButton>
              ) : (
                <div style={{ marginTop: "10px" }}>
                  <Spinner type="small" />
                </div>
              )}
            </MDBox>
            {/* <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox> */}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}
const mapStateToProps = (state) => {
  return {
    userData: state.global.userData,
  };
};
export default connect(mapStateToProps, {
  getUserDataThunk: base.getUserDataThunk,
})(Basic);
