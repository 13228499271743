import { useEffect } from 'react';

//Material
import CloseIcon from '@mui/icons-material/Close';

import { Box, Grid, Modal, Typography } from '@mui/material';

import { useDispatch } from 'react-redux';
import { LetsGoBtn2, LetsGoBtn3 } from '../../components/Buttons';
import { StyledLabel } from '../../components/StyledComponents';
import { setIsOpenDeleteModal } from '../../redux/mainReducer';

import starBlue from '../../assets/StarBlue.png';
import starGreen from '../../assets/StarGreen.png';

import { useState } from 'react';
import './DeleteModal.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  maxWidth: 900,
  bgcolor: 'background.paper',
  borderRadius: '20px',
  border: 'none',
  boxShadow: 24,
  padding: '50px 80px 50px 80px',
  overflow: 'scroll',
  height: 'inherit',
  maxHeight: '100vh',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none'
  }
};

function DeleteModal({ isOpenModal, func, text, id, creator }) {
  const [randomPhrase, setRandomPhrase] = useState(0);
  const dispatch = useDispatch();
  const phrases = [
    'Диму без вогню не буває, як і видалення без причини. Видаляємо',
    'Аби й очі не бачили! Видаляємо',
    'Подумай тричі й один раз відріж. Видаляємо'
  ];
  useEffect(() => {
    setRandomPhrase(Math.floor(Math.random() * phrases.length));  
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <Modal
        open={isOpenModal}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            return;
          }

          dispatch(setIsOpenDeleteModal({ isOpen: false, text: '', func: null, id: null,creatorId:null}));
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="bonfair_delete_modal"
      >
        <Box sx={style} className="bonfair_delete_modal-box">
          <img
            style={{ position: 'fixed', zIndex: '0', top: '0', left: '0' }}
            src={starGreen}
            alt="star-green"
          />
          <img
            style={{ position: 'fixed', zIndex: '0', bottom: '40px', right: '0' }}
            src={starBlue}
            alt="star-green"
          />
          <CloseIcon
            className="bonfair_delete_modal-close"
            style={{ position: 'absolute', right: '10px', top: '10px', cursor: 'pointer' }}
            onClick={() => {
              dispatch(setIsOpenDeleteModal({ isOpen: false, text: '', func: null, id: null,creatorId:null }));
            }}
          />

          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <StyledLabel className="deleteModal-title-text">
                {/* {translate('bonfair_service_modal')} */}
                {'Видалення'}
              </StyledLabel>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography
                variant="subtitle2"
                sx={{ mb: 2, fontSize: 16, fontFamily: 'Kyiv-Sans-Bold',textAlign:'center', maxWidth:"70%" }}
              >
                {`${phrases[randomPhrase]} ${text}`}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '20px auto 0px',
                maxWidth: '350px'
              }}
            >              
              <LetsGoBtn3
                size="large"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  func(id,creator);
                  dispatch(setIsOpenDeleteModal({ isOpen: false, text: '', func: null, id: null,creatorId:null }));
                }}
                width={150}
                style={{ textTransform: 'inherit' }}
                text="Видалити"
              />
              <LetsGoBtn2
                marginTop="0"
                size="large"
                onClick={() => {
                  dispatch(setIsOpenDeleteModal({ isOpen: false, text: '', func: null, id: null,creatorId:null }));
                }}
                width={150}
                style={{ textTransform: 'inherit' }}
                text="Залишити"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}


export default DeleteModal
