import { useState } from "react";

//Material
import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, Modal, Typography } from "@mui/material";
import starBlue from "../../assets/StarBlue.png";
import starGreen from "../../assets/StarGreen.png";

import { useDispatch } from "react-redux";

// import { useTranslate } from 'react-redux-multilingual';
import { DatePicker } from "@mui/x-date-pickers";
import { LetsGoBtn2, LetsGoBtn3 } from "components/Buttons";
import { StyledLabel } from "components/StyledComponents";
import dayjs from "dayjs";
import { setIsOpenPremiumSetterModal } from "../../redux/mainReducer";
// import './AuthModal.css';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  maxWidth: 900,
  bgcolor: "background.paper",
  borderRadius: "20px",
  border: "none",
  boxShadow: 24,
  padding: "50px 80px 50px 80px",
  overflow: "scroll",
  height: "inherit",
  maxHeight: "100vh",
  scrollbarWidth: "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
};

function PremiumSetterModal({ isOpenPremiumSetterModal }) {
  const dispatch = useDispatch();
  const [date, setDate] = useState(
    isOpenPremiumSetterModal?.endDate
      ? dayjs(new Date(isOpenPremiumSetterModal?.endDate))
      : dayjs(new Date().setDate(new Date().getDate() + 1))
  );
  const [dateError, setDateError] = useState(null);
  return (
    <div>
      <Modal
        open={isOpenPremiumSetterModal?.isOpen}
        onClose={(event, reason) => {
          if (reason === "backdropClick") {
            return;
          }

          dispatch(
            setIsOpenPremiumSetterModal({
              isOpen: false,
              func: null,
              id: null,
              isPremium: false,
            })
          );
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="bonfair_delete_modal"
      >
        <Box sx={style} className="bonfair_delete_modal-box">
          <img
            style={{ position: "fixed", zIndex: "0", top: "0", left: "0" }}
            src={starGreen}
            alt="star-green"
          />
          <img
            style={{
              position: "fixed",
              zIndex: "0",
              bottom: "40px",
              right: "0",
            }}
            src={starBlue}
            alt="star-green"
          />
          <CloseIcon
            className="bonfair_delete_modal-close"
            style={{
              position: "absolute",
              right: "10px",
              top: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              dispatch(
                setIsOpenPremiumSetterModal({
                  isOpen: false,
                  func: null,
                  id: null,
                  isPremium: false,
                })
              );
            }}
          />

          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <StyledLabel className="deleteModal-title-text">
                {/* {translate('bonfair_service_modal')} */}
                {"Преміум"}
              </StyledLabel>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  mb: 2,
                  fontSize: 16,
                  fontFamily: "Kyiv-Sans-Bold",
                  textAlign: "center",
                  maxWidth: "70%",
                }}
              >
                {isOpenPremiumSetterModal?.isPremium
                  ? "Забрати підписку у цього юзера?"
                  : "Надати підписку цьому юзеру?"}
              </Typography>
            </Grid>
            {!isOpenPremiumSetterModal?.isPremium ? (
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <DatePicker
                  value={dayjs(date)}
                  label="Оберіть кінцеву дату"
                  disablePast
                  minDate={
                    isOpenPremiumSetterModal?.endDate
                      ? dayjs(new Date(isOpenPremiumSetterModal?.endDate))
                      : dayjs(new Date().setDate(new Date().getDate() + 1))
                  }
                  onChange={(value, context) => {
                    if (context?.validationError) {
                      setDateError("error");
                    } else {
                      setDateError(null);
                    }
                    setDate(dayjs(new Date(dayjs(value))));
                  }}
                />
              </Grid>
            ) : null}

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "20px auto 0px",
                maxWidth: "350px",
              }}
            >
              <LetsGoBtn3
                size="large"
                disbled={dateError}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  if (!isOpenPremiumSetterModal?.isPremium) {
                    isOpenPremiumSetterModal?.func(
                      isOpenPremiumSetterModal?.id,
                      dayjs(date).format("YYYY-MM-DD")
                    );
                  } else {
                    isOpenPremiumSetterModal?.func(
                      isOpenPremiumSetterModal?.id,
                      null
                    );
                  }
                }}
                width={150}
                style={{ textTransform: "inherit" }}
                text={
                  isOpenPremiumSetterModal?.isPremium ? "Забрати" : "Надати"
                }
              />
              <LetsGoBtn2
                marginTop="0"
                size="large"
                onClick={() => {
                  dispatch(
                    setIsOpenPremiumSetterModal({
                      isOpen: false,
                      func: null,
                      id: null,
                      isPremium: false,
                    })
                  );
                }}
                width={150}
                style={{ textTransform: "inherit" }}
                text="Відмінити"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

export default PremiumSetterModal;
